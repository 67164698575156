import React, { ComponentProps, FunctionComponent, useEffect, useState } from "react";

import {
  Body1,
  colorsLight,
  H2,
  PrimaryButton,
  TextField,
  DateTextField,
  DATE_FORMAT,
  formatDateToISODate,
  parseDateFromISOStringWithoutFallback,
} from "@coherehealth/common";
import { Patient, usePatientSearch } from "@coherehealth/core-platform-api";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import isWithinInterval from "date-fns/isWithinInterval";
import parse from "date-fns/parse";
import { error as logError } from "logger";
import { assertIsApiError } from "util/api";
import { Grid, makeStyles, useTheme } from "@material-ui/core";

import { ReferralPatientSearchResult } from "./ReferralPatientSearchResult";

interface Props extends Pick<DialogProps, "open" | "onClose"> {}

const isDobInValidRange = (dateOfBirth: string) => {
  const date = parseDateFromISOStringWithoutFallback(dateOfBirth);
  return date && isWithinInterval(date, { start: new Date(1900, 1, 1), end: new Date(2100, 1, 1) });
};

export const PatientSearchModal: FunctionComponent<Props> = (props) => {
  const { open, onClose = () => {} } = props;
  const [showResults, setShowResults] = useState<boolean>(false);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [memberId, setMemberId] = useState<string>("");
  const [dobStr, setDobStr] = useState<string>("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const dateOfBirth = formatDateToISODate(parse(dobStr, DATE_FORMAT, new Date()));

  const { mutate: patientSearch, loading, error } = usePatientSearch({});

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (!open) {
      setShowResults(false);
      setMemberId("");
      setDobStr("");
      setAttemptedSubmit(false);
    }
  }, [open]);

  const updateSearch = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setAttemptedSubmit(true);
    if (Boolean(memberId) && isDobInValidRange(dateOfBirth)) {
      try {
        setPatients(await patientSearch({ memberId, dateOfBirth }));
        setShowResults(true);
      } catch (e) {
        assertIsApiError(e);
        setShowResults(true);
        if (e.message !== "Failed to fetch: Aborted") {
          logError(e);
        }
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { borderRadius: theme.spacing(2), width: theme.spacing(76) },
        }}
      >
        <MuiDialogTitle>
          <MuiIconButton
            className={classes.iconButton}
            onClick={(event) => {
              onClose(event, "backdropClick");
            }}
          >
            <CloseIcon />
          </MuiIconButton>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.dialogContent}>
          <Grid container spacing={2} component="form" onSubmit={updateSearch}>
            <Row data-public>
              <H2>Patient search</H2>
              <Body1 className={classes.lookupSubheader}>Find a patient: all information required.</Body1>
            </Row>
            <Row>
              <TextField
                error={attemptedSubmit && !memberId}
                helperText={attemptedSubmit && !memberId ? "Required" : ""}
                fullWidth
                label="Health plan member ID"
                value={memberId}
                onChangeValue={setMemberId}
                onClick={(event) => event.stopPropagation()}
              />
            </Row>
            <Row>
              <DateTextField
                error={attemptedSubmit && !isDobInValidRange(dateOfBirth)}
                helperText={attemptedSubmit && !isDobInValidRange(dateOfBirth) ? "Must have a valid date" : ""}
                fullWidth
                addDatePatternToLabel
                label={`Member date of birth`}
                value={dobStr}
                onChangeValue={setDobStr}
                onClick={(event) => event.stopPropagation()}
              />
            </Row>
            <Row>
              <PrimaryButton
                loading={loading}
                type="submit"
                onClick={(event) => event.stopPropagation()}
                className={classes.wideButton}
              >
                Search
              </PrimaryButton>
            </Row>
          </Grid>
        </MuiDialogContent>
        {showResults && !loading ? (
          <>
            <Divider />
            <MuiDialogContent className={classes.resultContent}>
              <ReferralPatientSearchResult error={error} patients={patients} showSummaryLink />
            </MuiDialogContent>
          </>
        ) : null}
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wideButton: {
    width: "33%",
    marginTop: theme.spacing(2),
    alignSelf: "center",
    marginBottom: theme.spacing(4),
  },
  dialogContent: {
    padding: `${theme.spacing(3)}px ${theme.spacing(7)}px`,
    overflow: "visible",
  },
  resultContent: {
    background: colorsLight.background.light,
    padding: theme.spacing(3, 7),
  },
  iconButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  lookupSubheader: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 0),
  },
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ textAlign: "center" }} item xs={12} {...props} />;
