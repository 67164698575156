import { FunctionComponent, useContext, useEffect, useState } from "react";
import { UserContext, User } from "./UserContext";
import { useFeature } from "@coherehealth/common";
import { useNavigate, useLocation } from "react-router-dom";
import { stringify as stringifyQueryString, parse as parseQueryString } from "qs";
import { useGetUserById, useValidateUniqueEmail } from "@coherehealth/core-platform-api";
import { isBackOfficeWithoutOrganization } from "util/user";
import RedirectToAuthBuilderComponent from "./RedirectToAuthBuilderComponent";
import useLogout from "hooks/useLogout";

const SSORedirectComponent: FunctionComponent<{ getAccessToken: () => Promise<string | undefined> }> = ({
  getAccessToken,
  children,
}) => {
  const { getUser } = useContext(UserContext);
  const logout = useLogout();
  const availityOnboardingFeatureOn = useFeature("availityOnboarding");
  const location = useLocation();
  const navigate = useNavigate();
  const [oktaUserData, setOktaUserData] = useState<User>();
  const [cohereId, setCohereId] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();
  useEffect(() => {
    getAccessToken().then((newToken) => {
      setAccessToken((prev) => newToken);
    });
  }, [getAccessToken]);
  const authorizationHeaders = { headers: { Authorization: `Bearer ${accessToken}` } };

  const { data: userData, refetch } = useGetUserById({
    requestOptions: authorizationHeaders,
    lazy: true,
    id: "",
  });

  const {
    data: validation,
    error: validationError,
    refetch: validationRefetch,
  } = useValidateUniqueEmail({
    requestOptions: authorizationHeaders,
    lazy: true,
  });

  useEffect(() => {
    if (availityOnboardingFeatureOn && accessToken) {
      getUser()?.then((oktaUser) => {
        refetch({ pathParams: { id: oktaUser.sub } });
        setOktaUserData(oktaUser);
      });
    }
  }, [availityOnboardingFeatureOn, getUser, refetch, accessToken]);

  useEffect(() => {
    if (userData?.isSsoUser && userData.ssoType === "NAVINET") {
      if (!validation && !validationError) {
        validationRefetch();
      } else if (validation?.unique) {
        //"SSO account email is not unique, please authenticate with Cohere credentials"
        logout();
      } else if (validationError?.status === 404) {
        //"Could not validate SSO account email, see server logs"
        logout();
      }
    }
  }, [userData, validationRefetch, validation, validationError, logout]);

  useEffect(() => {
    const queryParams = location.search;
    if (
      userData &&
      !userData.pendingOrganizationId &&
      oktaUserData &&
      isBackOfficeWithoutOrganization(oktaUserData) &&
      queryParams.includes("fromIdp") &&
      !queryParams.includes("cohereId")
    ) {
      const { fromIdp, ...otherParams } = parseQueryString(queryParams.replace(/^[?]/, ""));
      const returnTo = `${location.pathname}?${stringifyQueryString(otherParams)}`;
      const newQueryParams = stringifyQueryString({ returnTo });
      navigate(`/authenticated_organization_onboarding?${newQueryParams}`);
    } else if (userData && oktaUserData && queryParams.includes("fromIdp") && queryParams.includes("cohereId")) {
      const { fromIdp, ...otherParams } = parseQueryString(queryParams.replace(/^[?]/, ""));
      const cohereId = otherParams["cohereId"];
      if (cohereId !== undefined) {
        setCohereId(cohereId.toString());
      }
    }
  }, [userData, oktaUserData, location.search, navigate, location.pathname]);

  return cohereId !== undefined ? (
    <RedirectToAuthBuilderComponent cohereId={cohereId}>{children}</RedirectToAuthBuilderComponent>
  ) : (
    <>{children}</>
  );
};

export default SSORedirectComponent;
