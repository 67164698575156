import {
  ProcedureCodeWithId,
  FormTable,
  Body3,
  TextField,
  Tooltip,
  Chip,
  ProcedureCodeWithAggregate,
  withAggregate,
  InlineButton,
  colorsLight,
  SingleSelectDropdown,
  MultiSelectDropdown,
} from "@coherehealth/common";
import { ProcedureCode } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Box, Grid, makeStyles, styled, useTheme } from "@material-ui/core";
import { ComponentProps, Dispatch, SetStateAction, useState } from "react";
import { PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP } from "util/serviceRequest";
import MuiErrorIcon from "@material-ui/icons/Error";
import { ServiceRequestFormContent, UnitsInitialWidth } from "common/SharedServiceRequestFormComponents";
import DeleteIcon from "@material-ui/icons/Delete";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import usePxAttributeOptions from "hooks/usePxAttributeOptions";

interface Props {
  procedureCodes: ProcedureCodeWithId[];
  attemptedSubmit?: boolean;
  onUpdatePxCode: (newUnits: string, code: string, groupId: string | undefined) => void;
  onRemovePxCode: (code: string, groupId: string | undefined) => void;
  updatePxCodeSelectedDetails?: (index: number, pxCode: ProcedureCodeWithId[], selectedDetails: string[]) => void;
  showPxBasedAttributedField?: boolean;
  showPxAttributeValidation?: boolean;
  index: number;
  formContent?: ServiceRequestFormContent;
}

export interface CssProps {
  isFaxAuthBuilderFlow?: boolean;
}

const ADD_MORE_UNITS = "Add more units";
export default function ProcedureCodeTable({
  procedureCodes,
  attemptedSubmit,
  onUpdatePxCode,
  onRemovePxCode,
  updatePxCodeSelectedDetails,
  showPxBasedAttributedField,
  showPxAttributeValidation,
  index,
  formContent,
}: Props) {
  const theme = useTheme();
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  const classes = useStyles({ isFaxAuthBuilderFlow });
  const invalidUnitsMessage = (units: number | undefined): string => {
    if ((Number.isNaN(Number(units)) || units === null || units === 0) && attemptedSubmit) {
      return ADD_MORE_UNITS;
    }
    return "";
  };

  function RemoveButton({ rowIndex }: { rowIndex: number }) {
    return (
      <Box component="span" className={classes.pxRemoveBtnSpan}>
        <InlineButton
          className="removeButton"
          onClick={() => {
            const { code, groupId } = procedureCodes[rowIndex];
            onRemovePxCode(code, groupId);
          }}
          startIcon={<DeleteIcon />}
        >
          <Box component="span" style={{ display: isFaxAuthBuilderFlow ? "none" : "block" }}>
            Remove
          </Box>
        </InlineButton>
      </Box>
    );
  }

  const { options: pxAttributeOptions } = usePxAttributeOptions(procedureCodes);

  const units_on_px_contents = (
    id: string,
    rowIndex: number,
    code: string,
    units?: number,
    unitType?: string,
    description?: string,
    groupId?: string,
    attributeType?: string,
    relationshipTo?: string[],
    selectedDetails?: string[],
    isFocused?: boolean,
    setIsFocused?: Dispatch<SetStateAction<boolean>>
  ) => {
    const isAttributeTypeFreeText = attributeType === "Free text";
    return [
      {
        contents: (
          <>
            <Grid
              item
              container
              xs={12}
              className={!isAttributeTypeFreeText ? classes.tableContainerRow : classes.tableContainer}
              spacing={2}
            >
              <Grid
                item
                className={classes.ChipContainer}
                style={{ paddingTop: isAttributeTypeFreeText ? "25px" : undefined }}
              >
                <Chip style={{ height: theme.spacing(3) }} label={code} dataPublic={true} />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.tableFlexAuto}
                  error={Boolean(invalidUnitsMessage(units))}
                  type="text"
                  value={units || ""}
                  data-testid={"unit-field"}
                  inputClasses={{}}
                  style={{ width: UnitsInitialWidth }}
                  onChangeValue={function (newUnits): void {
                    onUpdatePxCode(newUnits, code, groupId);
                  }}
                  label={
                    unitType && PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]
                      ? `${PROCEDURE_CODE_UNIT_TYPE_LABEL_MAP[unitType]}s`
                      : "Units"
                  }
                  InputProps={{
                    endAdornment: Boolean(invalidUnitsMessage(units)) ? (
                      <Tooltip
                        classes={{ tooltip: classes.customWidth }}
                        title={invalidUnitsMessage(units)}
                        placement="top"
                      >
                        <ErrorIcon className={classes.customWidth} />
                      </Tooltip>
                    ) : undefined,
                  }}
                  dataPublic={true}
                />
              </Grid>
              {showPxBasedAttributedField && attributeType === "Single-select" && (
                <Grid item xs={4}>
                  {/* Show SingleSelectDropdown if attributeType is "Single-select" */}
                  <SingleSelectDropdown
                    options={pxAttributeOptions.get(code)}
                    disabled={
                      formContent?.authStatus === "APPROVED" || formContent?.authStatus === "PARTIALLY_APPROVED"
                    }
                    label={
                      isFocused && selectedDetails?.length
                        ? "Details"
                        : isFocused
                        ? "Details"
                        : !isFocused && selectedDetails?.length
                        ? "Details"
                        : "Select details"
                    }
                    onFocus={() => setIsFocused?.(true)}
                    onBlur={() => setIsFocused?.(false)}
                    error={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]}
                    helperText={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0] ? "Required" : ""}
                    value={selectedDetails?.[0] || ""}
                    onChange={function (selectedDetails): void {
                      if (updatePxCodeSelectedDetails !== undefined) {
                        updatePxCodeSelectedDetails(index, procedureCodes, [selectedDetails]);
                      }
                    }}
                  />
                </Grid>
              )}
              {showPxBasedAttributedField && attributeType === "Multi-select" && (
                <Grid item xs={4}>
                  <MultiSelectDropdown
                    options={pxAttributeOptions.get(code)}
                    selectedValues={Array.from(new Set(selectedDetails))}
                    onChange={function (selectedDetails): void {
                      if (updatePxCodeSelectedDetails !== undefined) {
                        updatePxCodeSelectedDetails(index, procedureCodes, selectedDetails);
                      }
                    }}
                    disabled={
                      formContent?.authStatus === "APPROVED" || formContent?.authStatus === "PARTIALLY_APPROVED"
                    }
                    label={
                      isFocused && selectedDetails?.length
                        ? "Details"
                        : isFocused
                        ? "Details"
                        : !isFocused && selectedDetails?.length
                        ? "Details"
                        : "Select details"
                    }
                    onFocus={() => setIsFocused?.(true)}
                    onBlur={() => setIsFocused?.(false)}
                    error={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.length}
                    helperText={
                      attemptedSubmit && showPxAttributeValidation && !selectedDetails?.length ? "Required" : ""
                    }
                  />
                </Grid>
              )}
              {showPxBasedAttributedField && isAttributeTypeFreeText && (
                <Grid item xs={4}>
                  <TextField
                    label={
                      isFocused && selectedDetails?.length
                        ? "Details"
                        : isFocused
                        ? "Details"
                        : !isFocused && selectedDetails?.length
                        ? "Details"
                        : "Explain why an unlisted code is needed"
                    }
                    disabled={
                      formContent?.authStatus === "APPROVED" || formContent?.authStatus === "PARTIALLY_APPROVED"
                    }
                    fullWidth
                    multiline
                    value={selectedDetails?.[0] || ""}
                    onFocus={() => setIsFocused?.(true)}
                    onBlur={() => setIsFocused?.(false)}
                    error={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]}
                    helperText={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0] ? "Required" : ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = event.target.value;
                      updatePxCodeSelectedDetails?.(index, procedureCodes, newValue ? [newValue] : []);
                    }}
                  />
                </Grid>
              )}
              <Grid item className={classes.pxDescriptionContainer}>
                <Box component="span" className={classes.pxDescriptionSpan}>
                  <Body3 data-public>{description}</Body3>
                </Box>
                <RemoveButton key={`removeBtn${id}`} rowIndex={rowIndex} />
              </Grid>
            </Grid>
          </>
        ),
        width: "90%",
      },
    ];
  };
  const pxCodesMap: Map<string, ProcedureCode> = procedureCodes.reduce((map, pxCode) => {
    map.set(pxCode.code, pxCode);
    return map;
  }, new Map());
  const allPxCodes = Array.from(pxCodesMap.values());
  // State to track whether the SingleSelectDropdown is currently focused.
  // This is used to change the label from "Select details" to "Details" when the dropdown is focused.
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <Row>
        {procedureCodes && procedureCodes.length > 0 && (
          <FormTable<ProcedureCodeWithAggregate>
            responsive={true}
            noPadding={true}
            withRowDivider={false}
            tableData={allPxCodes.map((px) => withAggregate(px))}
            headers={[]}
            TableRowProps={{ hover: true }}
            onRemoveRow={(rowIndex: number) => {
              const { code, groupId } = procedureCodes[rowIndex];
              onRemovePxCode(code, groupId);
            }}
          >
            {({
              rowData: {
                id,
                code,
                units,
                unitType,
                description,
                groupId,
                attributeType,
                relationshipTo,
                selectedDetails,
              },
              rowIndex,
            }) =>
              units_on_px_contents(
                id,
                rowIndex,
                code,
                units,
                unitType,
                description,
                groupId,
                attributeType,
                relationshipTo,
                selectedDetails,
                isFocused,
                setIsFocused
              )
            }
          </FormTable>
        )}
      </Row>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 250,
  },
  tableContainerRow: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      "& .removeButton": {
        opacity: 1,
      },
    },
    "& .removeButton": {
      opacity: 0,
      color: colorsLight.font.secondary,
      transition: theme.transitions.create("opacity"),
      minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    },
  },
  tableContainer: {
    display: "flex",
    flexWrap: "wrap",
    "&:hover": {
      "& .removeButton": {
        opacity: 1,
      },
    },
    "& .removeButton": {
      opacity: 0,
      color: colorsLight.font.secondary,
      transition: theme.transitions.create("opacity"),
      minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    },
  },
  tableFlexAuto: {
    flex: `0 0 ${UnitsInitialWidth}px`,
  },
  ChipContainer: {
    flex: `0 0 96px`,
    width: "16px",
  },
  pxDescriptionContainer: {
    display: "flex",
    flex: "1 1 30%",
    minWidth: "200px",
    alignItems: "center",
  },
  pxDescriptionSpan: {
    flex: `1 1 99%`,
  },
  pxRemoveBtnSpan: {
    flex: "0 1 auto",
    minWidth: (props: CssProps) => (props.isFaxAuthBuilderFlow ? "25px" : "80px"),
    marginLeft: theme.spacing(2),
  },
}));
