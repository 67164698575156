import { useState, useEffect, useCallback } from "react";
import { PatientStayDate, useGetAllHistoricPatientStayDates } from "@coherehealth/core-platform-api";
import { PaginateProps, useFeature } from "@coherehealth/common";
import { aggregateStayDateByCommonFields } from "components/ServiceRequest/PatientStay/PatientStays";
import { stayToTableEntryValueMapper } from "./index";

export interface PatientStayDateTableColumnData {
  dates: string;
  days: string;
  requestedLoc: string;
  approvedLoc: string;
  status: string;
  isAmended?: boolean;
}

export interface PatientStayDateTableData {
  record: PatientStayDateTableColumnData;
  data: PatientStayDateTableColumnData[];
}

export default function useFetchPatientStayDates(authorizationId?: string, stayDatesToAppend?: PatientStayDate[]) {
  const { refetch: getPatientStayDatesHistory } = useGetAllHistoricPatientStayDates({
    authorizationId: authorizationId || "",
    lazy: true,
  });

  const expandedReviewStayDatesEdits = useFeature("expandedReviewStayDatesEdits");
  const [groupedStayDates, setGroupedStayDates] = useState<PatientStayDateTableData[] | null>(null);
  const [paginatedStayDates, setPaginatedStayDates] = useState<PatientStayDateTableData[] | null>(null);
  const [paginateParams, setPaginatedParams] = useState<PaginateProps>({
    max: 6,
    offset: 0,
  });

  const groupAmendStayDates = useCallback(
    (mappedStayDates?: PatientStayDateTableColumnData[]): PatientStayDateTableData[] => {
      const groupedData: PatientStayDateTableData[] = [];
      const sortedMappedStayDateRanges = sortMappedStayDates(mappedStayDates);

      // Temporary object to hold the current group's data
      let currentGroup: PatientStayDateTableData | null = null;

      sortedMappedStayDateRanges?.forEach((stayDate) => {
        // Parsing the start and end date from the dates string
        const [startDate, endDate] = stayDate.dates.split(" - ");
        const start = new Date(startDate);
        const end = endDate ? new Date(endDate) : start;

        // Check if the stayDate should start a new group or continue the current one
        if (!currentGroup || start > new Date(currentGroup.record.dates.split(" - ")[1])) {
          // Finish the current group and start a new one
          if (currentGroup) {
            groupedData.push(currentGroup);
          }
          currentGroup = {
            record: { ...stayDate },
            data: [],
          };
        } else {
          // If it's an approved status within the range, update the currentGroup's record to extend the date range if needed
          const currentEnd = new Date(currentGroup.record.dates.split(" - ")[1]);
          if (end > currentEnd) {
            currentGroup.record.dates = `${formatDate(start)} - ${formatDate(end)}`;
            currentGroup.record.days = calculateDaysDifference(start, end) + " day(s)";
          }
        }
        if (stayDate?.isAmended) {
          // Add to current group's data array
          currentGroup.data.push({ ...stayDate, days: "1 day" }); // Assuming each amendment affects a single day
        }
      });

      // Push the last group if exists
      if (currentGroup) {
        groupedData.push(currentGroup);
      }

      return groupedData;
    },
    []
  );

  const sortMappedStayDates = (mappedStayDates?: PatientStayDateTableColumnData[]) => {
    return mappedStayDates?.sort((a, b) => {
      const aDate = new Date(a.dates.split(" - ")[0]);
      const bDate = new Date(b.dates.split(" - ")[0]);

      // Sort by date or amended status if dates are equal
      return aDate.getTime() !== bDate.getTime()
        ? aDate.getTime() - bDate.getTime()
        : a.isAmended === b.isAmended
        ? 0
        : a.isAmended
        ? 1
        : -1;
    });
  };

  const formatDate = (date: Date): string => {
    return date.toISOString().split("T")[0];
  };

  const calculateDaysDifference = (start: Date, end: Date): number => {
    return Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  };

  const fetchPatientStayDates = useCallback(async () => {
    if (!authorizationId || !expandedReviewStayDatesEdits) {
      return;
    }
    const patientStayDatesFromService = (await getPatientStayDatesHistory()) || [];
    const allPatientStayDates = patientStayDatesFromService.concat(stayDatesToAppend || []);

    const nonAmendedStayDates = allPatientStayDates?.filter((stayDate) => stayDate.reviewStatus !== "AMENDED");
    const amendedStayDates = allPatientStayDates?.filter((stayDate) => stayDate.reviewStatus === "AMENDED");

    const nonAmendedAggregate = aggregateStayDateByCommonFields(nonAmendedStayDates || []);
    const amendedAggregate = aggregateStayDateByCommonFields(amendedStayDates || []);

    const patientDatesAggregate = [...nonAmendedAggregate, ...amendedAggregate];

    const mappedPatientStayDates = patientDatesAggregate.map((stayDate) =>
      stayDate.reviewStatus === "AMENDED"
        ? { isAmended: true, ...stayToTableEntryValueMapper(stayDate) }
        : { isAmended: false, ...stayToTableEntryValueMapper(stayDate) }
    );
    const groupByStayDates = groupAmendStayDates(mappedPatientStayDates);
    setGroupedStayDates(groupByStayDates.reverse() || []);
  }, [
    authorizationId,
    expandedReviewStayDatesEdits,
    getPatientStayDatesHistory,
    stayDatesToAppend,
    groupAmendStayDates,
  ]);

  useEffect(() => {
    fetchPatientStayDates();
  }, [fetchPatientStayDates]);

  useEffect(() => {
    if (groupedStayDates !== null) {
      const start = paginateParams.offset ?? 0;
      const max = paginateParams.max ?? 6;
      const end = start + max;
      const currentSlice = groupedStayDates.slice(start, end) || [];
      setPaginatedStayDates(currentSlice);
    }
  }, [paginateParams, groupedStayDates]);

  return {
    groupedStayDates: paginatedStayDates,
    paginateParams,
    setPaginatedParams,
    expandedReviewStayDatesEdits,
  };
}
