import React, { FunctionComponent, ComponentProps, FocusEventHandler, Dispatch, SetStateAction } from "react";

import {
  Body2,
  Chip,
  SelectOptionsHook,
  MultipleSelect,
  useLazyLoadingQueryOptionsHook,
  useFeature,
} from "@coherehealth/common";
import { ProcedureCode, useGetProcedureCodes } from "@coherehealth/core-platform-api";
import Grid from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { AutocompleteGetTagProps } from "@material-ui/lab/Autocomplete";
import useDoPalCheckOnServiceRequestForm from "components/ServiceRequest/ServiceRequestForm/hooks/useDoPalCheckOnServiceRequestForm";

import { ServiceRequestFormContent, ContinuationFormContent } from "common/SharedServiceRequestFormComponents";
import { NonCohereCodes } from "components/AuthBuilder/common";
import ProcedureCodeWarning from "./ProcedureCodeWarning";

interface Props {
  error: boolean;
  procedureCodes: ProcedureCode[];
  setProcedureCodes: (pxs: ProcedureCode[]) => void;
  isInpatient?: boolean;
  disablePALCheck?: boolean;
  /**
   * Cannot delete these procedure codes
   */
  readOnlyPxCodes?: ProcedureCode[];
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (pxs: ProcedureCode[]) => void;
  nonPalProcedureCodes: ProcedureCode[];
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  nonCohereProcedureCodes: NonCohereCodes[];
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
  setNonPalPDFOpen: Dispatch<SetStateAction<boolean>>;
  patientId: string;
  label?: string;
  filteredProcedureCodes?: ProcedureCode[];
  formContent: ServiceRequestFormContent | ContinuationFormContent;
  /**
   * When enabled, if a user selects an already-selected procedure code that code will be unselected instead
   * of the default behavior of adding a duplicate selection
   */
  blockDuplicateSelections?: boolean;
  onBlur?: FocusEventHandler;
  omitSelectionChips?: boolean;
  autoFocus?: boolean;
  hidePalWarnings?: boolean;
  disabled?: boolean;
  doPalOrCrdCheck?: (payload: { desiredProcedureCodes: ProcedureCode[] }) => Promise<void>;
}

const ProcedureCodeSelect: FunctionComponent<Props> = ({
  error,
  procedureCodes,
  setProcedureCodes,
  isInpatient,
  readOnlyPxCodes,
  palProcedureCodes,
  setPalProcedureCodes,
  nonPalProcedureCodes,
  setNonPalProcedureCodes,
  nonCohereProcedureCodes,
  setNonCohereProcedureCodes,
  setNonPalPDFOpen,
  patientId,
  disablePALCheck,
  label,
  filteredProcedureCodes,
  blockDuplicateSelections,
  onBlur,
  omitSelectionChips,
  autoFocus,
  formContent,
  hidePalWarnings,
  disabled,
  doPalOrCrdCheck,
}) => {
  const useProcedures: SelectOptionsHook<ProcedureCode | null> = (selectOptionsParams) =>
    useLazyLoadingQueryOptionsHook({
      useGetHook: useGetProcedureCodes,
      additionalQueryParams: { clinicalServiceId: formContent?.clinicalService?.id },
      ...selectOptionsParams,
    });

  const { doPalCheck } = useDoPalCheckOnServiceRequestForm({
    patientId,
    isInpatient: isInpatient || false,
    startDate: formContent.startDate,
    palProcedureCodes,
    setProcedureCodes,
    setPalProcedureCodes,
    setNonPalProcedureCodes,
    setNonCohereProcedureCodes,
  });

  const nonPalWarningNeeded =
    !hidePalWarnings && nonPalProcedureCodes && nonPalProcedureCodes.length > 0 && !isInpatient;
  const nonCohereCodesWarningNeeded = !hidePalWarnings && nonCohereProcedureCodes && nonCohereProcedureCodes.length > 0;
  // const authSubmissionVendorEnabled = useFeature("authSubmissionVendorOnPalCheckEnabled");
  let showDecisionedEditWarning = false;

  const shouldUseCrdCheck = useFeature("useCrdCheckOnContinuations");

  const noOptionsText = formContent.clinicalService?.name
    ? `This code can’t be added to the ${formContent.clinicalService.name} service. You can submit a separate request or cancel this one and start over.`
    : `This code can’t be added to the service. You can submit a separate request or cancel this one and start over.`;

  return (
    <Grid container spacing={2}>
      <Row>
        <MultipleSelect
          fullWidth
          error={error}
          autoHighlight
          label="Procedure codes"
          emptyLabel={label || "Enter 5 digit procedure codes"}
          placeholder={procedureCodes.length > 0 ? "Enter another procedure code" : ""}
          customGetOptionSelected={(aCode, bCode) => {
            return blockDuplicateSelections ? aCode?.code === bCode?.code : false;
          }}
          useOptions={
            filteredProcedureCodes
              ? () => ({
                  options: filteredProcedureCodes,
                  optionsLoading: false,
                  filterOptions: (options, state) =>
                    options.filter((option) => option?.code.includes(state.inputValue)),
                })
              : useProcedures
          }
          noOptionsText={noOptionsText}
          selectedValue={procedureCodes}
          getOptionLabel={(px) => `${px?.code}`}
          TextFieldProps={{ warningHelperText: showDecisionedEditWarning }}
          renderTags={
            // Dont render the Chips if omitSelectionChips is set
            omitSelectionChips
              ? undefined
              : (value, getTagProps) =>
                  value.map((procedureCode, index) =>
                    procedureCode ? (
                      <PxChip
                        disablePALCheck={disablePALCheck}
                        disabled={readOnlyPxCodes?.map((code) => code.code).includes(procedureCode.code)}
                        px={procedureCode}
                        index={index}
                        key={`${procedureCode.code}-${index}`}
                        getTagProps={getTagProps}
                        isInpatient={isInpatient || false}
                      />
                    ) : null
                  )
          }
          renderOption={(px) => (
            <Body2Unbold>
              <b>{px?.code}</b> - {px?.description || px?.label}
            </Body2Unbold>
          )}
          setSelectedValue={async (pxs) => {
            // Filter out nulls
            let realPxs = pxs.filter((p): p is ProcedureCode => Boolean(p));
            //add nonCohereProcdureCodes to realPxs being sent to PAL Check
            realPxs = realPxs.concat(addNonCohereCodesToRealPxs(nonCohereProcedureCodes));
            const isOONExceptionRequest =
              formContent.userSelectedOONException ||
              formContent.facilityOONExceptionRequired ||
              formContent.performingProviderOONExceptionRequired ||
              false;
            if (!!doPalOrCrdCheck && shouldUseCrdCheck) {
              setProcedureCodes(realPxs);
              await doPalOrCrdCheck({ desiredProcedureCodes: realPxs });
            } else {
              await doPalCheck(realPxs, isOONExceptionRequest, formContent.userSelectedNonPalCode);
            }
          }}
          onBlur={onBlur}
          omitSelectionChips={omitSelectionChips}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      </Row>
      {nonCohereCodesWarningNeeded && (
        <Row>
          <ProcedureCodeWarning
            nonCohereProcedureCodes={nonCohereProcedureCodes}
            setNonPalPDFOpen={setNonPalPDFOpen}
            message={
              nonCohereProcedureCodes &&
              nonCohereProcedureCodes.length === 1 &&
              nonCohereProcedureCodes[0].procedureCode.length === 1
                ? "This code requires submission through another organization"
                : "These codes require submission through another organization"
            }
          />
        </Row>
      )}
      {nonPalWarningNeeded && (
        <Row>
          <ProcedureCodeWarning
            nonPalProcedureCodes={nonPalProcedureCodes}
            message={
              nonPalProcedureCodes && nonPalProcedureCodes.length === 1
                ? "This code doesn't require submission"
                : "These codes don't require submission"
            }
            setNonPalPDFOpen={setNonPalPDFOpen}
          />
        </Row>
      )}
    </Grid>
  );
};

const PxChip: FunctionComponent<{
  px: ProcedureCode;
  disablePALCheck?: boolean;
  index: number;
  getTagProps: AutocompleteGetTagProps;
  isInpatient: boolean;
  disabled?: boolean;
}> = ({ px, index, disablePALCheck, getTagProps, isInpatient, disabled }) => {
  // set onDelete to undefined to remove the "X"
  const disabledProps = disabled ? { disabled: true, onDelete: undefined } : {};

  return (
    <Chip label={px.code} key={px.code} size="small" type={"default"} {...getTagProps({ index })} {...disabledProps} />
  );
};

export default ProcedureCodeSelect;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Body2Unbold = styled(Body2)({
  fontWeight: "normal",
});

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

function addNonCohereCodesToRealPxs(nonCohereProcedureCodes: NonCohereCodes[]) {
  let codesToAdd: ProcedureCode[] = [];
  nonCohereProcedureCodes.forEach((codeAndVendor) => {
    codeAndVendor?.procedureCode?.forEach((pxCode) => {
      codesToAdd.push(pxCode);
    });
  });
  return codesToAdd;
}
