import React, { useState, MouseEvent, useContext, useCallback, useEffect, ComponentProps } from "react";
import { useMatch, Link, useLocation } from "react-router-dom";

import {
  Avatar,
  Body1,
  Body2,
  Caption,
  environmentAbbreviation,
  H4,
  InlineButton,
  useFeature,
  useStableUniqueId,
} from "@coherehealth/common";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import LaunchIcon from "@material-ui/icons/Launch";
import HeadsetMic from "@material-ui/icons/HeadsetMic";
import IntegrationBulkFunctionModal from "components/IntegrationBulkFunctionModal";
import PaperlessSettingsMenuItem from "./PaperlessSettingsMenuItem";
import { useAuthorized } from "authorization";
import { useAuth } from "hooks/useAuth";
import useLogout from "hooks/useLogout";
import routes from "routes";
import { User, UserContext } from "UserContext";
import HeaderContainer from "./HeaderContainer";
import PatientInfo from "./PatientInfo";
import config from "api/config";
import { appHeaderHeight, PreviewStatus } from "@coherehealth/common";
import { isMobileOnly } from "react-device-detect";
import CurrentUserMenuItem from "./CurrentUserMenuItem";
import HeaderLogo from "./HeaderLogo";
import { useMediaQuery } from "@material-ui/core";

type StyleProps = {
  isRotated?: boolean;
};
const useStyles = makeStyles((theme) => ({
  // We need a container with position relative so we can absolute position the HeaderDrowndownContainer dropdown on the right
  relativeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  dropdownMenuButtonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
  },
  accountInfoDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  linkTextContainer: {
    display: "flex",
    flexDirection: "row",
  },
  headsetMicIcon: {
    color: theme.palette.info.light,
  },
  bannerIcon: {
    padding: theme.spacing(3),
  },
  userAvatar: {
    marginRight: theme.spacing(2),
  },
  launchIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  expandIcon: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create("transform"),
    transform: (props: StyleProps) => (props.isRotated ? "rotate(180deg)" : "rotate(0deg)"),
  },
  inlineButton: {
    position: "absolute",
    left: 0,
  },
  menuButtonText: {
    padding: theme.spacing(1),
  },
  menuPaper: {
    borderColor: theme.palette.divider,
    borderRadius: 10,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.08)",
    boxSizing: "border-box",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "333px",
  },
  menuItem: {
    padding: theme.spacing(1), // default is 2
  },
}));

function AppHeader() {
  const theme = useTheme();
  const matchPatientId = useMatch(routes.AUTH_BUILDER);
  const matchReferralRoute = useMatch(routes.REFERRAL_BUILDER);
  const referralPatientId = matchReferralRoute?.params.patientId || "";
  const matchPatientIdPatientMgmt = useMatch(routes.PATIENT_MANAGEMENT_EDIT_PATIENT);

  const matchSrId = useMatch(routes.SERVICE_REQUEST);
  const matchSrIdOnFaxEditWorkflow = useMatch(routes.EDIT_SERVICE_REQUEST_FAX);
  const patientId = matchPatientId?.params.patientId || "";
  const srId = matchSrId?.params.serviceRequestId || matchSrIdOnFaxEditWorkflow?.params.serviceRequestId || "";
  const classes = useStyles({ isRotated: false });

  const currentPage = useLocation().pathname;
  const mobileViewEnabled = useFeature("mobileDashboardViewable");

  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);

  // Checks if the screen width is 1024px or larger
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up(1024));

  // Checks if the screen width is 200px or larger
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.up(200));

  return (
    <HeaderContainer
      height={appHeaderHeight(isDesktopScreen, isVerySmallScreen)}
      hasEnvIndicator
      isVerySmallScreen={isVerySmallScreen}
      isDesktopScreen={isDesktopScreen}
    >
      <div className={classes.relativeContainer}>
        {[
          routes.MY_ORGANIZATION,
          routes.ORGANIZATION_MANAGEMENT_LIST,
          routes.INTEGRATION_MANAGEMENT,
          routes.PATIENT_MANAGEMENT,
          routes.INTEGRATION_OBSERVABILITY_MANAGEMENT,
        ].includes(currentPage) || matchPatientIdPatientMgmt ? (
          <InlineButton
            to={routes.DASHBOARD}
            component={Link}
            startIcon={<NavigateBeforeIcon fontSize="large" />}
            className={classes.inlineButton}
          >
            Back to Dashboard
          </InlineButton>
        ) : (
          ""
        )}
        {(matchSrId || matchSrIdOnFaxEditWorkflow) && srId !== null ? (
          <PatientInfo serviceRequestId={srId} />
        ) : (
          matchPatientId && patientId && <PatientInfo patientId={patientId} />
        )}
        {matchReferralRoute && referralPatientId && <PatientInfo patientId={referralPatientId} />}

        {config.PREVIEW_ID && (
          <PreviewStatus
            previewId={config.PREVIEW_ID}
            gitHash={config.CURRENT_GIT_HASH}
            pullRequestNumber={config.PR_NUMBER}
          />
        )}
        <HeaderLogo classes={classes} isDesktopScreen={isDesktopScreen} isVerySmallScreen={isVerySmallScreen} />

        {(!isMobileOnly || !mobileViewEnabled) && (
          <DropdownMenuButtonContainer>
            <SupportButton />
            <MyAccountButton />
          </DropdownMenuButtonContainer>
        )}
        <IntegrationBulkFunctionModal open={integrationModalOpen} onClose={() => setIntegrationModalOpen(false)} />
      </div>
    </HeaderContainer>
  );
}

export const DropdownMenuButtonContainer = (props: ComponentProps<"div">) => {
  const classes = useStyles({ isRotated: false });
  return <div className={classes.dropdownMenuButtonContainer} {...props} />;
};

export function MyAccountButton() {
  const { authState } = useAuth();
  const { getUser } = useContext(UserContext);
  const logout = useLogout();

  const enableUserToUpdateOwnContactInfo = useFeature("enableUserToUpdateOwnContactInfo");

  const canViewFeatureFlagsPage = useAuthorized("FEATURE_FLAG_PAGE");
  const isServiceOps = useAuthorized("SERVICE_OPS_ICON");
  const canEditELetterOptInStatus = useAuthorized("CAN_OPT_IN_FOR_E_LETTERS");
  const canViewMyOrgPage = useAuthorized("MY_ORGANIZATION_PAGE");
  const canViewOrgMgmtPage = useAuthorized("ORGANIZATION_MANAGEMENT_PAGE");
  const canViewIntegrationManagement = useAuthorized("INTEGRATION_MANAGEMENT_PAGE");
  const canViewIntegrationObservability = useAuthorized("INTEGRATION_OBSERVABILITY_PAGE");
  const canViewPatientManagement = useAuthorized("PATIENT_MANAGEMENT_PAGE");
  const canViewConfigurationManagement = useAuthorized("VIEW_CONFIGURATIONS");

  const [accountAnchor, setAccountAnchor] = useState<HTMLButtonElement>();
  const [currUser, setCurrentUser] = useState<User>();

  useEffect(() => {
    getUser()?.then((currentUser) => {
      setCurrentUser(currentUser);
    });
  }, [getUser]);

  const handleAccountClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAccountAnchor(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAccountAnchor(undefined);
  }, [setAccountAnchor]);

  const menuId = useStableUniqueId();
  const classes = useStyles({ isRotated: Boolean(accountAnchor) });
  const patientManagementPage = useFeature("patientManagementPage");
  const isStagingEnvironment = environmentAbbreviation() === "staging";

  return authState?.isAuthenticated ? (
    <>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          handleAccountClick(e);
        }}
      >
        {isServiceOps && <HeadsetMic className={classes.headsetMicIcon} />}
        <Body1 data-public className={classes.menuButtonText}>
          My account
        </Body1>
        <ExpandMoreIcon className={classes.expandIcon} />
      </Button>
      <Menu
        id={menuId}
        anchorEl={accountAnchor}
        keepMounted
        open={Boolean(accountAnchor)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        {!enableUserToUpdateOwnContactInfo ? (
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            <Avatar className={classes.userAvatar}>
              <H4>{(currUser && `${currUser.given_name?.[0]}${currUser.family_name?.[0]}`) || "?"}</H4>
            </Avatar>
            {currUser && (
              <div>
                <Body2>{currUser.name}</Body2>
                <Caption>{currUser.email}</Caption>
              </div>
            )}
            {!Boolean(currUser) && (
              <div>
                <Body2>Cannot determine who you are</Body2>
                <Caption>Refresh, or logout if this persists</Caption>
              </div>
            )}
          </MenuItem>
        ) : (
          <CurrentUserMenuItem handleClose={handleClose} />
        )}
        <Divider className={classes.accountInfoDivider} />
        {(canViewMyOrgPage || canViewOrgMgmtPage) && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={
              canViewOrgMgmtPage ? routes.ORGANIZATION_MANAGEMENT_LIST : canViewMyOrgPage ? routes.MY_ORGANIZATION : "#" // unpossible fallback
            }
            onClick={handleClose}
          >
            <Body1>Management</Body1>
          </MenuItem>
        )}
        {canViewFeatureFlagsPage && !isStagingEnvironment && (
          <MenuItem className={classes.menuItem} component={Link} to={routes.FEATURE_FLAGS} onClick={handleClose}>
            <Body1>Feature Flags</Body1>
          </MenuItem>
        )}
        {canViewIntegrationManagement && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={routes.INTEGRATION_MANAGEMENT}
            onClick={handleClose}
          >
            <Body1>Integration Dashboard</Body1>
          </MenuItem>
        )}
        {canViewIntegrationObservability && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={routes.INTEGRATION_OBSERVABILITY_MANAGEMENT}
            onClick={handleClose}
          >
            <Body1>Integration Observability Dashboard</Body1>
          </MenuItem>
        )}
        {canViewConfigurationManagement && (
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to={routes.CONFIGURATION_MANAGEMENT}
            onClick={handleClose}
          >
            <Body1>Configuration Management</Body1>
          </MenuItem>
        )}
        {patientManagementPage && canViewPatientManagement && (
          <MenuItem className={classes.menuItem} component={Link} to={routes.PATIENT_MANAGEMENT} onClick={handleClose}>
            <Body1>Patient Management</Body1>
          </MenuItem>
        )}
        {canEditELetterOptInStatus && <PaperlessSettingsMenuItem handleClose={handleClose} />}
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <Body1>Logout</Body1>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <div>Loading...</div>
  );
}

function SupportButton() {
  const [accountAnchor, setAccountAnchor] = useState<HTMLButtonElement>();

  const handleAccountClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAccountAnchor(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAccountAnchor(undefined);
  }, [setAccountAnchor]);

  const menuId = useStableUniqueId();
  const classes = useStyles({ isRotated: Boolean(accountAnchor) });

  return (
    <>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          handleAccountClick(e);
        }}
      >
        <Body1 data-public className={classes.menuButtonText}>
          Support
        </Body1>
        <ExpandMoreIcon className={classes.expandIcon} />
      </Button>
      <Menu
        id={menuId}
        anchorEl={accountAnchor}
        keepMounted
        open={Boolean(accountAnchor)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        <MenuItem
          className={classes.menuItem}
          component="a"
          target="_blank"
          rel="noopener"
          href="https://coherehealth.zendesk.com/"
          onClick={handleClose}
        >
          <div>
            <Body1 className={classes.linkTextContainer}>
              Visit our Learning Center
              <LaunchIcon className={classes.launchIcon} />
            </Body1>
            <Caption color="textSecondary">Browse resources, guides, and FAQs</Caption>
          </div>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          component="a"
          target="_blank"
          rel="noopener"
          href="https://coherehealth.com/provider/resources/"
          onClick={handleClose}
        >
          <div>
            <Body1 className={classes.linkTextContainer}>
              Contact us
              <LaunchIcon className={classes.launchIcon} />
            </Body1>
            <Caption color="textSecondary">Chat with our support team</Caption>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}

export default AppHeader;
