import React, { FunctionComponent } from "react";

import { Body2, MultiSelectDropdown } from "@coherehealth/common";
import { useGetProcedureCodes, ProcedureCode } from "@coherehealth/core-platform-api";

interface Props {
  procedureCodes: ProcedureCode[];
  setProcedureCodes: (pxs: ProcedureCode[]) => void;
  attemptedSubmit: boolean;
  /**
   * Cannot delete these procedure codes
   */
  readOnlyPxCodes?: string[];
  label?: string;
  disable?: boolean;
  /**
   * When enabled, if a user selects an already-selected procedure code that code will be unselected instead
   * of the default behavior of adding a duplicate selection
   */
  blockDuplicateSelections?: boolean;
}

const RevenueCodeSelect: FunctionComponent<Props> = ({
  procedureCodes,
  setProcedureCodes,
  attemptedSubmit,
  disable,
}) => {
  const notEnoughProcedureCodes = attemptedSubmit && !procedureCodes.length && !disable;
  const tooManyProcedureCodes = procedureCodes.length > 10;
  const helperText = () => {
    return notEnoughProcedureCodes ? "Required" : tooManyProcedureCodes ? "Max of 10 procedure codes." : undefined;
  };

  // There should only be ~8 revenue codes
  const { data: pxs } = useGetProcedureCodes({
    queryParams: { max: 10, offset: 0, systems: "revcode" },
  });

  const options =
    pxs?.map((px) => {
      return {
        id: px?.id || "",
        label: (
          <div>
            <Body2 style={{ display: "inline" }}>{px?.code || ""}</Body2>
            {" - " + (px?.label || "")}
          </div>
        ),
        description: px?.description || "",
      };
    }) || [];
  const optionsSorted = options.sort((a, b) => a.description.localeCompare(b.description));

  return (
    <MultiSelectDropdown
      error={notEnoughProcedureCodes || tooManyProcedureCodes}
      disabled={disable}
      helperText={helperText()}
      label="Select Home Health services"
      labelAltSelected="Home Health services"
      onChange={(pxIds) => {
        const realPxs = pxs?.filter((p): p is ProcedureCode => Boolean(p));
        const selectedPxs = realPxs?.filter((px) => {
          return pxIds.includes(px.id || "");
        });
        if (selectedPxs) {
          setProcedureCodes(selectedPxs);
        }
      }}
      options={optionsSorted}
      selectedValues={procedureCodes.map((px) => px.id || "")}
    />
  );
};

export default RevenueCodeSelect;
