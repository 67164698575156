import {
  CardTextField,
  SingleSelectDropdown,
  useConfiguration,
  useFeature,
  SingleSelectDropdownCancel,
  Alert,
  colorsLight,
} from "@coherehealth/common";
import { Caption } from "@coherehealth/design-system";
import { useLocation } from "react-router";
import { useContext, useEffect, useState } from "react";
import { getTatInfo, showOverturnOption } from "components/ClinicalReview/reviewUtils/utils";
import { useGetPostDecisionConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { isFinallyDeterminedUtility } from "util/serviceRequest";
import { DisplayReviewOutcome, ReviewOutcomeProps } from "../../mdReviewModalTypes";
import { PeerToPeerReview, ReviewOutcome } from "@coherehealth/core-platform-api";
import {
  isPartialApprovalOutcome,
  useMdOutcomeOptions,
  usePeerToPeerOutcomeOptions,
  useReviewReadmissionOutcomeOptionsFor,
} from "../../mdReviewModalUtils";
import { useAuthorized } from "authorization";
import { Row, useModalStyles } from "../../../mdReviewStyles";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { isMultiCoverageDoctorReviewOutcome } from "../../genericDoctorReviewMultiCoverageReviewOutcomes";
import { ReviewOutcomeOption } from "components/ServiceRequest/ReviewSection/util/QueueManagementReviewUtil";

export const ReviewOutcomeDropdown = ({
  serviceRequest,
  review,
  displayCaseOutcome,
  setDisplayCaseOutcome,
  prePopulateDenialsTemplate,
  newDenialsWorkflow,
  sameGuidelines,
  allReviews,
  disabled,
  setIsInheritingBlobReview,
  setReview,
  error,
  setReviewOutcomeSubmenuOptionsAvailable,
  coverageLevelDetail,
}: ReviewOutcomeProps) => {
  const multiCoverageReviews = useFeature("multiCoverageReviews");
  const { isMultiCoverageReviewRequired, allowedReviewOutcomes } = useContext(ClinicalReviewContext);

  const multiCoverageContent = multiCoverageReviews && isMultiCoverageReviewRequired;

  const { isReadmission, initialDecisionDisposition, healthPlanName } = serviceRequest;
  const {
    reviewType,
    reviewOutcome: reviewLevelOutcome,
    serviceStatusCode: reviewLevelServiceStatusCode,
    readmissionOutcome,
  } = review;
  const coverageLevelReviewOutcome = coverageLevelDetail?.reviewOutcome;
  const coverageLevelServiceStatusCode = coverageLevelDetail?.serviceStatusCode;
  const reviewOutcome =
    multiCoverageContent && isMultiCoverageDoctorReviewOutcome(coverageLevelReviewOutcome)
      ? coverageLevelReviewOutcome
      : reviewLevelOutcome;
  const serviceStatusCode = multiCoverageContent ? coverageLevelServiceStatusCode : reviewLevelServiceStatusCode;

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const caseId = search.get("caseId") || "";
  const isQmCase = !!caseId.length;
  const postDecisionConfigEnabled = useFeature("usePostDecisionConfiguration");
  const readmissionIdentificationEnabled = useFeature("readmissionIdentification");
  const { data: postDecisionConfig } = useGetPostDecisionConfigurationByPayer(healthPlanName || "");
  const showOverturn = !postDecisionConfigEnabled || showOverturnOption(postDecisionConfig, serviceRequest);
  const [onLoad, setOnLoad] = useState<boolean>(true); //to avoid api for first time if same guidelines and outcome
  const isDecisioned = isFinallyDeterminedUtility(initialDecisionDisposition);
  const newDenialsAndPrePopulateAndCanViewDenialLanguage = newDenialsWorkflow && prePopulateDenialsTemplate;
  const ReviewReadmissionOutcomeOptions = useReviewReadmissionOutcomeOptionsFor(reviewOutcome, isReadmission);

  const calculateReviewOutcomeFromDisplayReviewOutcome = (
    displayCaseOutcome: DisplayReviewOutcome
  ): ReviewOutcome | undefined => {
    switch (displayCaseOutcome) {
      case "CANNOT_WORK":
        return undefined;
      case "UPHOLD_INITIAL_APPROVAL":
        return "APPROVED";
      default:
        return displayCaseOutcome;
    }
  };

  const handleSelectOutcome = (displayReviewOutcome: NonNullable<DisplayReviewOutcome>) => {
    setReview({ serviceStatusCode: "", serviceStatusLabel: "" });

    if (newDenialsAndPrePopulateAndCanViewDenialLanguage) {
      if (sameGuidelines && allReviews && onLoad) {
        //this loop is to compare the current outcome to the previous completed md review outcome
        //if same, then set previous denial template which is only on load of modal for the first time, else do api call to get denials template
        setOnLoad(false);
        for (const rw of allReviews.reverse()) {
          if (rw.reviewStatus === "COMPLETE" && rw.reviewType === "MdReview") {
            if (displayReviewOutcome !== rw.reviewOutcome || rw.denialsTemplate?.convertedToBlob) {
              setIsInheritingBlobReview?.(true);
            }
            break;
          }
        }
      }
    }
    const reviewOutcome = calculateReviewOutcomeFromDisplayReviewOutcome(displayReviewOutcome);

    setReview({ reviewOutcome, readmissionReason: undefined, readmissionOutcome: undefined });
    setDisplayCaseOutcome?.(displayReviewOutcome);
  };
  const canSubmitDenial = useAuthorized("SUBMIT_DENIAL");
  const outOfScopeEnabled = useFeature("diagnosisOutOfScope");
  const { tatTimeRemainingPercentage = 0 } = getTatInfo(serviceRequest);
  const lessThan50percentTat = tatTimeRemainingPercentage < 50;

  const isPartialApproval = isPartialApprovalOutcome(reviewOutcome);

  const featureFlagData = {
    outOfScopeEnabled,
  };

  const p2pReview = review as PeerToPeerReview;
  const oonReviewConfig = useConfiguration(
    "outOfNetworkReviewConfiguration",
    serviceRequest.healthPlanName,
    serviceRequest.delegatedVendor
  );
  const oonReviewFeatureEnabled = oonReviewConfig?.enabled;
  const hasRecDeniedOONDecision =
    serviceRequest.oonExceptionDecision === "RECOMMENDED_DENIAL" && oonReviewFeatureEnabled;
  const mdOpts = useMdOutcomeOptions(
    serviceRequest,
    isQmCase,
    lessThan50percentTat,
    featureFlagData,
    hasRecDeniedOONDecision
  );
  const p2pOpts = usePeerToPeerOutcomeOptions(
    p2pReview.isPostDenialP2P || false,
    serviceRequest,
    lessThan50percentTat,
    showOverturn,
    canSubmitDenial,
    featureFlagData,
    hasRecDeniedOONDecision
  );

  const options: ReviewOutcomeOption[] = allowedReviewOutcomes?.length
    ? allowedReviewOutcomes
    : reviewType === "MdReview"
    ? mdOpts
    : p2pOpts;
  const modalStyles = useModalStyles({ partialApprovalSelected: isPartialApproval });

  useEffect(() => {
    if (allowedReviewOutcomes && reviewOutcome && setReviewOutcomeSubmenuOptionsAvailable) {
      setReviewOutcomeSubmenuOptionsAvailable(
        Boolean(
          allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.submenu && !multiCoverageContent
        )
      );
    }
  }, [allowedReviewOutcomes, multiCoverageContent, reviewOutcome, setReviewOutcomeSubmenuOptionsAvailable]);

  const isOnlyPostTatUpholdApprovalOptionAvailable =
    options.length === 1 && options[0]?.id === "UPHOLD_INITIAL_APPROVAL" && tatTimeRemainingPercentage <= 0;
  const reviewOutcomeSubmenu = allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.submenu;
  const isReviewOutcomeSubmenuExist = reviewOutcomeSubmenu && reviewOutcomeSubmenu.length > 0;

  return (
    <>
      <Row
        style={
          isPartialApproval
            ? {
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: 8,
              }
            : {}
        }
      >
        {isReadmission && initialDecisionDisposition === "APPROVED" ? (
          <CardTextField label="Review Outcome" content={"Approved"} />
        ) : (
          <>
            {/* Inform users why they only see 1 available option */}
            {isOnlyPostTatUpholdApprovalOptionAvailable && (
              <Alert severity="info" className={modalStyles.infoAlert} iconStyles={{ marginRight: "8px" }}>
                <Caption style={{ color: colorsLight.font.main }}>
                  Since TAT has passed, the outcome of this approved request can be upheld but not overturned due to
                  compliance reasons.
                </Caption>
              </Alert>
            )}
            <SingleSelectDropdown
              label="Outcome"
              options={options as any}
              value={displayCaseOutcome || ""}
              disabled={disabled}
              onChange={(displayReviewOutcome) => handleSelectOutcome(displayReviewOutcome as any)}
              data-testid={"review-outcome-options"}
              error={error}
            />
          </>
        )}
      </Row>
      {isReviewOutcomeSubmenuExist && (
        <Row style={{ marginTop: multiCoverageContent ? 16 : 0 }}>
          <SingleSelectDropdownCancel
            data-testid="single-select-outcome-reason-review-outcome-config"
            label={`${allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.label || ""} reason`}
            options={allowedReviewOutcomes?.find((outcome) => outcome.id === reviewOutcome)?.submenu}
            value={serviceStatusCode || ""}
            disabled={disabled}
            onChange={(opt) => {
              setReview({
                serviceStatusCode: opt,
                serviceStatusLabel: allowedReviewOutcomes
                  ?.find((outcome) => outcome.id === reviewOutcome)
                  ?.submenu?.find((item) => item.id === opt)?.label,
              });
            }}
            canCancel={!!serviceStatusCode}
            onCancel={() => setReview({ serviceStatusCode: undefined })}
          />
        </Row>
      )}
      {readmissionIdentificationEnabled && isReadmission && isDecisioned && (
        <Row className={modalStyles.readmissionRow}>
          <SingleSelectDropdown
            label="Readmission decision outcome"
            options={ReviewReadmissionOutcomeOptions}
            value={readmissionOutcome || ""}
            defaultValue={readmissionOutcome}
            onChange={(opt) => {
              setReview({ readmissionOutcome: opt, readmissionReason: undefined });
            }}
          />
        </Row>
      )}
    </>
  );
};
