import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import {
  AdmissionCategoryMappingConfigurationResponse,
  AuthStatusAttachmentsTransitionConfigurationResponse,
  AutomatedDenialLetterConfigurationResponse,
  FacilityBasedRequestConfigurationResponse,
  LettersExpansionConfigurationResponse,
  LevelOfCareMappingConfigurationResponse,
  NoteConfigurationResponse,
  OtherInsuranceConfigurationResponse,
  OutOfNetworkReviewConfigurationResponse,
  PostDecisionConfigurationResponse,
  useGetFeatureConfiguration,
  StatusTransitionConfigurationResponse,
  DecisionAppealsConfigurationResponse,
  RequestStatusConfiguration,
  ReviewConfigurationResponse,
  TinConfigurationResponse,
} from "@coherehealth/core-platform-api";
import { debounce, DebouncedFuncLeading } from "lodash";

import {
  BCBS_SC,
  GEISINGER,
  HEALTH_PARTNERS,
  HIGHMARK,
  HUMANA,
  HealthPlans,
  MEDICAL_MUTUAL_OF_OHIO,
  OSCAR,
} from "../../util/healthPlanUtils";

interface Logger {
  warn: (...args: any[]) => void | undefined;
  error: (ex: any) => void | undefined;
}

interface FeatureConfigurationsRecord {
  admissionCategoryMappingConfiguration?: AdmissionCategoryMappingConfigurationResponse;
  levelOfCareMappingConfiguration?: LevelOfCareMappingConfigurationResponse;
  facilityBasedRequestConfiguration?: FacilityBasedRequestConfigurationResponse;
  postDecisionConfiguration?: PostDecisionConfigurationResponse;
  automatedDenialLetterConfiguration?: AutomatedDenialLetterConfigurationResponse;
  authStatusAttachmentsTransitionConfiguration?: AuthStatusAttachmentsTransitionConfigurationResponse;
  lettersExpansionConfiguration?: LettersExpansionConfigurationResponse;
  otherInsuranceConfiguration?: OtherInsuranceConfigurationResponse;
  outOfNetworkReviewConfiguration?: OutOfNetworkReviewConfigurationResponse;
  reviewConfiguration?: ReviewConfigurationResponse;
  statusTransitionConfiguration?: StatusTransitionConfigurationResponse;
  noteConfiguration?: NoteConfigurationResponse;
  decisionAppealsConfiguration?: DecisionAppealsConfigurationResponse;
  requestStatusConfiguration?: RequestStatusConfiguration;
  tinConfiguration?: TinConfigurationResponse;
}
type FeatureConfiguration = keyof FeatureConfigurationsRecord;

type FetchFeatureConfigurationReturnType<T extends FeatureConfiguration> = FeatureConfigurationsRecord[T];

type HealthPlanNameAndDelegatedVendorAndFeatureConfigurationsRecord = Record<
  string,
  FeatureConfigurationsRecord | undefined
>;

/**
 * Feature configs exist at the health plan level, or at the health plan -> delegated vendor sublevel.
 * To form a location (configurationsKey) to store the configs, we join plan + vendor using `::`.
 */
const initialFeatureConfigurationRecord: HealthPlanNameAndDelegatedVendorAndFeatureConfigurationsRecord = {
  // Health plan level configs
  [HUMANA]: undefined,
  [BCBS_SC]: undefined,
  [GEISINGER]: undefined,
  [HIGHMARK]: undefined,
  [MEDICAL_MUTUAL_OF_OHIO]: undefined,
  [OSCAR]: undefined,
  [HEALTH_PARTNERS]: undefined,
  // Delegated vendor level configs
  [`${HUMANA}::Oak Street Health`]: undefined,
};

interface FeatureConfigurationContextType {
  featureConfigurations: HealthPlanNameAndDelegatedVendorAndFeatureConfigurationsRecord;
  fetchFeatureConfiguration?: <T extends FeatureConfiguration>(
    configuration: T,
    healthPlanName: string,
    delegatedVendorName?: string
  ) => Promise<FetchFeatureConfigurationReturnType<T> | undefined>;
}

export const FeatureConfigurationContext = createContext<FeatureConfigurationContextType>({
  featureConfigurations: initialFeatureConfigurationRecord,
});

interface FeatureConfigurationProviderProps {
  getAccessToken: () => Promise<string | undefined>;
  logger: Logger;
  children: React.ReactNode;
}

/**
 *  Feature Configuration Provider should be place on the top hierarchy of the components.
 *  If you are using new Feature Configuration add it to the record + include useGetFacilityBasedRequestConfiguration in component below.
 *  Additionally add the call to fetchFeatureConfiguration method so it fetches the configuration.
 */
export const FeatureConfigurationProvider = ({
  getAccessToken,
  logger,
  children,
}: FeatureConfigurationProviderProps) => {
  const { error: logError, warn: logWarning } = logger;
  const [featureConfigurationsRecord, setFeatureConfigurationRecord] =
    useState<HealthPlanNameAndDelegatedVendorAndFeatureConfigurationsRecord>(initialFeatureConfigurationRecord);

  const { mutate: fetchConfiguration } = useGetFeatureConfiguration({
    configuration: "none",
    queryParams: {
      featureConfigurationLevel: "HEALTH_PLAN",
      healthPlanName: "none",
    },
  });

  const fetchFeatureConfiguration = async <T extends FeatureConfiguration>(
    configuration: T,
    healthPlanName: string,
    delegatedVendorName?: string
  ): Promise<FetchFeatureConfigurationReturnType<T> | undefined> => {
    if (!HealthPlans.includes(healthPlanName)) {
      // healthPlanName provided doesn't exist in HealthPlans list we should either include them to list or investigate.
      logWarning("While fetching feature configuration in provider healthPlanName provided which doesn't exist");
      return undefined;
    }
    try {
      const accessToken = await getAccessToken();
      // no access token not fetching anything
      if (!accessToken) {
        logWarning(
          "While fetching feature configuration in provider access token not found skipping configuration fetch"
        );
        return undefined;
      }

      const configurationsKey = delegatedVendorName ? `${healthPlanName}::${delegatedVendorName}` : healthPlanName;

      // If already fetched, return the existing config
      const featureConfig = featureConfigurationsRecord[configurationsKey]?.[configuration];
      if (featureConfig) {
        return featureConfig as FetchFeatureConfigurationReturnType<T>;
      }

      // Else, fetch and store in context
      const fetchedConfiguration = await fetchConfiguration(undefined, {
        pathParams: {
          configuration,
        },
        queryParams: {
          featureConfigurationLevel: !!delegatedVendorName ? "DELEGATED_VENDOR" : "HEALTH_PLAN",
          healthPlanName,
          delegatedVendorName,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      setFeatureConfigurationRecord((previousState) => {
        return {
          ...previousState,
          [configurationsKey]: {
            ...previousState[configurationsKey],
            [configuration]: { ...fetchedConfiguration },
          },
        };
      });
      return fetchedConfiguration as FetchFeatureConfigurationReturnType<T>;
    } catch (error) {
      logError(`Not able to fetch configuration ${configuration}`);
      logError(error);
    }
  };

  const debouncedFetchFuncs: Map<string, DebouncedFuncLeading<typeof fetchFeatureConfiguration>> = new Map();
  /**
   *  Each unique combo of config + health plan + delegated vendor has its own debounced function.
   *  This prevents identical API calls when multiple components mount at the same time.
   */
  const fetchFeatureConfigurationDebounced = <T extends FeatureConfiguration>(
    configuration: T,
    healthPlanName: string,
    delegatedVendorName?: string
  ): Promise<FetchFeatureConfigurationReturnType<T> | undefined> => {
    const key = `${configuration}-${healthPlanName}${!!delegatedVendorName ? `-${delegatedVendorName}` : ""}`;
    let debouncedFunc;

    if (debouncedFetchFuncs.has(key)) {
      debouncedFunc = debouncedFetchFuncs.get(key);
    } else {
      // Set leading: true so we start fetching ASAP
      debouncedFunc = debounce(fetchFeatureConfiguration, 1000, { leading: true, trailing: false });
      debouncedFetchFuncs.set(key, debouncedFunc);
    }

    return debouncedFunc?.(configuration, healthPlanName, delegatedVendorName) || Promise.resolve(undefined);
  };

  return (
    <FeatureConfigurationContext.Provider
      value={{
        featureConfigurations: featureConfigurationsRecord,
        fetchFeatureConfiguration: fetchFeatureConfigurationDebounced,
      }}
    >
      {children}
    </FeatureConfigurationContext.Provider>
  );
};

export const useConfiguration = <T extends keyof FeatureConfigurationsRecord>(
  configuration: T,
  healthPlanName?: string,
  delegatedVendorName?: string
) => {
  const [currentConfiguration, setCurrentConfiguration] = useState<
    FetchFeatureConfigurationReturnType<T> | undefined
  >();
  const context = useContext(FeatureConfigurationContext);
  const { fetchFeatureConfiguration } = context;

  const fetchConfig = useCallback(
    async (mounted: boolean) => {
      if (healthPlanName && fetchFeatureConfiguration) {
        const configurationFetched = await fetchFeatureConfiguration(
          configuration,
          healthPlanName,
          delegatedVendorName
        );
        if (configurationFetched && mounted) {
          setCurrentConfiguration(configurationFetched);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuration, healthPlanName, delegatedVendorName]
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchConfig(mounted);
    }

    return () => {
      mounted = false;
    };
  }, [fetchConfig]);

  return currentConfiguration;
};
