import { useGetServiceRequestByIdWithFallback } from "@coherehealth/common";
import useClaimHistory from "components/ClaimHistory/useClaimHistory";
import useOtherAuthorizations from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import { InjectedHooks } from "./types";
import {
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetLatestReviewLeadingToDenial,
  useIsCoverageActive,
  useGetAllCoverages,
  useGetAllReviews,
  useGetServiceRequests,
} from "@coherehealth/core-platform-api";
import { useGetServiceCase, useGetServiceRequestCases } from "@coherehealth/qm-api";
import { useAttachments } from "../reviewUtils/useAttachment";
import { useAuthorizationFetch, useDischargeDetails } from "../reviewUtils/utils";
import { useClinicalReviewFeatureFlags } from "./hooks/useClinicalReviewFeatureFlags";
import { useClinicalReviewPageConfigurations } from "./hooks/useClinicalReviewPageConfigurations";
import { useClinicalReviewPageUserAuth } from "./hooks/useClinicalReviewPageUserAuths";
import { useServiceRequestRuleResultsForClinicalReview } from "../reviewUtils/useServiceRequestRuleResultsForClinicalReview";
import usePatientStayDateOnCRR from "components/ServiceRequest/PatientStay/usePatientStayDateOnCRR";
import useOutreachAttempt from "components/ServiceRequest/ReviewSection/useOutreachAttempt";

export const managerProdHooks: InjectedHooks = {
  useGetServiceRequest: useGetServiceRequestByIdWithFallback,
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetLatestReviewLeadingToDenial,
  useIsCoverageActive,
  useGetAllCoverages,
  useAuthorizationFetch,
  useGetAllReviews,
  useGetServiceRequests,
  useOtherAuthorizations,
  useClaimHistory,
  useClinicalReviewFeatureFlags,
  useClinicalReviewPageConfigurations,
  useClinicalReviewPageUserAuth,
  useAttachments,
  useServiceRequestRuleResultsForClinicalReview,
  useGetServiceCase,
  useGetServiceRequestCases,
  useDischargeDetails,
  usePatientStayDateOnCRR,
  useOutreachAttempt,
};
