import { useEffect } from "react";

import {
  appHeaderHeight,
  Body1,
  Card,
  colorsLight,
  FixedSideRail,
  FullPageLayout,
  H2,
  H4,
  InlineButton,
  NotFoundPage,
  patientDisplayName,
  PrimaryButton,
  ScrollableMainPanel,
  useFeature,
  useMuiContainerStyles,
} from "@coherehealth/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";
import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { generatePath, Link, useMatch } from "react-router-dom";
import routes from "routes";
import { headerHeight } from "util/StyleConstants";
import { Divider, Grid } from "@material-ui/core";
import HeaderContainer from "components/AppHeader/HeaderContainer";
import { PatientInfoSection } from "components/PatientInfo";
import ScrubTinsContextProvider from "../../ScrubTinsContext";
import ReferralHeaderIcon from "components/images/ReferralHeaderIcon";
import ReferralRequestPatientSummaryPage from "./ReferralRequestPatientSummary";
import NoResults from "components/ReferralManagement/Dashboard/NoResults";
import { useAuthorized } from "authorization";
import { useGetPatient } from "@coherehealth/core-platform-api";

export default function ReferralRequestPatientSummaryPaginationPage() {
  const referralManagementFF = useFeature("referralManagement");
  const canStartReferral = useAuthorized("START_AUTH_REQUEST");
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    title: {
      display: "flex",
      alignItems: "center",
    },
    mainContent: {
      height: "100%",
      paddingTop: theme.spacing(3) + headerHeight,
      paddingBottom: theme.spacing(10),
    },
    card: { padding: theme.spacing(3) },
  }));
  const classes = useStyles();

  const match = useMatch(routes.REFERRAL_PATIENT_SUMMARY);
  const patientId = match?.params.patientId || "";
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: patient,
    loading: patientLoading,
    error: patientError,
  } = useGetPatient({
    id: patientId || "",
    queryParams: { includeReferralEligibility: true },
  });

  useEffect(() => {
    if (patientError) {
      enqueueSnackbar(`Failed to fetch the patient with id: ${patientId}`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [patientError, enqueueSnackbar, patientId]);

  const referralDelegatedEligibility = patient?.referralEligibility?.delegated;

  const containerClasses = useMuiContainerStyles();

  const handleViewAuthorizations = () => {
    window.location.assign(`/patient_summary/${patientId}`);
  };

  if (!!referralManagementFF) {
    return (
      <Container classes={containerClasses} maxWidth="lg" data-testid={`patient-summary-${patientId}`}>
        <HeaderContainer height={headerHeight}>
          <Grid container alignItems="center">
            <ReferralHeaderIcon />
            <div className={classes.title} style={{ paddingLeft: theme.spacing(1) }}>
              <H2 style={{ marginLeft: "auto", marginRight: "auto" }} data-public>
                {`${patient && patientDisplayName(patient)} • Referrals`}
                <InlineButton
                  style={{ paddingLeft: theme.spacing(2) }}
                  onClick={handleViewAuthorizations}
                  disableRipple
                >
                  View authorizations
                </InlineButton>
              </H2>
            </div>
            {canStartReferral && referralDelegatedEligibility && (
              <Grid item style={{ marginLeft: "auto" }}>
                <PrimaryButton component={Link} to={generatePath(routes.REFERRAL_BUILDER, { patientId })}>
                  Start referral request
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
        </HeaderContainer>
        <div className={classes.mainContent}>
          {Boolean(patientLoading) && <CircularProgress />}
          <FullPageLayout>
            <FixedSideRail
              sideRailWidth={314}
              columnSpacingRight={6}
              verticalHeightAroundSideRail={headerHeight + appHeaderHeight() - theme.spacing(2)}
              hasHeader
            >
              {patient && (
                <Card className={classes.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <H4>{patientDisplayName(patient)}</H4>
                      <Body1 style={{ paddingTop: theme.spacing(0.5), color: colorsLight.font.secondary }}>
                        Member ID {patient.memberId}
                      </Body1>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <PatientInfoSection patient={patient} />
                    </Grid>
                  </Grid>
                </Card>
              )}
            </FixedSideRail>
            <ScrollableMainPanel sideRailWidth={314}>
              {referralDelegatedEligibility ? (
                patient && (
                  <ScrubTinsContextProvider>
                    <ReferralRequestPatientSummaryPage patientId={patientId} />
                  </ScrubTinsContextProvider>
                )
              ) : (
                <NoResults heading={"Cohere does not process referrals for this patient’s health plan"} message="" />
              )}
            </ScrollableMainPanel>
          </FullPageLayout>
        </div>
      </Container>
    );
  } else {
    return <NotFoundPage />;
  }
}
