import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetServiceRequests } from "@coherehealth/core-platform-api";
import { useFeature } from "@coherehealth/common";

const RedirectToAuthBuilderComponent: FunctionComponent<{
  cohereId: string;
}> = ({ cohereId, children }) => {
  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");
  const navigate = useNavigate();
  const { data: serviceRequests } = useGetServiceRequests({ queryParams: { cohereId: `eq:${cohereId}` } });
  useEffect(() => {
    if (serviceRequests && serviceRequests.length > 0) {
      const workflowParam = authBuilderParamNavigationFF
        ? `&authBuilderStep=${serviceRequests[0].workflowStep}`
        : `#${serviceRequests[0].workflowStep}`;
      window.location.href = `/auth_builder/${serviceRequests[0].patient?.id}?draftServiceRequestId=${serviceRequests[0].id}${workflowParam}`;
    }
  }, [serviceRequests, navigate, authBuilderParamNavigationFF]);

  return <>{children}</>;
};

export default RedirectToAuthBuilderComponent;
