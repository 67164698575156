import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { AuthorizationResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import {
  Body1,
  H5,
  dateAndTimeParsedFromDateStr,
  incrementClickCount,
  UserClickInfoClinicalReviewPage,
  Tooltip,
  useFeature,
  extractClinicalServicesTextOld,
} from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { Divider, Grid, styled } from "@material-ui/core";
import ServiceRequestFormSection from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormSection";
import { SRFormViewState } from "components/PatientSummary/ServiceRequestSummaryCard/ServiceRequestFormSection";
import ServiceRequestSummaryCard, { ServiceRequestPrint } from "components/PatientSummary/ServiceRequestSummaryCard";
import Dialog from "@material-ui/core/Dialog";
import DetailsPrintoutProvider from "components/ServiceRequest/DetailsPrintoutProvider";
import DocumentViewer from "components/DocumentViewer";
import ClinicalReviewCard from "common/ClinicalReviewCard";
import { extractClinicalServicesText } from "@coherehealth/common";
import { getSortedServiceRequests } from "../../../util/authorization";
import { makeStyles } from "@material-ui/core/styles";
import useIsOverflow from "../../../hooks/useIsOverflow";
import RequestedByInfo from "../../PatientSummary/AuthorizationPatientSummary/RequestedByInfo";
import { CareCoordinationBadge } from "components/PatientSummary/AuthorizationPatientSummary/AuthorizationSummaryCard/AuthorizationSummaryCardAdornments/CareCoordinationBadge";
import useSearchAndLocation from "hooks/useSearchAndLocation";

interface Props {
  authorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  serviceRequest: ServiceRequestResponse | undefined | null;
  loadingSRData?: boolean;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  serviceRequestRefetch: () => Promise<ServiceRequestResponse | null>;
  isDenialReviewPage?: boolean;
}
interface StylesProps {
  srDatesDisplayUpdateEnabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  styledBodyContainer: {
    width: ({ srDatesDisplayUpdateEnabled }: StylesProps) => (srDatesDisplayUpdateEnabled ? undefined : "100%"),
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: ({ srDatesDisplayUpdateEnabled }: StylesProps) =>
      srDatesDisplayUpdateEnabled ? theme.spacing(3) : undefined,
  },
  hover: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      "background-blend-mode": "multiply",
    },
  },
}));

export default function ServiceRequestInformation({
  authorization,
  refreshAuthorization,
  serviceRequest,
  setUserClickInfoTracking,
  serviceRequestRefetch,
  isDenialReviewPage = false,
}: Props) {
  const { location, search } = useSearchAndLocation();

  const [isExpanded, setIsExpanded] = useState<boolean>(!isDenialReviewPage);
  const [viewState, setViewState] = useState<SRFormViewState>("READ_ONLY");
  const [showSrDetailsPrintout, setShowSrDetailsPrintout] = useState(false);
  const [srForDetailsPrintout, setSrForDetailsPrintout] = useState<ServiceRequestResponse>();
  const srDatesDisplayUpdateEnabled = useFeature("srDatesDisplayUpdate");
  const classes = useStyles({ srDatesDisplayUpdateEnabled });

  const { date: submittedDate, time: submittedTime } = dateAndTimeParsedFromDateStr(
    serviceRequest?.tatStartTimestamp ?? serviceRequest?.intakeTimestamp
  );

  const showSummaryPrintout = useCallback(
    (serviceRequest: ServiceRequestResponse) => {
      setShowSrDetailsPrintout(true);
      setSrForDetailsPrintout(serviceRequest);
    },
    [setShowSrDetailsPrintout, setSrForDetailsPrintout]
  );

  const updateUserClickInfo = () => {
    setUserClickInfoTracking &&
      incrementClickCount("ServiceRequestInformationCardToggle", "", -1, setUserClickInfoTracking);
  };
  const extractText = (clinicalServices?: ServiceRequestResponse["clinicalServices"]) => {
    return extractClinicalServicesText(
      clinicalServices || [],
      serviceRequest?.authCategory,
      serviceRequest?.authSubcategory,
      serviceRequest?.palCategory
    );
  };

  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);
  const serviceRequests = getSortedServiceRequests(authorization);

  const facilityBasedClinicalServicePriority = useFeature("facilityBasedClinicalServicePriority");
  const logrocketUnmasking = useFeature("logrocketUnmasking");

  return (
    <>
      <ClinicalReviewCard
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        updateUserClickInfo={updateUserClickInfo}
        carrotContainerProps={{ top: -8 }}
        header={
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <H5 data-public={!!logrocketUnmasking ? true : undefined}>Service Request Information</H5>
                {(authorization?.isCareRecommendationRequest ||
                  serviceRequest?.requestor?.isCareRecommendationRequest) && <CareCoordinationBadge />}
              </Grid>
              {isExpanded && serviceRequest && (
                <div style={{ margin: -8 }}>
                  <ServiceRequestPrint serviceRequest={serviceRequest} onShowSummaryPrintout={showSummaryPrintout} />
                </div>
              )}
            </div>
            <Grid container style={{ marginTop: 6, marginBottom: isExpanded ? "" : 4 }}>
              {serviceRequest?.clinicalServices && (
                <>
                  <Tooltip
                    title={extractText(serviceRequest?.clinicalServices)}
                    placement="top"
                    disableHoverListener={!hoverStatus}
                  >
                    <div
                      className={
                        hoverStatus ? `${classes.styledBodyContainer} ${classes.hover}` : classes.styledBodyContainer
                      }
                      ref={textElementRef}
                    >
                      <StyledBody1>
                        {facilityBasedClinicalServicePriority
                          ? extractClinicalServicesText(
                              authorization?.clinicalServices || serviceRequest?.clinicalServices || [],
                              serviceRequest?.authCategory,
                              serviceRequest?.authSubcategory,
                              serviceRequest?.palCategory
                            )
                          : extractClinicalServicesTextOld(serviceRequest, authorization)}
                      </StyledBody1>
                    </div>
                  </Tooltip>
                  {srDatesDisplayUpdateEnabled && (
                    <div>
                      <StyledBody1>Facility state: {serviceRequest?.associatedStateOfUnion}</StyledBody1>
                    </div>
                  )}
                </>
              )}

              {!srDatesDisplayUpdateEnabled && (
                <>
                  <div style={{ marginRight: 24 }}>
                    <StyledBody1>Facility state: {serviceRequest?.associatedStateOfUnion}</StyledBody1>
                  </div>
                  <div>
                    <StyledBody1>Submitted {submittedDate + " at " + submittedTime}</StyledBody1>
                  </div>
                </>
              )}
            </Grid>
          </>
        }
      >
        {isExpanded && (
          <>
            {serviceRequest && (
              <>
                <div style={{ width: "100%" }}>
                  <ServiceRequestSummaryCard
                    serviceRequest={serviceRequest}
                    clinicalReviewView
                    serviceRequestRefetch={serviceRequestRefetch}
                    authorizationServiceRequests={serviceRequests}
                  />
                </div>
                <DividerContainer>
                  <Divider />
                </DividerContainer>
                <div style={{ width: "100%" }}>
                  <ServiceRequestFormSection
                    clinicalReviewView
                    authorization={authorization}
                    refreshAuthorization={refreshAuthorization}
                    serviceRequest={serviceRequest}
                    serviceRequests={serviceRequests}
                    viewState={viewState}
                    setViewState={setViewState}
                    serviceRequestRefetch={serviceRequestRefetch}
                  />
                </div>
                {serviceRequests && serviceRequests?.length <= 1 ? (
                  <>
                    <div style={{ width: "100%" }}>
                      <RequestedByInfo
                        serviceRequest={serviceRequest}
                        serviceRequestRefetch={async () => {
                          serviceRequestRefetch();
                        }}
                        clinicalReviewView
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </ClinicalReviewCard>
      <Dialog fullScreen keepMounted open={showSrDetailsPrintout} onClose={() => setShowSrDetailsPrintout(false)}>
        <DetailsPrintoutProvider serviceRequest={srForDetailsPrintout}>
          {({ url, loading }) => (
            <DocumentViewer
              documentInfo={{
                name: `CohereServiceRequest_${srForDetailsPrintout?.cohereId}.pdf`,
                contentType: "application/pdf",
              }}
              loading={loading}
              url={url || undefined}
              handleClose={() => setShowSrDetailsPrintout(false)}
              canDelete={false}
              location={location}
              search={search}
            />
          )}
        </DetailsPrintoutProvider>
      </Dialog>
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const DividerContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(2, 0, 2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const StyledBody1 = styled(Body1)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
