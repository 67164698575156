import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Body1,
  Caption,
  H2,
  H5,
  H6,
  Pill,
  Sanitized,
  colorsLight,
  useFeature,
  MAX_DATE_SELECT_DATE,
  extractOnAcceptValueArray,
} from "@coherehealth/common";
import {
  ServiceRequestResponse,
  RecommendChangeRuleAction,
  Attachment,
  useGetServiceRequestAttachments,
  PlaceOfService,
  useGetPlaceOfServices,
  useCreateServiceRequestAttachment,
  useDeleteServiceRequestAttachment,
  AttachmentUpdatePayload,
  useUpdateServiceRequestAttachment,
  FacilityCategory,
  AuthBuilderWorkflowStep,
  ServiceChangeOption,
} from "@coherehealth/core-platform-api";
import { Card, Grid, makeStyles, IconButton, Collapse } from "@material-ui/core";
import ContentNudge from "components/ServiceRequest/ReadonlyDetail/ContentNudge";
import PlaceOfServiceSelect from "components/ServiceRequest/ServiceRequestForm/components/PlaceOfServiceSelect";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { UploadFile } from "components/AddAttachments/FileUploadItem";
import { useSnackbar } from "notistack";
import { TargetFieldLabel } from "@coherehealth/common";
import { getRuleNudgeProcedureCode, newValueText, nudgeUnitType, oldValueText } from "util/NudgeUtils";
import { getPatientHealthPlanName } from "util/patientUtils";
import { isEqual } from "lodash";
import DateRangeSelect from "../FillFormsGeneralAuthSubmission/DateRangeSelect";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { ServiceChangeOptions } from "./ServiceChangeOptions";
import { UploadAttachmentOnNudge } from "./UploadAttachmentOnNudge";
import { MissingAttachmentOptions } from "./MissingAttachmentOptions";

interface NudgeCardProps {
  ruleNudge: RecommendChangeRuleAction;
  serviceRequest: ServiceRequestResponse;
  setCanContinueToReviewPage: (b: boolean) => void;
  setPlaceOfServiceToUpdateTo: Dispatch<SetStateAction<PlaceOfService | undefined>>;
  placeOfServiceToUpdateTo: PlaceOfService | undefined;
  setCareTypeToUpdateTo: Dispatch<SetStateAction<FacilityCategory | undefined>>;
  setDedicatedNudgeAccepted: (b: boolean) => void;
  canContinueToReviewPage: boolean;
  workflowId: string;
  files: UploadFile[];
  setFiles: Dispatch<SetStateAction<UploadFile[]>>;
  currentDedicatedNudgeTargetForAction: TargetFieldLabel | undefined;
  acceptedDedicatedNudges?: string[] | undefined;
  setAcceptedDedicatedNudges?: Dispatch<SetStateAction<string[] | undefined>>;
  updateRuleNudges?: RecommendChangeRuleAction[];
  declinedNudges?: string[] | undefined;
  setDeclinedNudges?: (declinedNudges: string[] | undefined) => void;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  attemptedSubmit: boolean;
  workflowStep?: AuthBuilderWorkflowStep;
  patientId?: string;
  missingAttachmentsReason?: string;
  missingAttachmentsReasonText?: string;
  continueDisabledForAddAttachmentsDNS?: boolean;
  setContinueDisabledForAddAttachmentsDNS?: Dispatch<SetStateAction<boolean | undefined>>;
  setUpdatedClinicalServices: Dispatch<SetStateAction<string[] | undefined>>;
  updatedClinicalServices?: string[] | undefined;
  selectedCarePathId: string | undefined;
}

const convertGroupIdToTitleWithNoColon = (groupId: string, serviceRequest: ServiceRequestResponse) => {
  if (groupId === "parent") {
    return "";
  } else {
    const cs = serviceRequest?.clinicalServices?.find((cs) => cs?.id === groupId);
    return cs ? `${cs?.name}` : "";
  }
};

export const ReviewNudgesCard = ({
  ruleNudge,
  serviceRequest,
  setCanContinueToReviewPage,
  setPlaceOfServiceToUpdateTo,
  placeOfServiceToUpdateTo,
  setCareTypeToUpdateTo,
  setDedicatedNudgeAccepted,
  canContinueToReviewPage,
  workflowId,
  files,
  setFiles,
  currentDedicatedNudgeTargetForAction,
  acceptedDedicatedNudges,
  setAcceptedDedicatedNudges,
  updateRuleNudges,
  declinedNudges,
  setDeclinedNudges,
  formContent,
  setFormContent,
  attemptedSubmit,
  workflowStep,
  patientId,
  missingAttachmentsReason,
  missingAttachmentsReasonText,
  continueDisabledForAddAttachmentsDNS,
  setContinueDisabledForAddAttachmentsDNS,
  setUpdatedClinicalServices,
  updatedClinicalServices,
  selectedCarePathId,
}: NudgeCardProps) => {
  const startDate = serviceRequest?.startDate ? new Date(serviceRequest?.startDate) : new Date();
  const healthPlanName = getPatientHealthPlanName(serviceRequest?.patient, startDate) || "";

  const { data: serviceRequestAttachments, loading: serviceRequestAttachmentsLoading } =
    useGetServiceRequestAttachments({
      id: serviceRequest.id,
    });

  const [initalAttachmentsCount, setInitialAttachmentsCount] = useState<number | undefined>(0);
  const [hasFetchedAttachmentsData, setHasFetchedAttachmentsData] = useState<boolean>(false);

  const [needToSelectClinicalService, setNeedToSelectClinicalService] = useState<boolean>(false);

  useEffect(() => {
    if (!serviceRequestAttachmentsLoading && !hasFetchedAttachmentsData) {
      setInitialAttachmentsCount(serviceRequestAttachments?.length);
      setHasFetchedAttachmentsData(true);
    }
  }, [
    serviceRequestAttachmentsLoading,
    serviceRequestAttachments,
    hasFetchedAttachmentsData,
    setHasFetchedAttachmentsData,
  ]);

  const [attachments, setAttachments] = useState(serviceRequestAttachments);
  const multiSingleService = useFeature("multiSingleService");
  const nudgeToSwap = useFeature("nudgeToSwap");
  const { enqueueSnackbar } = useSnackbar();
  const [clickedAccept, setClickedAccept] = useState(0);
  const { mutate: deleteAttachment, error: deleteError } = useDeleteServiceRequestAttachment({
    id: "",
  });

  // fields used for DateRangeSelect
  const { formFieldConfigurations } = useServiceRequestConfigSpec({
    ...formContent,
    patientId: serviceRequest?.patient?.id || "",
    healthPlanName: healthPlanName,
  });
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: healthPlanName,
    encounterType: formContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
    skipRequestTimingCheck: true,
  });
  const showEndDate = Number(formContent.units) !== 1;
  const hasValidStartDate = (formContent.startDate ?? MAX_DATE_SELECT_DATE) <= MAX_DATE_SELECT_DATE;

  const {
    mutate: updateAttachment,
    loading: updateLoading,
    error: updateError,
  } = useUpdateServiceRequestAttachment({
    id: "",
    attachmentId: "",
  });

  useEffect(() => {
    if (deleteError) {
      enqueueSnackbar(`Failed to delete attachment: ${deleteError.message}`, {
        variant: "error",
      });
    }
  }, [deleteError, enqueueSnackbar]);

  useEffect(() => {
    if (serviceRequestAttachments) {
      setFiles(serviceRequestAttachments || attachments || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequestAttachments]);

  const [answerToNudge, setAnswerToNudge] = useState("");
  const [chosenOption, setChosenOption] = useState<string | undefined>("");

  const currentCareType = ruleNudge?.onAcceptValue?.value
    ? ruleNudge?.onAcceptValue.value.toString().toLowerCase() === "outpatient"
      ? "inpatient"
      : "outpatient"
    : "";

  const careTypeToChangeTo = ruleNudge.onAcceptValue?.value
    ? ruleNudge?.onAcceptValue.value.toString().toLowerCase()
    : "";

  const rangeOfOptionsOldValueText = useCallback(
    (serviceRequest: ServiceRequestResponse) => {
      const foundService = serviceRequest.clinicalServices?.find((cs) => cs?.id === ruleNudge.groupId);
      const negativeClinicalServiceName = foundService?.name;
      let negativePxCode = serviceRequest.semanticProcedureCodes?.find((px) => px.groupId === foundService?.id)?.code;
      if (foundService) {
        negativePxCode = serviceRequest.semanticProcedureCodes?.find((px) => px.groupId === foundService?.id)?.code;
      } else {
        negativePxCode = serviceRequest.semanticProcedureCodes?.[0].code;
      }
      const negativeUnits =
        serviceRequest.semanticProcedureCodes?.find((px) => px.groupId === foundService?.id)?.units?.toString() ||
        serviceRequest.units?.toString();
      return { negativeClinicalServiceName, negativePxCode, negativeUnits };
    },
    [ruleNudge.groupId]
  );

  const { negativeClinicalServiceName, negativePxCode, negativeUnits } = rangeOfOptionsOldValueText(serviceRequest);

  const initialSrValue = oldValueText(ruleNudge, serviceRequest) || "";
  const initialSrValueRef = useRef<string>(initialSrValue);
  const initialNudgeToSwapSrValueRef = useRef<{
    negativeClinicalServiceName?: string;
    negativePxCode?: string;
    negativeUnits?: string;
  }>(rangeOfOptionsOldValueText(serviceRequest));
  const newSrValue = newValueText(ruleNudge, serviceRequest);

  const ruleNudgeRef = useRef<RecommendChangeRuleAction>(ruleNudge);

  // this will ensure that the values used in the nudgeCopy
  // change only when the ruleNudge changes. If the component
  // is unmounted and remounted, this conditional below will
  // evaluate to false and the refs have the correct value,
  // since they are initialized with the correct values.
  useEffect(() => {
    if (!isEqual(ruleNudgeRef.current, ruleNudge)) {
      initialSrValueRef.current = initialSrValue;
      ruleNudgeRef.current = ruleNudge;
      initialNudgeToSwapSrValueRef.current = rangeOfOptionsOldValueText(serviceRequest);
      setAnswerToNudge("");
    }
  }, [
    initialSrValue,
    negativeClinicalServiceName,
    negativePxCode,
    negativeUnits,
    rangeOfOptionsOldValueText,
    ruleNudge,
    serviceRequest,
    setAcceptedDedicatedNudges,
    setDeclinedNudges,
  ]);

  const nudgeCopy = useMemo(
    () =>
      dnsNudgeCopy(
        ruleNudge,
        currentDedicatedNudgeTargetForAction,
        currentCareType,
        careTypeToChangeTo,
        initialSrValueRef.current,
        newSrValue,
        serviceRequest,
        initialNudgeToSwapSrValueRef.current
      ),
    [ruleNudge, currentDedicatedNudgeTargetForAction, currentCareType, careTypeToChangeTo, newSrValue, serviceRequest]
  );

  const allAnswers = [...acceptedDedicatedNudges!, ...declinedNudges!];
  const allNudgesAnswered = updateRuleNudges?.every((ruleNudge) => allAnswers?.includes(ruleNudge.groupId!));

  const hasFieldsFilled: boolean = useMemo(() => {
    //this could get undwieldy quickly with more and more complicated nudges
    //Todo - extract to something nicer
    const isOutpatientSelected =
      answerToNudge === "accept" &&
      currentDedicatedNudgeTargetForAction === "Site of service change" &&
      careTypeToChangeTo === "outpatient";

    if (isOutpatientSelected) {
      setCareTypeToUpdateTo("OUTPATIENT");
    }

    const isEndDateValidatedForOutpatient = isOutpatientSelected
      ? formContent?.endDate && formContent?.startDate && formContent?.endDate > formContent?.startDate
        ? true
        : false
      : true;

    // range of options - service change
    const isClinicalServiceSelected =
      answerToNudge === "accept" &&
      currentDedicatedNudgeTargetForAction === "Service change" &&
      updatedClinicalServices !== undefined &&
      updatedClinicalServices.length > 0 &&
      !needToSelectClinicalService;

    return (
      (allNudgesAnswered
        ? true
        : currentDedicatedNudgeTargetForAction === "Service change"
        ? answerToNudge === "decline" || isClinicalServiceSelected
        : currentDedicatedNudgeTargetForAction !== "Service quantity change"
        ? answerToNudge === ""
          ? false
          : true
        : false) && isEndDateValidatedForOutpatient
    );
  }, [
    answerToNudge,
    currentDedicatedNudgeTargetForAction,
    careTypeToChangeTo,
    formContent?.endDate,
    formContent?.startDate,
    allNudgesAnswered,
    updatedClinicalServices,
    needToSelectClinicalService,
    setCareTypeToUpdateTo,
  ]);

  const afterUploadAttachments = (attachment: Attachment) => {};

  function handleAttachmentUpdate(attachments: Attachment[]) {
    setAttachments(attachments);
  }

  const classes = useStyles();

  const { data: placeOfServiceData, loading: placeOfServiceLoading } = useGetPlaceOfServices({
    queryParams: {
      healthPlanName: serviceRequest.healthPlanName || undefined,
      encounterType: careTypeToChangeTo === "inpatient" ? "INPATIENT" : "OUTPATIENT",
      clinicalServiceId: serviceRequest.clinicalService?.id,
    },
  });

  const {
    mutate: uploadSRAttachment,
    loading: loadingSRAttachment,
    error: uploadSRAttachmentError,
  } = useCreateServiceRequestAttachment({
    id: serviceRequest.id || "",
  });

  useEffect(() => {
    if (uploadSRAttachmentError) {
      const errorMessage =
        typeof uploadSRAttachmentError.data === "object"
          ? uploadSRAttachmentError.data.message || uploadSRAttachmentError.message
          : uploadSRAttachmentError.message;
      enqueueSnackbar(`Failed to create service request attachment: ${errorMessage}`, {
        variant: "error",
      });
    }
  }, [uploadSRAttachmentError, enqueueSnackbar]);

  const afterUpload = (response: Attachment, totalFiles: number) => {
    afterUploadAttachments(response || undefined);
  };

  const deleteAttachmentGroup = (fileId: string): Promise<void> | Promise<void[]> => {
    const attachmentGroupId = files.find((f) => f.id === fileId)?.attachmentGroupId;
    if (multiSingleService && attachmentGroupId) {
      let promises: Promise<void>[] = [];
      files?.forEach((file) => {
        if (attachmentGroupId === file.attachmentGroupId) {
          const fileId = file.id;
          const serviceRequestId = file?.serviceRequest?.id;
          promises.push(
            deleteAttachment(fileId, {
              pathParams: { id: serviceRequestId || "" },
            })
          );
        }
      });
      setFiles((prev) =>
        prev.filter((file) => {
          if (file.attachmentGroupId === attachmentGroupId) {
            return false;
          } else {
            return true;
          }
        })
      );
      return Promise.all(promises);
    } else {
      setFiles((prev) =>
        prev.filter((file) => {
          if (file.id === fileId) {
            return false;
          } else {
            return true;
          }
        })
      );
      return deleteAttachment(fileId, {
        pathParams: {
          id: files.find((f) => f.id === fileId)?.serviceRequest?.id || "",
        },
      });
    }
  };
  // Given one attachment, update all identical attachments (across all SRs)
  // If feature flag off, just delete the one file
  const updateAttachmentGroup = async (
    fileId: string,
    attachmentUpdatePayload: AttachmentUpdatePayload
  ): Promise<void> => {
    const attachmentGroupId = files.find((f) => f.id === fileId)?.attachmentGroupId;
    if (multiSingleService && attachmentGroupId) {
      for (const file of files || []) {
        if (attachmentGroupId === file.attachmentGroupId) {
          const fileId = file.id;
          const serviceRequestId = file?.serviceRequest?.id;
          const response = await updateAttachment(attachmentUpdatePayload, {
            pathParams: {
              id: serviceRequestId || "",
              attachmentId: fileId,
            },
          });
          onUpdateFile(response);
        }
      }
    } else {
      const response = await updateAttachment(attachmentUpdatePayload, {
        pathParams: {
          id: files.find((f) => f.id === fileId)?.serviceRequest?.id || "",
          attachmentId: fileId,
        },
      });
      onUpdateFile(response);
    }
  };

  useEffect(() => {
    if (updateError) {
      enqueueSnackbar(`Failed to update attachment: ${updateError.message}`, {
        variant: "error",
      });
    }
  }, [updateError, enqueueSnackbar]);

  useEffect(() => {
    setCanContinueToReviewPage(hasFieldsFilled);
  }, [hasFieldsFilled, setCanContinueToReviewPage]);

  // Called after the file deletion http requests finish
  const onRemoveFile = ({ id }: { id: string }) => {
    const attachmentGroupId = files.find((f) => f.id === id)?.attachmentGroupId;
    if (attachmentGroupId) {
      setFiles((orig) => [...orig.filter((f) => f.attachmentGroupId !== attachmentGroupId)]);
    } else {
      setFiles((orig) => [...orig.filter((f) => f.id !== id)]);
    }
  };

  const onUpdateFile = (file: UploadFile) => {
    setFiles((orig) => {
      const existingIdx = orig.findIndex((f) => f.id === file.id);
      if (existingIdx >= 0) {
        // Return a copy of the array with the existing entry merged with `file` and all other elements untouched
        return [...orig.slice(0, existingIdx), { ...orig[existingIdx], ...file }, ...orig.slice(existingIdx + 1)];
      } else {
        // This probably shouldn't happen... oh well, just append it
        return [...orig, file];
      }
    });
  };
  const [clearEndDate, setClearEndDate] = useState<boolean | undefined>(undefined);

  const handleNudgeClick = (accept: boolean, groupId: string, optionId?: string) => {
    if (accept && acceptedDedicatedNudges) {
      formContent.isInpatient =
        (accept && careTypeToChangeTo === "inpatient") || (!accept && careTypeToChangeTo === "outpatient");
      setAnswerToNudge("accept");
      setChosenOption(optionId);
      // range of options - service change
      if (
        ruleNudge?.onAcceptAttribute === "rangeOfOptions?SERVICE_CHANGE" &&
        optionId &&
        !acceptedDedicatedNudges.includes(optionId)
      ) {
        // this contains the options that the user previously selected
        const selectedRangeOfOptionsValuesToRemove = extractOnAcceptValueArray(ruleNudge)
          .filter(
            (option: ServiceChangeOption) =>
              option.id && option.id !== optionId && acceptedDedicatedNudges.includes(option.id)
          )
          .map((option: ServiceChangeOption) => option.id);

        // filter out the options that the user previously selected, then add in new selection
        setAcceptedDedicatedNudges?.((prev) => {
          const updatedSelectedOptions = prev?.filter(
            (accept) => !selectedRangeOfOptionsValuesToRemove.includes(accept)
          );
          if (updatedSelectedOptions) {
            return [...updatedSelectedOptions, optionId];
          }
        });
        // unit reduction
      } else if (!acceptedDedicatedNudges.includes(groupId)) {
        setAcceptedDedicatedNudges!([...acceptedDedicatedNudges, groupId]);
      }
      setDeclinedNudges!(declinedNudges?.filter((declinedNudge) => declinedNudge !== groupId));
      setClickedAccept((prevClicked) => prevClicked + 1);
      setClearEndDate(true);
    } else {
      setAnswerToNudge("decline");
      setChosenOption("");
      setPlaceOfServiceToUpdateTo(undefined);
      setAcceptedDedicatedNudges!(
        acceptedDedicatedNudges?.filter((acceptedDedicatedNudge) => acceptedDedicatedNudge !== groupId)
      );
      if (!declinedNudges?.includes(groupId)) {
        setDeclinedNudges!([...declinedNudges!, groupId]);
      }
      return clickedAccept;
    }
  };

  useEffect(() => {
    setDedicatedNudgeAccepted(clickedAccept > 0);
  }, [clickedAccept, setDedicatedNudgeAccepted]);

  const pillLabelText = convertGroupIdToTitleWithNoColon(ruleNudge.groupId || "", serviceRequest);

  const additionalBadgesNudges: (TargetFieldLabel | undefined)[] = [
    "Service quantity change",
    "Site of service change",
    "Place of service change",
    "Urgency change",
    "Service change",
  ];

  return (
    <Card className={classes.paddedCard}>
      <H2 className={classes.title}>{nudgeCopy.title}</H2>
      {(currentDedicatedNudgeTargetForAction === "Service quantity change" ||
        currentDedicatedNudgeTargetForAction === "Site of service change" ||
        currentDedicatedNudgeTargetForAction === "Place of service change" ||
        currentDedicatedNudgeTargetForAction === "Urgency change") && (
        <Grid item className={classes.grid}>
          <div className={classes.container}>
            <div className={classes.component}>
              {currentDedicatedNudgeTargetForAction === "Service quantity change" && (
                <>
                  <Pill label={pillLabelText} variant={"info"} />
                  <div className={classes.spacer} />
                </>
              )}
              <H5 className={classes.valueText}>{nudgeCopy.subtitle}</H5>
              <ContentNudge
                ruleNudge={ruleNudge}
                state={"pending"}
                type={"dedicatedNudge"}
                serviceRequest={serviceRequest}
              />
            </div>
          </div>
        </Grid>
      )}
      <Body1 className={classes.message}>
        <Sanitized __html={nudgeCopy.message} />
      </Body1>

      {currentDedicatedNudgeTargetForAction === "Add attachments" ? (
        <MissingAttachmentOptions
          ruleNudge={ruleNudge}
          answerToNudge={answerToNudge}
          chosenOption={chosenOption}
          handleNudgeClick={handleNudgeClick}
          nudgeCopy={nudgeCopy}
          serviceRequest={serviceRequest}
          loadingSRAttachment={loadingSRAttachment}
          afterUpload={afterUpload}
          workflowId={workflowId}
          uploadFile={uploadSRAttachment}
          files={files}
          setFiles={setFiles}
          onRemoveFile={onRemoveFile}
          onUpdateFile={onUpdateFile}
          updateLoading={updateLoading}
          updateAttachmentGroup={updateAttachmentGroup}
          deleteAttachment={deleteAttachmentGroup}
          onUpdateAttachments={handleAttachmentUpdate}
          workflowStep={workflowStep}
          patientId={patientId || ""}
          missingAttachmentsReason={missingAttachmentsReason}
          missingAttachmentsReasonText={missingAttachmentsReasonText}
          setFormContent={setFormContent}
          oldAttachmentReasons={formContent?.possibleAttachmentNudgeReasons}
          continueDisabledForAddAttachmentsDNS={continueDisabledForAddAttachmentsDNS}
          setContinueDisabledForAddAttachmentsDNS={setContinueDisabledForAddAttachmentsDNS}
          initalAttachmentsCount={initalAttachmentsCount}
        />
      ) : nudgeToSwap && currentDedicatedNudgeTargetForAction === "Service change" ? (
        <ServiceChangeOptions
          ruleNudge={ruleNudge}
          answerToNudge={answerToNudge}
          chosenOption={chosenOption}
          handleNudgeClick={handleNudgeClick}
          nudgeCopy={nudgeCopy}
          serviceRequest={serviceRequest}
          loadingSRAttachment={loadingSRAttachment}
          afterUpload={afterUpload}
          workflowId={workflowId}
          uploadFile={uploadSRAttachment}
          files={files}
          setFiles={setFiles}
          onRemoveFile={onRemoveFile}
          onUpdateFile={onUpdateFile}
          updateLoading={updateLoading}
          updateAttachmentGroup={updateAttachmentGroup}
          deleteAttachment={deleteAttachmentGroup}
          onUpdateAttachments={handleAttachmentUpdate}
          setUpdatedClinicalServices={setUpdatedClinicalServices}
          setNeedToSelectClinicalService={setNeedToSelectClinicalService}
          selectedCarePathId={selectedCarePathId}
        />
      ) : (
        <div className={classes.buttonContainer} style={{ width: "100%", display: "flex" }}>
          <div
            className={`${classes.button} ${answerToNudge === "accept" ? "highlightOption" : ""}`}
            onClick={() => handleNudgeClick(true, ruleNudge.groupId!)}
          >
            <div className={classes.buttonAndLabel}>
              {answerToNudge === "accept" ? (
                <IconButton className={classes.radioButton}>
                  <RadioButtonCheckedIcon color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  data-tracking-id="accept-nudge-option"
                  className={classes.radioButton}
                  onClick={() => handleNudgeClick(true, ruleNudge.groupId!)}
                >
                  <RadioButtonUncheckedIcon />
                </IconButton>
              )}
              <div>
                <H6 className={classes.selectionText}>
                  {nudgeCopy.affirmativeText}
                  <b style={{ color: colorsLight.font.main }}>{nudgeCopy.affirmativeBoldedText}</b>
                  {nudgeCopy.affirmativeAdditionalText}
                </H6>
              </div>
            </div>
            {additionalBadgesNudges.includes(currentDedicatedNudgeTargetForAction) && (
              <div className={classes.captionContainer}>
                <Caption className={`${classes.caption} ${"acceptNudgeBanner"}`}>Recommended for approval</Caption>
              </div>
            )}
          </div>

          <div className={classes.spacer} />

          <div
            className={`${classes.button} ${answerToNudge === "decline" ? "highlightOption" : ""}`}
            onClick={() => handleNudgeClick(false, ruleNudge.groupId!)}
          >
            <div className={classes.buttonAndLabel}>
              {answerToNudge === "decline" ? (
                <IconButton className={classes.radioButton}>
                  <RadioButtonCheckedIcon color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  data-tracking-id="decline-nudge-option"
                  className={classes.radioButton}
                  onClick={() => handleNudgeClick(false, ruleNudge.groupId!)}
                >
                  <RadioButtonUncheckedIcon />
                </IconButton>
              )}
              <div>
                <H6 className={classes.selectionText}>
                  {nudgeCopy.negativeText}
                  <b style={{ color: colorsLight.font.main }}>{nudgeCopy.negativeBoldedText}</b>
                  {nudgeCopy.additionalText}
                </H6>
              </div>
            </div>
            {additionalBadgesNudges.includes(currentDedicatedNudgeTargetForAction) && (
              <div className={classes.captionContainer}>
                <Caption className={`${classes.caption} ${"rejectNudgeBanner"}`}>
                  Documentation to justify is recommended
                </Caption>
              </div>
            )}
          </div>
        </div>
      )}

      <Collapse in={answerToNudge !== ""} timeout="auto">
        <div>
          {answerToNudge === "accept" &&
            currentDedicatedNudgeTargetForAction === "Site of service change" &&
            currentCareType === "inpatient" && (
              <div className={classes.optionContainer}>
                <H6 className={classes.optionText}>
                  Please select {careTypeToChangeTo === "outpatient" ? "an" : "a"} {careTypeToChangeTo} place of service
                  and verify service dates
                </H6>
                <PlaceOfServiceSelect
                  error={
                    canContinueToReviewPage && answerToNudge === "accept" && placeOfServiceToUpdateTo === undefined
                  }
                  authStatus={serviceRequest.authStatus || "DRAFT"}
                  isInpatient={careTypeToChangeTo === "inpatient"}
                  placeOfService={placeOfServiceToUpdateTo || null}
                  setPlaceOfService={(pos) => setPlaceOfServiceToUpdateTo(pos || undefined)}
                  clinicalService={serviceRequest.clinicalService}
                  availablePlacesOfService={placeOfServiceData || undefined}
                  placeOfServiceLoading={placeOfServiceLoading}
                  menuWidth={862}
                />
                <div className={`${classes.optionContainer} ${classes.expandDateContainer}`}>
                  <DateRangeSelect
                    formConfiguration={formFieldConfigurations}
                    formContent={formContent}
                    setFormContent={setFormContent}
                    patient={serviceRequest?.patient || null}
                    attemptedSubmit={attemptedSubmit}
                    showEndDate={showEndDate}
                    facilityBasedFeatureEnabled={facilityBasedFeatureEnabled}
                    hasValidStartDate={hasValidStartDate}
                    clearEndDate={clearEndDate}
                    setClearEndDate={setClearEndDate}
                  />
                </div>
              </div>
            )}
          {currentDedicatedNudgeTargetForAction !== "Service change" &&
            currentDedicatedNudgeTargetForAction !== "Add attachments" &&
            answerToNudge === "decline" && (
              <UploadAttachmentOnNudge
                serviceRequest={serviceRequest}
                loadingSRAttachment={loadingSRAttachment}
                afterUpload={afterUpload}
                workflowId={workflowId}
                uploadFile={uploadSRAttachment}
                files={files}
                setFiles={setFiles}
                onRemoveFile={onRemoveFile}
                onUpdateFile={onUpdateFile}
                updateLoading={updateLoading}
                updateAttachmentGroup={updateAttachmentGroup}
                deleteAttachment={deleteAttachmentGroup}
                onUpdateAttachments={handleAttachmentUpdate}
              />
            )}
        </div>
      </Collapse>
    </Card>
  );
};

// should consider moving away from this. Perhaps a function that handles each
//individual currentDedicatedNudgeTargetForAction, and a switch where we invoke this.
const dnsNudgeCopy = (
  ruleNudge: RecommendChangeRuleAction,
  currentDedicatedNudgeTargetForAction: TargetFieldLabel | undefined,
  currentCareType: "inpatient" | "outpatient" | "",
  careTypeToChangeTo: string,
  initialSrValue: string,
  newSrValue: string,
  serviceRequest: ServiceRequestResponse,
  initialNudgeToSwapSrValue: {
    negativeClinicalServiceName?: string;
    negativePxCode?: string;
    negativeUnits?: string;
  }
) => {
  const copy = {
    title: ruleNudge.title || "",
    subtitle: "",
    message: ruleNudge.message || "",
    affirmativeText: "Change to ",
    negativeText: "Keep as ",
    affirmativeBoldedText: "",
    affirmativeAdditionalText: "",
    negativeBoldedText: "",
    negativePxCode: "",
    negativeUnits: "",
    updatedProcedureCode: "",
    updatedUnits: "",
    additionalText: "",
  };
  const ruleNudgeProcedureCode = getRuleNudgeProcedureCode(ruleNudge, serviceRequest);
  const unitType = nudgeUnitType(ruleNudgeProcedureCode);

  const serviceChangeConfig = () => {
    copy.negativeBoldedText = initialNudgeToSwapSrValue.negativeClinicalServiceName || "current procedure";
    copy.negativePxCode = `${initialNudgeToSwapSrValue.negativePxCode}`;
    copy.negativeUnits = `${initialNudgeToSwapSrValue.negativeUnits} ${unitType}${
      Number(initialNudgeToSwapSrValue.negativeUnits) !== 1 ? "s" : ""
    }`;
  };
  const addAttachmentsConfig = () => {};
  const sosConfig = () => {
    copy.subtitle = currentCareType === "inpatient" ? "Inpatient" : "Outpatient";
    copy.affirmativeBoldedText = careTypeToChangeTo;
    copy.negativeBoldedText = currentCareType;
  };
  const posConfig = () => {
    const newPlaceOfService =
      typeof ruleNudge?.onAcceptValue?.value === "object" && "name" in ruleNudge?.onAcceptValue?.value
        ? ruleNudge?.onAcceptValue?.value?.name?.toString().toLowerCase()
        : "";
    copy.subtitle = initialSrValue;
    copy.affirmativeBoldedText = newPlaceOfService;
    copy.negativeBoldedText = initialSrValue.toLowerCase();
  };
  const serviceQuantityConfig = () => {
    copy.subtitle = initialSrValue;
    copy.affirmativeBoldedText = newSrValue;
    copy.negativeBoldedText = `${initialSrValue} ${unitType}${Number(initialSrValue) !== 1 ? "s" : ""}`;
  };
  const urgencyChangeConfig = () => {
    const isExpedited = ruleNudge?.onAcceptValue?.value && ruleNudge?.onAcceptAttribute === "urgency.isExpedited";
    copy.subtitle = initialSrValue;
    copy.affirmativeText = "Change the urgency to";
    copy.affirmativeBoldedText = isExpedited ? " expedited" : " not expedited";
    copy.negativeText = "Continue with";
    const lowerCaseInitialSrValue = initialSrValue.toLowerCase();
    copy.negativeBoldedText = " " + lowerCaseInitialSrValue;
    copy.additionalText = " urgency";
  };
  const withdrawConfig = () => {
    copy.affirmativeText = "";
    copy.affirmativeBoldedText = "Discard ";
    copy.affirmativeAdditionalText = "this request";
    copy.negativeText = "";
    copy.negativeBoldedText = "Continue ";
    copy.additionalText = "with request";
  };
  switch (currentDedicatedNudgeTargetForAction) {
    case "Does not submit / withdraw":
      withdrawConfig();
      break;
    case "Service change":
      serviceChangeConfig();
      break;
    case "Add attachments":
      addAttachmentsConfig();
      break;
    case "Service quantity change":
      serviceQuantityConfig();
      break;
    case "Site of service change":
      sosConfig();
      break;
    case "Place of service change":
      posConfig();
      break;
    case "Urgency change":
      urgencyChangeConfig();
  }
  return copy;
};

const useStyles = makeStyles((theme) => ({
  paddedCard: {
    padding: theme.spacing(3),
    width: 816,
    position: "relative",
    zIndex: 1,
    boxShadow: "0px 2px 2px rgba(207, 226, 231, 0.8)",
  },
  spacer: {
    width: theme.spacing(2),
  },
  optionContainer: {
    paddingTop: theme.spacing(2),
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
  },
  radioButton: {
    padding: "0px",
  },
  button: {
    "&.highlightOption": {
      border: `1px solid ${colorsLight.brand.blue}`,
    },
    width: "50%",
    borderRadius: theme.spacing(1),
    border: `1px solid ${colorsLight.gray.divider}`,
    cursor: "pointer",
  },
  fullWidthButton: {
    "&.highlightOption": {
      border: `2px solid ${colorsLight.brand.blue}`,
    },
    width: "100%",
    borderRadius: theme.spacing(1),
    border: `1px solid ${colorsLight.gray.divider}`,
    cursor: "pointer",
  },
  buttonAndLabel: {
    display: "flex",
    verticalAlign: "top",
    padding: "15px 16px 12px 16px",
  },

  title: {
    color: colorsLight.font.primary,
    paddingBottom: theme.spacing(2),
  },
  valueText: {
    display: "inline-block",
    wordWrap: "break-word",
    fontFamily: "Gilroy-Light",
    paddingTop: "2px",
  },
  grid: {
    wordWrap: "break-word",
  },
  component: {
    minHeight: theme.spacing(4),
    alignItems: "center",
    display: "flex",
    paddingBottom: theme.spacing(2),
  },
  container: {
    minHeight: theme.spacing(4),
  },
  captionContainer: {
    paddingBottom: theme.spacing(2),
    display: "flex",
    paddingLeft: theme.spacing(2),
  },
  captionFloatRightContainer: {
    verticalAlign: "top",
    padding: "15px 16px 12px 16px",
    display: "flex",
    marginLeft: "auto",
  },
  caption: {
    "&.acceptNudgeBanner": {
      backgroundColor: colorsLight.success.light,
      color: colorsLight.success.dark,
    },
    "&.rejectNudgeBanner": {
      backgroundColor: colorsLight.warning.light,
      color: colorsLight.warning.dark,
    },
    padding: theme.spacing(1, 0.5),
    paddingLeft: "5px",
    display: "flex",
    borderRadius: theme.spacing(0.5),
  },
  optionText: {
    paddingBottom: theme.spacing(2),
  },
  selectionText: {
    color: colorsLight.font.secondary,
    paddingLeft: theme.spacing(1.5),
    alignItems: "center",
    height: theme.spacing(3),
    paddingTop: "2px",
    display: "inline-block",
    paddingRight: theme.spacing(2),
  },
  message: {
    color: colorsLight.font.secondary,
    paddingBottom: theme.spacing(3),
  },
  expandDateContainer: {
    width: "193.8%",
  },
}));
