import {
  GetCandidateSuggestedValuesResponse,
  FacilityCategory,
  SemanticProcedureCode,
  PlaceOfService,
  Provider,
  Facility,
  BulkUpdateCandidateFeedbackRequestBody,
  ServiceRequestResponse,
  Requestor,
  AdditionalCareParticipant,
} from "@coherehealth/core-platform-api";
import { PriorAuthRequirements } from "../components/AuthBuilder/common";
import { parseDateFromISOString, formatDateStr } from "@coherehealth/common";
import { DiagnosisCode, ProcedureCode, SuggestedValue } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "components/ServiceRequest";

export function initSuggestedRequestor(suggestions: GetCandidateSuggestedValuesResponse | null): SuggestedRequestor {
  return {
    firstName: suggestions?.requestorFirstName
      ? suggestions.requestorFirstName[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    lastName: suggestions?.requestorLastName
      ? suggestions.requestorLastName[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    email: suggestions?.requestorEmail
      ? suggestions.requestorEmail[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    phoneNumber: suggestions?.requestorPhoneNumber
      ? suggestions.requestorPhoneNumber[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: undefined },
    faxNumber: suggestions?.requestorFaxNumber
      ? suggestions.requestorFaxNumber[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: undefined },
  };
}

export function initSuggestedMember(suggestions: GetCandidateSuggestedValuesResponse | null): SuggestedMember {
  return {
    memberId: suggestions?.patientMemberId
      ? suggestions.patientMemberId[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    memberDob: suggestions?.patientDateOfBirth
      ? suggestions.patientDateOfBirth[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
    memberLastName: suggestions?.patientLastName
      ? suggestions.patientLastName[0]
      : { attachmentId: "", source: "", sourceVersion: "", fieldName: "", predictedValue: "" },
  };
}

function extractPredictions(suggestedValues: SuggestedValue[] | null): Record<string, SuggestedValue> {
  const predictions: Record<string, SuggestedValue> = {};
  if (!suggestedValues?.length) {
    return predictions;
  }

  suggestedValues.forEach((prediction) => {
    if (prediction?.predictedValue) {
      predictions[prediction.predictedValue] = prediction;
    }
  });

  return predictions;
}

type ClientSuggestedValue = SuggestedValue & {
  diagnosisCodeEntity?: DiagnosisCode;
  procedureCodeEntity?: ProcedureCode;
  dateEntity?: Date;
  facilityCategoryEntity?: FacilityCategory;
  placeOfServiceEntity?: PlaceOfService;
  providerEntity?: Provider;
  facilityEntity?: Facility;
  stringEntity?: string;
  booleanEntity?: boolean;
  additionalCareParticipantEntity?: AdditionalCareParticipant;
};

export interface SuggestedRequestor {
  firstName?: SuggestedValue;
  lastName?: SuggestedValue;
  email?: SuggestedValue;
  phoneNumber?: SuggestedValue;
  faxNumber?: SuggestedValue;
  channel?: SuggestedValue;
  user?: SuggestedValue;
  intakeTimestamp?: SuggestedValue;
}

export interface SuggestedPriorAuthRequirements {
  primaryDiagnosis?: ClientSuggestedValue;
  secondaryDiagnoses?: ClientSuggestedValue[];
  desiredProcedureCodes?: ClientSuggestedValue[];
  startDate?: ClientSuggestedValue;
  encounterType?: ClientSuggestedValue;
  showPxCheckbox: boolean;
  noPxServiceRequired: boolean;
}

export interface SuggestedFormContent {
  placeOfService?: ClientSuggestedValue;
  orderingProvider?: ClientSuggestedValue;
  orderingProviderSelectedTin?: ClientSuggestedValue;
  performingProvider?: ClientSuggestedValue;
  performingProviderSelectedTin?: ClientSuggestedValue;
  facility?: ClientSuggestedValue;
  facilitySelectedTin?: ClientSuggestedValue;
  isExpedited?: ClientSuggestedValue;
  endDate?: ClientSuggestedValue;
  caseType?: ClientSuggestedValue;
  additionalCareParticipant?: ClientSuggestedValue;
}

export interface SuggestedMember {
  memberId?: SuggestedValue;
  memberDob?: SuggestedValue;
  memberLastName?: SuggestedValue;
}

export function isNotNullOrUndefined<T extends SemanticProcedureCode>(input: null | undefined | T): input is T {
  return input != null;
}

export function initSuggestedPriorAuthRequirements(
  suggestions: GetCandidateSuggestedValuesResponse | null,
  fetchedPrimaryDxCode: DiagnosisCode[] | null,
  fetchedSecondaryDxCodes: DiagnosisCode[] | null,
  fetchedPxCodes: (SemanticProcedureCode | null)[] | null,
  currentSuggestedPAR: SuggestedPriorAuthRequirements
): SuggestedPriorAuthRequirements {
  const primaryDxCodeSuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.primarySemanticDiagnosisCode ? [suggestions.primarySemanticDiagnosisCode[0]] : []
  );
  const validatedPrimaryDxCodes: DiagnosisCode[] =
    fetchedPrimaryDxCode?.filter((dxCode) => dxCode.code in primaryDxCodeSuggestions) || [];
  const suggestedPrimaryDxCode = !!validatedPrimaryDxCodes.length
    ? Object.assign({}, primaryDxCodeSuggestions[validatedPrimaryDxCodes[0].code], {
        diagnosisCodeEntity: validatedPrimaryDxCodes[0],
      })
    : undefined;

  const secondaryDxCodeSuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.secondarySemanticDiagnosisCodes
  );
  const validatedSecondaryDxCodes: DiagnosisCode[] =
    fetchedSecondaryDxCodes?.filter((dxCode) => dxCode.code in secondaryDxCodeSuggestions) || [];
  const suggestedSecondaryDxCodes: SuggestedValue[] | undefined = !!validatedSecondaryDxCodes.length
    ? validatedSecondaryDxCodes.map((dxCode) =>
        Object.assign({}, secondaryDxCodeSuggestions[dxCode.code], { diagnosisCodeEntity: dxCode })
      )
    : undefined;

  const nonNullPxCodes: SemanticProcedureCode[] | undefined = fetchedPxCodes?.filter(isNotNullOrUndefined);
  const pxCodeSuggestions = extractPredictions(suggestions?.semanticProcedureCodes);
  const validatedPxCodes: ProcedureCode[] = nonNullPxCodes?.filter((pxCode) => pxCode.code in pxCodeSuggestions) || [];
  const suggestedPxCodes = !!validatedPxCodes.length
    ? validatedPxCodes.map((pxCode) =>
        Object.assign({}, pxCodeSuggestions[pxCode.code], { procedureCodeEntity: pxCode })
      )
    : undefined;

  const suggestedStartDate = suggestions?.startDate
    ? Object.assign({}, suggestions.startDate[0], {
        dateEntity: parseDateFromISOString(suggestions.startDate[0].predictedValue),
      })
    : undefined;

  const suggestedEncounterType = suggestions?.encounterType
    ? Object.assign({}, suggestions.encounterType[0], {
        facilityCategoryEntity: suggestions.encounterType[0].predictedValue as FacilityCategory,
      })
    : undefined;

  return {
    ...currentSuggestedPAR,
    primaryDiagnosis: suggestedPrimaryDxCode || currentSuggestedPAR.primaryDiagnosis,
    secondaryDiagnoses: suggestedSecondaryDxCodes || currentSuggestedPAR.secondaryDiagnoses,
    desiredProcedureCodes: suggestedPxCodes || currentSuggestedPAR.desiredProcedureCodes,
    startDate: suggestedStartDate || currentSuggestedPAR.startDate,
    encounterType: suggestedEncounterType || currentSuggestedPAR.encounterType,
  };
}

export function generateInitialPriorAuthRequirementsWithSuggestions(
  suggestedPriorAuthRequirements?: SuggestedPriorAuthRequirements,
  currentPAR?: PriorAuthRequirements,
  suggestionsReady?: boolean
): PriorAuthRequirements {
  const defaultSecondaryDiagnosesCodes: DiagnosisCode[] = [];
  const defaultProcedureCodes: ProcedureCode[] = [];
  const defaultEncounterType = "OUTPATIENT";

  return {
    ...(currentPAR || { showPxCheckbox: false, noPxServiceRequired: false }),
    primaryDiagnosis:
      currentPAR?.primaryDiagnosis || suggestedPriorAuthRequirements?.primaryDiagnosis?.diagnosisCodeEntity,
    secondaryDiagnoses: currentPAR?.secondaryDiagnoses?.length
      ? currentPAR.secondaryDiagnoses
      : suggestedPriorAuthRequirements?.secondaryDiagnoses
          ?.map((clientSV) => clientSV.diagnosisCodeEntity)
          .filter(isNotNullOrUndefined) || defaultSecondaryDiagnosesCodes,
    desiredProcedureCodes: currentPAR?.desiredProcedureCodes?.length
      ? currentPAR.desiredProcedureCodes
      : suggestedPriorAuthRequirements?.desiredProcedureCodes
          ?.map((clientSV) => clientSV.procedureCodeEntity)
          .filter(isNotNullOrUndefined) || defaultProcedureCodes,
    startDate:
      currentPAR?.startDate && !suggestionsReady
        ? currentPAR.startDate
        : suggestedPriorAuthRequirements?.startDate?.dateEntity,
    encounterType:
      currentPAR?.encounterType && !suggestionsReady
        ? currentPAR.encounterType
        : suggestedPriorAuthRequirements?.encounterType?.facilityCategoryEntity || defaultEncounterType,
  };
}

// Takes in two lists, A and B, of Diagnosis/Procedure codes and checks that the code values are equivalent
export function compareCodeListEqualityNoOrder(
  arr1: DiagnosisCode[] | ProcedureCode[] | undefined,
  arr2: DiagnosisCode[] | ProcedureCode[] | undefined
): boolean {
  if (!arr1 || !arr2 || arr1.length === 0 || arr2.length === 0) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  const counts = new Map();
  arr1.forEach((value) => counts.set(value?.code, (counts.get(value?.code) ?? 0) + 1));
  arr2.forEach((value) => counts.set(value?.code, (counts.get(value?.code) ?? 0) - 1));
  return Array.from(counts.values()).every((count) => count === 0);
}

function filterValidatedFetchedProvidersOrFacilities(
  predictions: Record<string, SuggestedValue>,
  fetchedProviders: Provider[] | null,
  fetchedFacilities: Facility[] | null,
  suggestedNpi: string | undefined,
  suggestedTin: string | undefined
): Provider[] | Facility[] {
  if (
    Object.keys(predictions).length === 0 ||
    (fetchedProviders && fetchedFacilities) ||
    (!suggestedNpi && !suggestedTin)
  ) {
    return [];
  }

  // If we have an suggested NPI, filter for fetched entities that have the suggested npi
  // If we don't have a suggested NPI, but have a suggested selectedTIN, then filter for the fetched
  //    entities that have the suggested TIN
  // Otherwise, return default []
  if (fetchedProviders) {
    if (suggestedNpi) {
      return fetchedProviders.filter((provider: Provider) => provider?.npi || "" in predictions);
    } else {
      return fetchedProviders.filter((provider: Provider) => {
        let externallyValid: boolean = false;
        if (provider?.tinList?.length) {
          provider?.tinList.forEach((tin) => {
            if (tin in predictions) {
              externallyValid = true;
            }
          });
        }
        return externallyValid;
      });
    }
  } else if (fetchedFacilities) {
    if (suggestedNpi) {
      return fetchedFacilities.filter((facility: Facility) => facility?.npi || "" in predictions);
    } else {
      return fetchedFacilities.filter((facility: Facility) => {
        let externallyValid: boolean = false;
        if (facility?.tinList?.length) {
          facility?.tinList.forEach((tin) => {
            if (tin in predictions) {
              externallyValid = true;
            }
          });
        }
        return externallyValid;
      });
    }
  } else {
    return [];
  }
}

export function initSuggestedFormContent(
  suggestions: GetCandidateSuggestedValuesResponse | null,
  fetchedPlaceOfServices: PlaceOfService[] | null,
  fetchedOrderingProviders: Provider[] | null,
  fetchedPerformingProviders: Provider[] | null,
  fetchedFacilities: Facility[] | null,
  currentSuggestedFormContent: SuggestedFormContent
): SuggestedFormContent {
  // Place of Service
  const posSuggestions: Record<string, SuggestedValue> = extractPredictions(
    suggestions?.placeOfServiceCode ? [suggestions.placeOfServiceCode[0]] : []
  );
  const validatedPos: PlaceOfService[] = fetchedPlaceOfServices?.filter((pos) => pos.code in posSuggestions) || [];
  const suggestedPos = !!validatedPos.length
    ? Object.assign({}, posSuggestions[validatedPos[0].code], {
        placeOfServiceEntity: validatedPos[0],
      })
    : undefined;

  // Ordering Provider
  const orderingProviderSuggestions: Record<string, SuggestedValue> = suggestions?.orderingProviderNpi
    ? extractPredictions(suggestions?.orderingProviderNpi ? [suggestions.orderingProviderNpi[0]] : [])
    : extractPredictions(suggestions?.orderingProviderSelectedTin ? [suggestions.orderingProviderSelectedTin[0]] : []);
  const validatedOrderingProviders: Provider[] = filterValidatedFetchedProvidersOrFacilities(
    orderingProviderSuggestions,
    fetchedOrderingProviders,
    null,
    suggestions?.orderingProviderNpi,
    suggestions?.orderingProviderSelectedTin
  );

  const suggestedOrderingProvider =
    !!validatedOrderingProviders.length &&
    suggestions?.orderingProviderNpi &&
    orderingProviderSuggestions[suggestions.orderingProviderNpi[0].predictedValue]
      ? Object.assign({}, orderingProviderSuggestions[suggestions.orderingProviderNpi[0].predictedValue], {
          providerEntity: validatedOrderingProviders[0],
        })
      : !!validatedOrderingProviders.length &&
        suggestions?.orderingProviderSelectedTin &&
        orderingProviderSuggestions[suggestions.orderingProviderSelectedTin[0].predictedValue]
      ? Object.assign({}, orderingProviderSuggestions[suggestions.orderingProviderSelectedTin[0].predictedValue], {
          providerEntity: validatedOrderingProviders[0],
        })
      : undefined;

  // Performing Provider
  const performingProviderSuggestions: Record<string, SuggestedValue> = suggestions?.performingProviderNpi
    ? extractPredictions(suggestions?.performingProviderNpi ? [suggestions.performingProviderNpi[0]] : [])
    : extractPredictions(
        suggestions?.performingProviderSelectedTin ? [suggestions.performingProviderSelectedTin[0]] : []
      );
  const validatedPerformingProviders: Provider[] = filterValidatedFetchedProvidersOrFacilities(
    performingProviderSuggestions,
    fetchedPerformingProviders,
    null,
    suggestions?.performingProviderNpi,
    suggestions?.performingProviderSelectedTin
  );
  const suggestedPerformingProvider =
    !!validatedPerformingProviders.length &&
    suggestions?.performingProviderNpi &&
    performingProviderSuggestions[suggestions.performingProviderNpi[0].predictedValue]
      ? Object.assign({}, performingProviderSuggestions[suggestions.performingProviderNpi[0].predictedValue], {
          providerEntity: validatedPerformingProviders[0],
        })
      : !!validatedPerformingProviders.length &&
        suggestions?.performingProviderSelectedTin &&
        performingProviderSuggestions[suggestions.performingProviderSelectedTin[0].predictedValue]
      ? Object.assign({}, performingProviderSuggestions[suggestions.performingProviderSelectedTin[0].predictedValue], {
          providerEntity: validatedPerformingProviders[0],
        })
      : undefined;

  // Facility
  const facilitySuggestions: Record<string, SuggestedValue> = suggestions?.facilityNpi
    ? extractPredictions(suggestions?.facilityNpi ? [suggestions.facilityNpi[0]] : [])
    : extractPredictions(suggestions?.facilitySelected ? [suggestions.facilitySelectedTin[0]] : []);
  const validatedFacilities: Provider[] = filterValidatedFetchedProvidersOrFacilities(
    facilitySuggestions,
    fetchedFacilities,
    null,
    suggestions?.facilityNpi,
    suggestions?.facilitySelectedTin
  );
  const suggestedFacility =
    !!validatedFacilities.length &&
    suggestions?.facilityNpi &&
    facilitySuggestions[suggestions.facilityNpi[0].predictedValue]
      ? Object.assign({}, facilitySuggestions[suggestions.facilityNpi[0].predictedValue], {
          facilityEntity: validatedFacilities[0],
        })
      : !!validatedFacilities.length &&
        suggestions?.facilitySelectedTin &&
        facilitySuggestions[suggestions.facilitySelectedTin[0].predictedValue]
      ? Object.assign({}, facilitySuggestions[suggestions.facilitySelectedTin[0].predictedValue], {
          facilityEntity: validatedFacilities[0],
        })
      : undefined;

  const suggestedOrderingProviderSelectedTin = suggestions?.orderingProviderSelectedTin
    ? Object.assign({}, suggestions.orderingProviderSelectedTin[0], {
        stringEntity: suggestions.orderingProviderSelectedTin[0].predictedValue,
      })
    : undefined;
  const suggestedPerformingProviderSelectedTin = suggestions?.performingProviderSelectedTin
    ? Object.assign({}, suggestions.performingProviderSelectedTin[0], {
        stringEntity: suggestions.performingProviderSelectedTin[0].predictedValue,
      })
    : undefined;
  const suggestedFacilitySelectedTin = suggestions?.facilitySelectedTin
    ? Object.assign({}, suggestions.facilitySelectedTin[0], {
        stringEntity: suggestions.facilitySelectedTin[0].predictedValue,
      })
    : undefined;

  // End Date
  const suggestedEndDate = suggestions?.endDate
    ? Object.assign({}, suggestions.endDate[0], {
        dateEntity: parseDateFromISOString(suggestions.endDate[0].predictedValue),
      })
    : undefined;

  // isExpedited
  const suggestedIsExpedited = suggestions?.isExpedited
    ? Object.assign({}, suggestions.isExpedited[0], {
        booleanEntity: suggestions.isExpedited[0].predictedValue === "true",
      })
    : undefined;

  // caseType
  const suggestedCaseType = suggestions?.caseType
    ? Object.assign({}, suggestions.caseType[0], {
        stringEntity: suggestions.caseType[0].predictedValue,
      })
    : undefined;

  return {
    ...currentSuggestedFormContent,
    placeOfService: suggestedPos || undefined,
    orderingProvider: suggestedOrderingProvider,
    orderingProviderSelectedTin: suggestedOrderingProviderSelectedTin,
    performingProvider: suggestedPerformingProvider,
    performingProviderSelectedTin: suggestedPerformingProviderSelectedTin,
    facility: suggestedFacility,
    facilitySelectedTin: suggestedFacilitySelectedTin,
    endDate: suggestedEndDate,
    isExpedited: suggestedIsExpedited,
    caseType: suggestedCaseType,
  };
}

export function generateFormContentFromSuggestions(
  suggestedFormContent: SuggestedFormContent,
  currentFormContent: ServiceRequestFormContent
): ServiceRequestFormContent {
  return {
    ...currentFormContent,
    placeOfService: suggestedFormContent?.placeOfService?.placeOfServiceEntity || currentFormContent.placeOfService,
    orderingProvider: suggestedFormContent?.orderingProvider?.providerEntity || currentFormContent?.orderingProvider,
    orderingProviderSelectedTin:
      suggestedFormContent?.orderingProviderSelectedTin?.stringEntity &&
      suggestedFormContent?.orderingProvider?.providerEntity?.tinList &&
      suggestedFormContent.orderingProvider?.providerEntity?.tinList.includes(
        suggestedFormContent.orderingProviderSelectedTin.stringEntity
      )
        ? suggestedFormContent.orderingProviderSelectedTin.stringEntity
        : suggestedFormContent?.orderingProvider?.providerEntity?.tinList?.length === 1
        ? suggestedFormContent.orderingProvider.providerEntity?.tinList[0]
        : currentFormContent?.orderingProviderSelectedTin,
    performingProvider:
      suggestedFormContent?.performingProvider?.providerEntity || currentFormContent?.performingProvider,
    performingProviderSelectedTin:
      suggestedFormContent?.performingProviderSelectedTin?.stringEntity &&
      suggestedFormContent?.performingProvider?.providerEntity?.tinList &&
      suggestedFormContent.performingProvider?.providerEntity.tinList.includes(
        suggestedFormContent.performingProviderSelectedTin.stringEntity
      )
        ? suggestedFormContent.performingProviderSelectedTin.stringEntity
        : suggestedFormContent?.performingProvider?.providerEntity?.tinList?.length === 1
        ? suggestedFormContent.performingProvider.providerEntity.tinList[0]
        : currentFormContent?.performingProviderSelectedTin,
    facility: suggestedFormContent?.facility?.facilityEntity || currentFormContent?.facility,
    facilitySelectedTin:
      suggestedFormContent?.facilitySelectedTin?.stringEntity &&
      suggestedFormContent?.facility?.facilityEntity?.tinList &&
      suggestedFormContent.facility?.facilityEntity.tinList.includes(
        suggestedFormContent.facilitySelectedTin.stringEntity
      )
        ? suggestedFormContent.facilitySelectedTin.stringEntity
        : suggestedFormContent?.facility?.facilityEntity?.tinList?.length === 1
        ? suggestedFormContent.facility.facilityEntity.tinList[0]
        : currentFormContent?.facilitySelectedTin,
    endDate: suggestedFormContent?.endDate?.dateEntity || currentFormContent?.endDate,
    isExpedited: suggestedFormContent?.isExpedited?.booleanEntity || currentFormContent.isExpedited,
  };
}

export function compareSuggestionsToFinalSR(
  sr: ServiceRequestResponse,
  requestor: Requestor | undefined,
  suggestedRequestor: SuggestedRequestor | undefined,
  suggestedPriorAuthRequirements: SuggestedPriorAuthRequirements | undefined,
  suggestedFormContent: SuggestedFormContent | undefined
): BulkUpdateCandidateFeedbackRequestBody {
  const accepted: string[] = [];
  const rejected: string[] = [];

  if (suggestedRequestor?.firstName && suggestedRequestor?.firstName?.id) {
    suggestedRequestor.firstName.predictedValue === requestor?.firstName
      ? accepted.push(suggestedRequestor.firstName.id)
      : rejected.push(suggestedRequestor.firstName.id);
  }

  if (suggestedRequestor?.lastName && suggestedRequestor?.lastName?.id) {
    suggestedRequestor.lastName.predictedValue === requestor?.lastName
      ? accepted.push(suggestedRequestor.lastName.id)
      : rejected.push(suggestedRequestor.lastName.id);
  }

  if (suggestedRequestor?.email && suggestedRequestor?.email?.id) {
    suggestedRequestor.email.predictedValue === requestor?.email
      ? accepted.push(suggestedRequestor.email.id)
      : rejected.push(suggestedRequestor.email.id);
  }

  if (suggestedRequestor?.phoneNumber && suggestedRequestor?.phoneNumber?.id) {
    suggestedRequestor.phoneNumber.predictedValue === requestor?.phoneNumber?.number
      ? accepted.push(suggestedRequestor.phoneNumber.id)
      : rejected.push(suggestedRequestor.phoneNumber.id);
  }

  if (suggestedRequestor?.faxNumber && suggestedRequestor?.faxNumber?.id) {
    suggestedRequestor.faxNumber.predictedValue === requestor?.faxNumber?.number
      ? accepted.push(suggestedRequestor.faxNumber.id)
      : rejected.push(suggestedRequestor.faxNumber.id);
  }

  if (
    suggestedPriorAuthRequirements?.primaryDiagnosis?.id &&
    suggestedPriorAuthRequirements?.primaryDiagnosis?.diagnosisCodeEntity
  ) {
    sr.primarySemanticDiagnosisCode?.code === suggestedPriorAuthRequirements.primaryDiagnosis.diagnosisCodeEntity.code
      ? accepted.push(suggestedPriorAuthRequirements.primaryDiagnosis.id)
      : rejected.push(suggestedPriorAuthRequirements.primaryDiagnosis.id);
  }

  if (!!suggestedPriorAuthRequirements?.secondaryDiagnoses?.length) {
    suggestedPriorAuthRequirements.secondaryDiagnoses.forEach((dxCodeSuggestion) => {
      if (dxCodeSuggestion.id) {
        sr.secondarySemanticDiagnosisCodes
          ?.map((dxCode) => dxCode.code)
          .includes(dxCodeSuggestion?.diagnosisCodeEntity?.code || "")
          ? accepted.push(dxCodeSuggestion.id)
          : rejected.push(dxCodeSuggestion.id);
      }
    });
  }

  if (!!suggestedPriorAuthRequirements?.desiredProcedureCodes?.length) {
    suggestedPriorAuthRequirements.desiredProcedureCodes.forEach((pxCodeSuggestion) => {
      if (pxCodeSuggestion.id) {
        sr.semanticProcedureCodes
          ?.map((pxCode) => pxCode.code)
          .includes(pxCodeSuggestion?.procedureCodeEntity?.code || "")
          ? accepted.push(pxCodeSuggestion.id)
          : rejected.push(pxCodeSuggestion.id);
      }
    });
  }

  if (suggestedPriorAuthRequirements?.startDate?.id && suggestedPriorAuthRequirements?.startDate?.dateEntity) {
    sr.startDate === suggestedPriorAuthRequirements.startDate.predictedValue
      ? accepted.push(suggestedPriorAuthRequirements.startDate.id)
      : rejected.push(suggestedPriorAuthRequirements.startDate.id);
  }

  if (
    suggestedPriorAuthRequirements?.encounterType?.id &&
    suggestedPriorAuthRequirements?.encounterType?.facilityCategoryEntity
  ) {
    sr.encounterType?.toString() === suggestedPriorAuthRequirements.encounterType.facilityCategoryEntity.toString()
      ? accepted.push(suggestedPriorAuthRequirements.encounterType.id)
      : rejected.push(suggestedPriorAuthRequirements.encounterType.id);
  }

  if (suggestedFormContent?.placeOfService?.id && suggestedFormContent.placeOfService.placeOfServiceEntity) {
    sr.placeOfService?.id === suggestedFormContent.placeOfService.placeOfServiceEntity.id
      ? accepted.push(suggestedFormContent.placeOfService.id)
      : rejected.push(suggestedFormContent.placeOfService.id);
  }

  if (suggestedFormContent?.orderingProvider?.id && suggestedFormContent.orderingProvider.providerEntity) {
    sr.orderingProvider?.id === suggestedFormContent.orderingProvider.providerEntity.id
      ? accepted.push(suggestedFormContent.orderingProvider.id)
      : rejected.push(suggestedFormContent.orderingProvider.id);
  }

  if (
    suggestedFormContent?.orderingProviderSelectedTin?.id &&
    suggestedFormContent.orderingProviderSelectedTin.stringEntity
  ) {
    sr.orderingProviderSelectedTin === suggestedFormContent.orderingProviderSelectedTin.stringEntity
      ? accepted.push(suggestedFormContent.orderingProviderSelectedTin.id)
      : rejected.push(suggestedFormContent.orderingProviderSelectedTin.id);
  }

  if (suggestedFormContent?.performingProvider?.id && suggestedFormContent.performingProvider.providerEntity) {
    sr.performingProvider?.id === suggestedFormContent.performingProvider.providerEntity.id
      ? accepted.push(suggestedFormContent.performingProvider.id)
      : rejected.push(suggestedFormContent.performingProvider.id);
  }
  if (
    suggestedFormContent?.performingProviderSelectedTin?.id &&
    suggestedFormContent.performingProviderSelectedTin.providerEntity
  ) {
    sr.performingProviderSelectedTin === suggestedFormContent.performingProviderSelectedTin.stringEntity
      ? accepted.push(suggestedFormContent.performingProviderSelectedTin.id)
      : rejected.push(suggestedFormContent.performingProviderSelectedTin.id);
  }

  if (suggestedFormContent?.facility?.id && suggestedFormContent.facility.facilityEntity) {
    sr.facility?.id === suggestedFormContent.facility.facilityEntity.id
      ? accepted.push(suggestedFormContent.facility.id)
      : rejected.push(suggestedFormContent.facility.id);
  }
  if (suggestedFormContent?.facilitySelectedTin?.id && suggestedFormContent.facilitySelectedTin.stringEntity) {
    sr.facilitySelectedTin === suggestedFormContent.facilitySelectedTin.stringEntity
      ? accepted.push(suggestedFormContent.facilitySelectedTin.id)
      : rejected.push(suggestedFormContent.facilitySelectedTin.id);
  }

  if (suggestedFormContent?.endDate?.id && suggestedFormContent?.endDate?.dateEntity) {
    sr.endDate === suggestedFormContent.endDate.predictedValue
      ? accepted.push(suggestedFormContent.endDate.id)
      : rejected.push(suggestedFormContent.endDate.id);
  }

  if (suggestedFormContent?.isExpedited?.id && suggestedFormContent?.isExpedited?.booleanEntity !== undefined) {
    sr.urgency?.isExpedited === suggestedFormContent.isExpedited.booleanEntity
      ? accepted.push(suggestedFormContent.isExpedited.id)
      : rejected.push(suggestedFormContent.isExpedited.id);
  }

  if (suggestedFormContent?.caseType?.id && suggestedFormContent?.caseType?.stringEntity) {
    sr.authorizationType?.toUpperCase() === suggestedFormContent.caseType.stringEntity.toUpperCase()
      ? accepted.push(suggestedFormContent.caseType.id)
      : rejected.push(suggestedFormContent.caseType.id);
  }

  return {
    accepted: accepted,
    rejected: rejected,
  };
}

export function compareSuggestionsToSearch(
  memberId: string,
  memberDob: string,
  memberLastName: string,
  suggestedMember: SuggestedMember | undefined
): BulkUpdateCandidateFeedbackRequestBody {
  const accepted: string[] = [];
  const rejected: string[] = [];

  if (suggestedMember?.memberId && suggestedMember?.memberId?.id) {
    suggestedMember.memberId.predictedValue === memberId
      ? accepted.push(suggestedMember.memberId.id)
      : rejected.push(suggestedMember.memberId.id);
  }

  if (suggestedMember?.memberDob && suggestedMember?.memberDob?.id) {
    formatDateStr(suggestedMember.memberDob.predictedValue) === memberDob
      ? accepted.push(suggestedMember.memberDob.id)
      : rejected.push(suggestedMember.memberDob.id);
  }

  if (suggestedMember?.memberLastName && suggestedMember?.memberLastName?.id) {
    suggestedMember.memberLastName.predictedValue === memberLastName
      ? accepted.push(suggestedMember.memberLastName.id)
      : rejected.push(suggestedMember.memberLastName.id);
  }

  return {
    accepted: accepted,
    rejected: rejected,
  };
}
