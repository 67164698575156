import {
  FormConfigurationFieldSpec,
  ServiceRequestConfigurationSpecification,
  useSearchServiceRequestConfigurationSpecification,
  FacilityCategory,
  ServiceRequestType,
  OutOfNetworkCheckConfiguration,
  PxCodeAttributeConfiguration,
} from "@coherehealth/core-platform-api";
import { error as logError } from "logger";
import { useEffect, useMemo } from "react";
import { formatDateToISODate, today, useGeneralAuthSubmissionWorflowOn } from "@coherehealth/common";
import { dateProperlyFormatted, safelyGetFormConfig } from "util/serviceRequest";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

//if you add a field to this list, make sure to also add the conversion to util/serviceRequest.ts#safelyGetFormConfig
export type ServiceRequestFieldName =
  | "singleClinicalService"
  | "encounterType"
  | "placeOfService"
  | "primaryDiagnosis"
  | "secondaryDiagnoses"
  | "procedureCodes"
  | "recurring"
  | "startEndDate"
  | "units"
  | "orderingProvider"
  | "orderingProviderAddress"
  | "orderingProviderNPI"
  | "orderingProviderTIN"
  | "performingProvider"
  | "performingProviderAddress"
  | "performingProviderNPI"
  | "performingProviderTIN"
  | "facility"
  | "facilityAddress"
  | "facilityTIN"
  | "facilityNPI"
  | "performingProviderPracticeSelectedTIN"
  | "performingProviderPracticeSelectedAddress"
  | "performingProviderPractice"
  | "performingProviderPracticeSelectedNPI"
  | "urgency"
  | "behavioralHealthReviewType"
  | "behavioralHealthAdmissionType"
  | "admissionDischargeDate"
  | "faxInputField"
  | "procedureCodeWithUnits"
  | "prescribedDrug"
  // facilityOutOfNetworkStatusDisplay controls if the OON check results are shown for the facility
  | "facilityOutOfNetworkStatusDisplay"
  // performingProviderPracticeOutOfNetworkStatusDisplay controls if the OON check results are shown for the performing provider practice
  | "performingProviderPracticeOutOfNetworkStatusDisplay"
  // performingProviderOutOfNetworkStatusDisplay controls if the OON check results are shown for the performing provider
  | "performingProviderOutOfNetworkStatusDisplay"
  // orderingProviderOutOfNetworkStatusDisplay controls if the OON check results are shown for the ordering provider
  | "orderingProviderOutOfNetworkStatusDisplay"
  // careParticipantOutOfNetworkStatusDisplay controls if the OON check results are shown for the additional care participant
  | "careParticipantOutOfNetworkStatusDisplay"
  // outOfNetworkOrderingProvider controls if the OON check is run and results are shown for ordering provider
  | "outOfNetworkOrderingProvider"
  // outOfNetworkCheck controls if the OON check is run for facility/performing provider
  | "outOfNetworkCheck"
  // facilityOutOfNetworkExceptionReason controls if a facility's OON waiver is disallowed, optional, or required
  | "facilityOutOfNetworkExceptionReason"
  // performingProviderOutOfNetworkExceptionReason controls if a performing provider's OON waiver is disallowed, optional, or required
  | "performingProviderOutOfNetworkExceptionReason"
  // performingProviderOutOfNetworkExceptionReason controls if a performing provider Practice's OON waiver is disallowed, optional, or required
  | "performingProviderPracticeOutOfNetworkExceptionReason"
  // orderingProviderOutOfNetworkExceptionReason controls if an ordering provider's OON waiver is disallowed, optional, or required
  | "orderingProviderOutOfNetworkExceptionReason"
  | "facilityOutOfNetworkExceptionComment"
  | "performingProviderOutOfNetworkExceptionComment"
  | "performingProviderPracticeOutOfNetworkExceptionComment"
  | "orderingProviderOutOfNetworkExceptionComment"
  | "careParticipantOutOfNetworkExceptionReason"
  | "careParticipantOutOfNetworkExceptionComment"
  | "userDeclaredOONException"
  | "userSelectedOONException"
  | "patientStayDateRanges"
  | "authCategory"
  | "authSubCategory"
  | "admissionDate"
  | "admissionTime"
  | "dischargeTime"
  | "dischargeDate"
  | "dischargedTo"
  | "expectedAdmissionDate"
  | "expectedDischargeDate"
  | "additionalCareParticipants"
  | "nonPalCheckbox"
  | "blockUserIfExceedsRecommendedEndDate"
  | "expeditedTatUpdateTimestamp"
  | "admissionSource"
  | "patientStatus"
  | "selectedDetails";

export type FacilityBasedGeneralAuthSubmissionForm =
  | "placeOfService"
  | "orderingProvider"
  | "orderingProviderNPI"
  | "orderingProviderTIN"
  | "orderingProviderAddress"
  | "facility"
  | "facilityAddress"
  | "facilityTIN"
  | "facilityNPI"
  | "performingProviderPracticeSelectedTIN"
  | "performingProviderPracticeSelectedAddress"
  | "performingProviderPractice"
  | "performingProviderPracticeSelectedNPI"
  | "performingProvider"
  | "performingProviderNPI"
  | "performingProviderTIN"
  | "performingProviderAddress"
  | "facilityOutOfNetworkExceptionReason"
  | "performingProviderOutOfNetworkExceptionReason"
  | "performingProviderPracticeOutOfNetworkExceptionReason"
  | "performingProviderPracticeOutOfNetworkExceptionComment"
  | "orderingProviderOutOfNetworkExceptionReason"
  | "facilityOutOfNetworkExceptionComment"
  | "performingProviderOutOfNetworkExceptionComment"
  | "orderingProviderOutOfNetworkExceptionComment"
  | "careParticipantOutOfNetworkExceptionReason"
  | "careParticipantOutOfNetworkExceptionComment"
  | "faxInputField"
  | "additionalCareParticipants";

export type ContinuationFieldName =
  | "urgency"
  | "procedureCodes"
  | "procedureCodeWithUnits"
  | "startEndDate"
  | "units"
  | "primaryDiagnosis"
  | "secondaryDiagnoses"
  | "admissionDischargeDate"
  | "orderingProvider"
  | "orderingProviderAddress"
  | "orderingProviderNPI"
  | "orderingProviderTIN"
  | "performingProvider"
  | "performingProviderAddress"
  | "performingProviderNPI"
  | "performingProviderTIN"
  | "facility"
  | "facilityAddress"
  | "facilityTIN"
  | "facilityNPI"
  | "performingProviderPracticeSelectedTIN"
  | "performingProviderPracticeSelectedAddress"
  | "performingProviderPractice"
  | "performingProviderPracticeSelectedNPI"
  | "encounterType"
  | "placeOfService"
  | "userDeclaredOONException"
  | "patientStayDateRanges"
  | "authCategory"
  | "authSubCategory"
  | "admissionDate"
  | "admissionTime"
  | "admissionSource"
  | "dischargeTime"
  | "dischargeDate"
  | "dischargedTo"
  | "orderingProviderOutOfNetworkStatusDisplay"
  | "performingProviderOutOfNetworkStatusDisplay"
  | "performingProviderPracticeOutOfNetworkStatusDisplay"
  | "facilityOutOfNetworkStatusDisplay"
  | "facilityOutOfNetworkExceptionComment"
  | "performingProviderOutOfNetworkExceptionComment"
  | "orderingProviderOutOfNetworkExceptionComment"
  | "facilityOutOfNetworkExceptionReason"
  | "performingProviderOutOfNetworkExceptionReason"
  | "performingProviderPracticeOutOfNetworkExceptionReason"
  | "performingProviderPracticeOutOfNetworkExceptionComment"
  | "orderingProviderOutOfNetworkExceptionReason"
  | "careParticipantOutOfNetworkExceptionReason"
  | "careParticipantOutOfNetworkExceptionComment"
  | "expectedAdmissionDate"
  | "expectedDischargeDate"
  | "additionalCareParticipants"
  | "nonPalCheckbox"
  | "blockUserIfExceedsRecommendedEndDate"
  | "expeditedTatUpdateTimestamp"
  | "patientStatus"
  | "selectedDetails";

export type FormConfiguration = {
  [key in ServiceRequestFieldName]: { fieldSpec: FormConfigurationFieldSpec };
};

export type ContinuationConfiguration = {
  [key in ContinuationFieldName]: { fieldSpec: FormConfigurationFieldSpec };
};

export const DEFAULT_FORM_CONFIG: FormConfiguration = {
  singleClinicalService: { fieldSpec: "REQUIRED" },
  encounterType: { fieldSpec: "REQUIRED" },
  placeOfService: { fieldSpec: "REQUIRED" },
  primaryDiagnosis: { fieldSpec: "REQUIRED" },
  secondaryDiagnoses: { fieldSpec: "OPTIONAL" },
  procedureCodes: { fieldSpec: "REQUIRED" },
  //TODO remove recurring from form config entirely after removal of simplifiedServiceFrequency flag
  recurring: { fieldSpec: "REQUIRED" },
  startEndDate: { fieldSpec: "REQUIRED" },
  units: { fieldSpec: "REQUIRED" },
  orderingProvider: { fieldSpec: "REQUIRED" },
  orderingProviderAddress: { fieldSpec: "OPTIONAL" },
  orderingProviderTIN: { fieldSpec: "OPTIONAL" },
  orderingProviderNPI: { fieldSpec: "OPTIONAL" },
  performingProvider: { fieldSpec: "OPTIONAL" },
  performingProviderAddress: { fieldSpec: "OPTIONAL" },
  performingProviderTIN: { fieldSpec: "OPTIONAL" },
  performingProviderNPI: { fieldSpec: "OPTIONAL" },
  facility: { fieldSpec: "REQUIRED" },
  facilityAddress: { fieldSpec: "REQUIRED" },
  facilityNPI: { fieldSpec: "REQUIRED" },
  facilityTIN: { fieldSpec: "REQUIRED" },
  performingProviderPracticeSelectedTIN: { fieldSpec: "REQUIRED" },
  performingProviderPracticeSelectedAddress: { fieldSpec: "REQUIRED" },
  performingProviderPractice: { fieldSpec: "REQUIRED" },
  performingProviderPracticeSelectedNPI: { fieldSpec: "REQUIRED" },
  urgency: { fieldSpec: "REQUIRED" },
  expeditedTatUpdateTimestamp: { fieldSpec: "REQUIRED" },
  patientStatus: { fieldSpec: "REQUIRED" },
  behavioralHealthAdmissionType: { fieldSpec: "OMIT" },
  behavioralHealthReviewType: { fieldSpec: "OMIT" },
  admissionDischargeDate: { fieldSpec: "OMIT" },
  faxInputField: { fieldSpec: "OMIT" },
  procedureCodeWithUnits: { fieldSpec: "OMIT" },
  prescribedDrug: { fieldSpec: "OMIT" },
  facilityOutOfNetworkStatusDisplay: { fieldSpec: "OMIT" },
  performingProviderPracticeOutOfNetworkStatusDisplay: { fieldSpec: "OMIT" },
  performingProviderOutOfNetworkStatusDisplay: { fieldSpec: "OMIT" },
  orderingProviderOutOfNetworkStatusDisplay: { fieldSpec: "OMIT" },
  careParticipantOutOfNetworkStatusDisplay: { fieldSpec: "OMIT" },
  outOfNetworkCheck: { fieldSpec: "OMIT" },
  outOfNetworkOrderingProvider: { fieldSpec: "OMIT" },
  facilityOutOfNetworkExceptionReason: { fieldSpec: "OMIT" },
  performingProviderOutOfNetworkExceptionReason: { fieldSpec: "OMIT" },
  performingProviderPracticeOutOfNetworkExceptionReason: { fieldSpec: "OMIT" },
  orderingProviderOutOfNetworkExceptionReason: { fieldSpec: "OMIT" },
  careParticipantOutOfNetworkExceptionReason: { fieldSpec: "OMIT" },
  careParticipantOutOfNetworkExceptionComment: { fieldSpec: "OMIT" },
  userDeclaredOONException: { fieldSpec: "OMIT" },
  userSelectedOONException: { fieldSpec: "OMIT" },
  patientStayDateRanges: { fieldSpec: "OMIT" },
  authCategory: { fieldSpec: "OMIT" },
  authSubCategory: { fieldSpec: "OMIT" },
  admissionDate: { fieldSpec: "OMIT" },
  admissionTime: { fieldSpec: "OMIT" },
  dischargeTime: { fieldSpec: "OMIT" },
  expectedAdmissionDate: { fieldSpec: "OMIT" },
  expectedDischargeDate: { fieldSpec: "OMIT" },
  dischargeDate: { fieldSpec: "OMIT" },
  dischargedTo: { fieldSpec: "OMIT" },
  admissionSource: { fieldSpec: "OMIT" },
  facilityOutOfNetworkExceptionComment: {
    fieldSpec: "OMIT",
  },
  performingProviderOutOfNetworkExceptionComment: {
    fieldSpec: "OMIT",
  },
  performingProviderPracticeOutOfNetworkExceptionComment: {
    fieldSpec: "OMIT",
  },
  orderingProviderOutOfNetworkExceptionComment: {
    fieldSpec: "OMIT",
  },
  additionalCareParticipants: {
    fieldSpec: "OMIT",
  },
  nonPalCheckbox: {
    fieldSpec: "OMIT",
  },
  blockUserIfExceedsRecommendedEndDate: {
    fieldSpec: "OMIT",
  },
  selectedDetails: {
    fieldSpec: "REQUIRED",
  },
};

export const DEFAULT_SR_CONFIG_SPEC: ServiceRequestConfigurationSpecificationWithTypedFormConfig = {
  id: "",
  dateCreated: "",
  lastUpdated: "",
  formFieldConfigurations: DEFAULT_FORM_CONFIG,
  outOfNetworkCheckConfiguration: { runOONCheckOnManuallyCreatedProviderFacility: false },
  pxCodeAttributeConfiguration: {
    showPxCodeAttributeField: false,
    delegatedVendorName: "Cohere",
  },
  isLoading: false,
};

interface GetServiceRequestConfigSpecProps extends ServiceRequestFormContent {
  patientId: string;
  healthPlanName: string;
}

interface ServiceRequestConfigurationSpecificationWithTypedFormConfig extends ServiceRequestConfigurationSpecification {
  formFieldConfigurations: FormConfiguration;
  outOfNetworkCheckConfiguration: OutOfNetworkCheckConfiguration;
  pxCodeAttributeConfiguration: PxCodeAttributeConfiguration;
  isLoading: boolean;
}

export function useServiceRequestConfigSpec({
  patientId,
  startDate,
  procedureCodes,
  isInpatient,
  serviceType,
  placeOfService,
  clinicalService,
  type,
  clinicalServices,
  healthPlanName,
  admissionDate,
  id,
}: GetServiceRequestConfigSpecProps): ServiceRequestConfigurationSpecificationWithTypedFormConfig {
  const effectiveStartDate = isInpatient ? admissionDate : startDate;
  const queryParams = useMemo(
    () => ({
      patientId: patientId,
      procedureCodes: procedureCodes?.map((procedureCode) => procedureCode.code),
      startDate: dateProperlyFormatted(effectiveStartDate)
        ? formatDateToISODate(effectiveStartDate)
        : formatDateToISODate(today()),
      encounterType: (isInpatient ? "INPATIENT" : "OUTPATIENT") as FacilityCategory,
      serviceType: serviceType ? serviceType : clinicalServices?.[0]?.serviceTypes?.[0],
      placeOfServiceName: placeOfService?.name,
      caseType: clinicalService ? clinicalService.caseType : clinicalServices?.[0]?.caseType,
      noPxServiceRequired: procedureCodes?.length === 0,
      serviceRequestType: (type === "Pharmacy" ? "PHARMACY" : "MEDICAL") as ServiceRequestType,
      clinicalServiceId: clinicalService?.id,
      serviceRequestId: id || "",
    }),
    [
      patientId,
      procedureCodes,
      isInpatient,
      serviceType,
      placeOfService?.name,
      type,
      clinicalService,
      clinicalServices,
      effectiveStartDate,
      id,
    ]
  );

  const {
    data: configSpec,
    refetch: getConfigSpec,
    loading: loadingConfigSpec,
    error,
  } = useSearchServiceRequestConfigurationSpecification({
    lazy: true,
    queryParams,
  });

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(healthPlanName);

  useEffect(() => {
    if (queryParams.patientId && (queryParams.clinicalServiceId || generalAuthSubmissionWorkflowEnabled)) {
      getConfigSpec({ queryParams });
    }
  }, [generalAuthSubmissionWorkflowEnabled, getConfigSpec, queryParams]);

  return safelyGetServiceRequestConfigurationSpecification(configSpec, loadingConfigSpec);
}

function safelyGetServiceRequestConfigurationSpecification(
  configSpec: ServiceRequestConfigurationSpecification | null,
  isLoading: boolean
): ServiceRequestConfigurationSpecificationWithTypedFormConfig {
  if (!configSpec) {
    return { ...DEFAULT_SR_CONFIG_SPEC, isLoading };
  }

  return {
    ...configSpec,
    formFieldConfigurations: safelyGetFormConfig(
      configSpec.formFieldConfigurations || DEFAULT_SR_CONFIG_SPEC.formFieldConfigurations
    ),
    outOfNetworkCheckConfiguration:
      configSpec.outOfNetworkCheckConfiguration || DEFAULT_SR_CONFIG_SPEC.outOfNetworkCheckConfiguration,
    pxCodeAttributeConfiguration:
      configSpec.pxCodeAttributeConfiguration || DEFAULT_SR_CONFIG_SPEC.pxCodeAttributeConfiguration,
    isLoading,
  };
}

export function convertContinuationFieldNameToFacilityBasedGeneralAuthSubmissionForm(
  prev: Record<ContinuationFieldName, boolean>
): Record<FacilityBasedGeneralAuthSubmissionForm, boolean> {
  return {
    ...prev,
    // These are here just because the continuation fields type doesn't include them, so they need defaults.
    faxInputField: false,
  };
}
