import { useContext, useMemo } from "react";
import { Body1, H2, SecondaryButton, useFeature } from "@coherehealth/common";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import ServiceRequestExclamation from "components/images/ServiceRequestExclamation.svg";
import { CommonFaxSidebarViewProps, backToMenuView } from "./common";
import { FaxAttachmentContext } from "./FaxAttachmentContext";
import { continuationWorkflowPath } from "util/serviceRequest";
import { getParametersFaxIntake } from "util/queryParams";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "inherit",
  },
  centered: {
    margin: "auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(10),
  },
  headerImage: {
    filter: "drop-shadow(0px 2px 2px rgba(207, 226, 231, 0.8))",
  },
  header: {
    padding: theme.spacing(4, 0, 1),
  },
  backButton: {
    margin: theme.spacing(3, 0),
    alignSelf: "flex-end",
  },
}));

interface Props extends CommonFaxSidebarViewProps {
  selectedServiceId: string;
  selectedPatientId: string;
}

export default function CompleteContinueRequestSidebar({
  selectedServiceId,
  selectedPatientId,
  setSidebarView,
}: Readonly<Props>) {
  const classes = useStyles();
  const { faxDbId: faxId, caseId: qmCaseId, queueId } = useContext(FaxAttachmentContext);
  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");
  const { pathname, search, hash } = continuationWorkflowPath(selectedPatientId, selectedServiceId);
  const updatedSearchParams = useMemo(
    () => getParametersFaxIntake(search, faxId, qmCaseId, queueId),
    [faxId, qmCaseId, queueId, search]
  );

  const url = !authBuilderParamNavigationFF
    ? `${pathname}?${updatedSearchParams}#${hash}`
    : `${pathname}?${updatedSearchParams}&faxStep=${hash}#${hash}`;
  return (
    <div className={classes.container}>
      <div className={classes.centered}>
        <img alt="Exclamation" src={ServiceRequestExclamation} />
        <H2 className={classes.header}>Complete continuation in new window</H2>
        <Body1>A continuation has been started in a new window.</Body1>
        <MuiLink variant="body2" color="primary" underline="none" href={url} target="_blank">
          Click here to open the request again
        </MuiLink>
      </div>
      <SecondaryButton fullWidth className={classes.backButton} onClick={() => setSidebarView(backToMenuView)}>
        Back to menu
      </SecondaryButton>
    </div>
  );
}
