// PatientDateSelectionMessage.tsx
import React from "react";
import { Caption } from "@coherehealth/design-system";
import { useTheme } from "@material-ui/core/styles";
import { formatDateWithCustomFormat } from "@coherehealth/common";
import { Coverage, Patient } from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import {
  ContinuationConfiguration,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { useCalculateDateSelectionMessageDisplay } from "components/AuthBuilder/FillFormsGeneralAuthSubmission/utils";

interface PatientDateSelectionMessageProps {
  formContent: ServiceRequestFormContent;
  coverages: Coverage[];
  patient: Patient | null;
  formFieldConfigurations: FormConfiguration | ContinuationConfiguration;
}

const PatientDateSelectionMessage: React.FC<PatientDateSelectionMessageProps> = ({
  formContent,
  coverages,
  patient,
  formFieldConfigurations,
}) => {
  const theme = useTheme();
  const {
    coverageEndDate,
    isDurationRecommendation,
    showDurationRecommendationSuccessMessage,
    showPatientCoverageExpirationMessage,
    showExceededDurationLimitMessageErrorState,
    serviceDuration,
  } = useCalculateDateSelectionMessageDisplay(formContent, coverages, patient, formFieldConfigurations);

  const displayEndDate = formatDateWithCustomFormat(new Date(coverageEndDate), "MM/dd/yyyy");

  if (showPatientCoverageExpirationMessage) {
    return (
      <Caption style={{ color: theme.palette.text.tertiary }}>{`Member coverage ends on ${displayEndDate}`}</Caption>
    );
  } else if (isDurationRecommendation) {
    return (
      <>
        <Caption
          style={{
            color: showExceededDurationLimitMessageErrorState ? theme.palette.error.dark : theme.palette.text.tertiary,
            paddingTop: "8px",
          }}
        >
          {`Duration is limited to ${serviceDuration} day${serviceDuration > 1 ? "s" : ""}`}
        </Caption>
      </>
    );
  } else {
    if (showDurationRecommendationSuccessMessage) {
      return <Caption style={{ color: theme.palette.success.dark }}>Recommended duration</Caption>;
    } else {
      return null;
    }
  }
};

export default PatientDateSelectionMessage;
