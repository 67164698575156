import { Body1, colorsLight, H2, H5, SingleSelectDropdown } from "@coherehealth/common";
import { useGetHealthPlanHierarchyFeatureConfigurations } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { CircularProgress, styled, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { CONFIGURATION_MANAGEMENT_DRAWER_WIDTH } from "./SelectConfigurationDrawer";

interface Props {
  selectedFeatureConfigurationName: string;
  setSelectedFeatureConfigurationName: (name: string) => void;
}

export const CONFIGURATION_MANAGEMENT_HEADER_HEIGHT = 75;
const TITLE_LENGTH = 446;

export default function ConfigurationManagementHeader({
  selectedFeatureConfigurationName,
  setSelectedFeatureConfigurationName,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { spacing } = useTheme();
  const {
    data: featureConfigurations,
    error: featureConfigurationsError,
    loading: featureConfigurationsLoading,
  } = useGetHealthPlanHierarchyFeatureConfigurations({});

  useEffect(() => {
    if (featureConfigurationsError) {
      enqueueSnackbar(
        `Failed to load feature configurations: ${featureConfigurationsError.message}. Please reload the page.`,
        { variant: "error" }
      );
    }
  }, [enqueueSnackbar, featureConfigurationsError]);

  return (
    <HeaderContainer>
      <DrawerTitleContainer>
        <DrawerTitleText>Configuration</DrawerTitleText>
      </DrawerTitleContainer>
      <ContentTitleContainer>
        {featureConfigurations && !featureConfigurationsLoading && (
          <FeatureSelectionContainer>
            <SingleSelectDropdown
              fullWidth
              label="Feature"
              onChange={(option) => {
                setSelectedFeatureConfigurationName(option);
              }}
              options={featureConfigurationOptionsFromData(featureConfigurations)}
              value={selectedFeatureConfigurationName}
            />
          </FeatureSelectionContainer>
        )}
        {featureConfigurationsLoading && (
          <FeatureSelectionContainer>
            <CircularProgress style={{ marginTop: spacing(1) }} />
          </FeatureSelectionContainer>
        )}
        {featureConfigurationsError && (
          <FeatureSelectionContainer>
            <ErrorText>Failed to load configurations.</ErrorText>
          </FeatureSelectionContainer>
        )}
        <TitleContainer>
          <H2>Health plan configuration management</H2>
        </TitleContainer>
      </ContentTitleContainer>
    </HeaderContainer>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderBottom: `3px solid ${theme.palette.primary.dark}`,
  height: CONFIGURATION_MANAGEMENT_HEADER_HEIGHT,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "fixed",
  marginTop: theme.spacing(2),
  right: `calc(50% - ${TITLE_LENGTH / 2}px)`,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DrawerTitleContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: colorsLight.gray.inactive,
  alignItems: "center",
  display: "flex",
  height: "100%",
  width: CONFIGURATION_MANAGEMENT_DRAWER_WIDTH,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FeatureSelectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "15%",
  height: CONFIGURATION_MANAGEMENT_HEADER_HEIGHT,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContentTitleContainer = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  display: "flex",
  width: `calc(100% - ${CONFIGURATION_MANAGEMENT_DRAWER_WIDTH}px)`,
  padding: theme.spacing(1, 2),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DrawerTitleText = styled(H5)(({ theme }) => ({
  margin: "auto",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ErrorText = styled(Body1)(({ theme }) => ({
  color: theme.palette.error.dark,
  marginTop: theme.spacing(2.5),
}));

const featureConfigurationOptionsFromData = (options?: string[]) => {
  return (
    options?.map((option) => ({ id: option, label: FEATURE_CONFIGURATION_DISPLAY_NAME_MAP[option] || option })) || []
  );
};

const FEATURE_CONFIGURATION_DISPLAY_NAME_MAP: Record<string, string> = {
  faxIntakeConfiguration: "Fax intake",
  finalDeterminationLetterIntegrationConfiguration: "Final determination letter integration",
  palCheckConfiguration: "PA Check",
  contactInfoConfiguration: "Contact information on PDFs",
  missingInformationOutreachConfiguration: "Missing Information Outreach Configuration",
  submissionConfiguration: "Submission configuration",
  admissionCategoryMappingConfiguration: "Admission Category Mapping Configuration",
  levelOfCareMappingConfiguration: "Level of Care Mapping Configuration",
  facilityBasedRequestConfiguration: "Facility based request configuration",
  postDecisionConfiguration: "Post-Decision Configuration",
  automatedDenialLetterConfiguration: "Automated Denial Letter Configuration",
  authStatusAttachmentsTransitionConfiguration: "Auth Status Attachments Transition Configuration",
  lettersExpansionConfiguration: "Letters Expansion Configuration",
  outOfNetworkReviewConfiguration: "Out of Network Review Configuration",
};
