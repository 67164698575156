import {
  assertIsFile,
  fileSizeString,
  isValidPdf,
  SecondaryButton,
  useFeature,
  useStableUniqueId,
} from "@coherehealth/common";
import React from "react";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";
import { v4 as uuid } from "uuid";
import { UploadFile } from "./FileUploadItem";
import {
  Attachment,
  CreateCarePathJourneyAttachmentPathParams,
  CreateServiceRequestAttachmentPathParams,
} from "@coherehealth/core-platform-api";
import { useTrackUserInteraction } from "../../util/userActivityTracker";
import { MutateMethod } from "restful-react";
import { error as logError } from "logger";

// these are base 2 bytes (I think) so 104857600 -> 100MB
const MAX_FILE_SIZE = 104857600;
const DEFAULT_ALLOWED_FILE_EXTENSIONS = [".jpeg", ".jpg", ".pdf", ".rtf", ".tif", ".tiff"];

interface Props {
  carePathJourneyId?: string;
  serviceRequestIds?: string[];
  allowedFileExtensions?: string[];
  uploading: boolean;
  afterUpload?: (arg0: Attachment, totalFiles: number) => void;
  isOnCarePathJourney: boolean;
  workflowId?: string;
  uploadFile: MutateMethod<
    Attachment,
    void,
    void,
    CreateCarePathJourneyAttachmentPathParams | CreateServiceRequestAttachmentPathParams
  >;
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  allowMultipleAttachmentFiles?: boolean;
  fileSizeLimit?: number;
}

export default function AddAFile({
  allowedFileExtensions = DEFAULT_ALLOWED_FILE_EXTENSIONS,
  uploading,
  files,
  setFiles,
  afterUpload,
  workflowId,
  serviceRequestIds,
  carePathJourneyId,
  isOnCarePathJourney,
  uploadFile,
  allowMultipleAttachmentFiles = true,
  fileSizeLimit = MAX_FILE_SIZE,
}: Props) {
  const inputId = useStableUniqueId();
  const multiSingleService = useFeature("multiSingleService");
  const { enqueueSnackbar } = useSnackbar();
  const trackInteraction = useTrackUserInteraction();
  const hasMultiSingleServices =
    multiSingleService && serviceRequestIds && serviceRequestIds.length > 1 && !isOnCarePathJourney;

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (input && input.files) {
      for (let i = 0; i < input.files.length; ++i) {
        let file = input.files[i];
        assertIsFile(file);
        const formData = new FormData();
        const fileSplit = file.name.split(".");
        if (!fileSplit.length) {
          continue;
        }
        const ext = fileSplit[fileSplit.length - 1];
        let trimmedFilename = fileSplit
          .filter((f) => f.length > 0 && !f.includes(ext))
          .map((f) => f.trim())
          .join("");

        if (!trimmedFilename.length) {
          const serviceRequestId = serviceRequestIds?.length ? `${serviceRequestIds.map((id) => id).join("_")}` : ``;
          trimmedFilename = `attachment_file_${serviceRequestId}`;
        }

        const fileName = `${trimmedFilename}.${ext}`;
        formData.set("file", file, fileName);
        file = new File([input.files[i]], fileName);

        if (file.size > fileSizeLimit) {
          enqueueSnackbar(
            `Unable to upload: ${file.name} is ${fileSizeString(file.size)}, but maximum allowed is ${fileSizeString(
              fileSizeLimit
            )}`,
            { variant: "warning" }
          );
        } else if (!allowedFileExtensions.some((e) => file.name.toLowerCase().endsWith(e))) {
          enqueueSnackbar(
            `Unable to upload ${
              file.name
            }: files must have one of the following extensions: ${allowedFileExtensions.join(", ")}`,
            { variant: "warning" }
          );
        } else if (!file.name) {
          enqueueSnackbar(`Unable to upload ${file.name}: files must have a name`, { variant: "warning" });
        } else if (file.name) {
          // make sure file name is not null
          // Assign our candidate a unique ID, so we can retrieve it later to update its status
          const candidateFile = { name: file.name, uploading: true, id: uniqueId() };
          setFiles((orig) => [...orig, candidateFile]);
          try {
            if (file.name.toLowerCase().endsWith(".pdf")) {
              // If file type is pdf, validate pdf
              try {
                await isValidPdf(file);
              } catch (error) {
                const errorMessage = `Invalid PDF structure for ${file.name}`;
                setFiles((orig) => [...orig.filter((f) => f.id !== candidateFile.id)]);
                // This is content file issue that should not be sent to Sentry
                console.error(errorMessage);
                enqueueSnackbar(errorMessage, { variant: "error" });
                return;
              }
            }

            // NOTE: `as` type assertions are evil, but this allows us to
            // use the built-in FormData handling in restful-react:
            // https://github.com/contiamo/restful-react/pull/257
            if (isOnCarePathJourney) {
              const resp = await uploadFile(formData as unknown as void, {
                pathParams: { id: carePathJourneyId || "" },
              });
              setFiles((orig) => [
                ...orig.filter((f) => f.id !== candidateFile.id),
                {
                  ...candidateFile,
                  uploading: false,
                  ...resp, // this should overwrite our temporary ID as well
                },
              ]);
              if (afterUpload) {
                afterUpload(resp, files.length + 1);
              }
              trackInteraction({
                event: "UPLOAD_ATTACHMENT",
                stage: "ADD_ATTACHMENTS",
                field: "ATTACHMENTS",
                beforeSnapshot: {
                  attachmentsCount: files.length,
                },
                afterSnapshot: {
                  attachmentsCount: files.length + i + 1,
                },
                activityContext: {
                  serviceRequestId: serviceRequestIds && serviceRequestIds.length > 0 ? serviceRequestIds[0] : "",
                  careJourneyId: carePathJourneyId,
                  workflowId: workflowId,
                },
              });
            } else {
              // Assign a unique id for this attachment group
              const attachmentGroupId: string = uuid();
              formData.set("attachmentGroupId", attachmentGroupId);
              if (serviceRequestIds) {
                for (let j = 0; j < serviceRequestIds?.length; j++) {
                  const resp = await uploadFile(formData as unknown as void, {
                    pathParams: { id: serviceRequestIds[j] || "" },
                  });

                  setFiles((orig) => [
                    ...orig.filter((f) => f.id !== candidateFile.id),
                    {
                      ...candidateFile,
                      uploading: false,
                      ...resp, // this should overwrite our temporary ID as well
                      attachmentGroupId: attachmentGroupId,
                      hasMultiSingleServices: hasMultiSingleServices,
                    },
                  ]);

                  if (afterUpload) {
                    afterUpload({ ...resp, attachmentGroupId: attachmentGroupId }, files.length + 1);
                  }
                  trackInteraction({
                    event: "UPLOAD_ATTACHMENT",
                    stage: "ADD_ATTACHMENTS",
                    field: "ATTACHMENTS",
                    beforeSnapshot: {
                      attachmentsCount: files.length,
                    },
                    afterSnapshot: {
                      attachmentsCount: files.length + i + 1,
                    },
                    activityContext: {
                      serviceRequestId: resp.serviceRequest?.id || "",
                      workflowId: workflowId,
                    },
                  });
                }
              }
            }
          } catch (e) {
            logError(e);
            setFiles((orig) => [...orig.filter((f) => f.id !== candidateFile.id)]);

            enqueueSnackbar(`Unable to upload file ${file.name}`, { variant: "error" });
          }
        }
      }
    }
    // Clear out the input: otherwise, if the user selects the same file
    // a second time, we don't trigger an onChange event
    input.value = "";
  };

  const addFileDisabled = uploading || (!allowMultipleAttachmentFiles && files.length > 0);
  return (
    <div>
      {/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file */}
      <input
        hidden
        accept={allowedFileExtensions
          .map((fileExt) => (fileExt.match(/^[^/.].+/g) ? ".".concat(fileExt) : fileExt))
          //add dot to filenames that do not have it
          //ex docx -> .docx
          .join(", ")}
        name={inputId}
        id={inputId}
        data-testid="add-service-attachment"
        multiple={allowMultipleAttachmentFiles}
        type="file"
        onChange={onFileChange}
        disabled={addFileDisabled}
      />
      <label htmlFor={inputId}>
        <SecondaryButton disabled={addFileDisabled} component="span" loading={addFileDisabled}>
          Add files
        </SecondaryButton>
      </label>
    </div>
  );
}
