import { colorsLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";
import { ServiceRequestSummaryFormWidth } from "common/SharedServiceRequestFormComponents";
import { headerHeight } from "util/StyleConstants";

interface StyleProps {
  hasServiceCategory?: boolean;
  isFaxEditSRFlow?: boolean;
  referralManagementFF?: boolean;
}

export const usePatientSummarySharedStyles = makeStyles((theme) => ({
  serviceRequestSummary: {
    display: "inline-block",
    "&$expanded": {
      margin: theme.spacing(3, 0, 1.5),
    },
  },
  summaryRoot: {
    cursor: "default",
    "&:hover:not(.Mui-disabled)": {
      cursor: "default",
    },
    padding: ({ isFaxEditSRFlow }: StyleProps) => (isFaxEditSRFlow ? "0px" : theme.spacing(0, 2)),
  },
  expanded: {},
  serviceDescription: {
    transition: theme.transitions.create(["max-height", "margin"]),
    marginBottom: theme.spacing(3),
    maxHeight: 50,
    display: "block",
    "&.hidden": {
      maxHeight: 0,
      overflow: "hidden",
      marginBottom: 0,
    },
  },
  acdAlert: {
    transition: theme.transitions.create(["margin", "maxHeight", "padding"]),
    marginBottom: theme.spacing(1),
    flex: 1,
    padding: theme.spacing(1, 3),
    maxHeight: 150,
    "&.hidden": {
      maxHeight: 0,
      overflow: "hidden",
      marginBottom: 0,
    },
  },
  iconContainer: {
    marginLeft: "12px",
    height: "32px",
    width: "32px",
  },
  serviceCategoryCaption: {
    color: colorsLight.font.light,
  },
  serviceName: {
    marginTop: ({ hasServiceCategory }: StyleProps) => (hasServiceCategory ? "8px" : "0"),
    lineHeight: "initial",
  },

  captionContainer: {
    marginLeft: ({ referralManagementFF }: StyleProps) => (referralManagementFF ? 0 : theme.spacing(1.5)),
    justifyContent: ({ hasServiceCategory }: StyleProps) => (hasServiceCategory ? "space-between" : "center"),
    paddingTop: "auto",
    paddingBottom: "auto",
  },

  rowContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  buttonContainer: {
    whiteSpace: "nowrap",
    marginLeft: "auto",
  },
  printButton: {
    height: theme.spacing(4),
    width: theme.spacing(9),
    marginRight: theme.spacing(1),
  },

  notificationsButton: {
    height: theme.spacing(4),
    width: theme.spacing(17),
    marginRight: theme.spacing(1),
  },
  expandToggleButton: {
    // set so button width doesn't change when text content changes
    height: theme.spacing(4),
    width: theme.spacing(4),
    padding: 0,
    minWidth: 0,
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  expandIconUnRotated: {
    transition: theme.transitions.create("transform"),
  },
  expandIconRotated: {
    transition: theme.transitions.create("transform"),
    transform: "rotate(180deg)",
  },
  relationDisplayContainer: {
    marginTop: theme.spacing(3),
  },
  styledBodyContainer: {
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "inherit",
  },
  hover: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      "background-blend-mode": "multiply",
    },
  },
  customWidth: {
    maxWidth: 600,
  },
}));

export const useAuthCardStyles = makeStyles((theme) => ({
  authorizationCard: {
    padding: theme.spacing(1.5, 0, 0, 0),
  },
  authorizationForm: {
    padding: theme.spacing(0, 1, 1.5, 1),
    position: "relative",
    width: "100%",
    maxWidth: ServiceRequestSummaryFormWidth,
  },
  marginSpacer: {
    marginTop: theme.spacing(2),
    padding: 0,
  },
}));

export const useServiceRequestCardStyles = makeStyles((theme) => ({
  serviceRequestAccordion: {
    padding: 0,
    border: ({ isFaxEditSRFlow }: StyleProps) => (isFaxEditSRFlow ? "none" : undefined),
    boxShadow: ({ isFaxEditSRFlow }: StyleProps) => (isFaxEditSRFlow ? "none" : undefined),
  },
  accordionCollapse: {
    padding: ({ isFaxEditSRFlow }: StyleProps) => (isFaxEditSRFlow ? "none" : theme.spacing(0, 1, 0.5, 1)),
    position: "relative",
    width: "100%",
  },
  accordionHeader: {
    padding: ({ isFaxEditSRFlow }: StyleProps) => (isFaxEditSRFlow ? "none" : theme.spacing(1.5, 1)),
    position: "relative",
    width: "100%",
  },
  accordianDetails: {
    padding: ({ isFaxEditSRFlow }: StyleProps) =>
      isFaxEditSRFlow ? theme.spacing(1, 0, 2, 0) : theme.spacing(1, 2, 2),
  },
  clinicalReviewPillContainer: {
    display: "inline-flex",
    marginBottom: theme.spacing(2),
  },

  clinicalReviewPillSubContainer: {
    marginRight: theme.spacing(2),
  },
}));

export const useSRPatientSummaryPageStyles = makeStyles((theme) => ({
  summaryHeader: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  requestBtnContainer: {
    marginLeft: "auto",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  mainContent: {
    height: "100%",
    paddingTop: theme.spacing(5) + headerHeight,
    paddingBottom: theme.spacing(10),
  },

  patientDemographicsCard: {
    padding: theme.spacing(3),
  },

  carePathSingleServiceDivider: {
    margin: theme.spacing(4, 0),
  },
  authSummaryPanel: {
    paddingBottom: theme.spacing(3),
  },
}));

export const useWithdrawModalStyles = makeStyles((theme) => ({
  center: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  content: {
    overflowY: "hidden",
  },
  withdrawAndReplaceContent: {
    overflowY: "hidden",
    padding: "0 !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2.5),
  },
  primaryButtonStyle: {
    width: "36%",
  },
  dialogTitleStyle: {
    padding: 0,
  },
  withdrawAndReplaceTopSpacing: {
    marginTop: theme.spacing(4),
  },
  withdrawAuthDecisionGroupContentStyle: {
    "&.MuiDialogContent-root": {
      padding: theme.spacing(7),
      overflow: "visible",
    },
  },
}));

export const useWithdrawAndReplaceStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "912px",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(4),
  },
  requestsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    paddingBottom: theme.spacing(4),
    width: "912px",
    maxHeight: "600px",
    overflowY: "scroll",
    justifyContent: "center",
  },
  box: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: theme.spacing(1.5, 2.75, 1.5, 1.5),
    alignItems: "center",
    marginTop: theme.spacing(1),
    width: "100%",
    "&:hover": {
      boxShadow: "0px 3px 5px rgba(207, 226, 231, 0.3)",
    },
  },
  gridContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  serviceTitle: {
    color: colorsLight.font.main,
    textAlign: "left",
  },
  historyIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  launchIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  seeDetailsText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  titleWidth: {
    maxWidth: "40%",
  },
  captionColor: {
    color: colorsLight.font.secondary,
  },

  historyIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export const useServiceRequestFormSectionStyles = makeStyles((theme) => ({
  splitAuthContent: {
    minWidth: 600,
    padding: theme.spacing(3, 7, 7),
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  withdrawReplacePadding: {
    paddingTop: theme.spacing(4),
  },

  withdrawPrimaryButton: {
    minWidth: 272,
    padding: theme.spacing(2, 10),
  },
  editBtn: {
    height: theme.spacing(4),
    width: theme.spacing(9),
    borderRadius: 4,
    "& :hover": {
      backgroundColor: colorsLight.action.hoverBackground,
    },
    "& :focus": {
      backgroundColor: colorsLight.action.hoverBackground,
    },
  },
}));

export const usePxCodeListStyles = makeStyles((theme) => ({
  nudgeLayoutComponent: {
    minWidth: "90px",
    minHeight: "20px",
    alignItems: "center",
    display: "flex",
  },
  valueText: {
    display: "inline-block",
  },
}));

export const useAutoApproveErrorAlertModalStyles = makeStyles((theme) => ({
  modalContent: {
    padding: theme.spacing(0, 1, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    backgroundColor: theme.palette.success.light,
    borderRadius: 96,
    boxSizing: "content-box",
    color: theme.palette.success.dark,
    fontSize: 80,
    padding: theme.spacing(1),
  },
  title: {
    textAlign: "center",
    paddingTop: theme.spacing(3),
  },
  details: {
    textAlign: "center",
    padding: theme.spacing(2, 0, 4),
  },
  leftButton: {
    marginRight: theme.spacing(3),
  },
}));
