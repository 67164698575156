import React, { ReactElement, useEffect, useState } from "react";
import PaginationFooter from "./PaginationFooter";
import PaginateResults, { PaginateResultsProps } from "./PaginateResults";
import DefaultHeader, { HeaderProps } from "./DefaultHeader";
import TableRow from "./TableRow";
import TableReadOnlyRow from "./TableReadOnlyRow";
import EmptyDataContainer from "./EmptyDataContainer";
import { default as MUICircularProgress } from "@material-ui/core/CircularProgress";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme, Theme } from "@material-ui/core/styles";
import HiddenNavLink from "./HiddenNavLink";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";

export interface Column<T> {
  name: string;
  label?: string;
  width: string;
  minWidth?: string | number;
  alignItems?: string;
  themedPaddingRight: number;
  value: (arg: T, isExpanded?: boolean) => React.ReactNode;
  unSortable?: boolean;
  header?: JSX.Element;
  allowTextWrap?: boolean;
}

type Linkable<T> = T & { href?: string; screenReaderDescription?: string };

interface IProps<T> extends HeaderProps<T> {
  title?: string;
  loading?: boolean;
  readonly?: boolean;
  noTextWrap?: boolean;
  rowColor?: string;
  tableColumns: Column<T>[];
  data: Array<Linkable<T>>;
  onRowClick?: (row: T, index: number) => void;
  onDelete?: (row: T, index: number) => void;
  cardHeight?: "short" | "tall";
  renderHeader?: (tableColumns: Column<T>[]) => ReactElement;
  omitHeader?: boolean;
  paginated?: boolean;
  rowExpansion?: (row: T, index: number) => React.ReactNode;
  slim?: boolean;
  withDivider?: boolean;
  rowsHeight?: string | number;
  wideRows?: boolean;
  deleteSpacing?: boolean;
  hideRowHoverBorderColor?: boolean;
  slimDividerNoBorders?: boolean;
  rowHoverShadow?: string;
  rowActiveBackgroundColor?: string;
  emptyListCaption?: string;
  show3linesAndThenEllipsis?: boolean;
  showTotalItems?: boolean;
  showFullInfo?: boolean;
  authHistorySpacing?: boolean;
  hideRowDividers?: number[];
  hideHeaderDivider?: boolean;
  hideFinalDivider?: boolean;
  hideRowPerPageDropDown?: boolean;
  showWarningBorder?: boolean;
  dataPublic?: boolean;
  hideFinalDividerMarginTop?: boolean;
}

/* if paginatedViaParams prop is passed, accept PaginateResultsProps and don't accept regular paginated prop.
 if paginatedViaParams prop is not passed, don't accept any PaginateResultsProps
*/
type PaginateParamsProps =
  | ({ paginateViaParams: true; paginated?: false } & Omit<PaginateResultsProps, "count">)
  | ({ paginateViaParams?: false } & { [property in keyof PaginateResultsProps]+?: never });

type CohereTableProps<T> = IProps<T> &
  PaginateParamsProps & {
    selectedRows?: number[]; // This can be mapped to the row's index
    onRowSelect?: (index: number, isSelected: boolean) => void;
    onSelectAll?: (isSelected: boolean) => void;
    selectableRows?: boolean;
  };

export default function CohereTable<T>({
  title,
  loading,
  readonly,
  noTextWrap,
  tableColumns,
  data,
  onRowClick,
  onDelete,
  cardHeight,
  omitHeader,
  renderHeader,
  paginated,
  rowExpansion,
  slim,
  withDivider,
  rowColor,
  rowsHeight,
  wideRows,
  deleteSpacing,
  paginateViaParams,
  paginateParams,
  setPaginateParams,
  displayMax,
  RESULTS_PER_PAGE,
  totalCount,
  rowHoverShadow,
  hideRowHoverBorderColor,
  slimDividerNoBorders,
  rowActiveBackgroundColor,
  emptyListCaption,
  show3linesAndThenEllipsis,
  showTotalItems,
  showFullInfo,
  authHistorySpacing,
  hideRowDividers,
  hideHeaderDivider,
  hideFinalDivider,
  hideRowPerPageDropDown,
  showWarningBorder,
  dataPublic = false,
  hideFinalDividerMarginTop = false,
  selectedRows = [],
  onRowSelect,
  onSelectAll,
  selectableRows = false,
  ...headerProps
}: CohereTableProps<T>) {
  const [paginatedData, setPaginatedData] = useState<Linkable<T>[]>([]);
  const theme = useTheme();

  useEffect(() => {
    if (!paginated) {
      setPaginatedData(data);
    }
  }, [data, paginated]);

  useEffect(() => {
    if (paginateViaParams) {
      setPaginatedData(data.slice(0, displayMax));
    }
  }, [data, paginateViaParams, displayMax]);

  if (!!loading) {
    const fixedMarginTop = title === "rules" ? "50px" : "0px";
    return <MUICircularProgress size={48} style={{ position: "absolute", left: "50%", marginTop: fixedMarginTop }} />;
  }

  if (data.length === 0) {
    return <EmptyDataContainer caption={emptyListCaption} />;
  }

  const dataPublicProps = dataPublic ? { "data-public": true } : {};

  const handleRowClick = (row: T, index: number, event: React.MouseEvent<Element, MouseEvent>) => {
    const isCheckbox = (event.target as HTMLElement | null)?.closest(".checkbox-column");
    if (isCheckbox) {
      return; // prevent click from propagating to the row, as it's handled by the checkbox
    }

    onRowClick && onRowClick(row, index);
  };

  const renderSelectAllCheckbox = () => {
    if (!selectableRows) {
      return null;
    }
    const allSelected = paginatedData.length > 0 && paginatedData.every((_, index) => selectedRows.includes(index));

    return (
      <Checkbox
        color="primary"
        checked={allSelected}
        onChange={(e) => onSelectAll && onSelectAll(e.target.checked)}
        inputProps={{ "aria-label": "select all" }}
      />
    );
  };

  return (
    <Table withDivider={withDivider} {...dataPublicProps}>
      {!omitHeader && renderHeader && renderHeader(tableColumns)}
      {!omitHeader && !renderHeader && (
        <DefaultHeader
          readonly={readonly}
          withDivider={withDivider}
          tableColumns={tableColumns}
          wide={wideRows}
          deleteSpacing={deleteSpacing}
          authHistorySpacing={authHistorySpacing}
          renderSelectAllCheckbox={selectableRows ? renderSelectAllCheckbox : undefined}
          {...headerProps}
        />
      )}
      {withDivider && <Divider style={{ margin: theme.spacing(0, -3.5, 1.5, -3.5) }} />}
      <TableRows
        rowsHeight={rowsHeight}
        style={slimDividerNoBorders && { border: "1px solid", borderColor: "#56656f", borderRadius: "4px" }}
      >
        {paginatedData.map((item, index) => (
          <div key={index} data-testid={`table-row-${index}`} style={{ position: "relative" }}>
            {item.href && <HiddenNavLink href={item.href}>{item.screenReaderDescription}</HiddenNavLink>}
            {readonly ? (
              <TableReadOnlyRow
                key={index}
                index={index}
                item={item}
                tableColumns={tableColumns}
                onRowClick={onRowClick}
                clickable={item.href ? true : undefined}
                slim={slim}
                showFullInfo={showFullInfo}
                rowExpansion={rowExpansion}
                hideDividers={hideRowDividers}
                hideHeaderDivider={hideHeaderDivider}
                rowColor={rowColor}
                slimDividerNoBorders={slimDividerNoBorders}
                selectableRows={selectableRows}
                selected={selectedRows.includes(index)}
                onSelect={(isSelected) => onRowSelect && onRowSelect(index, isSelected)}
              />
            ) : (
              <TableRow
                key={index}
                index={index}
                item={item}
                rowColor={rowColor}
                noTextWrap={noTextWrap}
                tableColumns={tableColumns}
                onRowClick={(_, index, event) => handleRowClick(item, index, event)}
                clickable={item.href ? true : undefined}
                onDelete={onDelete}
                cardHeight={cardHeight}
                rowExpansion={rowExpansion}
                wide={wideRows}
                hideRowHoverBorderColor={hideRowHoverBorderColor}
                rowHoverShadow={rowHoverShadow}
                rowActiveBackgroundColor={rowActiveBackgroundColor}
                show3linesAndThenEllipsis={show3linesAndThenEllipsis}
                showTotalItems={showTotalItems}
                showWarningBorder={showWarningBorder}
                selectableRows={selectableRows}
                selected={selectedRows.includes(index)}
                onSelect={(isSelected) => onRowSelect && onRowSelect(index, isSelected)}
              />
            )}
          </div>
        ))}
      </TableRows>
      {!hideFinalDivider && <Divider style={{ marginTop: theme.spacing(hideFinalDividerMarginTop ? 0 : 1) }} />}
      {paginated && <PaginationFooter data={data} onSegmentChange={setPaginatedData} />}
      {paginateViaParams && (
        <PaginateResults
          paginateParams={paginateParams}
          setPaginateParams={setPaginateParams}
          count={data.length}
          displayMax={displayMax}
          RESULTS_PER_PAGE={RESULTS_PER_PAGE}
          totalCount={totalCount}
          hideRowPerPageDropDown={hideRowPerPageDropDown}
        />
      )}
    </Table>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Table = styled(({ withDivider, ...other }) => <div {...other} />)<Theme, { withDivider?: boolean }>(
  ({ theme, withDivider }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    overflow: withDivider ? "visible" : "hidden",
  })
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TableRows = styled(({ rowsHeight, ...other }) => <div {...other} />)<Theme, { rowsHeight?: number | string }>(
  ({ theme, rowsHeight }) => ({
    overflowY: "auto",
    maxHeight: rowsHeight ? rowsHeight : "none",
  })
);
