import { H6 } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import { GridSize } from "@material-ui/core/Grid";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { warn as logWarning } from "logger";
import { CoverageCheck, DateCheck, getPatientHealthPlanName } from "util/patientUtils";
import { PriorAuthRequirements } from "components/AuthBuilder/common";
import { Dispatch, SetStateAction } from "react";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";

export type PalCheckFormSection =
  | "DiagnosisCodes"
  | "ProcedureCodes"
  | "ProcedureCodesWithCheckbox"
  | "StartDate"
  | "EncounterType"
  | "HomeHealth"
  | "RevenueCodes"
  | "NoPxService";
interface SectionConfig {
  sectionName: PalCheckFormSection;
  columnWidth: GridSize;
  shouldDisplaySection: (args: PalCheckFormSectionProps) => boolean;
}
type HealthPlanPalCheckConfig = Array<SectionConfig>;

const healthPlanPalCheckConfigs: Record<string, HealthPlanPalCheckConfig> = {
  Humana: [
    {
      sectionName: "DiagnosisCodes",
      columnWidth: 12,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "StartDate",
      columnWidth: 5,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "EncounterType",
      columnWidth: 7,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "ProcedureCodes",
      columnWidth: 12,
      shouldDisplaySection: () => true,
    },
  ],
  Oscar: [
    {
      sectionName: "DiagnosisCodes",
      columnWidth: 12,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "StartDate",
      columnWidth: 6,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "EncounterType",
      columnWidth: 6,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "ProcedureCodesWithCheckbox",
      columnWidth: 12,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "NoPxService",
      columnWidth: 6,
      shouldDisplaySection: ({ priorAuthRequirements }) => Boolean(priorAuthRequirements?.noPxServiceRequired),
    },
  ],
  Geisinger: [
    {
      sectionName: "DiagnosisCodes",
      columnWidth: 12,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "StartDate",
      columnWidth: 5,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "EncounterType",
      columnWidth: 7,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "HomeHealth",
      columnWidth: 12,
      shouldDisplaySection: () => true,
    },
    {
      sectionName: "RevenueCodes",
      columnWidth: 12,
      shouldDisplaySection: () => true,
    },
  ],
};

export const useHealthPlanPalCheckFormConfig = (patient: Patient | null): HealthPlanPalCheckConfig => {
  const healthPlan = getPatientHealthPlanName(patient || undefined);
  if (healthPlan && healthPlan in healthPlanPalCheckConfigs) {
    return healthPlanPalCheckConfigs[healthPlan];
  } else {
    logWarning(`No HealthPlanPalCheckFormConfig for health plan ${healthPlan}, defaulting to Humana config`);
    // We probably won't always want to default to Humana, but this seems reasonable for now
    return healthPlanPalCheckConfigs["Humana"];
  }
};

export interface PalCheckFormSectionProps {
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: (reqs: PriorAuthRequirements) => void;
  attemptedSubmit: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  resetPalResults: Function;
  startDateCoverage?: CoverageCheck;
  facilityBased?: boolean;
  generalAuthSubmissionStyling?: boolean;
  hideHeader?: boolean;
  isOutPatientEncounterType?: boolean;
  formContent?: ServiceRequestFormContent;
  setFormContent?: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  minDateCheck?: DateCheck;
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const SectionHeader = styled(H6)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
