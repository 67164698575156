import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Footer from "../Footer";
import ClinicalAssessmentContext from "components/ClinicalAssessment/ClinicalAssessmentContext";
import {
  Attachment,
  AuthBuilderWorkflowStep,
  CarePathJourney,
  HealthHelpPASRequestResponse,
  Patient,
  ProcedureCode,
  ServiceRequestCreatePayload,
  ServiceRequestResponse,
  Vendor,
  useGetClinicalAssessment,
  useGetMultiSingleServiceClinicalAssessment,
  useGetTransientServiceRequestRuleActions,
  useGetSingleServiceClinicalAssessment,
  useStartHealthHelpIntegration,
  useUpdateAuthorizationById,
  useUpdateServiceRequest,
  useWithdrawHealthHelpCase,
  CardExtensionDetails,
} from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "common/SharedServiceRequestFormComponents";
import {
  addNonPalTracking,
  getHealthCareManager,
  payloadFromSRFormContent,
  useIsHealthHelpEnabled,
} from "util/serviceRequest";
import {
  formatDateToISODate,
  getNoTimezoneDateTimeString,
  HEALTH_HELP_NAME,
  HEALTH_HELP_V2_NAME,
  useFeature,
  withId,
} from "@coherehealth/common";
import { ClinicalAssessmentProvider } from "..";
import { useSnackbar } from "notistack";
import {
  NonCohereCodes,
  PriorAuthRequirements,
  navigateToPS,
  servRequestFailedSnackbar,
  navigateToSRSummary,
  procedureBucketDataModel,
  convertStringToAuthBuilderWorkflowStep,
} from "../common";
import {
  activityContextFromServiceRequest,
  useTrackUserImpression,
  useTrackUserInteraction,
} from "util/userActivityTracker";
import { integrateWithHealthHelp } from "../FillForms/Footer";
import { error as logError } from "logger";
import { useGetReturnToUrl } from "util/queryParams";
import { useNavigate } from "react-router";
import { SmartOnFhirContext } from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import eq from "fast-deep-equal";
import { createFormContentForEachGroup } from "../FillFormsGeneralAuthSubmission/ServicesAndProceduresCard";
import {
  navigateStepEvalClinicalAssessment,
  getIntersectionObservationCodesAndSearchStatements,
} from "util/workflowUtils";
import { FaxAttachmentContext } from "components/DocumentViewer/FaxAttachment/FaxAttachmentContext";
import ServiceRequestFormRedirectModal, {
  formContentToSR,
} from "components/ServiceRequest/ServiceRequestForm/ServiceRequestFormRedirectModal";
import { ValidDisplayMessageAction, isRecommendChangeAction, isRedirectAction } from "util/rule";
import applyActionToServiceRequest from "util/rule/applyActionToServiceRequest";
import useDeleteDraftSrAndRedirect from "hooks/useDeleteDraftSrAndRedirect";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";

interface Props {
  clinicalAssessmentProviders: ClinicalAssessmentProvider[];
  setWorkflowStep: Dispatch<AuthBuilderWorkflowStep>;
  patient: Patient | null;
  serviceRequestFormContent: ServiceRequestFormContent;
  workflowId?: string;
  serviceRequestId?: string;
  importedEhrOrder?: ServiceRequestResponse;
  authorizationId?: string;
  editServiceRequest: Dispatch<ServiceRequestResponse>;
  setClinicalAssessmentProviders: Dispatch<SetStateAction<ClinicalAssessmentProvider[]>>;
  carePathJourney?: CarePathJourney;
  getCarePathClinicalAssessment: ReturnType<typeof useGetClinicalAssessment>["refetch"];
  getSingleServiceClinicalAssessment: ReturnType<typeof useGetSingleServiceClinicalAssessment>["refetch"];
  getMultiSingleServiceClinicalAssessment: ReturnType<typeof useGetMultiSingleServiceClinicalAssessment>["refetch"];
  nonPalProcedureCodes: ProcedureCode[];
  nonCohereCodes: NonCohereCodes[];
  palProcedureCodes: ProcedureCode[];
  serviceRequestFormHasNewEdits: boolean;
  priorAuthRequirements: PriorAuthRequirements;
  previousClinicalServiceIds: Record<string, string[]> | undefined;
  selectedClinicalServiceIds: Record<string, string[]> | undefined;
  setPreviousClinicalServiceIds: Dispatch<SetStateAction<Record<string, string[]>>>;
  setHealthHelpPASToken: Dispatch<SetStateAction<HealthHelpPASRequestResponse | undefined>>;
  vendorAssessmentError: boolean;
  setVendorAssessmentError: Dispatch<boolean>;
  setVendorAssessmentCompleted: Dispatch<boolean>;
  workflowStep: AuthBuilderWorkflowStep;
  setAttemptedServiceDetailsSubmit: Dispatch<SetStateAction<boolean>>;
  attemptedServiceRequestFormsSubmit: boolean;
  serviceDetailsFormsCanBeSubmitted: boolean[];
  attachments?: Attachment[];
  crdLogId?: string;
  serviceRequests: ServiceRequestResponse[];
  authFlowType: string;
  setCaqSkipped: Dispatch<SetStateAction<boolean>>;
  serviceRequestPatientAttributes: Set<string> | undefined;
  clinicalAssessmentSnomedCodes: Set<string> | undefined;
  isContinuationWorkflow?: boolean;
  setServiceRequestFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  crdExtensionCardResults?: CardExtensionDetails | undefined;
  setFooterHeight: Dispatch<SetStateAction<number>>;
}

const ServiceDetailsFooter = ({
  clinicalAssessmentProviders,
  setWorkflowStep,
  patient,
  serviceRequestFormContent,
  setServiceRequestFormContent,
  workflowId,
  serviceRequestId,
  importedEhrOrder,
  authorizationId,
  editServiceRequest,
  setClinicalAssessmentProviders,
  carePathJourney,
  getCarePathClinicalAssessment,
  getSingleServiceClinicalAssessment,
  getMultiSingleServiceClinicalAssessment,
  nonPalProcedureCodes,
  nonCohereCodes,
  palProcedureCodes,
  serviceRequestFormHasNewEdits,
  setHealthHelpPASToken,
  vendorAssessmentError,
  setVendorAssessmentError,
  setVendorAssessmentCompleted,
  workflowStep,
  priorAuthRequirements,
  previousClinicalServiceIds,
  selectedClinicalServiceIds,
  setPreviousClinicalServiceIds,
  setAttemptedServiceDetailsSubmit,
  attemptedServiceRequestFormsSubmit,
  serviceDetailsFormsCanBeSubmitted,
  attachments,
  crdLogId,
  serviceRequests,
  authFlowType,
  setCaqSkipped,
  serviceRequestPatientAttributes,
  clinicalAssessmentSnomedCodes,
  isContinuationWorkflow,
  crdExtensionCardResults,
  setFooterHeight,
}: Props) => {
  const [delegatedVendorName, setDelegatedVendorName] = useState<Vendor | undefined>(undefined);
  const [isDelegatedToHealthHelp, setIsDelegatedToHealthHelp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clinicalAssessment } = useContext(ClinicalAssessmentContext);
  const [redirectModalOpen, setRedirectModalOpen] = useState<boolean>(false);
  const [redirectModalAction, setRedirectModalAction] = useState<ValidDisplayMessageAction>();
  const noAssessmentQuestions = !clinicalAssessment?.questions || clinicalAssessment?.questions?.length === 0;
  const { faxDbId: faxId } = useContext(FaxAttachmentContext);
  const isFaxIntakeFlow = Boolean(faxId);
  const [shouldEvalPreSubmissionStep, setShouldEvalPreSubmissionStep] = useState<boolean>(false);
  const autoApprovalSkipCAQ = useFeature("autoApprovalSkipCAQ");
  const isClearerDraftWorkflow = useFeature("clearerDraftWorkflow");

  const trackUserImpression = useTrackUserImpression();
  //during the authbuild flow delegatedVendorName is set to undefined when HH assessment fails
  const skipWhenVendorAssessmentFails = !delegatedVendorName && vendorAssessmentError;
  const serviceRequest = serviceRequests?.length && serviceRequests.length > 0 ? serviceRequests[0] : undefined;

  const { enqueueSnackbar } = useSnackbar();

  const multiSingleService = useFeature("multiSingleService");

  const useHealthHelpIntegration = useIsHealthHelpEnabled();

  const serviceRequestIds = serviceRequestFormContent?.id ? [serviceRequestFormContent?.id] : [];

  const navigate = useNavigate();

  const returnToUrl = useGetReturnToUrl();

  const {
    mutate: updateServiceRequest,
    loading: updateServiceRequestLoading,
    error: updateServiceRequestError,
  } = useUpdateServiceRequest({ id: "" });

  const { mutate: patchAuthorization } = useUpdateAuthorizationById({ id: "" });

  const {
    mutate: withdrawHealthHelpCase,
    loading: withdrawHealthHelpCaseLoading,
    error: withdrawHealthHelpCaseError,
  } = useWithdrawHealthHelpCase({ id: "" });

  const {
    mutate: startHealthHelpIntegration,
    loading: startHealthHelpIntegrationLoading,
    error: startHealthHelpIntegrationError,
  } = useStartHealthHelpIntegration({ id: "" });

  const { deleteError, deleteAttachmentLoading, deleteAttachmentError, deleteAndNavigateCallback } =
    useDeleteDraftSrAndRedirect(returnToUrl, serviceRequestFormContent?.id || "", attachments);

  const trackUserActivityInteraction = useTrackUserInteraction();

  const { mutate: getTransientRuleActions, loading: loadingTransientActions } =
    useGetTransientServiceRequestRuleActions({});

  const { srEditConfig } =
    useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
      healthPlanName: serviceRequest?.healthPlanName || "",
    }) || {};
  const allowedPxCodePerPayer = srEditConfig?.maxAllowedPxCodePerPayer;

  const fetchRuleActions = async () => {
    if (patient) {
      return await getTransientRuleActions({
        displayTarget: "SERVICE_QUANTITY",
        serviceRequest: {
          patient,
          ...(!!formatDateToISODate(serviceRequestFormContent.startDate)
            ? { startDate: formatDateToISODate(serviceRequestFormContent.startDate) }
            : {}),
          encounterType: serviceRequestFormContent.isInpatient ? "INPATIENT" : "OUTPATIENT",
          semanticProcedureCodes: serviceRequestFormContent.procedureCodes,
          primarySemanticDiagnosisCode: serviceRequestFormContent.primaryDiagnosisCode ?? undefined,
          secondarySemanticDiagnosisCodes: serviceRequestFormContent.secondaryDiagnosisCodes,
          id: serviceRequestFormContent.id ?? undefined,
        },
        authStage: convertStringToAuthBuilderWorkflowStep("SERVICE_DETAILS"),
      });
    }
  };

  const fetchRedirectsAndRecommendChangeFromRuleActions = async () => {
    const ruleActions = await fetchRuleActions();
    const redirect = ruleActions?.find(isRedirectAction);
    const recommendChange = ruleActions?.find(isRecommendChangeAction);
    return { redirect, recommendChange };
  };

  const handleServiceQuantityRuleActions = async () => {
    const { redirect, recommendChange } = await fetchRedirectsAndRecommendChangeFromRuleActions();
    if (redirect || recommendChange) {
      setRedirectModalOpen(true);
      setRedirectModalAction(redirect || recommendChange);
    } else {
      onFooterButtonClick(false);
    }
  };
  useEffect(() => {
    if (updateServiceRequestError) {
      servRequestFailedSnackbar(enqueueSnackbar);
    }
  }, [updateServiceRequestError, enqueueSnackbar]);

  useEffect(() => {
    if (withdrawHealthHelpCaseError) {
      enqueueSnackbar("Could not withdraw previous case from HealthHelp, please try again", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }, [withdrawHealthHelpCaseError, enqueueSnackbar]);

  useEffect(() => {
    if (startHealthHelpIntegrationError) {
      setVendorAssessmentError(true);
      logError(
        new Error(
          `${delegatedVendorName} start integration failed. Service Request ID: [${serviceRequestFormContent?.id}]`
        )
      );
      delegatedVendorName === HEALTH_HELP_V2_NAME
        ? setWorkflowStep("ADD_ATTACHMENTS")
        : setWorkflowStep("VENDOR_CLINICAL_ASSESSMENT");
      setIsLoading(false);
    }
  }, [
    delegatedVendorName,
    serviceRequestFormContent?.id,
    setVendorAssessmentError,
    setWorkflowStep,
    startHealthHelpIntegrationError,
  ]);

  useEffect(() => {
    if (deleteError || deleteAttachmentError) {
      enqueueSnackbar(
        `Error canceling authorization request: ${deleteError?.message || deleteAttachmentError?.message || ""}`,
        { variant: "error", preventDuplicate: true }
      );
    }
  }, [enqueueSnackbar, deleteError, deleteAttachmentError]);

  const updateServiceRequestWorkflowStep = useCallback(async () => {
    for (const sr of serviceRequests) {
      await updateServiceRequest({ workflowStep: workflowStep }, { pathParams: { id: sr?.id } });
    }
  }, [serviceRequests, updateServiceRequest, workflowStep]);

  const evalContinueToPreSubmissionStep = useCallback(
    async (clinicalAssessmentProviders: ClinicalAssessmentProvider[]) => {
      if (isClearerDraftWorkflow && authFlowType !== "PHARMACY") {
        await updateServiceRequestWorkflowStep();
      }

      /**
       * If intersection exists don't skip caq questions, auth could pend overall
       * Else if auth would approve, it's safe to skip caq questions
       */
      const hasCaqSearchableStatementsIntersect = getIntersectionObservationCodesAndSearchStatements(
        serviceRequestPatientAttributes,
        clinicalAssessmentSnomedCodes
      );

      const canAutoApprove = !!(autoApprovalSkipCAQ && !hasCaqSearchableStatementsIntersect);
      const canContinueToReview = canAutoApprove || noAssessmentQuestions;
      await navigateStepEvalClinicalAssessment({
        trackUserImpression,
        noAssessmentQuestions,
        setCaqSkipped,
        isContinuationWorkflow,
        isCurrentDedicatedNudge: false, //fax intake flow does not use dedicated nudges
        setWorkflowStep,
        clinicalAssessmentProviders,
        goToReview: canContinueToReview,
        skipWhenVendorAssessmentFails,
        serviceRequest,
      });
    },
    [
      authFlowType,
      autoApprovalSkipCAQ,
      clinicalAssessmentSnomedCodes,
      isClearerDraftWorkflow,
      isContinuationWorkflow,
      noAssessmentQuestions,
      serviceRequest,
      serviceRequestPatientAttributes,
      setCaqSkipped,
      setWorkflowStep,
      skipWhenVendorAssessmentFails,
      trackUserImpression,
      updateServiceRequestWorkflowStep,
    ]
  );

  useEffect(() => {
    if (shouldEvalPreSubmissionStep && !!clinicalAssessment?.questions) {
      evalContinueToPreSubmissionStep(clinicalAssessmentProviders);
    }
  }, [clinicalAssessment, shouldEvalPreSubmissionStep, evalContinueToPreSubmissionStep, clinicalAssessmentProviders]);

  const canSubmitServiceRequestForms = !serviceDetailsFormsCanBeSubmitted.includes(false);
  const invalidForms = !canSubmitServiceRequestForms && attemptedServiceRequestFormsSubmit;

  let nonCohereCodesList: ProcedureCode[] = [];
  nonCohereCodes.forEach((group) => {
    nonCohereCodesList.push(...group.procedureCode);
  });
  const filteredSemanticProcedureCodes =
    serviceRequestFormContent && serviceRequestFormContent?.procedureCodes
      ? serviceRequestFormContent?.procedureCodes?.filter((pxCode) => {
          return !nonCohereCodesList.find((nonCohereCode) => {
            return pxCode.code === nonCohereCode.code;
          });
        })
      : [];

  const filteredOnlyPalCodes = filteredSemanticProcedureCodes?.filter((pxCode) => {
    return palProcedureCodes.find((palProcedureCodes) => {
      return pxCode.code === palProcedureCodes.code;
    });
  });

  const filteredOnlyNonPalCodes = filteredSemanticProcedureCodes?.filter((pxCode) => {
    return nonPalProcedureCodes.find((nonPalProcedureCodes) => {
      return pxCode.code === nonPalProcedureCodes.code;
    });
  });

  const canContinueSemanticProcedureCodes = priorAuthRequirements.userSelectedNonPalCode
    ? [...filteredOnlyPalCodes, ...filteredOnlyNonPalCodes]
    : filteredOnlyPalCodes;

  // Filter out only unique codes to display as the count on the continue button
  const canContinueSemanticProcedureCodesCount = canContinueSemanticProcedureCodes.filter((pxCode, index) => {
    return canContinueSemanticProcedureCodes.findIndex((filteredCode) => filteredCode.code === pxCode.code) === index;
  }).length;

  const isPxCodeLimitExceeded = Boolean(
    allowedPxCodePerPayer &&
      allowedPxCodePerPayer > 0 &&
      canContinueSemanticProcedureCodesCount &&
      canContinueSemanticProcedureCodesCount > allowedPxCodePerPayer
  );

  const isCrdExtensionDetailsDelegatedTo = !!(
    crdExtensionCardResults &&
    crdExtensionCardResults?.authSubmissionVendor &&
    crdExtensionCardResults?.authSubmissionVendor !== "Cohere" &&
    crdExtensionCardResults?.isAuthRequired
  );

  const canContinueToNextPage = !invalidForms && !isPxCodeLimitExceeded;
  const primaryButtonText = canContinueSemanticProcedureCodes.length
    ? `Continue with ${canContinueSemanticProcedureCodesCount} ${
        canContinueSemanticProcedureCodesCount > 1 ? "codes" : "code"
      } `
    : "Continue";

  const smartClient = useContext(SmartOnFhirContext);
  const inSmartOnFhirWorkflow = Boolean(smartClient);

  const calculateUnitsFromFormContent = (
    formContent: ServiceRequestFormContent,
    updatedPxCodes: ProcedureCode[]
  ): number => {
    const srFormContents = createFormContentForEachGroup({
      procedureCodesAndGroups: procedureBucketDataModel(updatedPxCodes?.map(withId)),
      clinicalServices: formContent.clinicalServices || [],
      isInpatient: formContent.isInpatient,
      startDate: formContent.startDate,
      approvedUnits: formContent.approvedUnits,
      isExpedited: formContent.isExpedited,
      expeditedReason: formContent.expeditedReason,
      userSelectedNonPalCode: formContent.userSelectedNonPalCode || false,
      isContinuation: false,
      userSelectedOONException: formContent.userSelectedOONException || false,
      id: formContent.id,
      cohereId: formContent.cohereId,
    });

    let updatedUnits = 0;
    srFormContents.forEach((group) => {
      if (!group.clinicalService || group.clinicalService.isUnitsOnPx) {
        group.procedureCodes.forEach((pxCode) => (updatedUnits += pxCode?.units || 0));
      } else {
        const serviceGroupUnits = group?.units ? parseInt(group?.units) : 0;
        updatedUnits += serviceGroupUnits;
      }
    });

    return updatedUnits;
  };

  const submitForm = async (saveAndExit: boolean): Promise<ClinicalAssessmentProvider> => {
    //if not save and exit then
    //only continue with pal codes and non pal codes if userSelectedNonPalCodes is true
    const payload: ServiceRequestCreatePayload = {
      ...payloadFromSRFormContent(serviceRequestFormContent, true, undefined, workflowId, false),
      patient: patient?.id,
      workflowId: workflowId,
      requestType: "INITIAL",
      ehrOrderId: importedEhrOrder?.ehrOrderId,
      ehrPracticeId: importedEhrOrder?.ehrPracticeId,
      ehrVendor: importedEhrOrder?.ehrVendor,
      ehrPatientId: importedEhrOrder?.ehrPatientId,
      ehrDepartmentId: importedEhrOrder?.ehrDepartmentId,
      ehrEncounterId: importedEhrOrder?.ehrEncounterId,
      workflowStep: workflowStep,
      userSelectedNonPalCode: !!priorAuthRequirements.userSelectedNonPalCode,
      crdLogId: crdLogId,
    };
    if (serviceRequestFormContent?.id) {
      let response: ServiceRequestResponse;

      // Need to save all possible codes if save and exit
      const saveAndExitCodes = [...filteredOnlyPalCodes, ...nonPalProcedureCodes, ...nonCohereCodesList];

      const semanticProcedureCodes = saveAndExit
        ? Array.from(new Set(saveAndExitCodes))
        : Array.from(new Set(canContinueSemanticProcedureCodes));

      const newPayload = {
        ...payload,
        carePathJourney: carePathJourney?.id,
        semanticProcedureCodes,
      };
      if (serviceRequestFormContent?.userSelectedNonPalCode) {
        const updatedUnits = calculateUnitsFromFormContent(serviceRequestFormContent, semanticProcedureCodes);
        newPayload.units = updatedUnits;
      }
      if (redirectModalAction && isRedirectAction(redirectModalAction)) {
        const serviceRequestFromFormContent = formContentToSR(serviceRequestFormContent, true);
        const newSR = applyActionToServiceRequest(serviceRequestFromFormContent, redirectModalAction);
        newPayload.units = newSR?.units;
        newPayload.semanticProcedureCodes = newSR?.semanticProcedureCodes || [];
      }
      response = await updateServiceRequest(newPayload, { pathParams: { id: serviceRequestFormContent?.id } });
      if (
        (serviceRequestFormContent?.dischargedTo ||
          (serviceRequestFormContent.dischargeDate && serviceRequestFormContent.dischargeTime)) &&
        response?.authorization?.id
      ) {
        const patchAuthParams = { dischargedTo: "", actualDischargeDateTime: "" };
        if (serviceRequestFormContent?.dischargedTo?.enumName) {
          patchAuthParams.dischargedTo = serviceRequestFormContent?.dischargedTo.enumName;
        }

        const dischargeDateTime = getNoTimezoneDateTimeString(
          serviceRequestFormContent.dischargeDate,
          serviceRequestFormContent.dischargeTime
        );
        if (dischargeDateTime !== "") {
          patchAuthParams.actualDischargeDateTime = dischargeDateTime;
        }
        const authRes = await patchAuthorization(patchAuthParams, { pathParams: { id: response.authorization.id } });
        response.dischargedTo = authRes?.dischargedTo;
      }
      await addNonPalTracking(
        response,
        nonPalProcedureCodes,
        patient?.id || "",
        trackUserActivityInteraction,
        workflowId
      );
      setDelegatedVendorName(response.delegatedVendor);

      try {
        await initiateHealthHelpIntegration(response, saveAndExit);
      } catch (e) {
        enqueueSnackbar("Failed to initiate HealthHelp integration", { variant: "error" });
      }

      if (response) {
        editServiceRequest(response);
      }
      if (saveAndExit) {
        if (inSmartOnFhirWorkflow) {
          navigateToSRSummary(response?.id || "", navigate, true);
        } else {
          navigateToPS(patient?.id || "", navigate, response?.id);
        }
      }
    }
    return getHealthCareManager(isDelegatedToHealthHelp);
  };

  const initiateHealthHelpIntegration = async (
    serviceRequestResponse: ServiceRequestResponse,
    saveAndExit: boolean
  ) => {
    //healthHelp intregrations
    if (
      useHealthHelpIntegration &&
      (serviceRequestResponse.delegatedVendor === HEALTH_HELP_NAME ||
        serviceRequestResponse.delegatedVendor === HEALTH_HELP_V2_NAME) &&
      !saveAndExit
    ) {
      setIsDelegatedToHealthHelp(true);
      if (serviceRequestFormHasNewEdits) {
        setVendorAssessmentError(false);

        //withdraw existing health help case and start a new one
        if (serviceRequestResponse.vendorIdentifier) {
          await withdrawHealthHelpCase(
            {},
            {
              pathParams: { id: serviceRequestResponse.id },
            }
          );
        }
        await integrateWithHealthHelp(
          startHealthHelpIntegration,
          serviceRequestResponse.id,
          setHealthHelpPASToken,
          vendorAssessmentError,
          delegatedVendorName
        );
        setVendorAssessmentCompleted(false);
      }
    }
  };

  const onFooterButtonClick = async (saveAndExit: boolean) => {
    //need to patch the service request
    let providers: ClinicalAssessmentProvider[] = [];

    //should add an if statement here the encapsulates everything below:
    if (saveAndExit || canSubmitServiceRequestForms) {
      setIsLoading(true);
      let provider: ClinicalAssessmentProvider = "Cohere";
      let serviceRequestSaved = true;
      try {
        provider = await submitForm(saveAndExit);
        if (!providers.includes(provider)) {
          providers.push(provider);
        }
      } catch (e) {
        serviceRequestSaved = false;
        enqueueSnackbar("Failed to save service request", { variant: "error" });
      }
      //clinicalAssessmentProviders
      setClinicalAssessmentProviders(providers);
      if (providers.includes("Cohere")) {
        await fetchClinicalAssessment();
      }
      if (serviceRequestSaved && !saveAndExit) {
        if (isFaxIntakeFlow) {
          await trackUserImpression({
            event: "ADD_ATTACHMENTS_SKIPPED",
            stage: "ADD_ATTACHMENTS",
            activityContext: activityContextFromServiceRequest(serviceRequests[0]),
          });
          setShouldEvalPreSubmissionStep(true);
        } else {
          setWorkflowStep("ADD_ATTACHMENTS");
        }
      }

      setIsLoading(false);
    }
  };

  /* Populate context with data from ClinicalAssessment
   * - if the clinicalAssessment has not been populated
   * - or if the clinicalAssessment needs to be refetched after selected clinical services changed */
  const fetchClinicalAssessment = async () => {
    if (
      noAssessmentQuestions ||
      (!!clinicalAssessment && !eq(previousClinicalServiceIds, selectedClinicalServiceIds))
    ) {
      if (carePathJourney) {
        await getCarePathClinicalAssessment({
          pathParams: { journeyId: carePathJourney.id || "" },
          queryParams: { serviceRequestIds: serviceRequestIds },
        });
      } else {
        if (multiSingleService) {
          if (serviceRequestIds.length > 0) {
            await getMultiSingleServiceClinicalAssessment({ queryParams: { serviceRequestIds: serviceRequestIds } });
          } else {
            await getSingleServiceClinicalAssessment({
              pathParams: { id: serviceRequestIds[serviceRequestIds.length - 1] },
            });
          }
        }
      }
      setPreviousClinicalServiceIds({});
    }
  };

  const showExitWithoutSaving = useMemo(() => {
    if (serviceRequestFormContent?.isInpatient && serviceRequestFormContent?.procedureCodes.length === 0) {
      return false;
    } else if (!serviceRequestFormContent?.userSelectedNonPalCode) {
      return palProcedureCodes.length === 0;
    } else if (nonPalProcedureCodes.length === 0) {
      return nonCohereCodes.length > 0;
    } else {
      return false;
    }
  }, [
    nonCohereCodes.length,
    nonPalProcedureCodes.length,
    palProcedureCodes.length,
    serviceRequestFormContent?.isInpatient,
    serviceRequestFormContent?.procedureCodes.length,
    serviceRequestFormContent?.userSelectedNonPalCode,
  ]);

  const isWaiting =
    startHealthHelpIntegrationLoading ||
    withdrawHealthHelpCaseLoading ||
    updateServiceRequestLoading ||
    isLoading ||
    loadingTransientActions;

  const onSaveAndExitClickButton = async () => {
    await onFooterButtonClick(true);
  };

  const handleRedirectModalClose = (success: boolean) => {
    setRedirectModalOpen(false);
    if (success) {
      onFooterButtonClick(false);
    }
  };

  return (
    <>
      <ServiceRequestFormRedirectModal
        open={redirectModalOpen}
        onClose={handleRedirectModalClose}
        redirectRuleAction={redirectModalAction}
        objectType={"service change"}
        formContent={serviceRequestFormContent}
        setFormContent={setServiceRequestFormContent}
        isHardStop={redirectModalAction && redirectModalAction.onAcceptAction === "NONE"}
      />
      <Footer
        primaryButtonText={primaryButtonText}
        onPrimaryButtonClick={async () => {
          await handleServiceQuantityRuleActions();
          setAttemptedServiceDetailsSubmit(true);
        }}
        primaryButtonDisabled={
          !canContinueToNextPage ||
          showExitWithoutSaving ||
          isWaiting ||
          deleteAttachmentLoading ||
          isPxCodeLimitExceeded ||
          isCrdExtensionDetailsDelegatedTo
        }
        primaryButtonLoading={isWaiting}
        showSaveAndExitButton={true}
        errorCaptionText={"There are some errors with the details above."}
        showError={!canContinueToNextPage}
        onSaveAndExitClick={onSaveAndExitClickButton}
        saveAndExitButtonDisabled={isWaiting}
        showExitWithoutSavingButton={showExitWithoutSaving}
        onExitWithoutSavingClick={deleteAndNavigateCallback}
        exitWithoutSavingButtonDisabled={isWaiting || deleteAttachmentLoading}
        setFooterHeight={setFooterHeight}
      />
    </>
  );
};

export default ServiceDetailsFooter;
