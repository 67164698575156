import React, { Dispatch, useCallback, useEffect, useRef, useState } from "react";

import {
  SecondaryButton,
  DATE_FORMAT,
  formatDateStr,
  formatDateToISODate,
  useFeature,
  InlineButton,
  dateStringSort,
  useGetServiceRequestByIdWithFallback,
  H4,
} from "@coherehealth/common";
import {
  Patient,
  ServiceRequestResponse,
  TrackingNumber,
  useGetServiceRequests,
  useGetCandidateSuggestedValues,
  BulkUpdateCandidateFeedbackRequestBody,
  useBulkUpdateCandidateFeedback,
  useGetFaxForwardLinesAndOptions,
  useGetReferralRequests,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { error as logError, warn as logWarning } from "logger";
import { useSnackbar } from "notistack";
import parse from "date-fns/parse";
import { useBelongsToOpsGroup } from "authorization";

import { CommonFaxSidebarViewProps, getReferralRequestsFaxAttachedTo, getServiceRequestsFaxAttachedTo } from "./common";
import {
  PatientResultsList,
  SearchFields,
  SearchFormState,
  ExistingServiceRequestResults,
} from "./AttachToServiceRequest";
import { useTrackUserInteraction } from "../../../util/userActivityTracker";
import Divider from "@material-ui/core/Divider";
import FaxMetaDataDisplay from "./FaxMetaDataDisplay";
import { SuggestionContext } from "../../AuthBuilder/SuggestionContext";
import { compareSuggestionsToSearch, initSuggestedMember, SuggestedMember } from "../../../util/suggestionUtils";
import { first } from "lodash";
import { H6 } from "@coherehealth/design-system";
import { Grid } from "@material-ui/core";
import { useGetFaxIntakeConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { AvailableRequestTypes } from "./AttachToServiceRequest/RequestTypeSelectionTab";
import { translateServiceCaseHealthPlan } from "util/serviceCaseUtils";
import { useFFPatientSearch } from "components/ReferralManagement/common/PatientSearchUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 100,
    height: "100%",
  },
  header: {
    padding: theme.spacing(3, 0, 1),
  },
  searchHeader: {
    marginBottom: theme.spacing(3),
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.contrastText,
    width: 560,
  },
  divider: (props: { showServiceRequests?: boolean }) => ({
    margin: props.showServiceRequests ? theme.spacing(3, 0, 3, 0) : theme.spacing(3, 0, 1, 0),
  }),
  otherOptionButtons: {
    padding: theme.spacing(1),
    fontSize: "15px",
  },
  verticalDivider: {
    margin: theme.spacing(0, 1.5),
    height: "28px",
  },
}));

export type Variant = "FINISH_OCR_SR" | "AUTH_SEARCH_VIEW";
type DisplayStringKeys = "header" | "searchFieldsHeader" | "collapsedSearchFieldsHeader";

const variantDisplayStrings: Record<Variant, Record<DisplayStringKeys, string>> = {
  FINISH_OCR_SR: {
    header: "Create new authorization",
    searchFieldsHeader: "Find a patient: 2 fields required",
    collapsedSearchFieldsHeader: "Check for duplicate request before creating a new one",
  },
  AUTH_SEARCH_VIEW: {
    header: "Search by auth or tracking number",
    searchFieldsHeader: "",
    collapsedSearchFieldsHeader: "",
  },
};

const EMPTY_SEARCH_FORM = {
  trackingNumber: "",
  memberId: "",
  memberDob: "",
  memberLastName: "",
  patientSearchFieldsError: false,
};

const isSearchFormEmpty = (form: SearchFormState) => {
  return !form.trackingNumber && !form.memberId && !form.memberDob && !form.memberLastName;
};

interface Props extends CommonFaxSidebarViewProps {
  variant: Variant;
  selectedPatient?: Patient;
  setSelectedPatient: Dispatch<Patient | undefined>;
  setOcrServiceRequest?: Dispatch<ServiceRequestResponse | undefined>;
  setSelectedServiceId: Dispatch<string>;
}

export default function AttachToServiceRequestSidebar({
  fileData: {
    name: fileName,
    cohereId: faxCohereId,
    id: faxId,
    attachedTo,
    usesCohereTemplate,
    healthPlanName,
    externalReferenceId,
  },
  refreshFileData,
  setSidebarView,
  variant,
  selectedPatient,
  setSelectedPatient,
  setOcrServiceRequest,
  fileData,
  serviceCase,
  setSelectedServiceId,
  url,
}: Props) {
  const isGhpUser = useBelongsToOpsGroup("GEISINGER");
  const cohereSuggestedValueFeatureEnabled = useFeature("cohereSuggestedValueFeatureEnabled");
  const geisingerSuggestedValueFeatureEnabled = useFeature("geisingerSuggestedValueFeatureEnabled");
  const faxIntakeShowOnlyLatestEditableServiceRequest = useFeature("faxIntakeShowOnlyLatestEditableServiceRequest");
  const highmarkPatientSearchSuggestedValueFeatureEnabled = useFeature(
    "highmarkPatientSearchSuggestedValueFeatureEnabled"
  );
  const { data: faxIntakeConfiguration } = useGetFaxIntakeConfigurationByPayer(healthPlanName ?? "");
  const facilityBasedClinicalServicePriority = useFeature("facilityBasedClinicalServicePriority");
  const unifyPatientSearch = useFeature("unifyPatientSearch");

  const listAndCreateReferralsFeatureEnabled = faxIntakeConfiguration?.referralsCreationFromFaxEnabled ?? false;
  const { enqueueSnackbar } = useSnackbar();
  const handleSideBarClick = useCallback(() => {
    setSidebarView("CREATE_FAX_NOTICE");
  }, [setSidebarView]);

  const handlePatientSelect = useCallback(
    (patient: Patient) => {
      setSelectedPatient(patient);
    },
    [setSelectedPatient]
  );

  const serviceRequestId = first(attachedTo)?.id;

  const { data: serviceRequest, refetch: getServiceRequest } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId || "",
    lazy: true,
    queryParams: {
      expandAuthorization: true,
    },
  });

  const [isManualFaxForwardingEnabled, setIsManualFaxForwardingEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (serviceRequestId) {
      getServiceRequest();
    }
  }, [getServiceRequest, serviceRequestId]);

  const [searchFormState, setSearchFormState] = useState<SearchFormState>(EMPTY_SEARCH_FORM);

  const { mutate: getFaxForwardLinesAndOptions, loading: isManualFaxForwardingOptionsLoading } =
    useGetFaxForwardLinesAndOptions({
      queryParams: {
        featureConfigurationLevel: "HEALTH_PLAN",
        healthPlanName: fileData?.healthPlanName || translateServiceCaseHealthPlan(serviceCase?.healthPlan) || "",
      },
    });

  useEffect(() => {
    const fetchFaxForwardLinesAndOptions = async () => {
      try {
        if (serviceCase?.healthPlan) {
          const response = await getFaxForwardLinesAndOptions();
          if (response) {
            setIsManualFaxForwardingEnabled(response?.manualFaxForwardingEnabled);
          }
        }
      } catch (error) {
        logWarning(error);
      }
    };

    if (serviceCase?.healthPlan) {
      fetchFaxForwardLinesAndOptions();
    }
  }, [getFaxForwardLinesAndOptions, serviceCase?.healthPlan]);

  const [isSearchingByTrackingNumber, setIsSearchingByTrackNumber] = useState(false);
  const [showOnlyRequestOfType, setShowOnlyRequestOfType] = useState<AvailableRequestTypes | undefined>();

  const clearSearchFields = () => {
    setSearchFormState(EMPTY_SEARCH_FORM);
    setIsSearchingByTrackNumber(false);
    setShowOnlyRequestOfType(undefined);
  };

  useEffect(() => {
    if (variant === "FINISH_OCR_SR" && serviceRequest && setOcrServiceRequest) {
      setOcrServiceRequest(serviceRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, serviceRequest]);

  useEffect(() => {
    if (selectedPatient && isSearchFormEmpty(searchFormState)) {
      setSearchFormState({
        memberId: selectedPatient.memberId || "",
        memberDob: formatDateStr(selectedPatient.dateOfBirth),
        memberLastName: selectedPatient.lastName || "",
        patientSearchFieldsError: false,
      });
    }
  }, [selectedPatient, searchFormState]);

  const [showServiceRequests, setShowServiceRequests] = useState(!!selectedPatient);
  const classes = useStyles({ showServiceRequests });
  /*
   * Get service requests associated with a patient
   */
  const {
    data: serviceRequestResults,
    loading: loadingServiceRequests,
    error: serviceRequestFetchError,
    refetch: getServiceRequests,
  } = useGetServiceRequests({
    lazy: true,
    queryParams: {
      view: "dashboardSummary",
    },
  });

  const {
    data: referralRequestResults,
    loading: loadingReferralRequests,
    error: referralRequestFetchError,
    refetch: getReferralRequests,
  } = useGetReferralRequests({ lazy: true });

  useEffect(() => {
    if (
      serviceRequestResults &&
      serviceRequestResults.length > 0 &&
      serviceRequestResults[0].patient?.id !== selectedPatient?.id
    ) {
      // if we're not currently showing results, and then new results show up, set the patient accordingly
      setSelectedPatient(serviceRequestResults[0].patient);
    }
    if (serviceRequestFetchError) {
      logError(serviceRequestFetchError);
      enqueueSnackbar("Failed to get service requests", {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceRequestResults, serviceRequestFetchError, enqueueSnackbar, setSelectedPatient]);

  useEffect(() => {
    if (referralRequestFetchError) {
      logError(referralRequestFetchError);
      enqueueSnackbar("Failed to get referral requests", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, referralRequestFetchError]);

  useEffect(() => {
    // refresh service request result list if the patient changes and we're not searching by tracking number
    if (selectedPatient && !searchFormState.trackingNumber) {
      getServiceRequests({
        queryParams: { patient: `eq:${selectedPatient.id}`, authStatus: `ne:DRAFT`, view: "dashboardSummary" },
      }).then(() => {
        setShowServiceRequests(true);
      });

      getReferralRequests({
        queryParams: { patient: `eq:${selectedPatient.id}`, sort: "lastUpdated:desc" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient]);

  useEffect(() => {
    if (searchFormState.trackingNumber) {
      if (referralRequestResults?.[0]?.patient || serviceRequestResults?.[0]?.patient) {
        setSelectedPatient(
          (!!referralRequestResults && referralRequestResults.length > 0
            ? referralRequestResults?.[0].patient
            : serviceRequestResults?.[0].patient) as Patient
        );
      }
    }
  }, [referralRequestResults, setSelectedPatient, selectedPatient, searchFormState, serviceRequestResults]);

  const [patients, setPatients] = useState<Patient[]>();
  const {
    mutate: patientSearch,
    loading: patientSearchLoading,
    error: patientSearchError,
  } = useFFPatientSearch(unifyPatientSearch, {
    onMutate: (_, data) => {
      setPatients(data);
    },
  });

  useEffect(() => {
    if (patientSearchError) {
      logError(patientSearchError);
      enqueueSnackbar("Failed to get list of patients", {
        variant: "error",
      });
    }
  }, [patientSearchError, enqueueSnackbar]);

  const { data: suggestions, refetch: fetchSuggestions } = useGetCandidateSuggestedValues({
    attachmentId: faxId || "",
    queryParams: { rankResults: true },
    lazy: true,
  });

  useEffect(() => {
    if (isGhpUser) {
      if (geisingerSuggestedValueFeatureEnabled) {
        fetchSuggestions();
      }
    } else if (healthPlanName === "Highmark") {
      if (highmarkPatientSearchSuggestedValueFeatureEnabled) {
        fetchSuggestions();
      }
    } else {
      if (cohereSuggestedValueFeatureEnabled) {
        fetchSuggestions();
      }
    }
  }, [
    fetchSuggestions,
    isGhpUser,
    geisingerSuggestedValueFeatureEnabled,
    cohereSuggestedValueFeatureEnabled,
    highmarkPatientSearchSuggestedValueFeatureEnabled,
    healthPlanName,
  ]);

  const suggestedMember = useRef<SuggestedMember>();
  useEffect(() => {
    if (suggestions) {
      setSearchFormState((currentSearchFormState) => {
        suggestedMember.current = initSuggestedMember(suggestions);
        return {
          ...currentSearchFormState,
          memberId: currentSearchFormState.memberId || suggestedMember.current.memberId?.predictedValue || "",
          memberDob:
            currentSearchFormState.memberDob || formatDateStr(suggestedMember.current.memberDob?.predictedValue) || "",
          memberLastName:
            currentSearchFormState.memberLastName || suggestedMember.current.memberLastName?.predictedValue || "",
        };
      });
    }
  }, [suggestions]);

  const trackUserActivityInteraction = useTrackUserInteraction();
  const { mutate: batchUpdateCandidateFeedback } = useBulkUpdateCandidateFeedback({});

  /*
   * Filter out any triage requests from the results and only return editable service requests
   */
  const filterServiceRequestData = (serviceRequestData: ServiceRequestResponse[]): ServiceRequestResponse[] => {
    if (faxIntakeShowOnlyLatestEditableServiceRequest) {
      const filteredServiceRequestData = serviceRequestData.filter(
        (sr) => sr.intakeRequestType !== "TRIAGE" && sr.isMostRecentEditableRequest
      );
      return filteredServiceRequestData.sort((a, b) =>
        dateStringSort(a?.intakeTimestamp || "", b?.intakeTimestamp || "", false)
      );
    } else {
      return serviceRequestData.filter((sr) => sr.intakeRequestType !== "TRIAGE");
    }
  };

  /*
   * Either searches for patients or service requests (from cohereId)
   */
  const search = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { trackingNumber, memberId, memberDob, memberLastName } = searchFormState;

    if (!!trackingNumber) {
      // clear patient search fields, in case if there is anything there
      setSearchFormState({
        ...searchFormState,
        memberId: "",
        memberDob: "",
        memberLastName: "",
        patientSearchFieldsError: false,
      });
      trackUserActivityInteraction({
        event: "FAX_SERVICE_REQUEST_SEARCH",
        stage: usesCohereTemplate === "Yes" ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW",
        activityContext: {
          faxId,
          trackingNumber: trackingNumber as TrackingNumber,
        },
      });
      if (trackingNumber.startsWith("R-") && listAndCreateReferralsFeatureEnabled) {
        await getReferralRequests({
          queryParams: { cohereId: `eq:${trackingNumber}`, sort: "lastUpdated:desc" },
        })
          .then(() => {
            setShowOnlyRequestOfType("referral");
          })
          .catch((err) => {
            if (err.message !== "Failed to fetch: Aborted") {
              throw err;
            }
          });
      } else {
        await getServiceRequests({
          queryParams: {
            cohereIdOrAuthNumber: `eq:${trackingNumber}`,
            authStatus: `ne:DRAFT`,
            view: "dashboardSummary",
          },
        });

        setShowOnlyRequestOfType("service");
      }

      setIsSearchingByTrackNumber(true);
      setShowServiceRequests(true);
    } else {
      const batchUpdateCandidateFeedbackRequestPayload: BulkUpdateCandidateFeedbackRequestBody =
        compareSuggestionsToSearch(memberId, memberDob, memberLastName, suggestedMember.current);
      await batchUpdateCandidateFeedback(batchUpdateCandidateFeedbackRequestPayload);

      // patient search, needs 2 of the 3 fields
      const filledFieldsCount = [memberId, memberDob, memberLastName].reduce(
        (count, field) => (!!field ? count + 1 : count),
        0
      );
      if (filledFieldsCount < 2) {
        setSearchFormState({ ...searchFormState, patientSearchFieldsError: true });
      } else {
        setSearchFormState({ ...searchFormState, patientSearchFieldsError: false });
        trackUserActivityInteraction({
          event: "FAX_PATIENT_SEARCH",
          stage: usesCohereTemplate === "Yes" ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW",
          activityContext: {
            faxId,
            trackingNumber: trackingNumber as TrackingNumber,
            patientSearchQuery: {
              memberId,
              dateOfBirth: formatDateToISODate(parse(memberDob, DATE_FORMAT, new Date())),
              lastName: memberLastName,
            },
          },
        });
        await patientSearch({
          memberId,
          dateOfBirth: formatDateToISODate(parse(memberDob, DATE_FORMAT, new Date())),
          lastName: memberLastName.toUpperCase(),
          healthPlanName: healthPlanName,
        });
      }
    }
  };

  const initialAttachedServiceRequestIds = getServiceRequestsFaxAttachedTo({ attachedTo })
    .map((attachedTo) => attachedTo.id)
    .filter((srId) => srId !== undefined);
  const initialAttachedReferralRequestIds = getReferralRequestsFaxAttachedTo({ attachedTo })
    .map((attachedTo) => attachedTo.id)
    .filter((srId) => srId !== undefined);

  if (facilityBasedClinicalServicePriority) {
    serviceRequestResults?.forEach((sr) => {
      if (sr.authorization?.clinicalServices) {
        sr.clinicalServices = sr.authorization.clinicalServices;
      }
    });
  }

  return (
    <>
      <div className={classes.container}>
        <>
          <FaxMetaDataDisplay
            fileData={fileData}
            serviceCase={serviceCase}
            showCaseId={true}
            showFaxId={true}
            showExternalId={true}
            url={url}
          />
          <Divider className={classes.divider} />
        </>
        <SuggestionContext.Provider
          value={{
            suggestedMember: suggestedMember.current,
          }}
        >
          {!showServiceRequests && <H4 className={classes.header}>{variantDisplayStrings[variant].header}</H4>}
          <SearchFields
            search={search}
            searchFormState={searchFormState}
            setSearchFormState={setSearchFormState}
            loadingSearch={patientSearchLoading || loadingServiceRequests}
            collapsed={showServiceRequests}
            resetSearch={() => {
              setShowServiceRequests(false);
              setSelectedPatient(undefined);
              clearSearchFields();
            }}
            searchHeaderText={variantDisplayStrings[variant].searchFieldsHeader}
            collapsedHeaderText={variantDisplayStrings[variant].collapsedSearchFieldsHeader}
            hideTrackingNumberSearch={variant === "FINISH_OCR_SR"}
            isReferralRequestFeatureFlagEnabled={listAndCreateReferralsFeatureEnabled}
          />
        </SuggestionContext.Provider>
        {!showServiceRequests && (
          <Grid container alignItems="center">
            <Grid item>
              <H6 color="textSecondary" marginRight={3} data-public>
                Other options:
              </H6>
            </Grid>

            <Grid item>
              <InlineButton
                className={classes.otherOptionButtons}
                onClick={() => setSidebarView("CREATE_FAX_NOTICE")}
                data-public
              >
                Create fax notice
              </InlineButton>
            </Grid>
            <Divider className={classes.verticalDivider} orientation="vertical" />
            <Grid item>
              <InlineButton
                onClick={() => setSidebarView("UNWORKABLE_FAX")}
                className={classes.otherOptionButtons}
                data-public
              >
                Unable to work
              </InlineButton>
            </Grid>

            {isManualFaxForwardingEnabled && !isManualFaxForwardingOptionsLoading && (
              <>
                <Divider className={classes.verticalDivider} orientation="vertical" />
                <Grid item>
                  <InlineButton
                    onClick={() => setSidebarView("FORWARDED_FAX")}
                    className={classes.otherOptionButtons}
                    data-public
                  >
                    Forward Fax
                  </InlineButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {showServiceRequests ? (
          <ExistingServiceRequestResults
            referralRequests={referralRequestResults ?? undefined}
            serviceRequests={serviceRequestResults ? filterServiceRequestData(serviceRequestResults) : null}
            initialAttachedServiceRequestIds={initialAttachedServiceRequestIds}
            initialAttachedReferralRequestIds={initialAttachedReferralRequestIds}
            patient={selectedPatient}
            onCancel={() => {
              clearSearchFields();
              if (initialAttachedServiceRequestIds.length > 0) {
                setSidebarView("CURRENTLY_ATTACHED_SERVICE_REQUESTS");
              } else {
                setShowServiceRequests(false);
                setSelectedPatient(undefined);
              }
            }}
            onAllFileAttachmentsComplete={() => {
              refreshFileData?.();
              setSidebarView("CURRENTLY_ATTACHED_SERVICE_REQUESTS");
            }}
            faxCohereId={faxCohereId}
            faxId={faxId}
            showCreateNewServiceRequest={true}
            setSidebarView={setSidebarView}
            ocrServiceRequest={serviceRequest ?? undefined}
            serviceCase={serviceCase}
            externalReferenceId={externalReferenceId}
            healthPlanName={healthPlanName}
            setSelectedServiceId={setSelectedServiceId}
            showCreateNewReferralRequest={faxIntakeConfiguration?.referralsCreationFromFaxEnabled}
            loadingServiceRequests={loadingServiceRequests || loadingReferralRequests}
            isSearchingByTrackingNumber={isSearchingByTrackingNumber}
            showOnlyRequestOfType={showOnlyRequestOfType}
          />
        ) : (
          <PatientResultsList
            patients={patients}
            createFaxNotice={handleSideBarClick}
            onPatientSelect={handlePatientSelect}
            patientSelectLoading={loadingServiceRequests || loadingReferralRequests}
            hideResults={!patients || patientSearchLoading}
            healthPlanName={fileData?.healthPlanName || translateServiceCaseHealthPlan(serviceCase?.healthPlan) || ""}
          />
        )}
      </div>
      {!showServiceRequests && variant !== "AUTH_SEARCH_VIEW" && (
        <FaxSidebarOuterControlPanelContainer>
          <FaxSidebarInnerControlPanelContainer>
            <SecondaryButton
              className={classes.cancelButton}
              warning
              onClick={() => {
                clearSearchFields();
                if (initialAttachedServiceRequestIds.length > 0) {
                  setSidebarView("CURRENTLY_ATTACHED_SERVICE_REQUESTS");
                } else {
                  setSidebarView("AUTH_SEARCH_VIEW");
                  setSelectedPatient(undefined);
                }
              }}
            >
              Cancel
            </SecondaryButton>
          </FaxSidebarInnerControlPanelContainer>
        </FaxSidebarOuterControlPanelContainer>
      )}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FaxSidebarOuterControlPanelContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 30,
  marginLeft: -24,
  marginBottom: -30,
  boxShadow:
    "0px 1px 10px 0px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)",

  backgroundColor: theme.palette.background.paper,
  width: 624,
  padding: theme.spacing(2, 3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
export const FaxSidebarInnerControlPanelContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
