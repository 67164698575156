import React from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { Caption } from "../Typography";
import { colorsDark } from "../../themes";

export default function EmptyDataContainer({ caption }: { caption?: string }) {
  return (
    <Container>
      <Caption>{caption || "No results found"}</Caption>
    </Container>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Container = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: colorsDark.font.secondary,
}));
