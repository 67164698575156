import { useEffect, useState } from "react";
import {
  useGetAllReviews,
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetServiceRequests,
  ServiceRequestResponse,
  Coverage,
  useGetLatestReviewLeadingToDenial,
  useIsCoverageActive,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  InformativeModal,
  useFeature,
  POLICY_UNITED_STATES_OPTIONS,
  NotFoundPage,
  parseDateFromISOStringWithoutFallback,
  useParsedQueryString,
  useSyncQueryParamsToPath,
  useGetServiceRequestByIdWithFallback,
} from "@coherehealth/common";
import { useAuthorized } from "authorization";
import LoadingDialog from "../LoadingDialog";
import SlimHeader from "../SlimHeader";
import ClinicalReviewShell from "common/ClinicalReviewShell";
import Tab from "@material-ui/core/Tab";
import { StyledTabs } from "../Review/ClinicalReviewPage";
import DenialLetterContent from "components/ServiceRequest/ReviewSection/MDReview/DenialLetterContent";
import DenialQueuedForDeliveryDialog from "components/ServiceRequest/ReviewSection/MDReview/DenialQueuedForDeliveryDialog";
import ReviewErrorModal from "common/ReviewErrorModal";
import { CircularProgress } from "@material-ui/core";
import { licenseDependentStates } from "components/ServiceRequest/ReviewSection/MDReview/DenialLetterSummaryCard";
import ClipboardWarningIcon from "components/images/ClipboardWarningIcon";
import { getCoverageBasedOnDate, getSortedListOfCoverages } from "util/patientUtils";
import ClipboardMissingInfo from "components/images/ClipboardMissingInfo";
import config from "api/config";
import ReviewLeftPanel from "../reviewUtils/ReviewLeftPanel";
import { useGetServiceRequestCases } from "@coherehealth/qm-api";
import { LeftPanelTabs, useAuthorizationFetch, tabProps } from "../reviewUtils/utils";
import { useAttachments } from "../reviewUtils/useAttachment";
import useClaimHistory from "components/ClaimHistory/useClaimHistory";
import useOtherAuthorizations from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";
import { useGetContinuationConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import { redirectToPatientSummaryFromReview, routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import { LeftReviewTabs, useTabStyles } from "../reviewUtils/LeftReviewTabs";

type DenialRightTabs = "LETTER_CONTENT";

export default function DenialReviewPage() {
  const canViewReview = useAuthorized("VIEW_REVIEW");
  const { serviceRequestId } = useParams();
  if (canViewReview && serviceRequestId) {
    return <DenialReviewPageAuthorized serviceRequestId={serviceRequestId} />;
  } else {
    return <NotFoundPage />;
  }
}

function DenialReviewPageAuthorized({ serviceRequestId }: { serviceRequestId: string }) {
  const tabClasses = useTabStyles({});
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const search = new URLSearchParams(location.search);

  const {
    data: existingReviews,
    error: existingReviewsError,
    loading: loadingExistingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequestId,
    queryParams: { max: 200 },
  });

  const {
    data: serviceRequest,
    error: serviceRequestFetchError,
    loading: serviceRequestLoading,
    refetch: serviceRequestRefetch,
  } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
  });

  const {
    data: patient,
    error: patientFetchError,
    loading: patientLoading,
    refetch: patientRefetch,
  } = useGetPatient({
    id: serviceRequest?.patient?.id || "",
    lazy: true,
  });

  const { data: outreachOpportunities } = useGetServiceRequestOutreachOpportunities({
    id: serviceRequestId,
  });

  // Retrieve the latest review leading to denial for this Service Request
  const {
    data: review,
    loading: latestReviewLeadingToDenialLoading,
    error: denialReviewLoadError,
  } = useGetLatestReviewLeadingToDenial({
    id: serviceRequestId,
  });

  // reviewId is required for time tracking
  const qs = useParsedQueryString();
  useSyncQueryParamsToPath({ ...qs, reviewId: review?.id });

  const {
    data: otherServiceRequests,
    error: otherServiceRequestsError,
    loading: otherServiceRequestsLoading,
    refetch: fetchOtherServiceRequests,
  } = useGetServiceRequests({
    queryParams: { patient: `eq:${serviceRequest?.patient?.id || ""}` },
    lazy: true,
    resolve: (patientServiceRequests: ServiceRequestResponse[]) =>
      patientServiceRequests.filter((patientServiceRequest) => patientServiceRequest.id !== serviceRequest?.id),
  });

  const {
    data: isCoverageActive,
    error: isCoverageActiveFetchError,
    refetch: isCoverageActiveRefetch,
  } = useIsCoverageActive({
    queryParams: {
      serviceRequestId: serviceRequest?.id || "",
    },
    lazy: true,
  });

  const { data: continuationConfiguration } = useGetContinuationConfigurationByPayer(
    serviceRequest?.healthPlanName ?? ""
  );

  const getIsLicenseNeeded = () => {
    const isLicenseNeeded = !!licenseDependentStates.find(
      (state) =>
        state ===
        getCoverageBasedOnDate(
          parseDateFromISOStringWithoutFallback(serviceRequest?.startDate),
          serviceRequest?.patient
        )?.stateOfIssue
    );
    return isLicenseNeeded;
  };
  const isLicenseNeeded = getIsLicenseNeeded();

  const [leftColumnTab, setLeftColumnTab] = useState<LeftPanelTabs>("REQUEST_INFORMATION");
  const [rightColumnTab, setRightColumnTab] = useState<DenialRightTabs>("LETTER_CONTENT");
  const [submissionError, setSubmissionError] = useState<string>();
  const [dualReviewErrorModalOpen, setDualReviewErrorModalOpen] = useState<boolean>(false);
  const [denialSentModalOpen, setDenialSentModalOpen] = useState(false);
  const [finalizeDenial, setFinalizeDenial] = useState<boolean>(false);
  const prePopulateDenialsTemplate = useFeature("prePopulateDenialsTemplate");
  const claimHistoryFeature = useFeature("claimHistoryFeature");
  const showLicensePopUpFF = useFeature("showLicensePopup");
  const requireLicensePopUp = showLicensePopUpFF && isLicenseNeeded && serviceRequest?.lobType === "Commercial";

  const tabLabel =
    review?.reviewOutcome === "DENIED" || review?.reviewOutcome === "RECOMMENDED_DENIAL"
      ? "Denial Letter"
      : review?.reviewOutcome === "PARTIALLY_APPROVED" || review?.reviewOutcome === "RECOMMENDED_PARTIAL_APPROVAL"
      ? "Partial Approval Letter"
      : "";

  const [licensedModalOpen, setLicensedModalOpen] = useState<boolean>(false);
  const [licensedState, setLicensedState] = useState<string>();
  const [openMemberCoverageExpiresModal, setOpenMemberCoverageExpiresModal] = useState<boolean>(false);
  const isAllowedCaseHistory = useAuthorized("CASE_HISTORY");
  const caseHistoryGA = useFeature("caseEventHistoryGA");
  const canAccessCaseHistory = isAllowedCaseHistory || caseHistoryGA;
  const genericNotesEnabled = useFeature("genericNotes");
  const viewNoteAuthorized = useAuthorized("VIEW_NOTES");
  const canViewGenericNotes = genericNotesEnabled && viewNoteAuthorized;
  const trackingNumberSearch = !!search.get("trackingNumberSearch");
  const caseId = search.get("caseId") || "";
  const { data: serviceCases, refetch: getServiceCases } = useGetServiceRequestCases({
    id: serviceRequest?.id || "none",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });

  useEffect(() => {
    if (serviceRequest && config.HAS_QM_ENVIRONMENT && canAccessCaseHistory) {
      getServiceCases();
    }
  }, [canAccessCaseHistory, getServiceCases, serviceRequest]);

  useEffect(() => {
    if (requireLicensePopUp && review) {
      const coverages = getSortedListOfCoverages(serviceRequest?.patient);
      let activePrimaryCoverage: Coverage | null | undefined = null;
      if (coverages?.[0]?.planActive) {
        //If the first coverage in the sorted list is active, then that is the primary coverage
        // we'll then set it aside from the other (non-primary) coverages and display them separately
        activePrimaryCoverage = coverages.shift();
        if (activePrimaryCoverage) {
          POLICY_UNITED_STATES_OPTIONS?.forEach((opt) => {
            if (opt.id === activePrimaryCoverage?.stateOfIssue) {
              setLicensedState(opt.label);
            }
          });
        }
      }
      setLicensedModalOpen(true);
    }
  }, [requireLicensePopUp, review, serviceRequest?.patient]);

  useEffect(() => {
    if (existingReviewsError) {
      enqueueSnackbar(`Failed to load reviews: ${existingReviewsError.message}`, { variant: "error" });
    }
    if (serviceRequestFetchError) {
      enqueueSnackbar(`Failed to load service request: ${serviceRequestFetchError.message}`, { variant: "error" });
    }
    if (patientFetchError) {
      enqueueSnackbar(`Failed to load patient information: ${patientFetchError.message}`, { variant: "error" });
    }
    if (isCoverageActiveFetchError) {
      enqueueSnackbar(`Failed to load active coverage information: ${isCoverageActiveFetchError.message}`, {
        variant: "error",
      });
    }
    if (otherServiceRequestsError) {
      enqueueSnackbar(`Failed to load patient's other service requests: ${otherServiceRequestsError.message}`, {
        variant: "error",
      });
    }
    if (denialReviewLoadError) {
      enqueueSnackbar(`Failed to load review leading to denial: ${denialReviewLoadError.message}`, {
        variant: "error",
      });
    }
  }, [
    enqueueSnackbar,
    existingReviewsError,
    serviceRequestFetchError,
    patientFetchError,
    otherServiceRequestsError,
    denialReviewLoadError,
    isCoverageActiveFetchError,
  ]);

  useEffect(() => {
    if (serviceRequest) {
      patientRefetch();
      fetchOtherServiceRequests();
    }
  }, [patientRefetch, fetchOtherServiceRequests, serviceRequest]);

  useEffect(() => {
    if (submissionError) {
      enqueueSnackbar(`${submissionError}`, { variant: "error" });
      setSubmissionError("");
    }
  }, [submissionError, enqueueSnackbar]);

  useEffect(() => {
    if (patient && serviceRequest) {
      isCoverageActiveRefetch();
    }
  }, [patient, serviceRequest, isCoverageActiveRefetch]);

  useEffect(() => {
    if (isCoverageActive !== null) {
      setOpenMemberCoverageExpiresModal(!isCoverageActive.active);
    }
  }, [isCoverageActive]);

  const { authorization, fetchAuthorization, authorizationFetchLoading } = useAuthorizationFetch(serviceRequest);
  const {
    attachments,
    attachmentListFetchLoading,
    onSwitchAttachmentTab,
    onViewAttachment,
    previousAttachmentsExpanded,
    setPreviousAttachmentsExpanded,
    attachmentIndexOpen,
    currentPage,
    currentRotate,
    zoomLevel,
    attachmentsInfo,
    setAttachmentsInfo,
    openAttachmentWarningModal,
    setOpenAttachmentWarningModal,
    newAttachmentsNumber,
  } = useAttachments({
    serviceRequest,
    existingReviews: existingReviews || [],
  });

  const claimHistoryProps = useClaimHistory({ patientId: serviceRequest?.patient?.id });

  const [incorrectReviewForDenialLetterModal, setIncorrectReviewForDenialLetterModal] = useState<boolean>(false);
  const [disableDueToWrongReview, setDisableDueToWrongReview] = useState<boolean>(false);

  const useOtherAuthorizationProps = useOtherAuthorizations({ serviceRequest });

  return (
    <>
      <ClinicalReviewShell
        attachmentView={leftColumnTab === "ATTACHMENTS"}
        header={<SlimHeader serviceRequest={serviceRequest} />}
        leftColumnTabs={
          <LeftReviewTabs
            leftColumnTab={leftColumnTab}
            setLeftColumnTab={setLeftColumnTab}
            onSwitchAttachmentTab={onSwitchAttachmentTab}
            newAttachmentsNumber={newAttachmentsNumber}
            showClaimsHistoryTab={claimHistoryFeature}
          />
        }
        leftColumn={
          <ReviewLeftPanel
            authorization={authorization}
            refreshAuthorization={fetchAuthorization}
            authorizationFetchLoading={authorizationFetchLoading}
            denialReview={review}
            patient={patient}
            patientLoading={patientLoading}
            serviceRequest={serviceRequest}
            currServiceRequestID={serviceRequestId}
            serviceRequestLoading={serviceRequestLoading}
            existingReviews={existingReviews}
            outreachOpportunities={outreachOpportunities}
            otherServiceRequests={otherServiceRequests}
            otherServiceRequestsLoading={otherServiceRequestsLoading}
            serviceRequestId={serviceRequestId}
            crrDenials
            serviceRequestRefetch={serviceRequestRefetch}
            attachments={attachments}
            previousAttachmentsExpanded={previousAttachmentsExpanded}
            setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
            attachmentIndexOpen={attachmentIndexOpen}
            currentPage={currentPage}
            zoomLevel={zoomLevel}
            currentRotate={currentRotate}
            attachmentsLoading={attachmentListFetchLoading}
            onViewAttachment={onViewAttachment}
            tabOpen={leftColumnTab}
            setTabOpen={setLeftColumnTab}
            serviceCases={serviceCases}
            trackingNumberSearch={trackingNumberSearch}
            caseId={caseId}
            canAccessCaseHistory={canAccessCaseHistory}
            canViewGenericNotes={canViewGenericNotes}
            attachmentsInfo={attachmentsInfo}
            setAttachmentsInfo={setAttachmentsInfo}
            canUseClaimHistoryFeature={claimHistoryFeature}
            {...useOtherAuthorizationProps}
            {...claimHistoryProps}
            isDenialReviewPage={true}
          />
        }
        rightColumn={
          <div style={{ maxHeight: "calc(100vh - 144px)" }}>
            <StyledTabs
              value={rightColumnTab}
              onChange={(_, index) => {
                setRightColumnTab(index);
              }}
              aria-label="auth status tab"
              style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}
            >
              <Tab label={tabLabel} {...tabProps("LETTER_CONTENT")} className={tabClasses.panelTab} />
            </StyledTabs>
            {serviceRequestLoading || latestReviewLeadingToDenialLoading ? (
              <CircularProgress />
            ) : (
              <DenialLetterContent
                serviceRequest={serviceRequest}
                review={review}
                prePopulateDenialsTemplate={prePopulateDenialsTemplate}
                setSubmissionError={setSubmissionError}
                setDenialSentModalOpen={setDenialSentModalOpen}
                setDualReviewErrorModalOpen={setDualReviewErrorModalOpen}
                setFinalizeDenial={setFinalizeDenial}
                crrView
                incorrectReviewForDenialLetter={incorrectReviewForDenialLetterModal}
                setIncorrectReviewForDenialLetterModal={setIncorrectReviewForDenialLetterModal}
                disableDueToWrongReview={disableDueToWrongReview}
                setDisableDueToWrongReview={setDisableDueToWrongReview}
                continuationConfiguration={continuationConfiguration}
              />
            )}
          </div>
        }
      />
      <LoadingDialog
        open={loadingExistingReviews || serviceRequestLoading || latestReviewLeadingToDenialLoading}
        headingText={"Loading your denial letter"}
      />
      <InformativeModal
        open={openAttachmentWarningModal}
        onClose={() => {
          setOpenAttachmentWarningModal(false);
        }}
        headerText={"New attachments uploaded"}
        additionalInfoText={`The submitter has uploaded ${newAttachmentsNumber} new attachments`}
        primaryButtonText={"View new attachments"}
        primaryButtonAction={() => {
          setLeftColumnTab("ATTACHMENTS");
          setOpenAttachmentWarningModal(false);
        }}
      />
      <InformativeModal
        headerText={"Is " + review?.createdByName + " licensed in " + licensedState + "?"}
        subHeaderText={
          "The last review author must be licenced in the applicable state before sending a final determination letter."
        }
        icon={<ClipboardWarningIcon />}
        primaryButtonText="Confirm license"
        primaryButtonAction={() => {
          setLicensedModalOpen(false);
        }}
        tertiaryButtonText={"Not licensed, go back"}
        tertiaryButtonRoute={
          patient?.id && routeToPatientSummaryFromReview({ serviceRequestId, patientId: patient.id })
        }
        showDivider
        open={licensedModalOpen}
        onClose={() => setLicensedModalOpen(false)}
      />
      <ReviewErrorModal
        open={dualReviewErrorModalOpen}
        setReviewErrorModalOpen={setDualReviewErrorModalOpen}
        patientId={serviceRequest?.patient?.id}
        headerText={"Review has already been completed"}
        subHeaderText={"You cannot save this letter over a completed review. Return to the patient summary to continue"}
        serviceRequestId={serviceRequestId}
      />
      <ReviewErrorModal
        patientId={serviceRequest?.patient?.id}
        open={denialSentModalOpen}
        setReviewErrorModalOpen={setDenialSentModalOpen}
        headerText={"This denial has already been queued for delivery"}
        subHeaderText={"Please return to the patient summary"}
        serviceRequestId={serviceRequestId}
      />
      <DenialQueuedForDeliveryDialog
        open={finalizeDenial}
        serviceRequestId={serviceRequestId}
        patientId={serviceRequest?.patient?.id || ""}
      />
      <InformativeModal
        icon={<ClipboardMissingInfo />}
        open={openMemberCoverageExpiresModal}
        onClose={() => {
          setOpenMemberCoverageExpiresModal(false);
        }}
        headerText={"This member’s coverage expires before the Date of Service"}
        additionalInfoText={`You may not be able to complete this review`}
        primaryButtonText={`Continue to review`}
        primaryButtonAction={() => {
          setOpenMemberCoverageExpiresModal(false);
        }}
      />
      <InformativeModal
        icon={<ClipboardMissingInfo />}
        open={incorrectReviewForDenialLetterModal}
        onClose={() => {
          setIncorrectReviewForDenialLetterModal(false);
        }}
        headerText={"There is a newer review available"}
        subHeaderText={`You will not be able to submit this determination letter. Please discard and exit, then start a new determination letter to send a letter for this request.`}
        primaryButtonText={`Discard and exit`}
        primaryButtonAction={() => {
          setIncorrectReviewForDenialLetterModal(false);
          redirectToPatientSummaryFromReview({ serviceRequest, caseId, navigate });
        }}
        tertiaryButtonText={`Continue to letter`}
        tertiaryButtonAction={() => {
          setIncorrectReviewForDenialLetterModal(false);
        }}
      />
    </>
  );
}
