import React, { useEffect, useState } from "react";
import { MutateMethod } from "restful-react";
import uniqueId from "lodash/uniqueId";
import { v4 as uuid } from "uuid";
import { useSnackbar } from "notistack";

import { SecondaryButton, colorsLight, Body2, TextField } from "@coherehealth/common";
import {
  Attachment,
  CreateCarePathJourneyAttachmentPathParams,
  CreateServiceRequestAttachmentPathParams,
} from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, useTheme } from "@material-ui/core/styles";
import { useAuthorized } from "authorization";
import { UploadFile } from "./FileUploadItem";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { HeadsetMicIcon, IconButton } from "../Requestor/common";
import AddAttachmentHeaderText from "./AddAttachmentHeaderText";
import AddAFile from "./AddAFile";
import AttachmentsTable from "./AttachmentsTable/AttachmentsTable";

export interface AddAttachmentProps {
  carePathJourneyId?: string;
  serviceRequestIds?: string[];
  uploadFile: MutateMethod<
    Attachment,
    void,
    void,
    CreateCarePathJourneyAttachmentPathParams | CreateServiceRequestAttachmentPathParams
  >;
  uploading: boolean;
  allowedFileExtensions?: string[];
  mockUpload?: (...args: any[]) => Promise<Attachment>;
  initialAttachments?: Attachment[];
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  setAttachmentsCompleted?: (arg0: boolean) => void;
  afterUpload?: (arg0: Attachment, totalFiles: number) => void;
  isOnCarePathJourney: boolean;
  workflowId?: string;
  stage?: string;
  allowMultipleAttachmentFiles?: boolean;
  fileSizeLimit?: number;
  setRemovedAttachments: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function AddAttachments({
  carePathJourneyId,
  serviceRequestIds,
  uploadFile,
  uploading,
  allowedFileExtensions,
  mockUpload,
  initialAttachments = [],
  onUpdateAttachments,
  setAttachmentsCompleted,
  afterUpload,
  isOnCarePathJourney,
  workflowId,
  stage,
  allowMultipleAttachmentFiles,
  fileSizeLimit,
  setRemovedAttachments,
}: AddAttachmentProps) {
  if (mockUpload) {
    uploadFile = mockUpload;
  }

  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<UploadFile[]>(initialAttachments);
  const [faxId, setFaxId] = useState<string>();
  const canUserEditViewable = useAuthorized("EDIT_ATTACHMENT_PRACTICE_VIEWABLE");
  const canAddFax = useAuthorized("VIEW_INCOMING_FAXES");

  const onAddFaxId = async (faxId: string) => {
    const formData = new FormData();
    formData.set("faxId", faxId);
    // Assign our candidate a unique ID so we can retrieve it later to update its status
    const candidateFile = { name: faxId, uploading: true, id: uniqueId() };
    setFiles((orig) => [...orig, candidateFile]);
    try {
      // NOTE: `as` type assertions are evil, but this allows us to
      // use the built-in FormData handling in restful-react:
      // https://github.com/contiamo/restful-react/pull/257
      if (isOnCarePathJourney) {
        const resp = await uploadFile(formData as unknown as void, {
          pathParams: { id: carePathJourneyId || "" },
        });
        setFiles((orig) => [
          ...orig.filter((f) => f.id !== candidateFile.id),
          {
            ...candidateFile,
            uploading: false,
            ...resp, // this should overwrite our temporary ID as well
          },
        ]);

        if (afterUpload) {
          afterUpload(resp, files.length + 1);
        }
      } else {
        // Assign a unique id for this attachment group
        const attachmentGroupId: string = uuid();
        formData.set("attachmentGroupId", attachmentGroupId);
        for (const srId of serviceRequestIds || []) {
          const resp = await uploadFile(formData as unknown as void, {
            pathParams: { id: srId || "" },
          });
          setFiles((orig) => [
            ...orig.filter((f) => f.id !== candidateFile.id),
            {
              ...candidateFile,
              uploading: false,
              ...resp, // this should overwrite our temporary ID as well
              attachmentGroupId: attachmentGroupId,
            },
          ]);
          if (afterUpload) {
            afterUpload({ ...resp, attachmentGroupId: attachmentGroupId }, files.length + 1);
          }
        }
      }
    } catch (e) {
      console.error(e);
      setFiles((orig) => [...orig.filter((f) => f.id !== candidateFile.id)]);

      enqueueSnackbar(`Unable to attach fax ID ${faxId}`, { variant: "error" });
    } finally {
      // Clear out the input: otherwise, if the user selects the same file
      // a second time, we don't trigger an onChange event
      setFaxId("");
    }
  };

  useEffect(() => {
    if (setAttachmentsCompleted) {
      setAttachmentsCompleted(true);
    }
    if (onUpdateAttachments) {
      onUpdateAttachments(mapUploadFilesToAttachments(files));
    }
    // including setAttachmentsCompleted changes too often
    // eslint-disable-next-line
  }, [files]);

  const { spacing: themeSpacing } = useTheme();

  return (
    <div>
      <AddAttachmentHeaderText allowMultipleAttachmentFiles={allowMultipleAttachmentFiles} />

      {/* https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file */}
      <AddAFile
        carePathJourneyId={carePathJourneyId}
        serviceRequestIds={serviceRequestIds}
        allowedFileExtensions={allowedFileExtensions}
        uploading={uploading}
        afterUpload={afterUpload}
        isOnCarePathJourney={isOnCarePathJourney}
        workflowId={workflowId}
        uploadFile={uploadFile}
        files={files}
        setFiles={setFiles}
        allowMultipleAttachmentFiles={allowMultipleAttachmentFiles}
        fileSizeLimit={fileSizeLimit}
      />
      {canAddFax && (
        <FaxPanel>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            style={{
              padding: themeSpacing(0, 2, 0, 0),
              margin: 0,
            }}
          >
            <Grid item xs={6}>
              <Tooltip title="Attach file by fax ID" placement="top" arrow data-public>
                <IconButton>
                  <HeadsetMicIcon />
                  <Body2 data-public>Attach file by fax ID</Body2>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <TextField type="text" label="Fax ID" value={faxId} onChangeValue={setFaxId} dataPublic={true} />
            </Grid>
            <Grid item xs={3}>
              <SecondaryButton
                onClick={() => {
                  if (faxId) {
                    onAddFaxId(faxId);
                  }
                }}
              >
                Add
              </SecondaryButton>
            </Grid>
          </Grid>
        </FaxPanel>
      )}
      {files && files.length > 0 && (
        <>
          <AttachmentsTable
            files={files}
            setFiles={setFiles}
            canUserEditViewable={canUserEditViewable}
            carePathJourneyId={carePathJourneyId}
            serviceRequestIds={serviceRequestIds}
            stage={stage}
            onUpdateAttachments={onUpdateAttachments}
            setRemovedAttachments={setRemovedAttachments}
            style={{ paddingTop: "40px" }}
          />
        </>
      )}
    </div>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const FaxPanel = styled("div")(({ theme }) => ({
  border: "1px solid",
  borderColor: colorsLight.gray.divider,
  backgroundColor: colorsLight.gray.light,
  boxShadow: "0 0 0 0px",
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  "&:before": {
    content: "none",
  },
}));

const mapUploadFilesToAttachments = (files: UploadFile[]): Attachment[] => {
  return files.map((f) => ({
    id: f.id || "",
    name: f.name || "",
    size: f.size || 0,
    type: f.type,
    practiceViewable: f.practiceViewable,
    dateCreated: f.dateCreated || "",
    lastUpdated: f.lastUpdated || "",
  }));
};
