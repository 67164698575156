import {
  IntegrationLog,
  ReferralRequestResponse,
  useGetReferralRequestIntegrationLogs,
} from "@coherehealth/core-platform-api";
import React, { useCallback, useEffect, useState } from "react";
import { SingleIntegration } from "../common/utils";
import { InlineButton, Modal } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import { CircularProgress, useTheme } from "@material-ui/core";
import {
  IntegrationExecution,
  IntegrationType,
  IntegrationStatus as ObservabilityIntegrationStatus,
  useGetAllIntegrationExecutionLogs,
  useGetIntegrationExecutionLogByIdAndIntegrationType,
} from "@coherehealth/int-obs-api";
import IntegrationLogDetailView from "components/ServiceRequest/ReadonlyDetail/IntegrationLogDetailView";
import config from "api/config";

interface Props {
  referralRequest: ReferralRequestResponse;
}

export default function IntegrationStatusSummary({ referralRequest }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [referralOutboundStatus, setReferralOutboundStatus] = useState<ObservabilityIntegrationStatus | undefined>(
    referralRequest.integrationStatus
  );

  const theme = useTheme();

  const { refetch: getIntegrationExecutionByIdAndIntegrationType } =
    useGetIntegrationExecutionLogByIdAndIntegrationType({
      base: `${config.INT_OBS_SERVICE_API_URL}`,
      objectId: referralRequest.id,
      queryParams: {
        integrationType: "REFERRALS_OUTBOUND",
      },
      lazy: true,
    });

  const fetchAndSetIntegrationStatus = useCallback(
    async (
      integrationType: IntegrationType,
      setIntegrationStatus: React.Dispatch<React.SetStateAction<ObservabilityIntegrationStatus | undefined>>,
      objectId: string
    ) => {
      try {
        const responseData = await getIntegrationExecutionByIdAndIntegrationType({
          pathParams: {
            objectId: referralRequest.id,
          },
          queryParams: {
            integrationType: integrationType,
          },
        });
        setIntegrationStatus(responseData?.status);
      } catch (error) {
        setIntegrationStatus("REQUEST_ERROR");
        enqueueSnackbar(`Failed to get ${integrationType} from int observability service`, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
    [enqueueSnackbar, getIntegrationExecutionByIdAndIntegrationType, referralRequest.id]
  );

  useEffect(() => {
    if (referralRequest?.id && !referralOutboundStatus) {
      fetchAndSetIntegrationStatus("REFERRALS_OUTBOUND", setReferralOutboundStatus, referralRequest?.id);
    }
  }, [fetchAndSetIntegrationStatus, referralRequest?.id, referralOutboundStatus]);

  const [expanded, setIsExpanded] = useState<boolean>();

  return (
    <>
      <SingleIntegration status={referralOutboundStatus} name="Payor" />
      <SingleIntegration status={referralRequest.integration?.matrix?.status} name="Mail vendor" />
      <SingleIntegration status={referralRequest.integration?.salesforce?.status} name="Salesforce" />

      <br />
      <InlineButton
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
        style={{ paddingTop: theme.spacing(2), paddingBottom: 0 }}
      >
        Integration log details
      </InlineButton>
      <Modal
        open={Boolean(expanded)}
        onClose={() => {
          setIsExpanded(false);
        }}
      >
        {!!expanded && <IntegrationLogDetails referralRequestId={referralRequest.id} />}
      </Modal>
    </>
  );
}
interface LogsComponentProps {
  referralRequestId: string;
}
function IntegrationLogDetails({ referralRequestId }: LogsComponentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: integrationLogsData,
    loading: integrationLogsLoading,
    error: integrationLogsError,
  } = useGetReferralRequestIntegrationLogs({ id: referralRequestId });

  const {
    data: intObservabilityLogsData,
    loading: intObservabilityLogsLoading,
    error: intObservabilityLogsError,
  } = useGetAllIntegrationExecutionLogs({
    base: `${config.INT_OBS_SERVICE_API_URL}`,
    queryParams: {
      objectId: referralRequestId,
    },
  });
  useEffect(() => {
    if (integrationLogsError) {
      enqueueSnackbar(`Failed to log integration log details: ${integrationLogsError.message}`, { variant: "error" });
    }
    if (intObservabilityLogsError) {
      enqueueSnackbar(`Failed to log integration log details: ${intObservabilityLogsError.message}`, {
        variant: "error",
      });
    }
  }, [intObservabilityLogsError, enqueueSnackbar, integrationLogsError]);

  if (integrationLogsLoading || intObservabilityLogsLoading) {
    return <CircularProgress />;
  }
  let logs: (IntegrationExecution | IntegrationLog)[] = [];
  if (integrationLogsData && integrationLogsData.logs) {
    logs = [...logs, ...integrationLogsData.logs];
  }
  if (intObservabilityLogsData) {
    logs = [...logs, ...intObservabilityLogsData];
  }
  return <IntegrationLogDetailView logs={logs} />;
}
