import { Attachment, AttachmentUpdatePayload } from "@coherehealth/core-platform-api";
import { TableBody } from "@material-ui/core";
import React from "react";
import { UploadFile } from "../FileUploadItem";
import UploadCarePathJourneyAttachment from "../UploadCarePathJourneyAttachment";
import UploadServiceRequestAttachment from "../UploadServiceRequestAttachment";
import { MutateMethod } from "restful-react";
import { first } from "lodash";

interface Props {
  carePathJourneyId?: string;
  serviceRequestIds?: string[];
  files: UploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  canUserEditViewable: boolean;
  stage?: string;
  onUpdateAttachments?: (arg0: Attachment[]) => void;
  noDivider?: boolean;
  hideDownloadIcon?: boolean;
  deleteAttachment: MutateMethod<void | void[], string, void, any>;
  deleteLoading: boolean;
  onRemoveFile: ({ id }: { id: string }) => void;
  onUpdateFile: (file: UploadFile) => void;
  updateAttachmentGroup: (fileId: string, attachmentUpdatePayload: AttachmentUpdatePayload) => Promise<void>;
  updateLoading: boolean;
}

export default function AttachmentTableBody({
  carePathJourneyId,
  serviceRequestIds,
  files,
  setFiles,
  canUserEditViewable,
  stage,
  noDivider,
  hideDownloadIcon,
  deleteAttachment,
  deleteLoading,
  onUpdateFile,
  onRemoveFile,
  updateAttachmentGroup,
  updateLoading,
}: Props) {
  const serviceRequestId = first(serviceRequestIds);

  return (
    <>
      {carePathJourneyId && serviceRequestId && (
        <TableBody>
          {files.map((file, idx) => (
            <UploadCarePathJourneyAttachment
              key={`${file.id || `fallback-${idx}`}`}
              carePathJourneyId={carePathJourneyId}
              serviceRequestId={serviceRequestId}
              afterDelete={onRemoveFile}
              afterUpdate={onUpdateFile}
              file={file}
              canEditViewable={canUserEditViewable}
              noDivider={noDivider && idx + 1 === files.length}
            />
          ))}
        </TableBody>
      )}
      {!carePathJourneyId && serviceRequestIds && (
        <TableBody style={{ padding: "0px", margin: "0px" }}>
          {files.map(
            (file, idx) =>
              file.serviceRequest?.id && (
                <UploadServiceRequestAttachment
                  key={`${file.id || `fallback-${idx}`}`}
                  serviceRequestId={file.serviceRequest.id}
                  afterDelete={onRemoveFile}
                  afterUpdate={onUpdateFile}
                  file={file}
                  canEditViewable={canUserEditViewable}
                  stage={stage}
                  noDivider={noDivider && idx + 1 === files.length}
                  hideDownloadIcon={hideDownloadIcon}
                  deleteAttachment={deleteAttachment}
                  deleteLoading={deleteLoading}
                  updateAttachment={updateAttachmentGroup}
                  updateLoading={updateLoading}
                />
              )
          )}
        </TableBody>
      )}
    </>
  );
}
