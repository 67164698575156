import React, { useEffect, Dispatch, SetStateAction, MutableRefObject } from "react";
import {
  Attachment,
  AuthorizationResponse,
  ReviewType,
  ServiceRequestResponse,
  useGetPatient,
  useGetServiceRequestOutreachOpportunities,
  useGetServiceRequests,
} from "@coherehealth/core-platform-api";
import { AttachmentInfo, UserClickInfoClinicalReviewPage } from "@coherehealth/common";
import { useSnackbar } from "notistack";
import ReviewLeftPanel from "../reviewUtils/ReviewLeftPanel";
import { ServiceCase } from "@coherehealth/qm-api";
import { UseClaimHistoryProps } from "components/ClaimHistory/useClaimHistory";
import { LeftPanelTabs } from "../reviewUtils/utils";
import { UseOtherAuthorizationProps } from "../ClinicalReviewInfoPanel/OtherServiceRequests/useOtherAuthorization";

interface LeftPanelProps extends UseClaimHistoryProps, UseOtherAuthorizationProps {
  authorization?: AuthorizationResponse;
  refreshAuthorization?: () => Promise<void>;
  authorizationFetchLoading?: boolean;
  serviceRequest: ServiceRequestResponse | null;
  attachments: Attachment[] | undefined;
  tabOpen: LeftPanelTabs;
  setTabOpen: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
  userClickInfoTracking?: UserClickInfoClinicalReviewPage;
  setUserClickInfoTracking?: Dispatch<SetStateAction<UserClickInfoClinicalReviewPage>>;
  attachmentIndexOpen: number;
  currentPage: MutableRefObject<number>;
  zoomLevel: MutableRefObject<number>;
  currentRotate: MutableRefObject<number>;
  serviceRequestLoading: boolean;
  attachmentsLoading?: boolean;
  existingReviews: ReviewType[] | null;
  serviceRequestRefetch: () => Promise<ServiceRequestResponse | null>;
  serviceCases: ServiceCase[] | null;
  trackingNumberSearch?: boolean;
  caseId?: string;
  serviceRequestId: string;
  canAccessCaseHistory?: boolean;
  previousAttachmentsExpanded?: boolean;
  setPreviousAttachmentsExpanded?: Dispatch<React.SetStateAction<boolean>>;
  canViewGenericNotes?: boolean;
  onViewAttachment: (index: number, landingPage?: number | undefined) => void;
  attachmentsInfo: AttachmentInfo[];
  setAttachmentsInfo: React.Dispatch<React.SetStateAction<AttachmentInfo[]>>;
  canUseClaimHistoryFeature?: boolean;
  currentServiceCase?: ServiceCase | null;
}
export default function ViewOnlyReviewLeftPanel({
  authorization,
  refreshAuthorization,
  authorizationFetchLoading,
  serviceRequest,
  attachments,
  tabOpen,
  setTabOpen,
  userClickInfoTracking,
  setUserClickInfoTracking,
  attachmentIndexOpen,
  currentPage,
  zoomLevel,
  currentRotate,
  serviceRequestLoading,
  existingReviews,
  serviceRequestRefetch,
  serviceCases,
  trackingNumberSearch,
  caseId,
  serviceRequestId,
  canAccessCaseHistory,
  previousAttachmentsExpanded,
  setPreviousAttachmentsExpanded,
  attachmentsLoading,
  canViewGenericNotes,
  onViewAttachment,
  attachmentsInfo,
  setAttachmentsInfo,
  canUseClaimHistoryFeature,
  currentServiceCase,
  otherAuthsProps,
  claimHistoryProps,
  ...props
}: LeftPanelProps) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: patient,
    error: patientFetchError,
    loading: patientLoading,
    refetch: patientRefetch,
  } = useGetPatient({
    id: serviceRequest?.patient?.id || "",
    lazy: true,
  });
  const { data: outreachOpportunities, refetch: fetchOutreachOpportunities } =
    useGetServiceRequestOutreachOpportunities({
      id: serviceRequest?.id || "",
      lazy: true,
    });
  const {
    data: otherServiceRequests,
    error: otherServiceRequestsError,
    loading: otherServiceRequestsLoading,
    refetch: fetchOtherServiceRequests,
  } = useGetServiceRequests({
    queryParams: { patient: `eq:${serviceRequest?.patient?.id || ""}` },
    lazy: true,
    resolve: (patientServiceRequests: ServiceRequestResponse[]) =>
      patientServiceRequests.filter((patientServiceRequest) => patientServiceRequest.id !== serviceRequest?.id),
  });

  useEffect(() => {
    if (serviceRequest) {
      patientRefetch();
      fetchOtherServiceRequests();
      fetchOutreachOpportunities();
    }
  }, [patientRefetch, fetchOtherServiceRequests, fetchOutreachOpportunities, serviceRequest]);

  useEffect(() => {
    if (patientFetchError) {
      enqueueSnackbar(`Failed to load patient information: ${patientFetchError.message}`, { variant: "error" });
    }
    if (otherServiceRequestsError) {
      enqueueSnackbar(`Failed to load patient's other service requests: ${otherServiceRequestsError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, otherServiceRequestsError, patientFetchError]);

  return (
    <ReviewLeftPanel
      otherAuthsProps={otherAuthsProps}
      authorization={authorization}
      refreshAuthorization={refreshAuthorization}
      authorizationFetchLoading={authorizationFetchLoading}
      attachments={attachments}
      patient={patient}
      patientLoading={patientLoading}
      serviceRequest={serviceRequest}
      serviceRequestLoading={serviceRequestLoading}
      existingReviews={existingReviews}
      outreachOpportunities={outreachOpportunities}
      otherServiceRequests={otherServiceRequests}
      otherServiceRequestsLoading={otherServiceRequestsLoading}
      userClickInfoTracking={userClickInfoTracking}
      setUserClickInfoTracking={setUserClickInfoTracking}
      serviceRequestRefetch={serviceRequestRefetch}
      tabOpen={tabOpen}
      setTabOpen={setTabOpen}
      attachmentIndexOpen={attachmentIndexOpen}
      previousAttachmentsExpanded={previousAttachmentsExpanded}
      setPreviousAttachmentsExpanded={setPreviousAttachmentsExpanded}
      currentPage={currentPage}
      zoomLevel={zoomLevel}
      currentRotate={currentRotate}
      viewOnly
      serviceCases={serviceCases}
      trackingNumberSearch={trackingNumberSearch}
      caseId={caseId}
      serviceRequestId={serviceRequestId}
      canAccessCaseHistory={canAccessCaseHistory}
      attachmentsLoading={attachmentsLoading}
      canViewGenericNotes={canViewGenericNotes}
      onViewAttachment={onViewAttachment}
      attachmentsInfo={attachmentsInfo}
      setAttachmentsInfo={setAttachmentsInfo}
      canUseClaimHistoryFeature={canUseClaimHistoryFeature}
      claimHistoryProps={claimHistoryProps}
      currentServiceCase={currentServiceCase}
    />
  );
}
