import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { Box, Container, Grid, useTheme, Divider } from "@material-ui/core";
import {
  Card,
  Caption,
  H2,
  useMuiContainerStyles,
  useGeneralAuthSubmissionWorflowOn,
  useGetAuthorizationByIdWithFallback,
} from "@coherehealth/common";
import {
  ContinuationFormContent,
  ServiceRequestFormContent,
  convertSRFormToContinuationForm,
} from "common/SharedServiceRequestFormComponents";
import { AuthBuilderRequestorProps } from "../index";
import { PriorAuthRequirements, ServiceRequestFormStateSetters } from "components/AuthBuilder/common";
import AuthorizationDetail from "../../../common/AuthorizationDetail";
import RequestorCard from "components/Requestor/RequestorCard";
import listReplace from "util/listReplace";
import { useServiceRequestConfigSpec } from "components/ServiceRequest/ConfigurableServiceRequestForm";
import { Patient, ServiceRequestResponse, AuthorizationResponse, ProcedureCode } from "@coherehealth/core-platform-api";
import ServiceRequestContinuationForm from "../FillFormsContinuation/ServiceRequestContinuationForm";
import { useSRFormStyles } from "../FillForms";
import {
  ContinuationConfiguration,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import GeneralAuthServiceRequestContinuationForm from "./GeneralAuthServiceRequestContinuationForm";
import { getSortedServiceRequests } from "util/authorization";
import { getPatientHealthPlanName } from "util/patientUtils";
import { addFlexibleCodeIntakeFieldsToProcedureCodes } from "util/serviceRequest";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import elementIsNonNull from "util/elementIsNonNull";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";

interface Props extends AuthBuilderRequestorProps, ServiceRequestFormStateSetters {
  serviceRequestFormContents: ServiceRequestFormContent[];
  serviceRequestsCanBeSubmitted: boolean[];
  attemptedSubmit: boolean;
  patient?: Patient;
  userFaxExtension?: string;
  workflowId?: string;
  serviceRequest?: ServiceRequestResponse;
  isContinuation?: boolean;
  authorizationId?: string;
  authorization?: AuthorizationResponse | null;
  priorAuthRequirements: PriorAuthRequirements;
  setPriorAuthRequirements: Dispatch<SetStateAction<PriorAuthRequirements>>;
  onUpdateDiagnosisCodes?: () => void;
  setAllowDischargeModalToOpen?: Dispatch<SetStateAction<boolean>>;
  setIsFormContentOnContinuationUpdated?: React.Dispatch<SetStateAction<boolean>>;
  isFormContentOnContinuationUpdated?: boolean;
  setAttemptedSubmit?: React.Dispatch<SetStateAction<boolean>>;
  performingProviderExceptionRequest?: boolean;
  facilityExceptionRequest?: boolean;
  setFacilityExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  setPerformingProviderExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  careParticipantExceptionRequest?: boolean;
  setCareParticipantExceptionRequest?: Dispatch<SetStateAction<boolean>>;
  setDisableContinueButtonOnContinuation?: Dispatch<SetStateAction<boolean>>;
  allowedPxCodePerPayer?: number;
  performingProviderPracticeExceptionRequest?: boolean;
  setPerformingProviderPracticeExceptionRequest?: Dispatch<SetStateAction<boolean>>;
}

export default function FillFormsContinuationContainer({
  attemptedSubmit,
  serviceRequestFormContents,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  patient,
  requestorFormAuthorized,
  userFaxExtension,
  workflowId,
  serviceRequest,
  authorizationId,
  authorization,
  priorAuthRequirements,
  setPriorAuthRequirements,
  onUpdateDiagnosisCodes,
  setAllowDischargeModalToOpen,
  setIsFormContentOnContinuationUpdated,
  isFormContentOnContinuationUpdated,
  performingProviderExceptionRequest,
  facilityExceptionRequest,
  setAttemptedSubmit,
  setFacilityExceptionRequest,
  setPerformingProviderExceptionRequest,
  careParticipantExceptionRequest,
  setCareParticipantExceptionRequest,
  setDisableContinueButtonOnContinuation,
  performingProviderPracticeExceptionRequest,
  setPerformingProviderPracticeExceptionRequest,
  allowedPxCodePerPayer,
  ...requestorProps
}: Props) {
  const containerClasses = useMuiContainerStyles();
  const { spacing } = useTheme();
  const { data: authorizationData } = useGetAuthorizationByIdWithFallback({ id: authorizationId || "" });
  const { healthPlanName, encounterType, delegatedVendor } = serviceRequest || {};
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType,
    skipRequestTimingCheck: true,
    delegatedVendorName: delegatedVendor,
  });
  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);
  // priorAuthRequirements gets initialized with default values on creating a continuation
  // Set the primary & secondary DXs so that the DX Select has values populated at init.
  useEffect(() => {
    if (!priorAuthRequirements.primaryDiagnosis && serviceRequest) {
      setPriorAuthRequirements({
        primaryDiagnosis: serviceRequest?.primarySemanticDiagnosisCode,
        secondaryDiagnoses: serviceRequest?.secondarySemanticDiagnosisCodes,
        showPxCheckbox: false,
        noPxServiceRequired: false,
      });
    }
  }, [
    priorAuthRequirements,
    serviceRequest?.primarySemanticDiagnosisCode,
    serviceRequest?.secondarySemanticDiagnosisCodes,
    setPriorAuthRequirements,
    serviceRequest,
  ]);

  // Define a callback function for setting the form content state
  const setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>> = useCallback(
    // The callback function takes a setStateAction parameter, which can be a function or a new state value
    (setStateAction: SetStateAction<ContinuationFormContent>) => {
      // Check if the setStateAction is a function
      if (typeof setStateAction === "function") {
        // If it is a function, update the state by applying the function to the previous state
        return setServiceRequestFormContents((prev) => {
          // Apply the function to the first element of the previous state
          const newSr = setStateAction(prev[0]);
          // Replace the first element with the updated one
          return listReplace(prev, 0, newSr);
        });
      } else {
        // If setStateAction is not a function, directly update the state by replacing the first element
        return setServiceRequestFormContents((prev) => listReplace(prev, 0, setStateAction));
      }
    },
    // Dependency array for the useCallback hook
    [setServiceRequestFormContents]
  );

  return (
    <Container classes={containerClasses} style={{ maxWidth: "lg", minWidth: "200px" }}>
      {requestorFormAuthorized && (
        <div style={{ paddingTop: isFaxAuthBuilderFlow ? spacing(3) : spacing(5) }}>
          <RequestorCard {...requestorProps} isContinuationWorkflow={true} />
        </div>
      )}
      {!!serviceRequest && serviceRequestFormContents?.length > 0 && (
        <Box marginTop={requestorFormAuthorized ? (isFaxAuthBuilderFlow ? 0 : 2) : 5}>
          <AuthorizationDetail
            facilityBasedWorkflow={facilityBasedFeatureEnabled}
            serviceRequest={serviceRequest}
            priorAuthRequirements={priorAuthRequirements}
            setPriorAuthRequirements={setPriorAuthRequirements}
            onUpdateDiagnosisCodes={onUpdateDiagnosisCodes}
            formContent={serviceRequestFormContents[0]}
            setFormContent={setFormContent}
            performingProviderExceptionRequest={performingProviderExceptionRequest}
            facilityExceptionRequest={facilityExceptionRequest}
            setFacilityExceptionRequest={setFacilityExceptionRequest}
            careParticipantExceptionRequest={careParticipantExceptionRequest}
            setCareParticipantExceptionRequest={setCareParticipantExceptionRequest}
            setPerformingProviderExceptionRequest={setPerformingProviderExceptionRequest}
            setDisableContinueButtonOnContinuation={setDisableContinueButtonOnContinuation}
            performingProviderPracticeExceptionRequest={performingProviderPracticeExceptionRequest}
            setPerformingProviderPracticeExceptionRequest={setPerformingProviderPracticeExceptionRequest}
          />
        </Box>
      )}
      {isFaxAuthBuilderFlow && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      {serviceRequestFormContents?.map((content, index) => (
        <ContinuationFormWrapper
          content={content}
          setServiceRequestFormContents={setServiceRequestFormContents}
          attemptedSubmit={attemptedSubmit}
          setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
          setServiceRequestFormsHaveNewEdits={setServiceRequestFormsHaveNewEdits}
          patient={patient}
          index={index}
          key={index}
          userFaxExtension={userFaxExtension}
          workflowId={workflowId}
          isContinuation={true}
          authorization={authorization || authorizationData}
          serviceRequest={serviceRequest}
          priorAuthRequirements={priorAuthRequirements}
          setPriorAuthRequirements={setPriorAuthRequirements}
          setAllowDischargeModalToOpen={setAllowDischargeModalToOpen}
          setIsFormContentOnContinuationUpdated={setIsFormContentOnContinuationUpdated}
          isFormContentOnContinuationUpdated={isFormContentOnContinuationUpdated}
          allowedPxCodePerPayer={allowedPxCodePerPayer}
        />
      ))}
    </Container>
  );
}

interface SRProps
  extends Omit<
    Props,
    | "buckets"
    | "requestorFormAuthorized"
    | "setRequestor"
    | "serviceRequestsCanBeSubmitted"
    | "serviceRequestFormContents"
  > {
  index: number;
  content: ServiceRequestFormContent;
  healthPlan?: string | null;
  userFaxExtension?: string;
  workflowId?: string;
  authorization: AuthorizationResponse | null;
  serviceRequest?: ServiceRequestResponse;
  allowedPxCodePerPayer?: number;
}

function ContinuationFormWrapper({
  attemptedSubmit,
  content,
  setServiceRequestFormContents,
  setServiceRequestFormsCanBeSubmitted,
  setServiceRequestFormsHaveNewEdits,
  patient,
  index,
  userFaxExtension,
  workflowId,
  authorization,
  serviceRequest,
  setAllowDischargeModalToOpen,
  setIsFormContentOnContinuationUpdated,
  isFormContentOnContinuationUpdated,
  allowedPxCodePerPayer,
}: SRProps) {
  const setFormContent: Dispatch<SetStateAction<ContinuationFormContent>> = useCallback(
    (setStateAction: SetStateAction<ContinuationFormContent>) => {
      if (typeof setStateAction === "function") {
        return setServiceRequestFormContents((prev) => {
          const newSr = setStateAction(prev[index]);
          return listReplace(prev, index, newSr);
        });
      } else {
        return setServiceRequestFormContents((prev) => listReplace(prev, index, setStateAction));
      }
    },
    [setServiceRequestFormContents, index]
  );
  const setCanBeSubmitted = useCallback(
    (canBeSubmitted: boolean) =>
      setServiceRequestFormsCanBeSubmitted((prev) => listReplace(prev, index, canBeSubmitted)),
    [setServiceRequestFormsCanBeSubmitted, index]
  );
  const setHasNewEdits = useCallback(
    (hasNewEdits) => setServiceRequestFormsHaveNewEdits((prev) => listReplace(prev, index, hasNewEdits)),
    [setServiceRequestFormsHaveNewEdits, index]
  );

  //only set form content procedure codes if it has already not been set.
  useEffect(() => {
    if (
      authorization?.semanticProcedureCodes &&
      authorization?.semanticProcedureCodes?.length > 0 &&
      content.procedureCodes?.length === 0 &&
      content.clinicalServices?.length === 0 //prevents overwriting no px facility based when back from attachments
    ) {
      const sortedSRs = getSortedServiceRequests(authorization);
      const latestApprovedSR = sortedSRs[0];
      let pxCodes: ProcedureCode[] = [];

      (authorization?.semanticProcedureCodes || []).forEach((px) => {
        pxCodes.push({ ...px, units: undefined });
      });

      if (authorization?.clinicalService) {
        //add group ids to
        pxCodes = addFlexibleCodeIntakeFieldsToProcedureCodes(pxCodes || [], authorization?.clinicalService);
      }
      setFormContent((prev) => {
        return {
          ...prev,
          procedureCodes: pxCodes,
          clinicalServices: (authorization.clinicalServices || latestApprovedSR.clinicalServices || []).filter(
            elementIsNonNull
          ),
          approvedUnits: authorization?.approvedUnits?.toString() ?? "0",
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorization, authorization?.semanticProcedureCodes, setFormContent]);

  const onUserEdit = useCallback(() => setHasNewEdits(true), [setHasNewEdits]);

  const classes = useSRFormStyles();

  const clinicalService = content.clinicalService;

  const healthPlanName = getPatientHealthPlanName(patient) || "";
  const { formFieldConfigurations } = useServiceRequestConfigSpec({
    ...content,
    patientId: patient?.id || "",
    healthPlanName,
  });
  const generalAuthSubmissionWorkflow = useGeneralAuthSubmissionWorflowOn(healthPlanName);

  return (
    <>
      {generalAuthSubmissionWorkflow ? (
        <GeneralAuthServiceRequestContinuationForm
          key={index}
          authStatus="DRAFT"
          formContent={convertSRFormToContinuationForm(content)}
          setFormContent={setFormContent}
          formConfiguration={convertFormConfigToContinuationConfig(formFieldConfigurations)}
          attemptedSubmit={attemptedSubmit}
          setServiceRequestFormsCanBeSubmitted={setServiceRequestFormsCanBeSubmitted}
          patient={patient}
          hideDiagnoses
          userFaxExtension={userFaxExtension}
          onUserEdit={onUserEdit}
          workflowId={workflowId}
          authorization={authorization}
          serviceRequest={serviceRequest}
          requestType={serviceRequest?.requestType}
          setAllowDischargeModalToOpen={setAllowDischargeModalToOpen}
          setIsFormContentOnContinuationUpdated={setIsFormContentOnContinuationUpdated}
          isFormContentOnContinuationUpdated={isFormContentOnContinuationUpdated}
          allowedPxCodePerPayer={allowedPxCodePerPayer}
        />
      ) : (
        <Card className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <H2>{clinicalService?.name || "Other Procedures"}</H2>
            </Grid>
            {!!authorization?.clinicalService && clinicalService?.description && (
              <Grid item xs={12}>
                <Caption className={classes.caption}>{clinicalService.description}</Caption>
              </Grid>
            )}
            <Grid item xs={12}>
              <ServiceRequestContinuationForm
                key={index}
                authStatus="DRAFT"
                formContent={convertSRFormToContinuationForm(content)}
                setFormContent={setFormContent}
                formConfiguration={convertFormConfigToContinuationConfig(formFieldConfigurations)}
                attemptedSubmit={attemptedSubmit}
                setCanBeSubmitted={setCanBeSubmitted}
                patient={patient}
                hideDiagnoses
                userFaxExtension={userFaxExtension}
                onUserEdit={onUserEdit}
                workflowId={workflowId}
                authorization={authorization}
                serviceRequest={serviceRequest}
                requestType={serviceRequest?.requestType}
              />
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}

export const convertFormConfigToContinuationConfig = (formConfig: FormConfiguration): ContinuationConfiguration => {
  return {
    userDeclaredOONException: formConfig.userDeclaredOONException,
    urgency: formConfig.urgency,
    procedureCodes: formConfig.procedureCodes,
    procedureCodeWithUnits: formConfig.procedureCodeWithUnits,
    startEndDate: formConfig.startEndDate,
    selectedDetails: formConfig.selectedDetails,
    units: formConfig.units,
    admissionDischargeDate: formConfig.admissionDischargeDate,
    orderingProvider: formConfig.orderingProvider,
    orderingProviderAddress: formConfig.orderingProviderAddress,
    orderingProviderNPI: formConfig.orderingProviderNPI,
    orderingProviderTIN: formConfig.orderingProviderTIN,
    performingProvider: formConfig.performingProvider,
    performingProviderAddress: formConfig.performingProviderAddress,
    performingProviderNPI: formConfig.performingProviderNPI,
    performingProviderTIN: formConfig.performingProviderTIN,
    facility: formConfig.facility,
    facilityAddress: formConfig.facilityAddress,
    facilityTIN: formConfig.facilityTIN,
    facilityNPI: formConfig.facilityNPI,
    primaryDiagnosis: formConfig.primaryDiagnosis,
    secondaryDiagnoses: formConfig.secondaryDiagnoses,
    encounterType: formConfig.encounterType,
    placeOfService: formConfig.placeOfService,
    patientStayDateRanges: formConfig.patientStayDateRanges,
    authCategory: formConfig.authCategory,
    authSubCategory: formConfig.authSubCategory,
    admissionDate: formConfig.admissionDate,
    admissionTime: formConfig.admissionTime,
    dischargeDate: formConfig.dischargeDate,
    dischargeTime: formConfig.dischargeTime,
    expectedAdmissionDate: formConfig.expectedAdmissionDate,
    expectedDischargeDate: formConfig.expectedDischargeDate,
    dischargedTo: formConfig.dischargedTo,
    facilityOutOfNetworkExceptionComment: formConfig.facilityOutOfNetworkExceptionComment,
    performingProviderOutOfNetworkExceptionComment: formConfig.performingProviderOutOfNetworkExceptionComment,
    orderingProviderOutOfNetworkExceptionComment: formConfig.orderingProviderOutOfNetworkExceptionComment,
    facilityOutOfNetworkExceptionReason: formConfig.facilityOutOfNetworkExceptionReason,
    performingProviderOutOfNetworkExceptionReason: formConfig.performingProviderOutOfNetworkExceptionReason,
    performingProviderPracticeOutOfNetworkExceptionComment:
      formConfig.performingProviderPracticeOutOfNetworkExceptionComment,
    performingProviderPracticeOutOfNetworkExceptionReason:
      formConfig.performingProviderPracticeOutOfNetworkExceptionReason,
    orderingProviderOutOfNetworkExceptionReason: formConfig.orderingProviderOutOfNetworkExceptionReason,
    additionalCareParticipants: formConfig.additionalCareParticipants,
    nonPalCheckbox: formConfig.nonPalCheckbox,
    careParticipantOutOfNetworkExceptionReason: formConfig.careParticipantOutOfNetworkExceptionReason,
    careParticipantOutOfNetworkExceptionComment: formConfig.careParticipantOutOfNetworkExceptionComment,
    blockUserIfExceedsRecommendedEndDate: formConfig.blockUserIfExceedsRecommendedEndDate,
    expeditedTatUpdateTimestamp: formConfig.expeditedTatUpdateTimestamp,
    performingProviderPractice: formConfig.performingProviderPractice,
    performingProviderPracticeSelectedAddress: formConfig.performingProviderPracticeSelectedAddress,
    performingProviderPracticeSelectedNPI: formConfig.performingProviderPracticeSelectedNPI,
    performingProviderPracticeSelectedTIN: formConfig.performingProviderPracticeSelectedTIN,
    admissionSource: formConfig.admissionSource,
    patientStatus: formConfig.patientStatus,
    orderingProviderOutOfNetworkStatusDisplay: formConfig.orderingProviderOutOfNetworkStatusDisplay,
    performingProviderOutOfNetworkStatusDisplay: formConfig.performingProviderOutOfNetworkStatusDisplay,
    performingProviderPracticeOutOfNetworkStatusDisplay: formConfig.performingProviderPracticeOutOfNetworkStatusDisplay,
    facilityOutOfNetworkStatusDisplay: formConfig.facilityOutOfNetworkStatusDisplay,
  };
};
