import {
  formatDateToISODate,
  DATE_FORMAT,
  TextField,
  DateTextField,
  parseDateFromISOStringWithoutFallback,
  PrimaryButton,
} from "@coherehealth/common";
import {
  VerifyPatientDetailsBeyondOrgResponse,
  useVerifyPatientDetailsBeyondOrg,
} from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import { isWithinInterval, parse } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState, useEffect, ComponentProps } from "react";
import { useNavigate } from "react-router";
import { assertIsApiError } from "util/api";
import {
  routeToPatientSummaryFromReviewAfterDelete,
  routeToPatientSummaryFromReview,
} from "util/routeUtils/routeUtils";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { error as logError } from "logger";

// import { useTrackUserImpression } from "util/userActivityTracker";

interface SearchBeyondProps {
  beyondOrgSearchResponseType?: string;
  queryString: string;
  setBeyondOrgSearchResponseType?: Dispatch<SetStateAction<string>>;
  attemptedSubmit: boolean;
  setAttemptedSubmit: Dispatch<SetStateAction<boolean>>;
  patientMatchValidated: boolean;
  setPatientMatchValidated: Dispatch<SetStateAction<boolean>>;
  setFormInputsValid: Dispatch<SetStateAction<boolean>>;
}

export const SearchBeyondOrgMatchFound = ({
  queryString,
  beyondOrgSearchResponseType,
  setBeyondOrgSearchResponseType,
  attemptedSubmit,
  setAttemptedSubmit,
  patientMatchValidated,
  setPatientMatchValidated,
  setFormInputsValid,
}: SearchBeyondProps) => {
  const [memberId, setMemberId] = useState<string>("");
  const [dobStr, setDobStr] = useState<string>("");
  const [apiResponse, setApiResponse] = useState<VerifyPatientDetailsBeyondOrgResponse>({});
  const classes = useStyles();

  const dateOfBirth = formatDateToISODate(parse(dobStr, DATE_FORMAT, new Date()));

  const {
    mutate: validatePatient,
    loading,
    // error,
  } = useVerifyPatientDetailsBeyondOrg({});

  // const trackUserActivityImpression = useTrackUserImpression();

  const updateSearch = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setAttemptedSubmit(true);
    if (Boolean(memberId) && isDobInValidRange(dateOfBirth)) {
      setFormInputsValid(true);
      try {
        const result = await validatePatient({
          memberId,
          dateOfBirth,
          searchQuery: queryString,
          responseType: beyondOrgSearchResponseType,
        });
        if (result?.patientId) {
          setPatientMatchValidated(true);
          setApiResponse(result);

          // if (beyondOrgSearchResponseType?.toLowerCase().includes("patient")) {
          //   const regex = /\d/;
          //   const hasDigits = regex.test(queryString);
          //   //! the below implementation is NOT perfect but it helps.
          //   //? because of the lack of uniformity between various health plans and orgs implementation of a cohere id, we need to do the below.
          //   // TODO: the path to success here will involve enhancing the backend's PatientController#verifyPatientDetails response from "patient" to "patientMatchedOnATTRIBUTE", where ATTRIBUTE is one of member id, first name, last name, or full name.
          //   if (hasDigits) {
          //     trackUserActivityImpression({
          //       stage: "SEARCH_MATCH_TRACKING",
          //       event: `SEARCH_V2_MATCH_MEMBER_ID`,
          //     });
          //   } else {
          //     trackUserActivityImpression({
          //       stage: "SEARCH_MATCH_TRACKING",
          //       event: `SEARCH_V2_MATCH_FULL_NAME`,
          //     });
          //   }
          //   //? the above is the only part that needs to change. We'll be able to review beyondOrgSearchResponseType instead of the digits regex.
          // } else if (beyondOrgSearchResponseType === "serviceRequest") {
          //   trackUserActivityImpression({
          //     stage: "SEARCH_MATCH_TRACKING",
          //     event: `SEARCH_V2_MATCH_COHERE_ID`,
          //   });
          // } else if (beyondOrgSearchResponseType === "authFirstServiceRequest") {
          //   trackUserActivityImpression({
          //     stage: "SEARCH_MATCH_TRACKING",
          //     event: `SEARCH_V2_MATCH_AUTH_NUMBER`,
          //   });
          // }
        }
      } catch (e) {
        assertIsApiError(e);
        if (e.message !== "Failed to fetch: Aborted") {
          logError(e);
        }
      }
    } else {
      setFormInputsValid(false);
    }
  };

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (patientMatchValidated) {
      if (beyondOrgSearchResponseType?.toLowerCase().includes("patient") && apiResponse.patientId) {
        routeToPatientSummaryFromReviewAfterDelete(apiResponse.patientId, navigate);
        enqueueSnackbar("Patient details verified", { variant: "success" });
      } else if (
        beyondOrgSearchResponseType === "serviceRequest" ||
        beyondOrgSearchResponseType === "authFirstServiceRequest"
      ) {
        if (apiResponse.serviceRequestId) {
          const link = routeToPatientSummaryFromReview({
            serviceRequestId: apiResponse.serviceRequestId,
            patientId: apiResponse.patientId,
          });
          navigate(link);
          enqueueSnackbar("Patient details verified", { variant: "success" });
        }
      }

      if (beyondOrgSearchResponseType && setBeyondOrgSearchResponseType) {
        setBeyondOrgSearchResponseType("");
      }
    }
  }, [
    patientMatchValidated,
    navigate,
    setBeyondOrgSearchResponseType,
    beyondOrgSearchResponseType,
    apiResponse,
    enqueueSnackbar,
  ]);

  return (
    <MuiDialogContent className={classes.dialogContent}>
      <Grid component="form" onSubmit={updateSearch}>
        <div>
          <Row>
            <TextField
              error={attemptedSubmit && !memberId}
              helperText={attemptedSubmit && !memberId ? "Required" : ""}
              fullWidth
              label="Health plan member ID"
              value={memberId}
              onChangeValue={setMemberId}
              onClick={(event) => event.stopPropagation()}
              style={{ maxWidth: 400 }}
            />
          </Row>
          <Row>
            <DateTextField
              error={attemptedSubmit && !isDobInValidRange(dateOfBirth)}
              helperText={attemptedSubmit && !isDobInValidRange(dateOfBirth) ? "Must have a valid date" : ""}
              fullWidth
              addDatePatternToLabel
              label={`Member date of birth`}
              value={dobStr}
              onChangeValue={setDobStr}
              onClick={(event) => event.stopPropagation()}
              style={{ maxWidth: 400 }}
            />
          </Row>
        </div>
        <Row style={{ paddingTop: "16px" }} data-public>
          {/* ensure error state works as intended when 500 returned */}
          <PrimaryButton
            disabled={attemptedSubmit && (!memberId || !isDobInValidRange(dateOfBirth))}
            loading={loading}
            type="submit"
            onClick={(event) => event.stopPropagation()}
          >
            Verify patient details
          </PrimaryButton>
        </Row>
      </Grid>
    </MuiDialogContent>
  );
};

const Row = (props: ComponentProps<typeof Grid>) => <Grid style={{ marginBottom: "16px" }} item xs={12} {...props} />;

const isDobInValidRange = (dateOfBirth: string) => {
  const date = parseDateFromISOStringWithoutFallback(dateOfBirth);
  return date && isWithinInterval(date, { start: new Date(1900, 1, 1), end: new Date(2100, 1, 1) });
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: `${theme.spacing(4)}px 0px ${theme.spacing(2)}px 0px`,
  },
}));
