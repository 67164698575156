import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import LinearProgress, { LinearProgressProps } from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import csvParse from "csv-parse/lib/browser/sync";
import csvStringify from "csv-stringify/lib/browser/sync";
import { Body1, Body2, H6, PrimaryButton, SecondaryButton, TextField, useStableUniqueId } from "@coherehealth/common";
import { Modal, ModalProps, SingleSelectDropdown } from "@coherehealth/common";
import {
  useMarkIntegrationStatus,
  useRetryInboundFhirIntegration,
  useUpdateServiceRequest,
  IntegrationStatus,
} from "@coherehealth/core-platform-api";
import { useRetryKafkaBasedIntegration, IntegrationType as ObsIntegrationType } from "@coherehealth/int-obs-api";
import { useSnackbar } from "notistack";
import { assertIsApiError } from "util/api";
import { IntegrationType } from "./IntegrationManagement/ServiceRequestIntegrationSummary/EditIntegrationStatus";
import config from "api/config";

const RETRIGGER_ATTACHMENT_INTEGRATION = "RETRIGGER_ATTACHMENT_INTEGRATION";
const RETRY_KAFKA_BASED_INTEGRATION = "RETRY_KAFKA_BASED_INTEGRATION";
const RETRY_SALESFORCE_INTEGRATION = "RETRY_SALESFORCE_INTEGRATION";

interface Props extends ModalProps {}
export default function IntegrationBulkFunctionModal({ open, onClose }: Props) {
  const [fileContents, setFileContents] = useState<Record<string, any>[]>();
  const { enqueueSnackbar } = useSnackbar();

  type IntegrationBulkFunction =
    | "WITHDRAW"
    | "RESEND_PAYOR"
    | "RETRY_INBOUND_FHIR_INTEGRATION"
    | typeof RETRIGGER_ATTACHMENT_INTEGRATION
    | typeof RETRY_KAFKA_BASED_INTEGRATION
    | typeof RETRY_SALESFORCE_INTEGRATION;

  const ObsIntegrationTypeNameMapping: Record<ObsIntegrationType, string> = {
    AUTHORIZATION_OUTBOUND: "AUTHORIZATION_OUTBOUND",
    AUTHORIZATION_OUTBOUND_ENRICHMENT: "AUTHORIZATION_OUTBOUND_ENRICHMENT",
    AUTHORIZATION_INBOUND: "AUTHORIZATION_INBOUND",
    ATTACHMENTS: "ATTACHMENTS",
    MAIL_VENDOR_OUTBOUND: "MAIL_VENDOR_OUTBOUND",
    MAIL_VENDOR_INBOUND: "MAIL_VENDOR_INBOUND",
    VAT_OUTBOUND: "VAT_OUTBOUND",
    VAT_INBOUND: "VAT_INBOUND",
    SALESFORCE: "SALESFORCE",
    CAREWEBQI: "CAREWEBQI",
    FINAL_DETERMINATION_FAX: "FINAL_DETERMINATION_FAX",
    REFERRALS_OUTBOUND: "REFERRALS_OUTBOUND",
    REFERRALS_OUTBOUND_ENRICHMENT: "REFERRALS_OUTBOUND_ENRICHMENT",
    REFERRALS_INBOUND: "REFERRALS_INBOUND",
  };

  interface IntegrationBulkFunctionConfigProps {
    action: (id: string) => Promise<void>;
    idFieldLabel?: string;
  }

  const [mode, setMode] = useState<IntegrationBulkFunction>();
  const [obsIntegrationType, setobsIntegrationType] = useState<string>("AUTHORIZATION_OUTBOUND");
  const [idField, setIdField] = useState("_id");

  const [authorizationNote, setAuthorizationNote] = useState<string>();

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    if (input && input.files && input.files[0]) {
      const file = input.files[0];

      assertIsFile(file);

      try {
        const contents = await file.text();

        setFileContents(csvParse(contents, { columns: true }));
      } catch (e) {
        assertIsApiError(e);
        enqueueSnackbar(`Failed to parse contents: ${e.message}`, { variant: "error" });
      }
    }
  };

  const [resultsBlobUrl, setResultsBlobUrl] = useState<string>();
  interface Result {
    success?: boolean;
    httpStatus?: number;
    message?: string;
  }

  const { mutate: markIntegrationStatus } = useMarkIntegrationStatus({ id: "" });
  const { mutate: patchServiceRequest } = useUpdateServiceRequest({ id: "" });
  const { mutate: retryInboundFhirIntegration } = useRetryInboundFhirIntegration({ id: "" });
  const { mutate: retryKafkaBasedIntegration } = useRetryKafkaBasedIntegration({
    base: `${config.INT_OBS_SERVICE_API_URL}`,
    objectId: "",
  });
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const withdrawOne = async (id: string) => {
    await patchServiceRequest({ authStatus: "WITHDRAWN", authorizationNote }, { pathParams: { id } });
  };

  const markIntegrationStatusByTypeAndStatus =
    (integrationType: IntegrationType, integrationStatus: IntegrationStatus) => async (id: string) => {
      await markIntegrationStatus({ integrationType, integrationStatus }, { pathParams: { id } });
    };

  const retryOneInboundFhirIntegration = async (id: string) => {
    await retryInboundFhirIntegration({}, { pathParams: { id } });
  };

  const retryKafkaBasedIntegrationByTypeAndStatus =
    (integrationType: string, integrationStatus: IntegrationStatus) => async (id: string) => {
      await retryKafkaBasedIntegration({ integrationStatus, integrationType }, { pathParams: { objectId: id } });
    };

  const ModalConfig: Record<IntegrationBulkFunction, IntegrationBulkFunctionConfigProps> = {
    WITHDRAW: {
      action: withdrawOne,
    },
    RESEND_PAYOR: {
      action: markIntegrationStatusByTypeAndStatus("payor", "READY"),
    },
    RETRY_INBOUND_FHIR_INTEGRATION: {
      action: retryOneInboundFhirIntegration,
      idFieldLabel: "Integration Log ID field",
    },
    RETRIGGER_ATTACHMENT_INTEGRATION: {
      action: markIntegrationStatusByTypeAndStatus("attachments", "READY"),
    },
    RETRY_KAFKA_BASED_INTEGRATION: {
      action: retryKafkaBasedIntegrationByTypeAndStatus(obsIntegrationType, "READY"),
    },
    RETRY_SALESFORCE_INTEGRATION: {
      action: markIntegrationStatusByTypeAndStatus("salesforce", "READY"),
    },
  };

  const configProps = ModalConfig[mode || "RESEND_PAYOR"];

  const [processed, setProcessed] = useState(0);
  const [cancelled, setCancelled] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const doTheUpload = async () => {
    setCancelled(false);

    if (!fileContents || fileContents.length === 0) {
      enqueueSnackbar("No file contents to upload!", { variant: "warning" });
      return;
    }

    const results: Result[] = [];
    setUploadInProgress(true);
    const actionFn = configProps.action;
    for (const index in fileContents) {
      const record = fileContents[index];
      try {
        if (!cancelled && record[idField]) {
          await actionFn(record[idField]);
          results.push({
            ...record,
            success: true,
          });
        } else if (!record[idField]) {
          results.push({
            ...record,
            success: false,
            message: "Invalid ServiceRequestId",
          });
          setUploadInProgress(false);
          enqueueSnackbar(
            "ServiceRequestId Column is Invalid, please check to make sure Cohere ServiceRequestIds have been provided in the file."
          );
          return;
        } else {
          results.push({
            ...record,
            success: false,
            message: "Cancelled by user",
          });
        }
      } catch (e) {
        assertIsApiError(e);
        results.push({
          ...record,
          success: false,
          httpStatus: e.status,
          message: `${e.message}${e.data?.message ? " - " + e.data?.message : ""}`,
        });
        setErrorCount((prev) => prev + 1);
      }
      setProcessed((prev) => prev + 1);
    }

    const resultsCsv = csvStringify(results, { header: true });
    const resultsBlob = new Blob([resultsCsv], { type: "text/csv;charset=utf-8" });
    setResultsBlobUrl(URL.createObjectURL(resultsBlob));

    setUploadInProgress(false);
    enqueueSnackbar("Finished!");
  };

  const readyToStart = mode && fileContents && fileContents.length > 0 && !uploadInProgress;

  const inputId = useStableUniqueId();

  return (
    <Modal open={open} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Body1>
            Select the mode and upload a CSV containing a Service Request ID column. Other columns are ignored. You may
            change the selected header name below.
          </Body1>
        </Grid>
        <Grid item xs={12}>
          <Body1>Results will be available as a downloadable CSV when complete.</Body1>
        </Grid>
        <Grid item xs={12}>
          <Body2>If something goes wrong, click Cancel to stop sending more requests.</Body2>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Mode"
            value={mode || ""}
            onChange={(event) => setMode(event.target.value as IntegrationBulkFunction)}
          >
            <MenuItem value="WITHDRAW">Withdraw</MenuItem>
            <MenuItem value="RESEND_PAYOR">Resend to payor</MenuItem>
            <MenuItem value="RETRY_INBOUND_FHIR_INTEGRATION">Retry Inbound Fhir Integration</MenuItem>
            <MenuItem value={RETRIGGER_ATTACHMENT_INTEGRATION}>Retrigger Attachment Integration</MenuItem>
            <MenuItem value={RETRY_KAFKA_BASED_INTEGRATION}>Retry Kafka based integration</MenuItem>
            <MenuItem value={RETRY_SALESFORCE_INTEGRATION}>Retry Salesforce Integration</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {mode === "RETRY_KAFKA_BASED_INTEGRATION" && (
            <SingleSelectDropdown
              label="Integration Type"
              value={obsIntegrationType}
              onChange={setobsIntegrationType}
              defaultValue={"AUTHORIZATION_OUTBOUND"}
              style={{ width: "240px" }}
              options={Object.entries(ObsIntegrationTypeNameMapping).map(([id, label]) => ({ id, label }))}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <input
            accept=".csv"
            data-testid="csv-choose-file-button"
            name={inputId}
            id={inputId}
            type="file"
            onChange={onFileChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={configProps.idFieldLabel || "Service request ID field"}
            value={idField}
            onChangeValue={setIdField}
          />
        </Grid>
        <Grid item xs={6}>
          {mode === "WITHDRAW" && (
            <TextField
              label="New authorization note"
              multiline
              fullWidth
              value={authorizationNote || ""}
              onChangeValue={setAuthorizationNote}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <PrimaryButton data-testid="upload-button" disabled={!readyToStart} onClick={doTheUpload}>
            Start upload{fileContents ? ` (${fileContents.length} records)` : ""}
          </PrimaryButton>
        </Grid>
        <Grid item xs={6}>
          <SecondaryButton disabled={!uploadInProgress} onClick={doTheUpload}>
            Cancel
          </SecondaryButton>
        </Grid>
        <Grid item xs={12}>
          <H6>Progress</H6>
          <LinearProgressWithLabel numerator={processed} denominator={fileContents?.length || 0} />
          <Collapse in={Boolean(resultsBlobUrl)}>
            <a href={resultsBlobUrl} target="_blank" rel="noreferrer" download="bulk-results.csv">
              Download results as csv
            </a>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <H6>Errors</H6>
          <Body2>{errorCount}</Body2>
        </Grid>
      </Grid>
    </Modal>
  );
}

function assertIsFile(ipt: File | string | null): asserts ipt is File {
  if (!ipt || typeof ipt === "string") {
    throw new Error("Not a file");
  }
}

// https://material-ui.com/components/progress/#LinearWithValueLabel.tsx
function LinearProgressWithLabel({
  numerator,
  denominator,
  ...props
}: Omit<LinearProgressProps, "value"> & { numerator: number; denominator: number }) {
  const value = (100 * numerator) / (denominator || 0);
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={value} {...props} />
        </Box>
        <Box minWidth={35}>
          <Body2 color="textSecondary">{`${numerator} / ${denominator}`}</Body2>
        </Box>
      </Box>
    </Box>
  );
}
