import { Body1, Caption, colorsLight, formatDateStrWithTz, InlineButton, Sanitized } from "@coherehealth/common";
import { ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { Body2 } from "@coherehealth/design-system";
import { Grid, makeStyles } from "@material-ui/core";
import { HistoryAppeal } from "components/ClinicalReview/ClinicalReviewInfoPanel/NotesCard/notesCardTypes";
import { ClinicalReviewContext } from "components/ClinicalReview/Review/ClinicalReviewPage";
import { LeftPanelTabs } from "components/ClinicalReview/reviewUtils/utils";
import { requestorTypeDisplayName } from "components/ContactInfoModal";
import { channelDisplayName } from "components/PatientSummary/AuthorizationPatientSummary/RequestedByInfo";
import { format } from "date-fns";
import { useContext } from "react";

interface AppealViewOnlyProps {
  appeal: HistoryAppeal;
  setTabOpen?: React.Dispatch<React.SetStateAction<LeftPanelTabs>>;
  serviceRequest: ServiceRequestResponse;
}

const appealViewOnlyStyle = makeStyles((theme) => ({
  postAppealNote: {
    margin: "-7px 0px",
  },
  appealInitiationRowGrid: {
    marginTop: theme.spacing(1),
  },
  viewButton: {
    marginTop: theme.spacing(1),
  },
  appealCaption: {
    color: colorsLight.font.secondary,
  },
}));

const AppealOutcomesToDisplayValue: Record<string, string> = {
  APPROVED: "approval",
  PARTIALLY_APPROVED: "partial approval",
  DENIED: "denial",
  NOT_COMPLETED: "Not completed",
  WITHDRAWN: "Withdrawn",
};

const upholdOrOverturn = (currentAuthStatus?: string, optionAuthStatus?: string) => {
  return currentAuthStatus === optionAuthStatus ? "Uphold initial" : "Overturned to";
};

export const AppealViewOnly = ({ appeal, setTabOpen, serviceRequest }: AppealViewOnlyProps) => {
  const { allowedReviewOutcomes } = useContext(ClinicalReviewContext);
  const appealOutcome = allowedReviewOutcomes?.find((outcome) => outcome.id === appeal.outcome);
  const { initialDecisionDisposition } = serviceRequest ?? {};
  const outcomeText = ["DENIED", "APPROVED", "PARTIALLY_APPROVED"].includes(appeal.outcome)
    ? `${upholdOrOverturn(appeal.outcome, initialDecisionDisposition)} ${AppealOutcomesToDisplayValue[appeal.outcome]}`
    : `${AppealOutcomesToDisplayValue[appeal.outcome]}`;
  const style = appealViewOnlyStyle();
  return (
    <Grid
      data-testid="appeal-view-only-container"
      container
      style={{ marginTop: 16 }}
      direction="column"
      alignItems="flex-start"
    >
      {appeal.appealType === "APPEAL_INITIATION" && (
        <>
          <Grid item container direction="row" xs={11} alignItems="center">
            <Body2 style={{ paddingRight: "16px" }}>Appeal initiation</Body2>
            <Caption className={style.appealCaption}>{`${formatDateStrWithTz(appeal?.dateCreated)} by ${
              appeal?.createdByName
            }`}</Caption>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="received-date-read-only">
              <Caption className={style.appealCaption}>Received date</Caption>
              <Body1>
                {appeal.appealInitiationTimestamp
                  ? format(new Date(appeal.appealInitiationTimestamp), "MM/dd/yyyy")
                  : "--"}
              </Body1>
            </Grid>
            <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="received-time-read-only">
              <Caption className={style.appealCaption}>Received time (24-hour)</Caption>
              <Body1>
                {appeal.appealInitiationTimestamp ? format(new Date(appeal.appealInitiationTimestamp), "hh:mm") : "--"}
              </Body1>
            </Grid>
            <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="requestor-type-read-only">
              <Caption className={style.appealCaption}>Requestor type</Caption>
              <Body1>{appeal.requestorType ? requestorTypeDisplayName[appeal.requestorType] : "--"}</Body1>
            </Grid>
            <Grid item xs={3} className={style.appealInitiationRowGrid} data-testid="format-received-read-only">
              <Caption className={style.appealCaption}>Format received</Caption>
              <Body1>{appeal.appealChannel ? channelDisplayName[appeal.appealChannel] : "--"}</Body1>
            </Grid>
            <Grid item xs={12} className={style.appealInitiationRowGrid} data-testid="case-description-read-only">
              <Caption className={style.appealCaption}>Case description</Caption>
              <Body1>{appeal.caseDescription ?? "--"}</Body1>
            </Grid>
            <Grid item xs={12} className={style.appealInitiationRowGrid} data-testid="expedited-read-only">
              <Caption className={style.appealCaption}>Expedited</Caption>
              <Body1>{appeal.isExpedited ? "Yes" : "No"}</Body1>
            </Grid>
          </Grid>
        </>
      )}
      {appeal.appealType === "APPEAL_LOG" && (
        <>
          <Grid item container direction="row" xs={11} alignItems="center">
            <Body2 style={{ paddingRight: "16px" }}>Post-appeal note</Body2>
            <Caption className={style.appealCaption}>{`${appealOutcome?.label ?? outcomeText} on ${formatDateStrWithTz(
              appeal?.dateCreated
            )} by ${appeal?.createdByName}`}</Caption>
          </Grid>
          <Grid>
            <Caption>Post-appeal note</Caption>
            <Sanitized __html={appeal?.postAppealNote} className={style.postAppealNote} />
          </Grid>
        </>
      )}
      <InlineButton
        data-testid="view-post-appeal-attachments"
        onClick={() => setTabOpen?.("ATTACHMENTS")}
        className={style.viewButton}
      >
        {appeal.appealType === "APPEAL_INITIATION"
          ? "View appeal initiation attachments"
          : "View post-appeal attachments"}
      </InlineButton>
    </Grid>
  );
};
