import { CaseType, ServiceCase, RouteConfigByCaseType, RouteConfig } from "@coherehealth/qm-api";
import { authorizeBaseUrl, queueMgmtBaseUrl } from "./envUtils";
import { createSearchParams, generatePath } from "react-router-dom";

export const startNextCase = (
  setNoCaseAvailable?: React.Dispatch<React.SetStateAction<boolean>>,
  serviceCaseFromQueue?: ServiceCase | string | null,
  enqueueSnackbar?: any,
  caseComplete?: boolean,
  unsolvable?: boolean,
  queueId?: string,
  routeConfigByCaseType?: RouteConfigByCaseType
) => {
  if (typeof serviceCaseFromQueue === "string" && serviceCaseFromQueue === "No open case available") {
    if (caseComplete || unsolvable) {
      enqueueSnackbar("No open case available", {
        variant: "info",
        preventDuplicate: true,
      });
    } else {
      if (setNoCaseAvailable) {
        setNoCaseAvailable(true);
      }
    }
  } else if (serviceCaseFromQueue && typeof serviceCaseFromQueue !== "string") {
    const caseType = serviceCaseFromQueue?.caseType;

    const caseUrl = getCaseRedirectUrl({
      caseId: serviceCaseFromQueue?.id,
      caseType: caseType,
      serviceRequestId: serviceCaseFromQueue?.serviceRequest?.serviceRequestId,
      faxId: serviceCaseFromQueue?.faxDetail?.attachmentId,
      isPostDenialP2P: serviceCaseFromQueue?.isPostDenialP2P,
      queueId,
      routeConfigByCaseType,
    });
    setNoCaseAvailable?.(false);
    caseUrl && window.location.assign(caseUrl);
  }
};

export const caseTypeLabel = (caseType: CaseType) => {
  const caseTypeLabels: Record<CaseType, string> = {
    FAX_INTAKE: "Fax",
    RN_REVIEW: "Clinical review",
    OUT_OF_NETWORK_REVIEW: "OON review",
    MD_REVIEW: "MD review",
    LETTER_WRITING: "Letter writing",
    NEW_HIRE_AUDIT_REVIEW: "New hire audit review",
    NUDGE_OUTREACH: "Outreach",
    MISSING_INFORMATION: "Missing information",
    P2P_REVIEW: "Peer to peer review",
    SCHEDULING_OUTREACH: "P2P scheduling",
    CPP_REVIEW: "Clinical preprocessing review",
    OUT_OF_SCOPE_AUDIT_REVIEW: "Out of scope audit review",
    REQUIRES_VERBAL_NOTIFICATIONS: "Cases Requiring Verbal Notifications",
    FAX_AUDIT_REVIEW: "Fax audit review",
  };
  return caseTypeLabels[caseType];
};

export const commaSeparatedStrings = (strings: string[]) => {
  return strings.join(", ");
};

export interface CaseRouteInfo {
  caseType?: CaseType;
  serviceRequestId?: string;
  caseId?: string;
  faxId?: string;
  isPostDenialP2P?: boolean;
  fromSupervisorDashboard?: boolean;
  queueId?: string;
  routeConfigByCaseType?: RouteConfigByCaseType;
}

export const getCaseRedirectUrl = (caseRouteInfo: CaseRouteInfo) => {
  if (caseRouteInfo.routeConfigByCaseType) {
    const redirectUrlFromConfig = getCaseRedirectUrlFromConfig(caseRouteInfo);
    if (redirectUrlFromConfig) {
      return redirectUrlFromConfig;
    }
  }
  return getDefaultCaseRedirectUrl(caseRouteInfo);
};

const getCaseRedirectUrlFromConfig = (caseRouteInfo: CaseRouteInfo) => {
  const { caseType, fromSupervisorDashboard, routeConfigByCaseType } = caseRouteInfo;
  if (caseType) {
    const routeConfigForCaseType = routeConfigByCaseType?.[caseType];
    if (routeConfigForCaseType) {
      if (fromSupervisorDashboard) {
        return getUrlFromConfig(routeConfigForCaseType.supervisorDashboardRoute, caseRouteInfo);
      } else {
        return getUrlFromConfig(routeConfigForCaseType.agentWorkCaseRoute, caseRouteInfo);
      }
    }
  }
};

const getUrlFromConfig = (routeConfig: RouteConfig, caseRouteInfo: CaseRouteInfo) => {
  try {
    const urlWithPathVariables = generatePath(routeConfig.url, caseRouteInfo);
    const startOfSearchParams = urlWithPathVariables.indexOf("?");
    let existingSearchParams = "";
    let urlWithoutSearchParams = urlWithPathVariables;
    if (startOfSearchParams !== -1) {
      existingSearchParams = urlWithPathVariables.substring(startOfSearchParams);
      urlWithoutSearchParams = urlWithPathVariables.substring(0, startOfSearchParams);
    }
    const searchParams = createSearchParams(existingSearchParams);
    searchParams.set("caseId", caseRouteInfo.caseId || "");
    searchParams.set("queueId", caseRouteInfo.queueId || "");
    if (caseRouteInfo.fromSupervisorDashboard) {
      searchParams.set("origin", "qm_supervisor_dashboard");
    }
    let baseUrl = authorizeBaseUrl();
    if (routeConfig.baseApp === "QM") {
      baseUrl = queueMgmtBaseUrl();
    }
    const fullUrl = `${baseUrl}${urlWithoutSearchParams}?${searchParams.toString()}`;
    return fullUrl;
  } catch (error) {
    console.error(error);
    return;
  }
};

const getDefaultCaseRedirectUrl = ({
  caseType,
  serviceRequestId,
  caseId,
  faxId,
  isPostDenialP2P,
  fromSupervisorDashboard,
  queueId,
}: CaseRouteInfo) => {
  const supervisorRoute = `${authorizeBaseUrl()}/view_only_review?caseId=${caseId}&queueId=${queueId}&serviceRequestId=${serviceRequestId}&origin=qm_supervisor_dashboard`;
  switch (caseType) {
    case "FAX_INTAKE":
      return `${authorizeBaseUrl()}/fax/${faxId}?caseId=${caseId}&queueId=${queueId}${
        fromSupervisorDashboard ? `&origin=qm_supervisor_dashboard` : ""
      }`;
    case "MD_REVIEW":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/${serviceRequestId}/review?createNewMdReview&caseId=${caseId}&queueId=${queueId}`;
    case "P2P_REVIEW":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/${serviceRequestId}/review?createNewPeerToPeerReview&caseId=${caseId}&queueId=${queueId}${
            isPostDenialP2P ? "&isPostDenialP2P" : ""
          }`;
    case "RN_REVIEW":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/${serviceRequestId}/review?createNewNurseReview&caseId=${caseId}&queueId=${queueId}`;
    case "NEW_HIRE_AUDIT_REVIEW":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/${serviceRequestId}/review?createNewHireAuditReview&caseId=${caseId}&queueId=${queueId}`;
    case "CPP_REVIEW":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/${serviceRequestId}/review?createNewCPPReview&caseId=${caseId}&queueId=${queueId}`;
    case "LETTER_WRITING":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/denial_review/${serviceRequestId}?caseId=${caseId}&queueId=${queueId}`;
    case "NUDGE_OUTREACH":
    case "MISSING_INFORMATION":
    case "REQUIRES_VERBAL_NOTIFICATIONS":
    case "SCHEDULING_OUTREACH":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/${serviceRequestId}/outreach?caseId=${caseId}&queueId=${queueId}`;
    case "OUT_OF_SCOPE_AUDIT_REVIEW":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/${serviceRequestId}/review?createNewOOSReview&caseId=${caseId}&queueId=${queueId}`;
    case "FAX_AUDIT_REVIEW":
      return fromSupervisorDashboard
        ? supervisorRoute
        : `${authorizeBaseUrl()}/fax_audit/${serviceRequestId}?createNewFaxAuditReview&caseId=${caseId}&queueId=${queueId}`;
    default:
      return fromSupervisorDashboard ? supervisorRoute : authorizeBaseUrl();
  }
};

// These queue names need to match data in the database and should not be changed without a corresponding data migration
export const NURSE_REVIEW_QUEUE_NAME = "Nurse Review";
export const CPP_REVIEW_QUEUE_NAME = "CPP Review";
export const FAX_INTAKE_QUEUE_NAME = "Fax Intake";
export const MD_REVIEW_QUEUE_NAME = "MD Review";
export const P2P_REVIEW_QUEUE_NAME = "P2P Review";
export const NEW_HIRE_AUDIT_REVIEW_QUEUE_NAME = "New Hire Audit Review";
export const LETTER_WRITING_QUEUE_NAME = "Letter Writing";
export const OUTREACH_QUEUE_NAME = "Outreach";
