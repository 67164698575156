import { PropsWithChildren } from "react";
import { useFeature } from "@coherehealth/common";
import { useClinicalReviewManager, ClinicalReviewContext } from "./clinical-review-store";
import { managerProdHooks } from "./injected";

export interface ClinicalReviewManagerProps {}

export function ClinicalReviewManager({ children }: PropsWithChildren<ClinicalReviewManagerProps>) {
  // setup state management
  const { state, dispatch, dispatchAsync } = useClinicalReviewManager({ hooks: managerProdHooks });
  return (
    <ClinicalReviewContext.Provider value={{ state, dispatch, dispatchAsync }}>
      <div>ClinicalReviewManager enabled (this "banner" will be removed prior to go live)</div>
      {children}
    </ClinicalReviewContext.Provider>
  );
}

export function ClinicalReviewManagerWrapper({ children }: PropsWithChildren<ClinicalReviewManagerProps>) {
  const isEnabled = useFeature("clinicalReviewNext");
  return isEnabled ? <ClinicalReviewManager>{children}</ClinicalReviewManager> : <>{children}</>;
}
