import { useState } from "react";
import { useTheme } from "@mui/material";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core/styles";
import { ProcedureCode, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import { H6, CohereCard, Subtitle2 } from "@coherehealth/design-system";
import getCodesInfo, { awaitingTerminalStatus } from "./CodesInfo";
import ApprovalBadge from "./ApprovalBadge";
import ContinueOrWithdraw from "./ContinueOrWithdraw";
import {
  authorizeBaseUrl,
  Body3,
  formatDateStr,
  HEALTH_HELP_NAME,
  InlineButton,
  useFeature,
} from "@coherehealth/common";
import { canBeWithdrawn, useIsDelegatedToVendor, useServiceRequestWithdrawn } from "util/serviceRequest";
import { Grid, Divider } from "@material-ui/core";
import WithdrawModal from "components/PatientSummary/ServiceRequestSummaryCard/WithdrawModal";
import DeleteDraftServiceRequestModal from "components/PatientSummary/ServiceRequestSummaryCard/DeleteDraftServiceRequestModal";
import RequestedByInfo from "../../PatientSummary/AuthorizationPatientSummary/RequestedByInfo";
import ClinicalAssessmentSection from "../../PatientSummary/ServiceRequestSummaryCard/ClinicalAssessmentSection";
import { compact } from "lodash";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { aggregateStayDateByCommonFields } from "components/ServiceRequest/PatientStay/PatientStays";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { useGetServiceRequestEditConfigurationByPayerAndAuthStatus } from "hooks/useGetFeatureConfigurations";
import useOpsEditDisabled from "hooks/useOpsEditDisabled";

interface ExpandedCardProps {
  positionInSrList?: number;
  id?: string;
  sr: ServiceRequestResponse;
  // the most recent service request prior to this one for an auth (needed to calculate which procedure codes are new)
  priorSr?: ServiceRequestResponse;
  nextSr?: ServiceRequestResponse;
  clinicalReviewView?: boolean;
  continueDraft: () => void;
  onEdit?: () => void;
  shouldShowWithdrawButton?: boolean;
  isFacilityBased?: boolean;
}

const ExpandedServiceRequestCard = ({
  positionInSrList,
  id,
  sr,
  priorSr,
  nextSr,
  clinicalReviewView,
  continueDraft,
  onEdit,
  shouldShowWithdrawButton,
  isFacilityBased,
}: ExpandedCardProps) => {
  const { healthPlanName, encounterType, delegatedVendor } = sr;
  const { facilityBasedFeatureEnabled, includePatientStayDatesOnPlannedAdmission } =
    useGetFacilityBasedRequestConfigurationByPayer({
      healthPlanName,
      encounterType,
      skipRequestTimingCheck: true,
      delegatedVendorName: delegatedVendor,
    });
  const hasFacilityBased = isFacilityBased ?? facilityBasedFeatureEnabled;

  const theme = useTheme();
  const isInitial = sr.requestType === "INITIAL";
  const isOnPx = sr.clinicalServices?.some((obj) => obj?.isUnitsOnPx === true);

  let unitsOnPxCodes: ProcedureCode[] =
    (sr?.semanticProcedureCodes || []).map((semanticProcedureCode) => {
      if (sr.authStatus === "APPROVED" && !semanticProcedureCode.approvedUnits) {
        semanticProcedureCode.approvedUnits = semanticProcedureCode.units;
      }
      return semanticProcedureCode;
    }) || [];

  if (sr?.encounterType) {
    unitsOnPxCodes = unitsOnPxCodes.filter((pxCode) =>
      sr?.clinicalServices?.find((cs) => cs?.id === pxCode.groupId && cs?.isUnitsOnPx === true)
    );
  }

  let unitsOnservicePxCode: ProcedureCode[] =
    (sr?.semanticProcedureCodes || []).map((semanticProcedureCode) => {
      if (sr.authStatus === "APPROVED" && !semanticProcedureCode.approvedUnits) {
        semanticProcedureCode.approvedUnits = semanticProcedureCode.units;
      }
      return semanticProcedureCode;
    }) || [];

  if (sr?.encounterType) {
    unitsOnservicePxCode = unitsOnservicePxCode.filter((pxCode) =>
      sr?.clinicalServices?.find((cs) => cs?.id === pxCode.groupId && cs?.isUnitsOnPx === false)
    );
  }

  const codesInfo = getCodesInfo({
    priorCodes: priorSr?.procedureCodes || [],
    isOnPx: isOnPx || false,
    authStatus: sr.authStatus,
    unitsOnPxCodes: unitsOnPxCodes,
    unitsOnservicePxCode: unitsOnservicePxCode,
  });
  const isIntegrateAuthDecisionGroups = useFeature("integrateAuthDecisionGroups");
  const limitVoidsAfterApprovalFF = useFeature("LimitVoidsAfterApproval");
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [showClinicalAssessment, setShowClinicalAssessment] = useState<boolean>(false);
  const isSingleService = !Boolean(sr?.carePathJourney?.id);
  const isDelegatedToHealthHelp = useIsDelegatedToVendor(sr, [HEALTH_HELP_NAME]);
  const hasNoAssessment = (isDelegatedToHealthHelp && !sr?.vendorIdentifier) || (isSingleService && sr.palCategory);

  const {
    withdrawModalOpen,
    setWithdrawModalOpen,
    withdrawRequestor,
    setWithdrawRequestor,
    withdrawnReason,
    setWithdrawnReason,
    onWithdraw,
    submitting,
    handleWithdrawButtonClick,
  } = useServiceRequestWithdrawn(sr, undefined, onEdit, undefined);

  let isFromReview: boolean = false;
  let isNotFirstElement: boolean = false;
  if (positionInSrList !== undefined) {
    isFromReview = true;
    isNotFirstElement = positionInSrList > 0;
  }
  const opsEditEnabled = !useOpsEditDisabled(sr);

  const { srEditConfig } = useGetServiceRequestEditConfigurationByPayerAndAuthStatus({
    healthPlanName: sr?.healthPlanName,
    authStatus: sr.authStatus,
  });

  const srNoLongerNeededButton = srEditConfig?.srNoLongerNeededButton && limitVoidsAfterApprovalFF;

  return isVoidedContinuationWithoutStayDates(sr) ? null : (
    <Grid style={{ width: "100%" }} id={id} data-testid={!!sr.id && `expanded-sr-card-${sr.id}`}>
      {(!isFromReview || (isFromReview && isNotFirstElement)) && (
        <Grid item xs={12}>
          <Divider style={{ height: 2 }} />
        </Grid>
      )}
      <CohereCard
        style={
          clinicalReviewView
            ? { padding: theme.spacing(3, 0), border: "none", boxShadow: "none" }
            : { padding: theme.spacing(3), border: "none", boxShadow: "none" }
        }
      >
        <TopRow>
          <Body3 style={{ color: theme.palette.text.secondary, display: "flex", alignItems: "center" }} data-public>
            {formatDateStr(sr.dateCreated)}
          </Body3>
          <ContinueOrWithdraw
            sr={sr}
            withdrawRequest={handleWithdrawButtonClick}
            continueDraft={continueDraft}
            deleteDraft={() => {
              setDeleteModalOpen(true);
            }}
            shouldShowWithdrawButton={shouldShowWithdrawButton}
            srNoLongerNeededButton={srNoLongerNeededButton}
          />
        </TopRow>
        <ExpandedRow
          onClick={() => {
            const clincialReviewUrl = `${authorizeBaseUrl()}/view_only_review?serviceRequestId=${
              sr.id
            }&origin=expandedSRCard`;
            clinicalReviewView && window.open(clincialReviewUrl);
          }}
          clickable={clinicalReviewView === true}
        >
          <H6 sx={{ color: theme.palette.text.primary }} data-public>
            {isInitial ? "Initial" : "Continuation"}
          </H6>
          <ApprovalBadge
            authStatus={
              limitVoidsAfterApprovalFF && sr.authStatus === "APPROVED" && sr.isSrNoLongerNeeded
                ? "NO_LONGER_NEEDED"
                : sr.authStatus
            }
            cohereId={sr.cohereId}
          />
        </ExpandedRow>
        <Row style={{ marginTop: theme.spacing(1.5) }}>
          <Body3 style={{ color: theme.palette.text.primary }} data-public>
            {hasFacilityBased
              ? InpatientDetails(sr, priorSr, nextSr, codesInfo, includePatientStayDatesOnPlannedAdmission || false)
              : OutpatientDetails(isOnPx, sr, isInitial, codesInfo, unitsOnPxCodes, unitsOnservicePxCode)}
          </Body3>
        </Row>
        {!hasNoAssessment && (
          <>
            <Row style={{ marginTop: theme.spacing(0.5) }}>
              <InlineButton
                onClick={() => {
                  setShowClinicalAssessment(!showClinicalAssessment);
                }}
              >
                <Subtitle2>
                  {showClinicalAssessment ? "Hide clinical assessment" : "Show clinical assessment"}
                </Subtitle2>
              </InlineButton>
            </Row>
            {showClinicalAssessment && !hasNoAssessment && (
              <Row style={{ marginTop: showClinicalAssessment ? theme.spacing(3) : "0px" }}>
                <Grid container item xs={12}>
                  <ClinicalAssessmentSection serviceRequest={sr} showReadOnlyVendorSummary={isDelegatedToHealthHelp} />
                </Grid>
              </Row>
            )}
          </>
        )}
        {showClinicalAssessment && (
          <Row style={{ marginTop: theme.spacing(3) }}>
            <Grid item xs={12}>
              <Divider style={{ height: 1 }} />
            </Grid>
          </Row>
        )}
        <Row style={{ marginTop: showClinicalAssessment ? theme.spacing(3) : theme.spacing(0.5) }}>
          <RequestedByInfo
            serviceRequest={sr}
            serviceRequestRefetch={async () => {
              onEdit?.();
            }}
          />
        </Row>
      </CohereCard>
      {opsEditEnabled && canBeWithdrawn(sr) && (
        <WithdrawModal
          open={withdrawModalOpen}
          onClose={() => {
            setWithdrawModalOpen(false);
            setWithdrawnReason("");
          }}
          onWithdraw={onWithdraw}
          withdrawnReason={withdrawnReason}
          setWithdrawnReason={setWithdrawnReason}
          withdrawRequestor={withdrawRequestor}
          setWithdrawRequestor={setWithdrawRequestor}
          submitting={submitting}
          serviceRequestId={sr.id}
          serviceRequest={sr}
          authDecisionGroupId={
            (sr?.authDecisionGroup?.id && isIntegrateAuthDecisionGroups) || sr?.authDecisionGroup?.authNumber
              ? sr.authDecisionGroup?.id
              : undefined
          }
          withdrawnReasonOptionsList={sr.reviewOutcomeWithdrawOptions}
          srNoLongerNeededButton={srNoLongerNeededButton}
        />
      )}
      <DeleteDraftServiceRequestModal
        serviceRequestId={sr.id}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDeleteDraft={() => {
          onEdit?.();
        }}
      />
    </Grid>
  );
};

const VisitsInfo = ({ sr, isInitial }: { sr: ServiceRequestResponse; isInitial: boolean }) => {
  const isUnapprovedUnits = (sr.units || 0) > (sr.approvedUnits || 0);
  if (sr.units) {
    return (
      <>
        Number of visits: {isInitial ? "" : "Additional "}
        <>
          {isUnapprovedUnits && sr.approvedUnits && sr.approvedUnits > 0 && !awaitingTerminalStatus(sr.authStatus)
            ? `${sr.units} requested, ${sr.approvedUnits} approved `
            : isUnapprovedUnits || awaitingTerminalStatus(sr.authStatus)
            ? `${sr.units} requested `
            : `${sr.approvedUnits} approved `}
          &bull;{" "}
        </>
      </>
    );
  } else {
    return <></>;
  }
};

const InpatientDetails = (
  sr: ServiceRequestResponse,
  priorSr: ServiceRequestResponse | undefined,
  nextSr: ServiceRequestResponse | undefined,
  codesInfo: string,
  includePatientStayDatesOnPlannedAdmission: boolean
) => {
  if (sr.patientStatus === "NOT_YET_ADMITTED") {
    const previousExpectedDateString = formatDateStr(priorSr?.expectedAdmissionDate);
    const expectedAdmissionDateString = formatDateStr(sr.expectedAdmissionDate);

    /* Should show expected admission date in the following scenarios:
     * - No prior SR / Current SR: has expectedAdmissionDate
     * - Prior SR was: previousExpectedDate / Current SR: expectedAdmissionDate - previousExpectedDate!== expectedAdmissionDate
     */
    const shouldShowExpectedAdmission =
      (!priorSr?.expectedAdmissionDate && !!expectedAdmissionDateString && expectedAdmissionDateString.length > 0) ||
      (!!previousExpectedDateString &&
        !!expectedAdmissionDateString &&
        expectedAdmissionDateString.length > 0 &&
        previousExpectedDateString !== expectedAdmissionDateString);

    const patientStayDateRange = sr?.patientStayDates ? aggregateStayDateByCommonFields(sr.patientStayDates) : [];
    const patientStayDateRangeString = getStayDateRangeString(patientStayDateRange);

    return (
      <>
        {[
          shouldShowExpectedAdmission ? `Expected admission date: ${expectedAdmissionDateString}` : "",
          includePatientStayDatesOnPlannedAdmission &&
          patientStayDateRange !== undefined &&
          patientStayDateRange.length > 0
            ? `Review dates: ${patientStayDateRangeString}`
            : "",
          codesInfo,
          `Expedited: ${sr.urgency?.isExpedited ? "Yes" : "No"}`,
        ]
          .filter((item) => item !== "")
          .join(" \u2022 ")}
      </>
    );
  } else {
    const previousAdmissionDateString = formatDateStr(priorSr?.admissionDate);
    const admissionDateString = formatDateStr(sr.admissionDate);
    const previousDischargeDateString = formatDateStr(priorSr?.dischargeDate);
    const discharageDateString = formatDateStr(sr.dischargeDate);
    const nextDischargeDateString = formatDateStr(nextSr?.dischargeDate);

    const patientStayDateRange = sr?.patientStayDates ? aggregateStayDateByCommonFields(sr.patientStayDates) : [];
    const patientStayDateRangeString = getStayDateRangeString(patientStayDateRange);

    /* Should show admission date in the following scenarios:
     * - Prior SR was : patientStatus=NOT_YET_ADMITTED / Current SR: has admissionDate
     * - Current SR is: requestType=INITIAL, has admissionDate
     * - Prior SR was: previousAdmissionDate / Current SR: newAdmissionDate - previousAdmissionDate!== newAdmissionDate
     * - Prior SR was : authStatus=(DENIED", "DISMISSED","VOIDED","WITHDRAWN) / Current SR: has admissionDate
     */
    const shouldShowAdmissionDate =
      ((!priorSr || priorSr.patientStatus === "NOT_YET_ADMITTED") &&
        !!admissionDateString &&
        admissionDateString.length > 0) ||
      (sr.requestType === "INITIAL" && !!admissionDateString && admissionDateString.length > 0) ||
      (!!previousAdmissionDateString &&
        previousAdmissionDateString.length > 0 &&
        !!admissionDateString &&
        admissionDateString.length > 0 &&
        previousAdmissionDateString !== admissionDateString) ||
      (["DENIED", "DISMISSED", "VOIDED", "WITHDRAWN"].includes(priorSr?.authStatus ?? "") &&
        !!admissionDateString &&
        admissionDateString.length > 0);

    /* Should show discharge date in the following scenarios:
     * - Prior SR was :  patientStatus!==DISCHARGED / Current SR: has patientStatus===DISCHARGED
     * - Prior SR was: previousDischargeDate / Current SR: newDischargeDate - previousDischargeDate!== newDischargeDate
     * - Prior SR was : authStatus=(DENIED", "DISMISSED","VOIDED","WITHDRAWN) / Current SR: has dischargeDate
     */
    const shouldShowDischargeDateFromCurrentSr =
      ((!priorSr || priorSr.patientStatus !== "DISCHARGED") &&
        sr.patientStatus === "DISCHARGED" &&
        !!discharageDateString &&
        discharageDateString.length > 0) ||
      (!!previousDischargeDateString &&
        previousDischargeDateString.length > 0 &&
        !!discharageDateString &&
        discharageDateString.length > 0 &&
        previousDischargeDateString !== discharageDateString) ||
      (["DENIED", "DISMISSED", "VOIDED", "WITHDRAWN"].includes(priorSr?.authStatus ?? "") &&
        !!discharageDateString &&
        discharageDateString.length > 0);

    /*
     * We hide voided Continuations without stay dates, and show their
     * discharge date on the previous request.
     */
    const shouldShowDischargeDateFromNextSr = isVoidedContinuationWithoutStayDates(nextSr) && !!nextDischargeDateString;

    let dischargeDateStringToDisplay = "";
    if (shouldShowDischargeDateFromCurrentSr) {
      dischargeDateStringToDisplay = discharageDateString;
    } else if (shouldShowDischargeDateFromNextSr) {
      dischargeDateStringToDisplay = nextDischargeDateString;
    }

    return (
      <>
        {[
          shouldShowAdmissionDate ? `Admission date: ${admissionDateString}` : "",
          dischargeDateStringToDisplay ? `Discharge date: ${dischargeDateStringToDisplay}` : "",
          patientStayDateRange !== undefined && patientStayDateRange.length > 0
            ? `Review dates: ${patientStayDateRangeString}`
            : "",
          codesInfo,
        ]
          .filter((item) => item !== "")
          .join(" \u2022 ")}
      </>
    );
  }
};

const OutpatientDetails = (
  isOnPx: boolean | undefined,
  sr: ServiceRequestResponse,
  isInitial: boolean,
  codesInfo: string,
  unitsOnPxCodes?: ProcedureCode[],
  unitsOnservicePxCode?: ProcedureCode[]
) => {
  const serviceDatesTitle = compact([sr.startDate, sr.endDate]).length <= 1 ? "Date of service" : "Dates of service";
  const startDateString = formatDateStr(sr.startDate);
  const endDateString = formatDateStr(sr.endDate);
  //sets to true if SR has both units on service and units on px codes
  const checkSrIsonUnitsOnPxAndUnitsOnService =
    sr.encounterType === "OUTPATIENT" &&
    unitsOnPxCodes &&
    unitsOnPxCodes.length > 0 &&
    unitsOnservicePxCode &&
    unitsOnservicePxCode.length > 0;
  return (
    <>
      {checkSrIsonUnitsOnPxAndUnitsOnService || !isOnPx ? <VisitsInfo sr={sr} isInitial={isInitial}></VisitsInfo> : ""}
      {[
        `${serviceDatesTitle}: ${startDateString}${
          endDateString !== undefined && endDateString.length > 0 ? " - " : ""
        }${endDateString}`,
        codesInfo,
        `Expedited: ${sr.urgency?.isExpedited ? "Yes" : "No"}`,
      ]
        .filter((item) => item !== "")
        .join(" \u2022 ")}
    </>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const TopRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Row = styled("div")({
  display: "flex",
  alignItems: "center",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ExpandedRow = styled(({ clickable, ...other }) => <Row {...other} />)<
  Theme,
  {
    clickable: boolean;
  }
>(({ theme, clickable }) => ({
  borderRadius: 0,
  boxShadow: "none !important",
  cursor: clickable ? "pointer" : "",
  ...(clickable
    ? {
        "&:active": {
          backgroundColor: "#F5F5F5",
        },
      }
    : {}),
}));

function getStayDateRangeString(patientStayDateRange: PatientStayDateRange[]) {
  const stayDateRangeLength = patientStayDateRange.length;

  const rangeStartDate =
    patientStayDateRange !== undefined && stayDateRangeLength > 0 && patientStayDateRange[0].rangeStartDate !== null
      ? patientStayDateRange[0].rangeStartDate
      : undefined;
  const rangeEndDate =
    patientStayDateRange !== undefined &&
    stayDateRangeLength > 0 &&
    patientStayDateRange[stayDateRangeLength - 1].rangeEndDate !== null
      ? patientStayDateRange[stayDateRangeLength - 1].rangeEndDate
      : undefined;
  const dates =
    rangeStartDate !== rangeEndDate
      ? `${formatDateStr(rangeStartDate ?? undefined)} - ${formatDateStr(rangeEndDate ?? undefined)}`
      : `${formatDateStr(rangeStartDate ?? undefined)}`;

  return dates;
}

const isVoidedContinuationWithoutStayDates = (serviceRequest?: ServiceRequestResponse) => {
  if (!serviceRequest) {
    return false;
  }
  return (
    serviceRequest.encounterType === "INPATIENT" &&
    serviceRequest.requestType === "CONTINUATION" &&
    serviceRequest.authStatus === "VOIDED" &&
    (serviceRequest.patientStayDates?.length === 0 ||
      serviceRequest.patientStayDates?.every((stayDate) => stayDate.reviewStatus === "VOID"))
  );
};

// export for storybook
export function ExpandedServiceRequestCardProps(props: ExpandedCardProps) {}
export default ExpandedServiceRequestCard;
