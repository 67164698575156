import React from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { isIE } from "../../util/envUtils";

export interface ModalProps extends DialogProps {
  hideX?: boolean;
  contentClassName?: string;
  dialogTitleStyle?: string;
  withdrawAuthDecisionGroup?: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
    borderRadius: "16px",
    minWidth: isIE ? 600 : "",
  },
  paperWidthMd: {
    maxWidth: 800,
  },
  paperWidthXl: {
    maxWidth: 1024,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const Modal = ({
  hideX,
  children,
  open,
  onClose,
  contentClassName,
  dialogTitleStyle,
  withdrawAuthDecisionGroup,
  ...props
}: ModalProps) => {
  const { closeButton: closeButtonClass, ...dialogClasses } = useStyles();

  return (
    <Dialog
      classes={dialogClasses}
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose?.(event, reason);
        }
      }}
      {...props}
    >
      <DialogTitle className={dialogTitleStyle ? dialogTitleStyle : ""}>
        {!hideX && (
          <IconButton
            className={closeButtonClass}
            aria-label="Dismiss modal"
            onClick={(event) => {
              onClose?.(event, "escapeKeyDown");
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent className={contentClassName}>{children}</DialogContent>
    </Dialog>
  );
};
Modal.displayName = "Modal";

export default Modal;

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DialogTitle = styled(MuiDialogTitle)({});
const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(7),
  overflow: "visible",
}));
