import React from "react";
import { useMemo, useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Typography from "@material-ui/core/Typography";
import {
  AuthBuilderWorkflowStep,
  AuthCategoryResponse,
  AuthStatus,
  BaseReview,
  PendingReason,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import { PillVariant } from "../Pill";
import { PrimaryButton } from "../PrimaryButton";
import { useTheme } from "@material-ui/core/styles";
import { Tooltip } from "../Tooltip";
import { InlineButton } from "../InlineButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  pendingReasonMessage,
  statusCopy as authStatusCopy,
  extractClinicalServicesText,
  getClinicalServicesText,
} from "../../util/serviceRequest";
import { authorizeBaseUrl } from "../../util/envUtils";
import { SubdirectoryArrowRight } from "@material-ui/icons";
import { useGeneralAuthSubmissionWorflowOn } from "../../hooks";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PencilIcon from "@material-ui/icons/Create";
import { InpatientSelectionCard } from "./InpatientSelectionCard";
import { CommonSelectionCard } from "./CommonSelectionCard";
import { colorsLight } from "../../themes";
import { Body2 } from "../Typography";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFeature } from "../../components";

interface ViewProps {
  attachingFile?: boolean;
  authorizationLoading?: boolean;
  isAttached?: boolean;
  isFacilityBasedServiceRequest?: boolean;
  isSelected?: boolean;
  loadingContinuation?: boolean;
  loadingReview?: boolean;
  loadingServiceRequest?: boolean;
  mostRecentReview?: BaseReview;
  onContinuationClick?: () => Promise<void>;
  onEditClick?: () => void;
  onRemove?: (serviceRequestId: string) => void;
  onSelect?: (serviceRequestId: string) => void;
  onUnselect?: (serviceRequestId: string) => void;
  /**
   * This prop is for the actual remove attachment action, not just "unselecting" it
   */
  serviceCaseId?: string;
  serviceRequest: ServiceRequestResponse | null;
  showCreateContinuation?: boolean;
  showPatientAlert?: boolean;
  showRemoveButton?: boolean;
  showSecondaryButtonForAttach?: boolean;
  showEditRequest?: boolean;
  draftContinue?: (_: AuthBuilderWorkflowStep) => Promise<void>;
  disableAttachmentCta?: boolean;
}

export const serviceRequestSelectionCardStyles = makeStyles((theme) => ({
  serviceRequestSelectionCard: {
    border: `1px solid ${theme.palette.divider}`,
    minHeight: 225,
    display: "flex",
    padding: "16px",
  },
  requestDetailsLink: {
    whiteSpace: "nowrap",
  },
  cardHeaderLine1: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  cardHeaderItem: {
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  reviewNotes: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  fieldLabel: {
    display: "inline-block",
    marginBottom: theme.spacing(0.5),
  },
  loadingSpinner: {
    display: "block",
    alignSelf: "center",
    margin: "auto",
  },
  actionButton: {
    paddingLeft: 0,
    paddingRight: "16px",
  },
  disabledAttachButton: {
    paddingLeft: theme.spacing(0, 2),
  },
  redButtonOutline: {
    // error colors
    color: theme.palette.error.main,
    "&:hover,&:focus": {
      // instead of a wide border, we use box shadow to avoid changing the button dimensions
      boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
    },
    "&:active": {
      boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
    },
  },
  removeButton: {
    color: theme.palette.error.main,
    "&:hover,&:focus": {
      color: theme.palette.error.main,
    },
    "&:active": {
      color: theme.palette.error.main,
    },
  },
  h5BodyContainer: {
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "100%",
    "&.MuiGrid-item": {
      margin: theme.spacing(0.5, 0),
    },
  },
  hover: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
      "background-blend-mode": "multiply",
    },
  },
  gridPadding: {
    padding: theme.spacing(0.5, 1),
  },
  zeroMargin: {
    "&.MuiGrid-item": {
      margin: theme.spacing(0),
    },
  },
  customWidth: {
    maxWidth: 500,
  },
  continuationBtnWrapper: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  warningCaption: {
    display: "flex",
    color: colorsLight.warning.dark,
  },
  warningCaptionText: {
    fontWeight: 400,
    fontFamily: "Gilroy-SemiBold",
  },
  warningIcon: {
    marginTop: "5px",
    marginRight: "5px",
  },
  cardButtons: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

/**
 * To make this easier to read/model in storybook, the "view" is split into its own component here.
 * The default export ServiceRequestSelectionCard in auth which leverages the view contains the rest hooks to fetch the most recent review for a SR and
 * methods to attach a fax to the passed in service request
 */

export function ServiceRequestSelectionCardView({
  attachingFile,
  authorizationLoading,
  isAttached,
  isFacilityBasedServiceRequest,
  isSelected,
  loadingContinuation,
  loadingReview,
  loadingServiceRequest,
  mostRecentReview,
  onContinuationClick,
  onRemove,
  onSelect,
  onUnselect,
  serviceCaseId,
  serviceRequest,
  showCreateContinuation,
  showPatientAlert,
  showRemoveButton,
  showSecondaryButtonForAttach,
  showEditRequest = false,
  draftContinue,
  onEditClick,
  disableAttachmentCta,
}: ViewProps) {
  const classes = serviceRequestSelectionCardStyles();
  const theme = useTheme();
  const { id } = serviceRequest ?? {};

  const cardButtonComponent = useMemo<JSX.Element>(() => {
    if (serviceCaseId) {
      return (
        <PrimaryButton
          className={classes.actionButton}
          style={{ width: "50%" }}
          onClick={() =>
            window.location.assign(
              `${authorizeBaseUrl()}/view_only_review?caseId=${serviceCaseId || ""}&serviceRequestId=${id}`
            )
          }
          data-public
        >
          Go to request
        </PrimaryButton>
      );
    }

    if (showRemoveButton && id) {
      return (
        <InlineButton
          startIcon={<DeleteIcon />}
          className={classes.actionButton}
          style={{
            color: `${theme.palette.error.main}`,
          }}
          onClick={() => onRemove?.(id)}
          data-public
        >
          Remove
        </InlineButton>
      );
    }

    if (isAttached) {
      return (
        <PrimaryButton className={classes.disabledAttachButton} disabled data-public>
          Attached
        </PrimaryButton>
      );
    }

    if (isSelected && id) {
      return (
        <InlineButton
          startIcon={<RemoveCircleOutlineIcon />}
          className={classes.actionButton}
          classes={{ textPrimary: classes.removeButton }}
          onClick={() => onUnselect?.(id)}
          disabled={disableAttachmentCta}
          loading={attachingFile}
          data-public
        >
          Remove from request
        </InlineButton>
      );
    }

    if (showSecondaryButtonForAttach && id) {
      return (
        <InlineButton
          startIcon={<AddCircleIcon />}
          className={classes.actionButton}
          onClick={() => onSelect?.(id)}
          disabled={disableAttachmentCta}
          data-public
        >
          Attach to request
        </InlineButton>
      );
    }

    if (id) {
      return (
        <InlineButton
          startIcon={<AddCircleIcon />}
          className={classes.actionButton}
          disabled={disableAttachmentCta}
          onClick={() => onSelect?.(id)}
          data-public
        >
          Attach to request
        </InlineButton>
      );
    }

    return <></>;
  }, [
    attachingFile,
    classes.actionButton,
    classes.disabledAttachButton,
    classes.removeButton,
    disableAttachmentCta,
    id,
    isAttached,
    isSelected,
    onRemove,
    onSelect,
    onUnselect,
    serviceCaseId,
    showRemoveButton,
    showSecondaryButtonForAttach,
    theme.palette.error.main,
  ]);

  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(serviceRequest?.healthPlanName || "");

  const facilityBasedClinicalServicePriority = useFeature("facilityBasedClinicalServicePriority");
  const extractClinicalServiceText = (
    clinicalServices?: ServiceRequestResponse["clinicalServices"],
    authCategory?: AuthCategoryResponse
  ) => {
    if (facilityBasedClinicalServicePriority) {
      return extractClinicalServicesText(clinicalServices || [], authCategory);
    } else {
      // TODO: remove once facilityBasedClinicalServicePriority feature flag is ready to be cleaned up
      return authCategory
        ? `Inpatient ${authCategory?.name ?? ""}${
            clinicalServices && clinicalServices.length > 0 ? `, ${getClinicalServicesText(clinicalServices)}` : ""
          }`
        : getClinicalServicesText(clinicalServices) || serviceRequest?.palCategory?.name || "Request";
    }
  };

  const reviewNotesComponent = !mostRecentReview?.authorizationNote ? (
    <CircularProgress className={classes.loadingSpinner} size={20} />
  ) : (
    <Tooltip interactive title={mostRecentReview.authorizationNote}>
      {/* Need to use the MUI Typography instead of Body1 so the tooltip can pass a ref */}
      <Typography variant="body1" className={classes.reviewNotes}>
        {mostRecentReview.authorizationNote}
      </Typography>
    </Tooltip>
  );

  const continueDraftComponent = (
    <InlineButton
      data-tracking-id="continue-draft"
      data-testid="continue-draft-button"
      endIcon={<ArrowForwardIcon />}
      style={{
        paddingRight: 8,
      }}
      onClick={() => {
        serviceRequest?.workflowStep && draftContinue?.(serviceRequest?.workflowStep);
      }}
      aria-label="Continue working on draft"
      title="Continue working on draft"
      data-public
    >
      Continue
    </InlineButton>
  );

  const draftWarningComponent = (
    <div className={classes.warningCaption}>
      <InfoIcon className={classes.warningIcon} data-public />
      <Body2 className={classes.warningCaptionText} data-public>
        To move forward with this attached fax, this existing service request draft must be submitted or removed.
      </Body2>
    </div>
  );

  if (isFacilityBasedServiceRequest) {
    return (
      <InpatientSelectionCard
        authorizationLoading={authorizationLoading}
        cardButtonComponent={cardButtonComponent}
        serviceRequestSelectionCardStyles={serviceRequestSelectionCardStyles}
        extractClinicalServiceText={extractClinicalServiceText}
        generalAuthSubmissionWorkflowEnabled={generalAuthSubmissionWorkflowEnabled}
        loadingContinuation={loadingContinuation}
        loadingReview={loadingReview}
        loadingServiceRequest={loadingServiceRequest}
        mostRecentReview={mostRecentReview}
        onContinuationClick={onContinuationClick}
        onEditClick={onEditClick}
        reviewNotesComponent={reviewNotesComponent}
        serviceCaseId={serviceCaseId}
        serviceRequest={serviceRequest}
        showCreateContinuation={showCreateContinuation}
        showPatientAlert={!!showPatientAlert}
        showEditRequest={showEditRequest}
        continueDraftComponent={serviceRequest?.workflowStep && draftContinue && continueDraftComponent}
        draftWarningComponent={draftWarningComponent}
      />
    );
  }

  return (
    <CommonSelectionCard
      authorizationLoading={authorizationLoading}
      cardButtonComponent={cardButtonComponent}
      serviceRequestSelectionCardStyles={serviceRequestSelectionCardStyles}
      extractClinicalServiceText={extractClinicalServiceText}
      generalAuthSubmissionWorkflowEnabled={generalAuthSubmissionWorkflowEnabled}
      loadingContinuation={loadingContinuation}
      loadingReview={loadingReview}
      loadingServiceRequest={loadingServiceRequest}
      mostRecentReview={mostRecentReview}
      onContinuationClick={onContinuationClick}
      onEditClick={onEditClick}
      reviewNotesComponent={reviewNotesComponent}
      serviceCaseId={serviceCaseId}
      serviceRequest={serviceRequest}
      showCreateContinuation={showCreateContinuation}
      showPatientAlert={!!showPatientAlert}
      showEditRequest={showEditRequest}
      continueDraftComponent={serviceRequest?.workflowStep && draftContinue && continueDraftComponent}
      draftWarningComponent={draftWarningComponent}
    />
  );
}

interface Props {
  onClick?: () => void;
  loading?: boolean;
}
export const StartContinuationButton = ({ onClick, loading }: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(true);
    onClick?.();
  };

  return (
    <ContinuationButton
      data-tracking-id="create-continuation"
      data-testid="create-continuation-button"
      startIcon={loading ? <CircularProgress size={20} /> : <SubdirectoryArrowRight />}
      onClick={handleClick}
      disabled={isClicked || loading}
      data-public
    >
      Start continuation
    </ContinuationButton>
  );
};

export const EditRequestButton = ({ onClick, loading }: Props) => {
  return (
    <EditButton
      data-tracking-id="edit-request"
      data-testid="edit-request-button"
      startIcon={loading ? <CircularProgress size={20} /> : <PencilIcon />}
      onClick={onClick}
      disabled={loading}
      data-public
    >
      Edit request
    </EditButton>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ContinuationButton = styled(InlineButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  minWidth: "max-content",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const EditButton = styled(InlineButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  minWidth: "max-content",
}));

export const authStatusPillVariant: Record<AuthStatus, PillVariant> = {
  DRAFT: "info",
  PENDING_FACILITY_VERIFICATION: "warning",
  PENDING_EXTERNAL_DETERMINATION: "warning",
  PENDING_ASSESSMENT: "info",
  PENDING: "warning",
  INTAKE: "warning",
  RECOMMENDED_PARTIAL_APPROVAL: "warning",
  RECOMMENDED_DENIAL: "warning",
  APPROVED: "success",
  NO_LONGER_NEEDED: "success",
  PARTIALLY_APPROVED: "error",
  DENIED: "error",
  WITHDRAWN: "info",
  VOIDED: "info",
  DISMISSED: "info",
  POST_DENIAL_PEER_TO_PEER: "warning",
};

export const getAuthStatusCopy = (
  authStatus: AuthStatus,
  pendingReason?: PendingReason,
  delegatedVendor?: string,
  healthPlanName?: string
): string => {
  let copy = authStatusCopy(delegatedVendor, healthPlanName)[authStatus];
  if (authStatus === "PENDING" && !!pendingReason) {
    copy = `${copy}: ${pendingReasonMessage(pendingReason)}`;
  }
  return copy;
};

export function getSelectedRequestActionButtons(
  authStatus: AuthStatus | undefined,
  authorizationLoading?: boolean,
  continueDraftComponent?: JSX.Element,
  showCreateContinuation?: boolean,
  onContinuationClick?: () => Promise<void>,
  loadingContinuation?: boolean,
  showEditRequest?: boolean,
  onEditClick?: () => void
): React.ReactNode {
  if (authorizationLoading) {
    return <CircularProgress size={30} />;
  }

  if (authStatus === "DRAFT" && continueDraftComponent) {
    return continueDraftComponent;
  }
  return (
    <>
      {showCreateContinuation && (
        <StartContinuationButton onClick={onContinuationClick} loading={loadingContinuation} />
      )}
      {showEditRequest && <EditRequestButton onClick={onEditClick} />}
    </>
  );
}
