import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Body1,
  Chip,
  CohereTable,
  colorsLight,
  formatDateStr,
  Switch,
  themeLight,
  Tooltip,
  H4,
  TextSearch,
  Caption,
  useFeature,
} from "@coherehealth/common";
import {
  User,
  UserResponse,
  useDeleteUser,
  useGetUsers,
  useUnlockUser,
  useUpdateUser,
  useUpdateUserExtension,
  useResendActivationEmail,
} from "@coherehealth/core-platform-api";
import { makeStyles } from "@material-ui/core/styles";
import { UserType } from "types/userType";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, debounce } from "@material-ui/core";
import { useAuthorized } from "authorization";
import { useSnackbar } from "notistack";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useTrackUserInteraction } from "util/userActivityTracker";
import EditMemberModal from "./EditMemberModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UnlockMemberModal from "./UnlockMemberModal";
import { GetEmailWrapper, GetNameWrapper, GetPhoneNumberColumnWrapper } from "./ProviderOrgUsers";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Edit, Delete, Mail, LockOpen } from "@material-ui/icons";

interface IProviderOrgUsersProps {
  setEditingMember: (value: SetStateAction<User>) => void;
  setMenuItemOpen: (value: SetStateAction<boolean>) => void;
}

interface IProviderOrgUsersTableProps {
  orgName: string;
  orgId: string | undefined;
  userId: string;
}

interface IProviderOrgUsersColumnProps extends IProviderOrgUsersProps {
  user: UserResponse;
  setAnchorKebab: (value: SetStateAction<HTMLElement | undefined>) => void;
  styleClasses: ClassNameMap<"actionsMenu" | "vertIconBtn" | "iconButtons" | "menuItem" | "vertIcon">;
  setUserStatus: (value: React.SetStateAction<string>) => void;
}

interface MenuItemProps {
  setAnchorKebab: (value: SetStateAction<HTMLElement | undefined>) => void;
  anchorKebab: HTMLElement | undefined;
  canReviewAndEditUsers: boolean;
  disableDestructiveActionsForUser: (userToEdit: User) => boolean | undefined;
  setAddMemberModalOpen: (value: SetStateAction<boolean>) => void;
  setUnlockMemberModalOpen: (value: SetStateAction<boolean>) => void;
  setDeleteMemberModalOpen: (value: SetStateAction<boolean>) => void;
  editingMember: User;
  styleClasses: ClassNameMap<"actionsMenu" | "vertIconBtn" | "iconButtons" | "menuItem" | "vertIcon">;
  showResendActivationLinkButton: boolean;
  userStatus: string;
  setMenuItemOpen: (value: SetStateAction<boolean>) => void;
}
interface IIsAdminColumnProps {
  canReviewAndEditUsers: boolean;
  disableDestructiveActionsForUser: (userToEdit: User) => boolean | undefined;
  user: UserResponse;
  updateMember: (user: User, skipUsersRefresh: boolean) => Promise<UserResponse>;
  styleClasses: ClassNameMap<
    | "dividerBorder"
    | "switchLabel"
    | "switchLabelPlacementStartOverrides"
    | "switchContainer"
    | "switchSpinnerContainer"
  >;
  setUserChangedAdmin: (value: React.SetStateAction<UserResponse | undefined>) => void;
  userChangeAdminId: string;
  setUserChangeAdminId: (value: React.SetStateAction<string>) => void;
}

interface ChipAlertProps {
  userStatus: string;
}

export interface UserQueryParams {
  organizationName?: string;
  max?: number;
  offset?: number;
  sort?: string;
  organization?: string;
  query?: string;
}

const useStyles = makeStyles((theme) => ({
  dividerBorder: {
    borderColor: colorsLight.gray.divider,
  },
  switchLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(1),
    width: "100%",
  },
  switchLabelPlacementStartOverrides: {
    marginLeft: 0,
  },
  tableColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  actionsMenu: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    padding: theme.spacing(1.0625),
    top: theme.spacing(0),
    left: theme.spacing(0),
  },
  newUserChip: { marginLeft: theme.spacing(1.5) },
  statusChip: { padding: theme.spacing(1.5, 2) },
  textSearchBox: { width: "400px" },
  switchContainer: {
    padding: theme.spacing(1.5),
    paddingLeft: 0,
  },
  switchSpinnerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: theme.spacing(7),
    padding: "9px 0",
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  membersSubheader: {
    color: colorsLight.font.light,
  },
  noMemberCaption: {
    color: colorsLight.font.light,
  },
  infoIcon: {
    color: colorsLight.font.light,
    marginLeft: theme.spacing(1),
  },
  tableGrid: { marginTop: "40px", marginBottom: "24px" },
  styledBodyContainer: {
    width: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  vertIconBtn: {
    boxShadow: "none",
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: 5,

    backgroundColor: "transparent",
    "&:hover, &:active": {
      boxShadow: "none",
      backgroundColor: colorsLight.action.hoverBackground,
    },
  },
  vertIcon: {
    color: colorsLight.primary.main,
    height: 20,
    width: 20,
  },
  iconButtons: { color: colorsLight.font.main, marginRight: theme.spacing(1) },
  menuItem: {
    display: "inline-flex",
    flexDirection: "row",
    width: "100%",
    minWidth: "200px",
    minHeight: "48px",
  },
}));

const StatusChip = ({ userStatus }: ChipAlertProps) => {
  const styleClasses = useStyles();
  if (userStatus === "ACTIVE") {
    return <Chip size="small" type="success" className={styleClasses.statusChip} label={"Active"} />;
  }
  if (["STAGED", "PROVISIONED"].includes(userStatus)) {
    return <Chip size="small" type="warning" className={styleClasses.statusChip} label={"Pending"} />;
  }
  if (userStatus === "LOCKED_OUT") {
    return <Chip size="small" type="error" className={styleClasses.statusChip} label={"Locked"} />;
  }
  if (userStatus === "DEPROVISIONED") {
    return <Chip size="small" type="error" className={styleClasses.statusChip} label={"Deactivated"} />;
  }
  if (userStatus === "RECOVERY") {
    return (
      <Tooltip title={"Reset password initiated"} placement="top">
        <Chip size="small" type="warning" className={styleClasses.statusChip} label={"Reset pa..."} />
      </Tooltip>
    );
  }
  if (userStatus === "PASSWORD_EXPIRED") {
    return (
      <Tooltip title={"New password required"} placement="top">
        <Chip size="small" type="warning" className={styleClasses.statusChip} label={"New pas..."} />
      </Tooltip>
    );
  }
  if (userStatus === "SUSPENDED") {
    return <Chip size="small" type="error" className={styleClasses.statusChip} label={"Suspended"} />;
  }
  return null;
};

const SwitchLabel = ({ isAdmin, isDisabled }: { isAdmin: boolean; isDisabled: boolean }) => {
  const styleClasses = useStyles();
  return (
    <Body1 className={isDisabled ? styleClasses.textSecondary : styleClasses.textPrimary}>
      {isAdmin ? "Yes" : "No"}
    </Body1>
  );
};

const RESULTS_PER_PAGE = [
  { id: "10", label: "10" },
  { id: "25", label: "25" },
  { id: "50", label: "50" },
];
const SEARCH_CHARS_MIN = 4;

const ActionsMenuColumn = ({
  user,
  setAnchorKebab,
  setEditingMember,
  styleClasses,
  setUserStatus,
  setMenuItemOpen,
}: IProviderOrgUsersColumnProps) => {
  return (
    <>
      <IconButton
        aria-controls={user.id}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setEditingMember(user);
          setUserStatus(user.oktaStatus ?? "");
          setAnchorKebab(event.currentTarget);
          setMenuItemOpen(true);
        }}
        className={styleClasses.vertIconBtn}
        disableTouchRipple
      >
        <MoreVertIcon className={styleClasses.vertIcon} />
      </IconButton>
    </>
  );
};

const MenuItems = ({
  setAnchorKebab,
  canReviewAndEditUsers,
  disableDestructiveActionsForUser,
  anchorKebab,
  setAddMemberModalOpen,
  setUnlockMemberModalOpen,
  setDeleteMemberModalOpen,
  editingMember,
  styleClasses,
  showResendActivationLinkButton,
  userStatus,
  setMenuItemOpen,
}: MenuItemProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const trackInteraction = useTrackUserInteraction();
  const { mutate: resendActivationEmailToUser, error: resendActivationEmailError } = useResendActivationEmail({
    id: editingMember.id || "",
  });

  const resendActivationEmail = useCallback(
    async (user: User) => {
      await resendActivationEmailToUser({ ...user }, { pathParams: { id: user.id || "" } });
      trackInteraction({
        event: "ACTIVATION_LINK_SENT",
        stage: "ORGANIZATION_MANAGEMENT",
        activityContext: {
          userEmail: user.email,
          userId: user.id,
          organizationId: user.organization,
        },
      });
      enqueueSnackbar(`Activation link sent`, {
        variant: "success",
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar, resendActivationEmailToUser, trackInteraction]
  );

  useEffect(() => {
    if (resendActivationEmailError) {
      enqueueSnackbar(
        `There was an error sending the activation link to user ${resendActivationEmailError.message}, please contact Cohere`,
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, resendActivationEmailError]);

  function getMenuItems() {
    return [
      <Tooltip
        key={"edit-menu-item-tt" + editingMember.id}
        title={!canReviewAndEditUsers ? "Only admin users can edit organization members" : ""}
        data-public
      >
        <MenuItem
          onClick={() => {
            setAddMemberModalOpen(true);
            setAnchorKebab(undefined);
          }}
          disabled={!canReviewAndEditUsers}
          className={styleClasses.menuItem}
        >
          <Edit className={styleClasses.iconButtons} data-public />
          <Body1 data-public>Edit user</Body1>
        </MenuItem>
      </Tooltip>,
      <Tooltip
        key={"delete-menu-item-tt" + editingMember.id}
        title={!canReviewAndEditUsers ? "Only admin users can edit organization members" : ""}
        data-public
      >
        <MenuItem
          disabled={disableDestructiveActionsForUser(editingMember) ?? !canReviewAndEditUsers}
          onClick={() => {
            setDeleteMemberModalOpen(true);
            setAnchorKebab(undefined);
          }}
          className={styleClasses.menuItem}
        >
          <Delete className={styleClasses.iconButtons} data-public />
          <Body1 data-public>Delete user</Body1>
        </MenuItem>
      </Tooltip>,
      showResendActivationLinkButton && ["STAGED", "PROVISIONED", "PASSWORD_EXPIRED"].includes(userStatus) ? (
        <Tooltip
          key={"resend-email-menu-item-tt" + editingMember.id}
          title={!canReviewAndEditUsers ? "Only admin users can edit organization members" : ""}
          data-public
        >
          <MenuItem
            disabled={disableDestructiveActionsForUser(editingMember) ?? !canReviewAndEditUsers}
            onClick={() => {
              resendActivationEmail(editingMember);
              setAnchorKebab(undefined);
            }}
            className={styleClasses.menuItem}
            data-public
          >
            <Mail className={styleClasses.iconButtons} data-public />
            <Body1 data-public>Send activation link</Body1>
          </MenuItem>
        </Tooltip>
      ) : null,
    ];
  }
  return (
    <>
      <Menu
        id={editingMember.id}
        anchorEl={anchorKebab}
        keepMounted
        open={Boolean(anchorKebab)}
        onClose={() => {
          setAnchorKebab(undefined);
          setMenuItemOpen(false);
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 190,
        }}
        MenuListProps={{
          style: {
            padding: themeLight.spacing(0),
            maxWidth: themeLight.spacing(25),
          },
        }}
      >
        {userStatus !== "LOCKED_OUT" ? (
          getMenuItems()
        ) : (
          <MenuItem
            onClick={() => {
              setUnlockMemberModalOpen(true);
              setAnchorKebab(undefined);
            }}
            className={styleClasses.menuItem}
          >
            <LockOpen className={styleClasses.iconButtons} data-public />
            <Body1 data-public>Unlock account</Body1>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export function ProviderOrgUsersTable({ orgName, orgId, userId }: Readonly<IProviderOrgUsersTableProps>) {
  const { enqueueSnackbar } = useSnackbar();
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [deleteMemberModalOpen, setDeleteMemberModalOpen] = useState(false);
  const [unlockMemberModalOpen, setUnlockMemberModalOpen] = useState(false);
  const [userChangedAdmin, setUserChangedAdmin] = useState<UserResponse>();
  const [userChangedEdit, setUserChangedEdit] = useState<UserResponse>();
  const [userChangeAdminId, setUserChangeAdminId] = useState("");
  // Default is sorted by date created in descending order
  const [sortingColumnName, setSortingColumnName] = useState("dateCreated");
  const [sortingAscending, setSortingAscending] = useState(false);
  const [anchorKebab, setAnchorKebab] = useState<HTMLElement>();
  const [editingMember, setEditingMember] = useState<User>({});
  const [menuItemOpen, setMenuItemOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [queryParams, setQueryParams] = useState<UserQueryParams>(() => ({
    organizationName: orgName,
    organization: String(orgId),
    max: Number(RESULTS_PER_PAGE[1].id) + 1,
    offset: 0,
    sort: "dateCreated:desc",
    query: searchText,
  }));

  const canReviewAndEditUsers = useAuthorized("CAN_REVIEW_AND_EDIT_VERIFIED_USERS");
  const displayMax = queryParams.max ? queryParams.max - 1 : Number(RESULTS_PER_PAGE[1].id);
  const shouldQuery = searchText && searchText.length >= SEARCH_CHARS_MIN;
  const [userStatus, setUserStatus] = useState<string>("");
  const showResendActivationLinkButton = useFeature("showResendActivationLinkButton");
  const {
    data: users,
    refetch: refetchUsers,
    loading: loadingUsers,
    error: getUsersError,
  } = useGetUsers({
    debounce: 600,
    queryParams,
  });

  const [minLoaderHeight, setMinHeight] = useState("400px");
  const verifiedMembersRef = useRef<HTMLDivElement>(null);

  const updateMinHeight = useCallback(() => {
    if (verifiedMembersRef.current) {
      const rect = verifiedMembersRef.current?.getBoundingClientRect();
      const calculatedHeight = window.innerHeight - rect.top;
      if (calculatedHeight > 0) {
        setMinHeight(`${calculatedHeight}px`);
      }
    }
  }, [verifiedMembersRef]);

  const debouncedUpdateMinHeight = useMemo(() => debounce(updateMinHeight, 300), [updateMinHeight]);

  useEffect(() => {
    updateMinHeight();
    window.addEventListener("resize", debouncedUpdateMinHeight);
    window.addEventListener("scroll", debouncedUpdateMinHeight);

    return () => {
      window.removeEventListener("resize", debouncedUpdateMinHeight);
      window.removeEventListener("scroll", debouncedUpdateMinHeight);
    };
  }, [debouncedUpdateMinHeight, updateMinHeight]);

  const numberOfAdminsInOrg = users?.filter(({ roles }) => roles?.includes("backOfficeAdmin"))?.length || 0;

  const {
    mutate: updateUser,
    loading: updatingUser,
    error: userUpdateError,
  } = useUpdateUser({
    id: editingMember?.id || "",
  });

  const {
    mutate: updateUserFaxExtension,
    loading: updatingUserFaxExtension,
    error: userUpdateFaxExtensionError,
  } = useUpdateUserExtension({
    id: editingMember?.id || "",
  });

  useEffect(() => {
    if (shouldQuery) {
      setQueryParams((prev) => ({ ...prev, query: searchText }));
    }
  }, [shouldQuery, searchText]);

  const updateMember = useCallback(
    async (user: User, skipUsersRefresh: boolean) => {
      const updatedUser = await updateUser({ ...user }, { pathParams: { id: user.id || "" } });
      setAddMemberModalOpen(false);
      setEditingMember({});
      if (user.extension?.mostRecentlyUsedFaxNumber !== editingMember?.extension?.mostRecentlyUsedFaxNumber) {
        let mostRecentlyUsedFaxNumber = user.extension?.mostRecentlyUsedFaxNumber;
        await updateUserFaxExtension({ mostRecentlyUsedFaxNumber }, { pathParams: { id: user?.id || "" } });
      }
      if (!skipUsersRefresh) {
        await refetchUsers();
      }
      return updatedUser;
    },
    [
      updateUser,
      setAddMemberModalOpen,
      setEditingMember,
      editingMember?.extension?.mostRecentlyUsedFaxNumber,
      refetchUsers,
      updateUserFaxExtension,
    ]
  );

  const {
    mutate: unlockUser,
    loading: unlockingUser,
    error: userUnlockError,
  } = useUnlockUser({
    id: editingMember?.id || "",
  });
  const unlockMember = useCallback(
    async (user: User) => {
      await unlockUser({}, { pathParams: { id: user.id || "" } });
      await refetchUsers();
    },
    [unlockUser, refetchUsers]
  );
  const { mutate: deleteUser, loading: deletingUser, error: userDeletionError } = useDeleteUser({});
  const trackInteraction = useTrackUserInteraction();

  useEffect(() => {
    if (getUsersError) {
      enqueueSnackbar(`There was an error retrieving users ${getUsersError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getUsersError]);

  useEffect(() => {
    if (userUpdateError) {
      setUserChangeAdminId("");
      enqueueSnackbar(`There was an error updating the user ${userUpdateError.message}`, {
        variant: "error",
      });
    }
    if (userUpdateFaxExtensionError) {
      enqueueSnackbar(`There was an error updating the fax extension of user ${userUpdateFaxExtensionError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, userUpdateError, userUpdateFaxExtensionError]);

  useEffect(() => {
    if (userUnlockError) {
      enqueueSnackbar(`There was an error unlocking the user ${userUnlockError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, userUnlockError]);

  useEffect(() => {
    if (userDeletionError) {
      enqueueSnackbar(`There was an error deactivating the user ${userDeletionError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, userDeletionError]);

  const sortHandler = useCallback(
    (columnName: string, ascending: boolean) => {
      if (sortingColumnName !== columnName) {
        setSortingColumnName(columnName);
      }
      if (sortingAscending !== ascending) {
        setSortingAscending(ascending);
      }
      setQueryParams((prevQueryParams: UserQueryParams) => {
        const sortByColumn = `${columnName}:${sortingAscending ? "desc" : "asc"}`;
        return { ...prevQueryParams, sort: sortByColumn };
      });
    },
    [setQueryParams, sortingAscending, sortingColumnName]
  );

  useEffect(() => {
    if (!!userChangedAdmin && users?.length && users.length > 0) {
      const userFound = users.find((user) => {
        return user.id === userChangedAdmin.id;
      });
      if (!!userFound) {
        userFound.roles = userChangedAdmin.roles;
      }
      setUserChangedAdmin(undefined);
      setUserChangeAdminId("");
    }
  }, [userChangedAdmin, users]);

  useEffect(() => {
    //some times syncronizing okta users/db users is slow, so
    // updating the in memory data with what the api returned
    if (!!userChangedEdit && users?.length && users.length > 0) {
      const userFound = users.find((user) => {
        return user.id === userChangedEdit.id;
      });
      if (!!userFound) {
        userFound.firstName = userChangedEdit.firstName;
        userFound.lastName = userChangedEdit.lastName;
        userFound.phone = userChangedEdit.phone;
        userFound.extension = userChangedEdit.extension;
        userFound.email = userChangedEdit.email;
        userFound.name = userChangedEdit.name;
        userFound.oktaStatus = userChangedEdit.oktaStatus;
        userFound.roles = userChangedEdit.roles;
        userFound.specialties = userChangedEdit.specialties;
        userFound.title = userChangedEdit.title;
        //fax mapping is missing in UserResponse definition
      }
      setUserChangedEdit(undefined);
    }
  }, [userChangedEdit, users]);

  const deleteMember = useCallback(
    async (user: User) => {
      if (user.id) {
        await deleteUser(user.id);
        setDeleteMemberModalOpen(false);
        trackInteraction({
          event: "ORGANIZATION_DELETE_EMAIL_AUTO_VERIFIED_USER",
          stage: "ORGANIZATION_DASHBOARD",
          activityContext: {
            deletedUserName: user.name,
            deletedUserEmail: user.email,
            adminUserId: userId,
            organizationId: user.organization,
          },
        });
        await refetchUsers();
      }
    },
    [deleteUser, refetchUsers, trackInteraction, userId]
  );

  const disableDestructiveActionsForUser = useCallback(
    (userToEdit: User) => {
      const userIsAdmin = userToEdit.roles?.includes("backOfficeAdmin");

      return (
        userId === userToEdit.id /* user is self */ ||
        (updatingUser &&
          updatingUserFaxExtension &&
          userToEdit.id === editingMember.id) /* A request is in flight to update this user */ ||
        (numberOfAdminsInOrg < 2 && userIsAdmin)
      ); /* User is the only admin */
    },
    [editingMember.id, numberOfAdminsInOrg, updatingUser, updatingUserFaxExtension, userId]
  );

  const styleClasses = useStyles();

  const tableColumns = useMemo(
    () => [
      {
        name: "name",
        width: "260px",
        themedPaddingRight: 0,
        header: (
          <Body1
            onClick={() => {
              setSortingColumnName("name");
              setSortingAscending(!sortingAscending);
            }}
          >
            Name
          </Body1>
        ),
        value: (user: UserResponse) => GetNameWrapper(user, styleClasses),
      },
      {
        name: "email",
        width: "250px",
        themedPaddingRight: 3,
        unSortable: true,
        header: (
          <Body1
            onClick={() => {
              setSortingColumnName("email");
              setSortingAscending(!sortingAscending);
            }}
            color="textSecondary"
          >
            Email
          </Body1>
        ),
        value: (user: UserResponse) => GetEmailWrapper(user, styleClasses),
      },
      {
        name: "phoneNumber",
        width: "210px",
        themedPaddingRight: 0,
        unSortable: true,
        header: <Body1 color="textSecondary">Phone number</Body1>,
        value: (user: UserResponse) => GetPhoneNumberColumnWrapper(user, styleClasses),
      },
      {
        name: "roles",
        width: "140px",
        themedPaddingRight: 0,
        header: (
          <Body1
            onClick={() => {
              setSortingColumnName("roles");
              setSortingAscending(!sortingAscending);
            }}
          >
            Admin
          </Body1>
        ),
        value: (user: UserResponse) =>
          getIsAdminColumn({
            canReviewAndEditUsers,
            disableDestructiveActionsForUser,
            user,
            updateMember,
            styleClasses,
            setUserChangedAdmin,
            userChangeAdminId,
            setUserChangeAdminId,
          }),
      },
      {
        name: "dateCreated",
        width: "180px",
        themedPaddingRight: 0,
        header: (
          <Body1
            onClick={() => {
              setSortingColumnName("dateCreated");
              setSortingAscending(!sortingAscending);
            }}
          >
            Date created
          </Body1>
        ),
        value: (user: UserResponse) => (user.dateCreated ? formatDateStr(user.dateCreated) : ""),
      },
      {
        name: "oktaStatus",
        width: "135px",
        themedPaddingRight: 0,
        header: (
          <Body1
            onClick={() => {
              setSortingColumnName("oktaStatus");
              setSortingAscending(!sortingAscending);
            }}
          >
            Status
          </Body1>
        ),
        value: (user: UserResponse) => <StatusChip userStatus={user.oktaStatus ?? ""} />,
      },
      {
        name: "",
        width: "60px",
        themedPaddingRight: 0,
        value: (user: UserResponse) => (
          <ActionsMenuColumn
            user={user}
            setAnchorKebab={setAnchorKebab}
            setEditingMember={setEditingMember}
            setMenuItemOpen={setMenuItemOpen}
            styleClasses={styleClasses}
            setUserStatus={setUserStatus}
          />
        ),
        unSortable: true,
      },
    ],
    [
      sortingAscending,
      styleClasses,
      canReviewAndEditUsers,
      disableDestructiveActionsForUser,
      updateMember,
      userChangeAdminId,
    ]
  );

  return (
    <>
      <EditMemberModal
        open={addMemberModalOpen}
        handleClose={() => {
          setAddMemberModalOpen(false);
          setEditingMember({});
        }}
        onSubmit={async (user: User) => {
          if (Boolean(user.id)) {
            const updatedUser = await updateMember(user, true);
            setUserChangedEdit(updatedUser);
          }
        }}
        submitting={updatingUser}
        member={editingMember}
        requireAdmin={users?.length === 0}
        oktaUserStatus={editingMember.oktaStatus}
        disableAdminSwitch={disableDestructiveActionsForUser(editingMember)}
      />
      <DeleteConfirmationModal
        open={deleteMemberModalOpen}
        handleClose={() => {
          setDeleteMemberModalOpen(false);
        }}
        onDelete={() => deleteMember(editingMember)}
        deleting={deletingUser}
        subject="MEMBER"
      />
      <UnlockMemberModal
        open={unlockMemberModalOpen}
        handleClose={() => {
          setUnlockMemberModalOpen(false);
        }}
        onUnlock={() => unlockMember(editingMember)}
        unlocking={unlockingUser}
      />
      {
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          className={styleClasses.tableGrid}
          justifyContent="space-between"
          ref={verifiedMembersRef}
        >
          <Grid item style={{ display: "flex" }}>
            <H4 className={styleClasses.membersSubheader} data-public>
              Verified users
            </H4>
            <Tooltip
              title={"These users can submit new auths on behalf of the organization and view existing auths."}
              placement="top"
              data-public
            >
              <InfoOutlinedIcon className={styleClasses.infoIcon} data-public />
            </Tooltip>
          </Grid>
          <Grid item>
            <TextSearch
              fullWidth
              className={styleClasses.textSearchBox}
              label="Search by user's name or email"
              value={searchText || ""}
              onChangeValue={(newInputValue) => {
                setSearchText(newInputValue);
                if (newInputValue.length === 0) {
                  // if user clear the input
                  setQueryParams((prev) => ({ ...prev, query: "" }));
                }
              }}
              data-public
            />
          </Grid>
        </Grid>
      }
      {!loadingUsers && Number(users?.length) === 0 && (
        <Grid container alignItems="center">
          <Caption className={styleClasses.noMemberCaption} data-public>
            No results found
          </Caption>
        </Grid>
      )}
      <Divider />
      {loadingUsers && (
        <Grid container style={{ height: minLoaderHeight }} alignItems="center" justifyContent="center">
          <CircularProgress data-public />
        </Grid>
      )}
      {!loadingUsers && !!users && Number(users?.length) > 0 && (
        <>
          <CohereTable<UserResponse>
            headerMargin="0px 0px 24px 0px"
            show3linesAndThenEllipsis
            cardHeight={"short"}
            tableColumns={tableColumns}
            data={users}
            sortingColumnName={sortingColumnName}
            sortingAscending={sortingAscending}
            onSortChange={sortHandler}
            paginateViaParams
            paginateParams={queryParams}
            setPaginateParams={setQueryParams}
            displayMax={displayMax}
            RESULTS_PER_PAGE={RESULTS_PER_PAGE}
            dataPublic
          />
          {menuItemOpen && (
            <MenuItems
              setAnchorKebab={setAnchorKebab}
              anchorKebab={anchorKebab}
              canReviewAndEditUsers={canReviewAndEditUsers}
              disableDestructiveActionsForUser={disableDestructiveActionsForUser}
              setAddMemberModalOpen={setAddMemberModalOpen}
              setUnlockMemberModalOpen={setUnlockMemberModalOpen}
              setDeleteMemberModalOpen={setDeleteMemberModalOpen}
              editingMember={editingMember}
              styleClasses={styleClasses}
              showResendActivationLinkButton={showResendActivationLinkButton}
              userStatus={userStatus}
              setMenuItemOpen={setMenuItemOpen}
            ></MenuItems>
          )}
        </>
      )}
    </>
  );
}

function getIsAdminColumn({
  canReviewAndEditUsers,
  disableDestructiveActionsForUser,
  user,
  updateMember,
  styleClasses,
  setUserChangedAdmin,
  userChangeAdminId,
  setUserChangeAdminId,
}: IIsAdminColumnProps) {
  const userIsBackofficAdmin = user.roles?.includes(UserType.BackOfficeAdmin);
  const isUpdatingIsAdminValue = userChangeAdminId !== "" && userChangeAdminId === user.id;
  const notUpdateIsAdminValue = userChangeAdminId === "" || (userChangeAdminId !== "" && userChangeAdminId !== user.id);

  return (
    <div>
      <Tooltip title={!canReviewAndEditUsers ? "Only admin users can edit organization members" : ""}>
        <div className={styleClasses.switchContainer}>
          {isUpdatingIsAdminValue && (
            <div className={styleClasses.switchSpinnerContainer}>
              <SwitchLabel isAdmin={Boolean(userIsBackofficAdmin)} isDisabled={true} />
              <CircularProgress size={20} />
            </div>
          )}
          {notUpdateIsAdminValue && (
            <Switch
              disabled={(disableDestructiveActionsForUser(user) && userIsBackofficAdmin) || !canReviewAndEditUsers}
              checked={userIsBackofficAdmin}
              label={<SwitchLabel isAdmin={Boolean(userIsBackofficAdmin)} isDisabled={false} />}
              onChange={async (checked) => {
                setUserChangeAdminId(user.id);
                const updatedUser = await updateMember(
                  {
                    ...user,
                    roles: checked ? [UserType.BackOfficeAdmin] : [UserType.BackOffice],
                  },
                  true
                );
                setUserChangedAdmin(updatedUser);
              }}
              FormControlLabelProps={{
                classes: {
                  label: styleClasses.switchLabel,
                  labelPlacementStart: styleClasses.switchLabelPlacementStartOverrides,
                },
                labelPlacement: "start",
              }}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
}
