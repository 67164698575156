import {
  ActivityField,
  ActivitySnapshot,
  ClinicalService,
  ReferralRequestResponse,
  ServiceRequestResponse,
  useAddUserActivity,
  UserActivity,
} from "@coherehealth/core-platform-api";
import { ServiceRequestFormContent } from "../common/SharedServiceRequestFormComponents";
import { formatDateToISODate } from "@coherehealth/common";
import { useCallback } from "react";
import LogRocket from "logrocket";

export function trackUserActivity(eventName: string) {
  try {
    LogRocket.track(eventName);
  } catch (e) {
    console.warn(e);
  }
}

export type TrackUserActivityProps = Omit<UserActivity, "type">;

export function useTrackUserImpression() {
  const { mutate } = useAddUserActivity({});
  return useCallback((props: TrackUserActivityProps) => mutate({ ...props, type: "IMPRESSION" }), [mutate]);
}

export function useTrackUserInteraction() {
  const { mutate } = useAddUserActivity({});
  return useCallback(
    (props: TrackUserActivityProps) => {
      return mutate({ ...props, type: "INTERACTION" });
    },
    [mutate]
  );
}

export function activitySnapshotFromServiceRequestFormContent(sr?: ServiceRequestFormContent) {
  return activitySnapshotFromServiceRequest({
    // TODO remove the else condition of the ternary with removal of simplifiedServiceFrequency
    ...(sr?.isRecurring || (sr && typeof sr?.isRecurring === "undefined")
      ? {
          units: Number(sr.units),
          unitType: sr.unitType,
          endDate: formatDateToISODate(sr.endDate),
        }
      : {
          units: undefined,
          unitType: undefined,
          endDate: "",
        }),
    facilitySelectedTin: sr?.facilitySelectedTin || undefined,
    performingProviderSelectedTin: sr?.performingProviderSelectedTin || undefined,
    facility: sr?.facility || undefined,
    orderingProvider: sr?.orderingProvider || undefined,
    performingProvider: sr?.performingProvider || undefined,
    placeOfService: sr?.placeOfService || undefined,
    procedureCodes: sr?.procedureCodes,
    primaryDiagnosis: sr?.primaryDiagnosisCode || undefined,
    secondaryDiagnoses: sr?.secondaryDiagnosisCodes,
    startDate: formatDateToISODate(sr?.startDate),
    urgency: { isExpedited: sr?.isExpedited },
  });
}

export function activitySnapshotFromServiceRequest(
  sr: Omit<ServiceRequestResponse, "id" | "dateCreated" | "lastUpdated">
) {
  const {
    primaryDiagnosis: pdc,
    secondaryDiagnoses: sdcs,
    procedureCodes: pcs,
    orderingProvider,
    facility,
    performingProvider,
    placeOfService,
    startDate,
    endDate,
    units,
    unitType,
    facilitySelectedTin,
    performingProviderSelectedTin,
    urgency,
    attachments,
  } = sr;
  const procedureCodes = pcs?.map((code) => code.code);
  const primaryDiagnosisCode = pdc?.code;
  const secondaryDiagnosisCodes = sdcs?.map((code) => code.code);
  const snapshot: ActivitySnapshot = {
    facilityId: facility?.id,
    orderingProviderId: orderingProvider?.id,
    performingProviderId: performingProvider?.id,
    placeOfServiceId: placeOfService?.id,
    procedureCodes,
    primaryDiagnosisCode,
    secondaryDiagnosisCodes,
    startDate,
    endDate,
    units,
    unitType,
    facilitySelectedTin,
    performingProviderSelectedTin,
    isExpedited: !!urgency?.isExpedited,
    attachmentsCount: attachments?.length || 0,
    mongoPatientId: sr.patient?.id,
  };
  return snapshot;
}

export function activityContextFromServiceRequest(sr: ServiceRequestResponse) {
  const { patient, clinicalService, clinicalServices, id } = sr;
  let services: Array<ClinicalService | null> = [];
  if (clinicalServices && clinicalServices.length) {
    services = clinicalServices;
  } else if (clinicalService) {
    services = [clinicalService];
  }
  return {
    patientId: patient?.id,
    clinicalServiceIds: services?.filter((cs): cs is { id: string } => !!cs?.id).map((cs) => cs.id),
    serviceRequestId: id,
  };
}

export function activityContextFromReferralRequest(rr: ReferralRequestResponse) {
  const { patient, id } = rr;
  return {
    patientId: patient?.id,
    referralRequestId: id,
  };
}

export function activityContextFromCreateReferralRequest(patientId: string) {
  return {
    patientId: patientId,
  };
}

export const convertRequestFieldsToTrackingField = (field?: string): ActivityField | undefined => {
  switch (field) {
    case "units":
      return "UNITS";
    case "procedureCodes":
      return "PROCEDURE_CODES";
    case "encounterType":
      return "ENCOUNTER_TYPE";
    case "placeOfService":
      return "PLACE_OF_SERVICE";
    case "urgency.isExpedited":
      return "EXPEDITED";
    default:
      return undefined;
  }
};
