import React, { ChangeEvent, useEffect, useState } from "react";
import { MutateMethod } from "restful-react";

import { Caption, Body1, Tooltip, parseDateFromISOString, colorsLight } from "@coherehealth/common";
import {
  Attachment,
  AttachmentType,
  AttachmentUpdatePayload,
  ServiceRequestResponse,
} from "@coherehealth/core-platform-api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { differenceInMilliseconds } from "date-fns";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@mui/material/Grid";
import { formatDateStrAsEastern, useGetServiceRequestByIdWithFallback } from "@coherehealth/common";

import DownloadFile from "./FileDownload";
import ViewFile from "./FileView";
import { useAuthorized } from "authorization";

export interface UploadFile extends Partial<Attachment> {
  id: string;
  uploading?: boolean;
  serviceRequest?: Partial<ServiceRequestResponse>;
  attachmentGroupId?: string;
}

interface FileUploadProps {
  file: UploadFile;
  updateAttachment: (fileId: string, attachmentUpdatePayload: AttachmentUpdatePayload) => Promise<void>;
  updateLoading: boolean;
  deleteAttachment: MutateMethod<void | void[], string, void, any>;
  deleteLoading: boolean;
  downloadAttachmentPath: string;
  afterDelete: (f: UploadFile) => void;
  afterUpdate: (f: UploadFile) => void;
  canEditViewable: boolean;
  serviceRequestId: string;
  stage?: string;
  noDivider?: boolean;
  hideDownloadIcon?: boolean;
}

interface StyleProps {
  noDivider?: boolean;
}
const dividerStyles = makeStyles((theme) => ({
  tableRow: {
    padding: "0px",
    margin: "0px",
    height: "56px",
    "&:first-child": {
      "& td": {
        border: "none",
      },
    },
    "& td": {
      borderTop: ({ noDivider }: StyleProps) => (noDivider ? "none" : `1px ${theme.palette.divider} solid`),
      borderBottom: "none",
      height: "56px",
      margin: "0px",
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  noDivider: {
    borderBottom: "none",
    paddingBottom: "0px",
  },
  divider: {
    paddingBottom: "16px",
  },
  fileDiv: {
    width: "50%",
    fontWeight: 500,
  },
  fileName: {
    paddingBottom: theme.spacing(1),
  },
  fileType: {
    width: "30%",
  },
  practiceViewable: {
    width: "10%",
  },
  fileActions: {
    width: "10%",
  },
  noBorder: {
    borderBottom: "none",

    alignItems: "center",
  },
  tablePadding: {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  timeStampText: {
    color: colorsLight.font.light,
  },
}));

const TIME_ALLOTED_FOR_ATTACHMENT_DELETION = 300000;

export default function FileUploadItem({
  file,
  updateAttachment,
  updateLoading,
  deleteAttachment,
  deleteLoading,
  downloadAttachmentPath,
  afterDelete,
  afterUpdate,
  canEditViewable,
  serviceRequestId,
  stage,
  noDivider,
  hideDownloadIcon,
}: FileUploadProps) {
  const onChangePracticeViewable = async (event: ChangeEvent<HTMLInputElement>) => {
    const resp = await updateAttachment(file.id, { practiceViewable: event.target.checked }).catch((e) => null);
    if (resp) {
      afterUpdate(resp);
    }
  };

  const onDelete = async () => {
    if (file.id) {
      const resp = await deleteAttachment(file.id).catch((e) => "error");
      if (resp !== "error") {
        afterDelete({ id: file.id });
      }
      return resp;
    }
  };

  const canAlterPermissions = () => {
    if (
      !updateAttachment ||
      deleteLoading ||
      updateLoading ||
      (file.integrationStatus && file.integrationStatus !== "READY")
    ) {
      return false;
    }

    return true;
  };

  const { data: serviceRequest } = useGetServiceRequestByIdWithFallback({
    id: serviceRequestId,
  });

  const [attachmentIsNew, setAttachmentIsNew] = useState(
    differenceInMilliseconds(new Date(), parseDateFromISOString(file.dateCreated)) <
      TIME_ALLOTED_FOR_ATTACHMENT_DELETION
  );
  useEffect(() => {
    if (attachmentIsNew) {
      setTimeout(() => {
        setAttachmentIsNew(false);
      }, TIME_ALLOTED_FOR_ATTACHMENT_DELETION - differenceInMilliseconds(new Date(), parseDateFromISOString(file.dateCreated)));
    }
  }, [attachmentIsNew, file.dateCreated]);

  const isAttachmentPartOfCarePathJourney = downloadAttachmentPath.includes("carePathJourney");

  const canDeleteAttachmentsAfterDraft = useAuthorized("DELETE_ATTACHMENT_AFTER_DRAFT");

  const disableDeleteButton = () => {
    // if the deleteAttachment is not provided or any for the attachments operations are in progress
    if (!deleteAttachment || deleteLoading || updateLoading) {
      return true;
    }
    // Only ops team can delete previously added attachment or if the attachement is part of the cpj
    if (isAttachmentPartOfCarePathJourney || serviceRequest?.authStatus === "DRAFT" || canDeleteAttachmentsAfterDraft) {
      return false;
    }
    // backoffice user can only delete new added attachments before a certain period of time
    if (attachmentIsNew) {
      return false;
    }
    return true;
  };

  const styleClasses = dividerStyles({ noDivider });

  return (
    <TableRow className={styleClasses.tableRow}>
      <TableCell
        className={`${styleClasses.fileDiv} ${noDivider ? styleClasses.noDivider : styleClasses.divider} ${
          styleClasses.tablePadding
        }`}
      >
        <Grid container>
          <Grid item xs={12} className={styleClasses.fileName}>
            {file.name && file.name.length >= 50 ? file.name?.substr(0, 50) : file.name}
          </Grid>
          <Grid item xs={12}>
            <Caption className={styleClasses.timeStampText} data-public>
              Uploaded on {formatDateStrAsEastern(file.dateCreated)} by {file.createdByName}
            </Caption>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell
        className={`${styleClasses.fileType} ${noDivider ? styleClasses.noDivider : styleClasses.divider} ${
          styleClasses.tablePadding
        }`}
      >
        {Boolean(file.uploading) && (
          <UploadingDisplayContainer>
            <CircularProgress size={30} />
            <Body1>Uploading file...</Body1>
          </UploadingDisplayContainer>
        )}
      </TableCell>
      {Boolean(canEditViewable) && (
        <TableCell
          align="center"
          className={`${styleClasses.practiceViewable} ${noDivider ? styleClasses.noDivider : styleClasses.divider} ${
            styleClasses.tablePadding
          }`}
        >
          <Switch
            checked={file.practiceViewable}
            onChange={onChangePracticeViewable}
            disabled={!canAlterPermissions()}
            color="primary"
            inputProps={{ "aria-label": "Provider viewable" }}
          />
        </TableCell>
      )}
      <TableCell
        align="right"
        className={`${styleClasses.fileActions} ${noDivider ? styleClasses.noDivider : styleClasses.divider} ${
          styleClasses.tablePadding
        }`}
      >
        <TableCell align="center" className={`${styleClasses.noBorder} ${styleClasses.tablePadding}`} component="span">
          <ViewFile
            file={file}
            viewAttachmentPath={downloadAttachmentPath}
            disabled={deleteLoading || updateLoading}
            onDelete={onDelete}
            canDelete={!disableDeleteButton()}
          />
        </TableCell>
        {!hideDownloadIcon && (
          <TableCell
            align="center"
            className={`${styleClasses.noBorder} ${styleClasses.tablePadding}`}
            component="span"
          >
            <DownloadFile
              downloadAttachmentPath={downloadAttachmentPath}
              file={file}
              disabled={deleteLoading || updateLoading}
              stage={stage}
            />
          </TableCell>
        )}
        <TableCell align="center" className={`${styleClasses.noBorder} ${styleClasses.tablePadding}`} component="span">
          <Tooltip
            title={
              disableDeleteButton() && !deleteLoading && !updateLoading
                ? `You cannot delete files after submission has been ${StatusLabel[serviceRequest?.authStatus || ""]}`
                : ""
            }
          >
            <IconButton
              aria-label="Delete"
              onClick={() => {
                if (!disableDeleteButton()) {
                  onDelete();
                }
              }}
            >
              <DeleteIcon color={!disableDeleteButton() ? "action" : "disabled"} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableCell>
    </TableRow>
  );
}

export const attachmentTypes: AttachmentType[] = [
  "Current medication list",
  "Clinical note",
  "Diagnostic image",
  "Diagnostic image report",
  "Discharge plan",
  "History and physical",
  "Lab",
  "Physician order",
  "Progress note",
  "RAD documentation",
  "Treatment plan",
  "Therapy note",
  "Other",
];

const StatusLabel: Record<string, string> = {
  APPROVED: "Approved.",
  PARTIALLY_APPROVED: "Partially Approved.",
  PENDING: "Pended.",
  INTAKE: "Pended.",
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const UploadingDisplayContainer = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  "& div": {
    marginRight: theme.spacing(2),
  },
}));
