import { Coverage, Patient } from "@coherehealth/core-platform-api";
import {
  coverageEndsBeforeLastDateOfService,
  getCombinedCoverageRanges,
  getCoverageBasedOnDate,
  getPatientHealthPlanName,
} from "util/patientUtils";
import {
  ContinuationConfiguration,
  FormConfiguration,
} from "components/ServiceRequest/ConfigurableServiceRequestForm/serviceRequestFormConfiguration";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { isAfter, addDays } from "date-fns";

export const useCalculateDateSelectionMessageDisplay = (
  formContent: ServiceRequestFormContent,
  coverages: Coverage[],
  patient: Patient | null,
  formFieldConfigurations: FormConfiguration | ContinuationConfiguration,
  clearEndDate?: boolean
) => {
  const patientHealthPlanName = getPatientHealthPlanName(patient || undefined, formContent.expectedAdmissionDate) || "";
  const clinicalServices = formContent?.clinicalServices || [];
  const serviceDuration = Math.max(0, ...clinicalServices.map((i) => i?.defaultDuration || 0));
  const selectedEndDate = formContent.isInpatient
    ? formContent.expectedDischargeDate || formContent.endDate
    : formContent.endDate;

  const patientCoverageDates = getCombinedCoverageRanges(coverages);
  const coverageEndDates = patientCoverageDates.map((interval) => new Date(interval.end).getTime());
  const coverageEndDate = new Date(Math.max(...coverageEndDates));

  const selectedEndDateCovered = selectedEndDate
    ? !coverageEndsBeforeLastDateOfService(
        coverages,
        patientHealthPlanName,
        formContent.expectedAdmissionDate || formContent.startDate,
        selectedEndDate
      )
    : true;
  const serviceRecommendationCalculatedEndDate = addDays(
    formContent.expectedAdmissionDate || formContent.startDate,
    serviceDuration
  );

  const isEndDateCoverageBounded =
    getCoverageBasedOnDate(serviceRecommendationCalculatedEndDate, patient || undefined) === null;

  const datesWithinWindow =
    selectedEndDateCovered && selectedEndDate && !isAfter(selectedEndDate, serviceRecommendationCalculatedEndDate);
  const isDurationRecommendation =
    serviceDuration > 0 && formFieldConfigurations.blockUserIfExceedsRecommendedEndDate.fieldSpec === "REQUIRED";

  const isStartEndDateShown = formFieldConfigurations.startEndDate.fieldSpec !== "OMIT";
  // not show this when in DNS page and we are only using clearEndDate in DNS
  const showDurationRecommendationSuccessMessage =
    clearEndDate !== null
      ? false
      : selectedEndDate && !isEndDateCoverageBounded && !isDurationRecommendation && datesWithinWindow;
  const showPatientCoverageExpirationMessage = selectedEndDate && isEndDateCoverageBounded && selectedEndDateCovered;
  const showExceededDurationLimitMessageErrorState =
    selectedEndDate && isDurationRecommendation && !datesWithinWindow && isStartEndDateShown;
  return {
    coverageEndDate,
    isDurationRecommendation,
    showDurationRecommendationSuccessMessage,
    showPatientCoverageExpirationMessage,
    showExceededDurationLimitMessageErrorState,
    serviceDuration,
  };
};
