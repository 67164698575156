import {
  AuthStatus,
  AuthorizationResponse,
  ClinicalService,
  PatientStatus,
  ProcedureCode,
  ServiceRequestResponse,
  ServiceRequestSearchResponse,
  PatientStayDate,
} from "@coherehealth/core-platform-api";
import { isEmpty } from "lodash";
import maxDate from "date-fns/max";
import {
  compareISODates,
  formatDateToISODate,
  parseDateFromISOString,
  parseDateFromISOStringWithoutFallback,
  plusDays,
  removeTimeFromDate,
} from "@coherehealth/common";
import { getPendingAuthStatuses, isTerminalStatus } from "./serviceRequest";
import elementIsNonNull from "./elementIsNonNull";
import { differenceInCalendarDays, isValid } from "date-fns";
import { PatientStayDateRange } from "common/SharedServiceRequestFormComponents";
import { aggregateStayDateByCommonFields } from "../components/ServiceRequest/PatientStay/PatientStays";
import isEqual from "lodash/isEqual";

// Sorts authorization's serviceRequests by dateCreated, oldest to newest
export function getSortedServiceRequests(authorization?: AuthorizationResponse): ServiceRequestResponse[] {
  return (
    authorization?.serviceRequestsOnAuth?.sort((a, b) => {
      return Date.parse(a.dateCreated) - Date.parse(b.dateCreated);
    }) || []
  );
}

// Sorts latest approved auth SRs
export function getLatestApprovedSR(authorization?: AuthorizationResponse): ServiceRequestResponse | undefined {
  const sortedSRs = getSortedServiceRequests(authorization || undefined);
  let latestApprovedSR = undefined;
  // Iterate through the array in reverse order
  for (let i = sortedSRs.length - 1; i >= 0; i--) {
    const currentItem = sortedSRs[i];
    // Check if the status is 'approved'
    if (currentItem.authStatus === "APPROVED") {
      latestApprovedSR = currentItem;
      break; // Exit the loop once the latest approved SR is found
    }
  }
  return latestApprovedSR;
}

// Returns sorted SRs [rest..., latestSr]
export function getLatestServiceRequest(
  authorization?: AuthorizationResponse
): [ServiceRequestResponse[], ServiceRequestResponse | undefined] {
  if (authorization?.serviceRequestsOnAuth && authorization?.serviceRequestsOnAuth.length > 0) {
    const sorted = getSortedServiceRequests(authorization);
    if (sorted.length > 1) {
      return [sorted.slice(0, sorted.length - 1), sorted[sorted.length - 1]];
    } else if (sorted.length === 1) {
      return [[], sorted[0]];
    }
  }
  return [[], undefined];
}

// Gets the latest SR that is not a draft or voided or withdrawn (might not be continuable if not APPROVED or PENDING)
export function getLatestDeterminedServiceRequest(
  authorization?: AuthorizationResponse
): ServiceRequestResponse | undefined {
  if (authorization && authorization.serviceRequestsOnAuth && authorization.serviceRequestsOnAuth.length > 0) {
    const sorted = getSortedServiceRequests(authorization).reverse();
    for (const sr of sorted) {
      if (!(sr.authStatus === "DRAFT" || sr.authStatus === "VOIDED" || sr.authStatus === "WITHDRAWN")) {
        return sr;
      }
    }
  }
}

export function getLatestServiceRequestRequestingOonException(
  authorization?: AuthorizationResponse
): ServiceRequestResponse | undefined {
  if (authorization?.serviceRequestsOnAuth?.length) {
    const sorted = getSortedServiceRequests(authorization).reverse();
    return sorted.find((sr) => sr.wasOonExceptionRequested && sr.authStatus !== "DRAFT");
  }
}

export const getStartAndEndDate = (serviceRequest: ServiceRequestResponse, additionalDays: number = 0) => {
  const { startDate, endDate } = serviceRequest;
  return {
    startDate: parseDateFromISOString(startDate),
    endDate: !endDate
      ? plusDays(90 + additionalDays, parseDateFromISOString(startDate))
      : plusDays(additionalDays, parseDateFromISOString(endDate)),
  };
};
export const calculateStartEndDate = (authorization?: AuthorizationResponse | null, additionalDays = 0) => {
  if (!authorization) {
    return;
  }
  const [initialSR, continuationSR] = getInitialServiceRequest(authorization);
  if (!initialSR) {
    return;
  }
  if (continuationSR.length === 0) {
    return getStartAndEndDate(initialSR, 0);
  }
  const filteredCSR = continuationSR.filter(
    (csr) => csr.authStatus && !["WITHDRAWN", "VOIDED"].includes(csr.authStatus)
  );
  if (filteredCSR.length === 0) {
    return getStartAndEndDate(initialSR, additionalDays);
  } else if (filteredCSR.length === 1) {
    // in case only one continuation look at the initialSR startDate to determine minStartDate and initialSR endDate+1 to determine maxStartDate
    const startDate = getStartDate(initialSR, 0);
    const endDate = getEndDate(initialSR, additionalDays);
    return { startDate, endDate };
  } else {
    // in case of more than one continuation look at the initialSR startDate determine minStartDate and last before continuation's endDate+1 to determine maxStartDate
    const length = filteredCSR.length;
    const startDate = getStartDate(initialSR, 0);
    const endDate = getEndDate(filteredCSR[length - 2], additionalDays);
    return { startDate, endDate };
  }
};

const getEndDate = (serviceRequest: ServiceRequestResponse, additionalDays: number = 0): Date => {
  const { startDate, endDate } = serviceRequest;
  return !endDate
    ? plusDays(90 + additionalDays, parseDateFromISOString(startDate))
    : plusDays(additionalDays, parseDateFromISOString(endDate));
};

const getStartDate = (serviceRequest: ServiceRequestResponse, additionalDays: number = 0): Date => {
  const { startDate } = serviceRequest;
  return plusDays(additionalDays, startDate);
};

// Returns sorted SRs [initialSr, rest...]
export function getInitialServiceRequest(
  authorization: AuthorizationResponse
): [ServiceRequestResponse | undefined, ServiceRequestResponse[]] {
  if (authorization.serviceRequestsOnAuth && authorization.serviceRequestsOnAuth.length > 0) {
    const sorted = getSortedServiceRequests(authorization);
    if (sorted.length > 1) {
      return [sorted[0], sorted.slice(1)];
    } else if (sorted.length === 1) {
      return [sorted[0], []];
    }
  }
  return [undefined, []];
}

export function getClinicalServicesText(clinicalServices?: ServiceRequestResponse["clinicalServices"]) {
  if (clinicalServices && clinicalServices?.length > 0) {
    return clinicalServices
      .filter(elementIsNonNull<ClinicalService>)
      .map((cs) => cs.name)
      .join(", ");
  }
  return "";
}

export function getClinicalServicesIdsFromProcedures(procedures: ProcedureCode[] | undefined): string[] {
  if (procedures && procedures?.length > 0) {
    const pxWithGroupBy = procedures.filter((px) => {
      return px.groupBy !== undefined && px.groupBy === "ClinicalService" && px.groupId !== undefined;
    });
    if (pxWithGroupBy?.length > 0) {
      const filteredCsIds = pxWithGroupBy.map((px) => (px.groupId ? px.groupId : "")).filter((csId) => csId !== "");
      if (filteredCsIds?.length > 0) {
        return Array.from(new Set(filteredCsIds));
      }
    }
  }
  return [];
}

export const checkDateValidityForContinuations = (serviceRequest: ServiceRequestSearchResponse | undefined) => {
  const today = formatDateToISODate(new Date());
  if (serviceRequest?.startDate) {
    const { startDate } = serviceRequest;
    let { endDate } = serviceRequest;
    if (!endDate) {
      endDate = formatDateToISODate(plusDays(90, startDate));
    }
    const beforeEndDate = compareISODates(today, formatDateToISODate(plusDays(1, endDate)));
    return beforeEndDate !== undefined ? beforeEndDate >= 0 : false;
  }
  return false;
};
const APPROVED_PARTIALLYAPPROVED_ALLOWED_STATUSES = ["APPROVED", "PARTIALLY_APPROVED"];
const INPATIENT_CONTINUATION_ALLOWED_STATUSES = ["APPROVED", "PARTIALLY_APPROVED", "DENIED"];

const checkAuthStatusValidityForContinuations = (authStatus: AuthStatus, isInpatient: boolean) => {
  return isInpatient
    ? INPATIENT_CONTINUATION_ALLOWED_STATUSES.includes(authStatus)
    : APPROVED_PARTIALLYAPPROVED_ALLOWED_STATUSES.includes(authStatus);
};

export const isAuthorizationValidForContinuations = (
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse,
  latestSr?: ServiceRequestResponse | null,
  isInAuthBuilder?: boolean
): boolean => {
  return (
    isSRMaybeValidForContinuations(serviceRequest) &&
    latestSr?.authStatus !== "DRAFT" &&
    (latestSr?.patientStatus !== "DISCHARGED" ||
      (latestSr?.patientStatus === "DISCHARGED" &&
        APPROVED_PARTIALLYAPPROVED_ALLOWED_STATUSES.includes(latestSr?.authStatus ?? ""))) &&
    !isInAuthBuilder
  );
};

export const isSRMaybeValidForContinuations = (
  serviceRequest: ServiceRequestSearchResponse | ServiceRequestResponse
): boolean => {
  return (
    //ignore check date validity for inpatient requests
    (serviceRequest.encounterType === "INPATIENT" || checkDateValidityForContinuations(serviceRequest)) &&
    !!serviceRequest?.authStatus &&
    checkAuthStatusValidityForContinuations(serviceRequest.authStatus, serviceRequest.encounterType === "INPATIENT") &&
    !serviceRequest.authDecisionGroup
  );
};

export const requestTimingToString = (patientStatus?: PatientStatus) => {
  switch (patientStatus) {
    case "CURRENTLY_ADMITTED":
      return "Currently admitted";
    case "DISCHARGED":
      return "Discharged";
    case "NOT_YET_ADMITTED":
      return "Not yet admitted";
    default:
      return "--";
  }
};

//returns the number of days that are requested but not decisioned
export const calculateRequestedLengthOfStay = (
  serviceRequests: ServiceRequestResponse[] | undefined,
  currentServiceRequest?: ServiceRequestResponse
): number => {
  if (!serviceRequests || isEmpty(serviceRequests)) {
    return 0;
  }
  return serviceRequests
    .map((sr) => {
      // Update the current service request being edited to reflect the latest changes BEFORE the PATCH request.
      // This ensures the authorization object remains unchanged.
      sr = sr.id === currentServiceRequest?.id ? currentServiceRequest : sr;
      if (getPendingAuthStatuses().includes(sr.authStatus || "") || sr.authStatus === "DRAFT") {
        return sr?.patientStayDates?.length || 0;
      } else {
        return 0;
      }
    })
    .reduce((a, b) => a + b, 0);
};

//returns the number of days that have been decisioned
export const calculateDecisionedLengthOfStay = (
  serviceRequests: ServiceRequestResponse[] | undefined,
  currentServiceRequest?: ServiceRequestResponse
): number => {
  if (!serviceRequests || isEmpty(serviceRequests)) {
    return 0;
  }
  return serviceRequests
    .map((sr) => {
      if (isTerminalStatus(sr)) {
        // Update the current service request being edited to reflect the latest changes BEFORE the PATCH request.
        // This ensures the authorization object remains unchanged.
        sr = sr.id === currentServiceRequest?.id ? currentServiceRequest : sr;
        return (
          sr?.patientStayDates?.filter(
            (elem): elem is PatientStayDate => elem.reviewStatus !== "VOID" && elem.reviewStatus !== "AMENDED"
          ).length || 0
        );
      } else {
        return 0;
      }
    })
    .reduce((a, b) => a + b, 0);
};

export interface DischargeDateErrorObj {
  fromDateValid: boolean;
  toDateValid: boolean;
  gapRangeError: boolean;
  rowIndex: number;
  isRowInValid: boolean;
}

type DischargeDateErrorObjWithOriginalIndex = DischargeDateErrorObj & { originalIndex: number };

function dateIsWithinPatientStayRange(dateStr: string | undefined, range: PatientStayDateRange) {
  const date = parseDateFromISOString(dateStr);

  return (
    date && range.rangeStartDate && date >= range.rangeStartDate && range.rangeEndDate && date <= range.rangeEndDate
  );
}

const unifyPatientStayDateRanges = (existingDates: PatientStayDate[], newDateRanges: PatientStayDateRange[]) => {
  if (!existingDates.length) {
    return newDateRanges;
  }

  // exclude dates that are within a requested range, as we may be editing those previously requested dates
  const existingDatesNonOverlapping = existingDates.filter(
    (existingDate) => !newDateRanges.some((range) => dateIsWithinPatientStayRange(existingDate?.date, range))
  );

  return [...aggregateStayDateByCommonFields(existingDatesNonOverlapping), ...(newDateRanges || [])].sort(
    (a: PatientStayDateRange, b: PatientStayDateRange) =>
      a.rangeStartDate && b.rangeStartDate && a.rangeStartDate < b.rangeStartDate ? -1 : 1
  );
};

export const validatePatientStayRange = (
  newPatientStayDateRanges: PatientStayDateRange[],
  authorization?: Pick<AuthorizationResponse, "patientStayDates"> | null
): DischargeDateErrorObj[] => {
  let patientStayDateRanges = newPatientStayDateRanges;
  if (authorization?.patientStayDates) {
    patientStayDateRanges = unifyPatientStayDateRanges(authorization.patientStayDates, newPatientStayDateRanges);
  }

  let errorsList = new Array<DischargeDateErrorObjWithOriginalIndex>();
  for (let i = 0; i < patientStayDateRanges.length; i++) {
    const rangeStartDate = removeTimeFromDate(patientStayDateRanges[i].rangeStartDate ?? new Date());
    const rangeEndDate = removeTimeFromDate(patientStayDateRanges[i].rangeEndDate ?? new Date());

    const isFromDateValid = isValid(rangeStartDate);
    const isEndDateValid = isValid(rangeEndDate);
    const isToEndDateValid =
      (isEndDateValid && rangeStartDate && rangeEndDate && rangeStartDate?.getTime() <= rangeEndDate?.getTime()) ||
      false;

    // Get the index of this range in the "newPatientStayDateRanges" list, if it exists in there
    const originalIndex = newPatientStayDateRanges.findIndex((newPatientStayDateRange) =>
      isEqual(newPatientStayDateRange, patientStayDateRanges[i])
    );

    const errorObj: DischargeDateErrorObj & { originalIndex: number } = {
      rowIndex: i,
      originalIndex: originalIndex,
      fromDateValid: isFromDateValid,
      toDateValid: isToEndDateValid,
      gapRangeError: false,
      isRowInValid: false,
    };

    if (i > 0) {
      const prevStartDate = patientStayDateRanges[i - 1].rangeStartDate ?? 0;
      const prevEndDate = patientStayDateRanges[i - 1].rangeEndDate ?? 0;
      //TODO: this is a temporary fix to handle the case where the first range is pending
      // Current pending range is the first range that is pending
      const currentPending = patientStayDateRanges.filter((range) => range.reviewStatus === "PENDING")[0];
      const currentStartDate =
        i > 2 ? patientStayDateRanges[i].rangeStartDate ?? 0 : currentPending?.rangeStartDate ?? 0;
      const currentEndDate = i > 2 ? patientStayDateRanges[i].rangeEndDate ?? 0 : currentPending?.rangeEndDate ?? 0;

      const doesRangeNotOverlap = prevEndDate < currentStartDate && currentEndDate < prevStartDate;
      const isOutsidePreviousRange = currentStartDate > prevEndDate;

      if (doesRangeNotOverlap || isOutsidePreviousRange) {
        const diffDays = differenceInCalendarDays(currentStartDate, prevEndDate);
        if (diffDays !== 1) {
          errorObj.gapRangeError = true;
          errorsList[i - 1].gapRangeError = true;

          errorObj.fromDateValid = false;
          errorsList[i - 1].toDateValid = false;
        }
      }
    }

    errorsList.push(errorObj);
  }

  errorsList.forEach((errorObj) => {
    if (!errorObj.fromDateValid || !errorObj?.toDateValid || errorObj?.gapRangeError) {
      errorObj.isRowInValid = true;
    }
  });

  // We will only log errors for "new" date ranges
  return errorsList
    .filter(({ originalIndex }) => originalIndex > -1)
    .map(({ originalIndex, ...error }): DischargeDateErrorObj => ({ ...error, rowIndex: originalIndex }));
};

export function getDateForDischargeBasedOnPreviousRanges({
  decisionedStayDates,
  currentStayDateRanges,
}: {
  decisionedStayDates?: PatientStayDate[];
  currentStayDateRanges?: PatientStayDateRange[];
}) {
  const decisionedDates = decisionedStayDates
    ?.map(({ date }) => parseDateFromISOStringWithoutFallback(date))
    .filter((date): date is Date => !!date);

  let lastDecisionedDate: Date | undefined;
  if (decisionedDates?.length) {
    lastDecisionedDate = maxDate(decisionedDates);
  }

  if (currentStayDateRanges) {
    lastDecisionedDate = currentStayDateRanges.reduce((prevLastDecisionedDate, currRange) => {
      if (currRange.rangeEndDate && prevLastDecisionedDate && currRange.rangeEndDate > prevLastDecisionedDate) {
        return currRange.rangeEndDate;
      } else {
        return prevLastDecisionedDate;
      }
    }, lastDecisionedDate);
  }

  if (lastDecisionedDate && isValid(lastDecisionedDate)) {
    return plusDays(1, lastDecisionedDate);
  }

  return undefined;
}
