import { colorsLight, H5, isTargetForActionAmongOptions, TargetFieldLabel } from "@coherehealth/common";
import {
  ServiceRequestResponse,
  RecommendChangeRuleAction,
  RuleActions,
  PlaceOfService,
  FacilityCategory,
  AuthBuilderWorkflowStep,
  PossibleAttachmentNudgeReasons,
} from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import ImpressionTracking from "common/ImpressionTracking";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { isRecommendChangeAction } from "util/rule";
import {
  convertRequestFieldsToTrackingField,
  activitySnapshotFromServiceRequest,
  activityContextFromServiceRequest,
} from "util/userActivityTracker";
import { UploadFile } from "components/AddAttachments/FileUploadItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import NoItemsToDisplay from "common/NoItemsToDisplay";
import { ReviewNudgesCard } from "./ReviewNudgesCard";
import { ServiceRequestFormContent } from "components/ServiceRequest";

interface Props {
  serviceRequests: ServiceRequestResponse[];
  dedicatedNudgeIds: string[];
  currentDedicatedNudgeTargetForAction: TargetFieldLabel | undefined;
  rulesActionsData: RuleActions | null;
  setCanContinueToReviewPage: (b: boolean) => void;
  placeOfServiceToUpdateTo: PlaceOfService | undefined;
  setPlaceOfServiceToUpdateTo: Dispatch<SetStateAction<PlaceOfService | undefined>>;
  setCareTypeToUpdateTo: Dispatch<SetStateAction<FacilityCategory | undefined>>;
  dedicatedNudgeServiceRequestId: string | undefined;
  setDedicatedNudgeAccepted: (b: boolean) => void;
  canContinueToReviewPage: boolean;
  workflowId: string;
  setHasRuleRuns: React.Dispatch<React.SetStateAction<boolean>>;
  loadingActions?: boolean;
  setDedicatedNudgeServiceRequestId: (s: string) => void;
  acceptedDedicatedNudges?: string[] | undefined;
  setAcceptedDedicatedNudges?: Dispatch<SetStateAction<string[] | undefined>>;
  declinedNudges?: string[] | undefined;
  setDeclinedNudges?: (declinedNudges: string[] | undefined) => void;
  formContent: ServiceRequestFormContent;
  setFormContent: Dispatch<SetStateAction<ServiceRequestFormContent>>;
  attemptedSubmit: boolean;
  workflowStep?: AuthBuilderWorkflowStep;
  patientId?: string;
  possibleAttachmentNudgeReasons?: PossibleAttachmentNudgeReasons[];
  currentRuleNudge?: RecommendChangeRuleAction;
  setCurrentRuleNudge?: Dispatch<SetStateAction<RecommendChangeRuleAction | undefined>>;
  continueDisabledForAddAttachmentsDNS?: boolean;
  setContinueDisabledForAddAttachmentsDNS?: Dispatch<SetStateAction<boolean | undefined>>;
  setUpdatedClinicalServices: Dispatch<SetStateAction<string[] | undefined>>;
  updatedClinicalServices?: string[] | undefined;
  selectedCarePathId: string | undefined;
}

export default function ReviewNudgeContainer({
  serviceRequests,
  rulesActionsData,
  dedicatedNudgeIds,
  currentDedicatedNudgeTargetForAction,
  setCanContinueToReviewPage,
  placeOfServiceToUpdateTo,
  setPlaceOfServiceToUpdateTo,
  setCareTypeToUpdateTo,
  dedicatedNudgeServiceRequestId,
  setDedicatedNudgeAccepted,
  canContinueToReviewPage,
  workflowId,
  setHasRuleRuns,
  loadingActions,
  setDedicatedNudgeServiceRequestId,
  acceptedDedicatedNudges,
  setAcceptedDedicatedNudges,
  declinedNudges,
  setDeclinedNudges,
  formContent,
  setFormContent,
  attemptedSubmit,
  workflowStep,
  patientId,
  possibleAttachmentNudgeReasons,
  currentRuleNudge,
  setCurrentRuleNudge,
  continueDisabledForAddAttachmentsDNS,
  setContinueDisabledForAddAttachmentsDNS,
  setUpdatedClinicalServices,
  updatedClinicalServices,
  selectedCarePathId,
}: Props) {
  const [files, setFiles] = useState<UploadFile[]>([]);
  const classes = useStyles();
  const [serviceRequestNudgedOn, setServiceNuddgedOn] = useState<ServiceRequestResponse>(serviceRequests[0]);
  const [showLoadingStateOnPageLoad, setShowLoadingStateOnPageLoad] = useState<boolean>(true);

  const updateRuleNudges = rulesActionsData
    ?.filter(isRecommendChangeAction)
    .filter((r) => dedicatedNudgeIds.includes(r.ruleId || ""));
  const unitReductionRuleNudges = updateRuleNudges?.filter((rule) => rule.onAcceptAttribute === "units");
  const nonUnitReductionRuleNudges = updateRuleNudges?.filter((rule) => !unitReductionRuleNudges?.includes(rule));

  /*Sometimes a user will land on this page after saving a draft
  if that happens we want to set the dedicatedNudgeId and the service request the nudge is on
  A loading state was added to the page to display to the user that recommendations are loading
  Loading state should only be shown on page load
  A no recommendations to display state was added on the off change a user accepted the nudge went
  to the review page and exited the workflow (not using save and exit) and they could end up back on 
  this nudge page
  Rules are being re-run if the user accepts the nudge because the service request has changed
  Some state was added to make sure the view does not change when the rules are being re-run
  when the user hits continue to go the review screen*/
  useEffect(() => {
    if (!loadingActions) {
      if (dedicatedNudgeServiceRequestId === undefined && serviceRequests.length > 0) {
        //came back to a draft service request
        setDedicatedNudgeServiceRequestId(serviceRequests[0].id || "");
      }
      if (!!dedicatedNudgeIds.length) {
        setShowLoadingStateOnPageLoad(false);
      }
      const updateRuleNudges = rulesActionsData
        ?.filter(isRecommendChangeAction)
        .filter((r) => dedicatedNudgeIds.includes(r.ruleId || ""));
      //only update the ruleNudge if ruleActionsData contains rule we are looking for
      if (updateRuleNudges !== undefined && !!updateRuleNudges.length) {
        if (currentDedicatedNudgeTargetForAction && setCurrentRuleNudge) {
          setCurrentRuleNudge(
            updateRuleNudges.find((action) =>
              isTargetForActionAmongOptions([currentDedicatedNudgeTargetForAction], action)
            )
          );
        }
      }
      setHasRuleRuns(true);
      setServiceNuddgedOn(serviceRequests.find((sr) => sr.id === dedicatedNudgeServiceRequestId) || serviceRequests[0]);
    }
  }, [
    currentDedicatedNudgeTargetForAction,
    dedicatedNudgeIds,
    dedicatedNudgeServiceRequestId,
    loadingActions,
    rulesActionsData,
    serviceRequests,
    setDedicatedNudgeServiceRequestId,
    setHasRuleRuns,
    updateRuleNudges,
    setCurrentRuleNudge,
  ]);

  return (
    <>
      {(loadingActions || rulesActionsData == null) && showLoadingStateOnPageLoad ? (
        <ReviewNudgesLoading />
      ) : (
        <>
          {!!currentRuleNudge?.ruleId &&
            unitReductionRuleNudges &&
            currentDedicatedNudgeTargetForAction === "Service quantity change" &&
            unitReductionRuleNudges?.map((action) => (
              <Grid
                container
                justifyContent={"center"}
                spacing={4}
                className={classes.mainComponent}
                key={JSON.stringify([action?.groupId])}
              >
                <ImpressionTracking
                  event="FIELD_RECOMMENDED_CHANGE"
                  stage="DEDICATED_NUDGE_PAGE"
                  field={convertRequestFieldsToTrackingField(currentRuleNudge?.onAcceptAttribute || "")}
                  beforeSnapshot={activitySnapshotFromServiceRequest(serviceRequestNudgedOn)}
                  activityContext={{
                    ...activityContextFromServiceRequest(serviceRequestNudgedOn),
                    ruleId: currentRuleNudge?.ruleId || "",
                    actionId: currentRuleNudge?.actionId || "",
                  }}
                >
                  <ReviewNudgesCard
                    ruleNudge={action}
                    serviceRequest={{ ...serviceRequestNudgedOn }}
                    setCanContinueToReviewPage={setCanContinueToReviewPage}
                    setPlaceOfServiceToUpdateTo={setPlaceOfServiceToUpdateTo}
                    placeOfServiceToUpdateTo={placeOfServiceToUpdateTo}
                    setCareTypeToUpdateTo={setCareTypeToUpdateTo}
                    setDedicatedNudgeAccepted={setDedicatedNudgeAccepted}
                    canContinueToReviewPage={canContinueToReviewPage}
                    workflowId={workflowId}
                    files={files}
                    setFiles={setFiles}
                    currentDedicatedNudgeTargetForAction={currentDedicatedNudgeTargetForAction}
                    acceptedDedicatedNudges={acceptedDedicatedNudges}
                    setAcceptedDedicatedNudges={setAcceptedDedicatedNudges}
                    updateRuleNudges={unitReductionRuleNudges}
                    declinedNudges={declinedNudges}
                    setDeclinedNudges={setDeclinedNudges}
                    formContent={formContent}
                    setFormContent={setFormContent}
                    attemptedSubmit={attemptedSubmit}
                    workflowStep={workflowStep}
                    patientId={patientId || ""}
                    missingAttachmentsReason={possibleAttachmentNudgeReasons?.[0]?.reason}
                    missingAttachmentsReasonText={possibleAttachmentNudgeReasons?.[0]?.reasonText}
                    continueDisabledForAddAttachmentsDNS={continueDisabledForAddAttachmentsDNS}
                    setContinueDisabledForAddAttachmentsDNS={setContinueDisabledForAddAttachmentsDNS}
                    setUpdatedClinicalServices={setUpdatedClinicalServices}
                    updatedClinicalServices={updatedClinicalServices}
                    selectedCarePathId={selectedCarePathId}
                  />
                </ImpressionTracking>
              </Grid>
            ))}
          {!!currentRuleNudge?.ruleId &&
            nonUnitReductionRuleNudges &&
            currentDedicatedNudgeTargetForAction !== "Service quantity change" && (
              <Grid container justifyContent={"center"} spacing={4} className={classes.mainComponent}>
                <ImpressionTracking
                  event="FIELD_RECOMMENDED_CHANGE"
                  stage="DEDICATED_NUDGE_PAGE"
                  field={convertRequestFieldsToTrackingField(currentRuleNudge?.onAcceptAttribute || "")}
                  beforeSnapshot={activitySnapshotFromServiceRequest(serviceRequestNudgedOn)}
                  activityContext={{
                    ...activityContextFromServiceRequest(serviceRequestNudgedOn),
                    ruleId: currentRuleNudge?.ruleId || "",
                    actionId: currentRuleNudge?.actionId || "",
                  }}
                >
                  <ReviewNudgesCard
                    ruleNudge={currentRuleNudge}
                    serviceRequest={{ ...serviceRequestNudgedOn }}
                    setCanContinueToReviewPage={setCanContinueToReviewPage}
                    setPlaceOfServiceToUpdateTo={setPlaceOfServiceToUpdateTo}
                    placeOfServiceToUpdateTo={placeOfServiceToUpdateTo}
                    setCareTypeToUpdateTo={setCareTypeToUpdateTo}
                    setDedicatedNudgeAccepted={setDedicatedNudgeAccepted}
                    canContinueToReviewPage={canContinueToReviewPage}
                    workflowId={workflowId}
                    files={files}
                    setFiles={setFiles}
                    currentDedicatedNudgeTargetForAction={currentDedicatedNudgeTargetForAction}
                    acceptedDedicatedNudges={acceptedDedicatedNudges}
                    setAcceptedDedicatedNudges={setAcceptedDedicatedNudges}
                    declinedNudges={declinedNudges}
                    setDeclinedNudges={setDeclinedNudges}
                    formContent={formContent}
                    setFormContent={setFormContent}
                    attemptedSubmit={attemptedSubmit}
                    workflowStep={workflowStep}
                    patientId={patientId || ""}
                    missingAttachmentsReason={possibleAttachmentNudgeReasons?.[0]?.reason}
                    missingAttachmentsReasonText={possibleAttachmentNudgeReasons?.[0]?.reasonText}
                    continueDisabledForAddAttachmentsDNS={continueDisabledForAddAttachmentsDNS}
                    setContinueDisabledForAddAttachmentsDNS={setContinueDisabledForAddAttachmentsDNS}
                    setUpdatedClinicalServices={setUpdatedClinicalServices}
                    updatedClinicalServices={updatedClinicalServices}
                    selectedCarePathId={selectedCarePathId}
                  />
                </ImpressionTracking>
              </Grid>
            )}
          {!unitReductionRuleNudges && !nonUnitReductionRuleNudges && (
            <NoItemsToDisplay
              mainText="No recommendations to review at this time."
              subText="Keep up the great work by continuing to the next step."
            />
          )}
        </>
      )}
    </>
  );
}

const ReviewNudgesLoading = () => {
  const classes = useStyles();
  return (
    <>
      <CircularProgress className={classes.loadingSpinner} size={100} />
      <H5 className={classes.centeredText}>Loading recommendations for review</H5>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mainComponent: {
    paddingTop: "40px",
    "&.MuiPaper-elevation1": {
      boxShadow: "0px 2px 2px rgba(207, 226, 231, 0.8)",
    },
    marginTop: 0,
  },
  optionContainer: {
    paddingTop: "32px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
  },
  selectionTextContainer: {
    minHeight: "24px",
    alignItems: "center",
  },
  radioButton: {
    padding: "0px",
  },
  button: {
    "&.highlightOption": {
      border: `1px solid #039EC3`,
    },
    width: "50%",
    borderRadius: "8px",
    border: `1px solid #E5E5E5`,
    cursor: "pointer",
  },
  buttonAndLabel: {
    display: "inline-flex",
    verticalAlign: "top",
    paddingLeft: "16px",
    paddingTop: "15px",
    paddingBottom: "12px",
  },

  title: {
    color: colorsLight.font.primary,
    paddingBottom: "12px",
  },
  valueText: {
    display: "inline-block",
    wordWrap: "break-word",
    fontFamily: "Gilroy-Light",
    paddingTop: "2px",
  },
  grid: {
    wordWrap: "break-word",
  },
  radioButtons: {
    flexDirection: "row",
    width: "100%",
    flexWrap: "nowrap",
  },
  buttonLabel: {
    "&.MuiFormControlLabel-root": {
      marginLeft: "0px",
      marginRight: "0px",
      verticalAlign: "top",
      alignItems: "start",
      paddingTop: theme.spacing(1),
    },
    "&.highlightOption": {
      border: `1px solid #039EC3`,
    },
    borderRadius: theme.spacing(1),
    border: `1px solid #E5E5E5`,
    paddingLeft: "12px",
    width: "100%",
  },
  component: {
    minHeight: theme.spacing(4),
    alignItems: "center",
    display: "flex",
  },
  container: {
    minHeight: theme.spacing(4),
  },
  captionContainer: {
    paddingBottom: theme.spacing(2),
    display: "flex",
    paddingLeft: theme.spacing(2),
  },
  caption: {
    "&.acceptNudgeBanner": {
      backgroundColor: colorsLight.success.light,
      color: colorsLight.success.dark,
    },
    "&.rejectNudgeBanner": {
      backgroundColor: colorsLight.warning.light,
      color: colorsLight.warning.dark,
    },
    paddingTop: "5px",
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    borderRadius: theme.spacing(0.5),
  },
  optionText: {
    paddingBottom: theme.spacing(2),
  },
  selectionText: {
    color: colorsLight.font.secondary,
    paddingLeft: "12px",
    alignItems: "center",
    height: "24px",
    paddingTop: "2px",
  },
  message: {
    color: colorsLight.font.secondary,
    paddingBottom: "23px",
    paddingTop: "3px",
  },
  loadingSpinner: {
    display: "block",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  centeredText: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));
