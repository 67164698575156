import { PrimaryButton, SecondaryButton, TertiaryButton, useFeature } from "@coherehealth/common";
import { useReviewControlPanelStyles } from "./reviewControlPanelStyles";

export type ReviewControlPanelProps = {
  handleDiscardAndExit?: () => void;
  discarding?: boolean;
  discardAndExitDisabled: boolean;
  handleSaveAndExit?: () => void;
  saveAndExitLoading: boolean;
  saveAndExitDisabled: boolean;
  handleFinishReview: () => void;
  finishButtonDisabled: boolean;
  canSaveDraft?: boolean;
  currentlySavingDraft?: boolean;
  saveForLaterDisabled?: boolean;
  handleHeadingClick?: () => void;
  classes?: {
    finishButton?: string;
    discardAndExitButton?: string;
    saveAndExitButton?: string;
    saveReviewButton?: string;
    discardReviewButton?: string;
  };
  reviewType?: string;
  submitting?: boolean;
  isAuditReview?: boolean;
};

export const ReviewControlPanel = ({
  handleDiscardAndExit,
  handleSaveAndExit,
  handleFinishReview,
  discarding,
  discardAndExitDisabled,
  reviewType,
  saveAndExitLoading,
  saveAndExitDisabled,
  finishButtonDisabled,
  canSaveDraft,
  currentlySavingDraft,
  saveForLaterDisabled,
  handleHeadingClick,
  submitting,
  isAuditReview,
}: ReviewControlPanelProps) => {
  const saveReviews = useFeature("saveReviews");
  const classes = useReviewControlPanelStyles({
    saveReviews,
  });
  const buttonLabels = {
    discardAndExit: "Discard and exit",
    saveAndExit: "Save and exit",
    finishReview: isAuditReview ? "Finish audit" : "Finish review",
    saveForLater: "Save for later",
    discardLabel: "Discard",
  };
  return (
    <>
      {saveReviews ? (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TertiaryButton
              onClick={handleDiscardAndExit}
              loading={discarding}
              disabled={discardAndExitDisabled}
              className={classes?.discardAndExitButton}
              data-testid="controls-discard-and-exit"
            >
              {buttonLabels.discardAndExit}
            </TertiaryButton>
            <TertiaryButton
              data-public
              onClick={handleSaveAndExit}
              loading={saveAndExitLoading}
              disabled={saveAndExitDisabled}
              className={classes?.saveAndExitButton}
              data-testid="controls-save-and-exit"
            >
              {buttonLabels.saveAndExit}
            </TertiaryButton>
          </div>
          <PrimaryButton
            onClick={handleFinishReview}
            disabled={finishButtonDisabled}
            disableRipple
            className={classes?.finishButton}
            loading={submitting}
            data-testid="controls-finish-review-0"
          >
            {buttonLabels.finishReview}
          </PrimaryButton>
        </>
      ) : (
        <>
          {canSaveDraft ? (
            <SecondaryButton
              warning
              onClick={handleHeadingClick}
              loading={currentlySavingDraft}
              disabled={saveForLaterDisabled}
              className={classes?.saveAndExitButton}
              data-testid="controls-save-for-later"
            >
              {buttonLabels.saveForLater}
            </SecondaryButton>
          ) : (
            <TertiaryButton
              loading={discarding}
              disabled={saveForLaterDisabled}
              onClick={handleDiscardAndExit}
              className={classes?.discardReviewButton}
              data-testid="controls-discard"
            >
              {buttonLabels.discardLabel}
            </TertiaryButton>
          )}
          <PrimaryButton
            onClick={handleFinishReview}
            disableRipple
            disabled={finishButtonDisabled}
            className={classes?.finishButton}
            loading={submitting}
            data-testid="controls-finish-review-1"
          >
            {buttonLabels.finishReview}
          </PrimaryButton>
        </>
      )}
    </>
  );
};
