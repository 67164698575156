import { H6, RadioGroup } from "@coherehealth/common";
import { PalCheckFormSectionProps } from "./HealthPlanPalCheckFormSectionConfigs";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { makeStyles, styled } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

export default function HomeHealthSection({
  priorAuthRequirements,
  setPriorAuthRequirements,
  resetPalResults,
}: PalCheckFormSectionProps) {
  const classes = useStyles();

  const [initialSelection, setInitialSelection] = useState<boolean>();

  // Fetch default selection from local storage
  useEffect(() => {
    const serializedHomeHealth = localStorage.getItem("lastHomeHealthSelection");
    // Without tampering, serializedHomeHealth = "undefined" | "true" | "false"
    // With tampering, it could be anything (so check explicitly for good values before using)
    if (
      serializedHomeHealth !== null &&
      serializedHomeHealth !== "undefined" &&
      (serializedHomeHealth === "true" || serializedHomeHealth === "false")
    ) {
      setInitialSelection(JSON.parse(serializedHomeHealth));
      if (priorAuthRequirements.homeHealth === undefined) {
        setPriorAuthRequirements({
          ...priorAuthRequirements,
          homeHealth: JSON.parse(serializedHomeHealth),
        });
      }
    }
  }, [priorAuthRequirements, setPriorAuthRequirements]);

  const value =
    priorAuthRequirements.homeHealth === undefined
      ? initialSelection
        ? "YES"
        : "NO"
      : priorAuthRequirements.homeHealth
      ? "YES"
      : "NO";
  return (
    <>
      <CustomSectionHeader>Is this request for Home Health? (excluding Shift Care)</CustomSectionHeader>
      <RadioGroup
        row
        className={classes.homeHealthSelect}
        value={value}
        options={homeHealthOptions}
        onChange={(type) => {
          const isHomeHealth = type === "YES";
          setPriorAuthRequirements({
            ...priorAuthRequirements,
            homeHealth: isHomeHealth,
            desiredProcedureCodes: [],
          });
          resetPalResults();
        }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  homeHealthSelect: {
    color: theme.palette.text.secondary,
  },
}));

type HomeHealthOptions = {
  id: "YES" | "NO";
  label: string;
};

const homeHealthOptions: HomeHealthOptions[] = [
  { id: "NO", label: "No" },
  { id: "YES", label: "Yes" },
];

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomSectionHeader = styled(H6)(({ theme }) => ({
  marginBottom: "7px",
}));
