import { Routes as ReactRouterRoutes, Route, Navigate } from "react-router-dom";

import "App.css";
import config from "api/config";
import { Security, SecureRoute } from "components/Security";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import AuthBuilderPage from "components/AuthBuilder";
import DashboardPage from "components/DashboardPage";
import { HomePage } from "components/HomePage";
import { environmentAbbreviation, NotFoundPage, TrackUsage } from "@coherehealth/common";
import AuthorizationPatientSummaryPaginationPage from "components/PatientSummary/AuthorizationPatientSummary/AuthorizationPatientSummaryPaginationPage";
import FeatureFlagPage from "components/FeatureFlagPage";
import ServiceRequestPage from "components/ServiceRequest/ServiceRequestPage";
import TestErrorPage from "components/TestErrorPage";
import { MyOrganizationPage } from "components/ProviderOrganization";
import routes from "routes";
import OrganizationManagementPage from "components/ProviderOrganization/OrganizationManagementPage";
import { ErrorBoundary } from "@sentry/react";
import CarewebqiRedirectPage from "components/ServiceRequest/CarewebqiRedirectPage";
import FaxAttachmentPage from "components/DocumentViewer/FaxAttachment/FaxAttachmentPage";
import ErrorPage from "components/ErrorPage";
import MonitoringLoginCallback from "MonitoringLoginCallback";
import UnauthorizedCheckStatusPage from "components/UnauthorizedCheckStatus";
import FaxServiceRequestPage from "components/FaxServiceRequest";
import ClaimServiceRequestPage from "components/ClaimServiceRequest";
import WithdrawAndReplacePage from "components/WithdrawAndReplacePage";
import { OpsUserManagementPage } from "components/OpsUserManagementPage";
import { IntegrationManagementPage } from "components/IntegrationManagement";
import { PatientManagementPage } from "components/PatientManagement";
import {
  OrganizationOnboardingPage,
  UnauthenticatedOrganizationOnboardingPage,
} from "components/OrganizationOnboardingPage";

import { ClinicalReviewPageWrapper as ClinicalReviewPageNextWrapper } from "./components/ClinicalReview/next/ClinicalReviewPage";
import AssessmentTestPage from "./testUtils/ClinicalAssessmentTesting";
import DenialReviewPage from "components/ClinicalReview/Denials/DenialReviewPage";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import ConfigurationManagementPage from "components/ConfigurationManagement";
import SmartOnFhirLaunchPage from "components/SmartOnFhir/SmartOnFhirLaunchPage";
import SmartOnFhirLandingPage from "components/SmartOnFhir/SmartOnFhirLandingPage";
import SmartOnFhirSecurityProvider from "components/SmartOnFhir/SmartOnFhirSecurityProvider";
import { IntegrationObservabilityPage } from "components/IntegrationObservability";
import SmartOnFhirServiceRequestPageWrapper from "./components/SmartOnFhir/SmartOnFhirServiceRequestPageWrapper";
import AdhocLetterPage from "components/AdhocLetter/AdhocLetterPage";
import AddNewPatientFormPage from "components/AuthBuilder/FillFormsAddNewPatient";
import EditPatientCoveragesPage from "components/PatientManagement/EditPatientCoveragesPage";
import FaxAuditPage from "components/ClinicalReview/FaxAudit/FaxAuditPage";
import SmartOnFhirNotFoundPage from "components/SmartOnFhir/SmartOnFhirNotFoundPage";
import FaxAuthBuilderPage from "components/DocumentViewer/FaxAttachment/FaxAuthBuilderPage/FaxAuthBuilderPage";
import FaxEditServiceRequestPage from "components/DocumentViewer/FaxAttachment/FaxAuthBuilderPage/FaxEditServiceRequestPage";
import ReferralsDashboard from "./components/ReferralManagement/Dashboard";
import ReferralRequestPatientSummaryPaginationPage from "components/ReferralManagement/PatientSummary/ReferralRequestPatientSummaryPaginationPage";
import ReferralRequestPage from "components/ReferralManagement/RequestBuilder";
import FaxReferralsBuilderPage from "components/DocumentViewer/FaxAttachment/FaxReferralsBuilderPage/FaxReferralsBuilderPage";

const SentryRoutes = withSentryReactRouterV6Routing(ReactRouterRoutes);

const isStagingEnvironment = environmentAbbreviation() === "staging";

const oktaAuth = new OktaAuth({
  issuer: `${config.AUTH_API_URL}oauth2/default`,
  clientId: config.AUTH_CLIENT_ID,
  redirectUri: window.location.origin + "/implicit/callback",
  // Okta now recommends using "passive autorenew", eg renewing async when tokens are needed rather than trying to keep them up-to-date in a service.
  // https://github.com/okta/okta-auth-js/blob/64a548b9f0037887d1bf8908a76bf9f3848ee0d6/docs/autoRenew-notice.md
  tokenManager: {
    autoRenew: false,
    // Otherwise, on page load, old access tokens are deleted and the user is forced to log in again, even if session is not idle in okta
    autoRemove: false,
  },
  services: {
    autoRenew: false,
    autoRemove: false,
  },
  pkce: true,
});

function App() {
  return (
    <ErrorBoundary showDialog fallback={<ErrorPage />}>
      <Security
        restoreOriginalUri={async (_oktaAuth, originalUri) => {
          window.location.assign(toRelativeUrl(originalUri, window.location.origin));
        }}
        oktaAuth={oktaAuth}
      >
        <TrackUsage application="AUTH" baseUrl={config.QM_SERVICE_API_URL} />
        <AppWithSecurity />
      </Security>
    </ErrorBoundary>
  );
}
const ViewOnlyReview = () => {
  return <ClinicalReviewPageNextWrapper viewOnly />;
};

const NewOutReachPage = () => {
  return <ClinicalReviewPageNextWrapper reviewPageVersion="NewOutReachPage" viewOnly={false} />;
};

const LogOutreachPage = () => {
  return <ClinicalReviewPageNextWrapper reviewPageVersion="LogOutreachPage" viewOnly={false} />;
};

const NewNotePage = () => {
  return <ClinicalReviewPageNextWrapper reviewPageVersion="NewNotePage" />;
};

const AppealNotePage = () => {
  return <ClinicalReviewPageNextWrapper reviewPageVersion="AppealNotePage" />;
};

export default App;
function AppWithSecurity() {
  return (
    <SentryRoutes>
      <Route
        path={routes.TEST_ERROR}
        element={
          <SecureRoute>
            <TestErrorPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.HOME}
        element={
          <SecureRoute>
            <HomePage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.DASHBOARD}
        element={
          <SecureRoute initializeLogRocket logRocketDelay={5000}>
            <DashboardPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.AUTH_BUILDER}
        element={
          <SecureRoute initializeLogRocket>
            <AuthBuilderPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.CAREWEBQI}
        element={
          <SecureRoute>
            <CarewebqiRedirectPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.PATIENT_SUMMARY}
        element={
          <SecureRoute>
            <AuthorizationPatientSummaryPaginationPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.FAX}
        element={
          <SecureRoute initializeLogRocket>
            <FaxAttachmentPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.FAX_AUTH_BUILDER}
        element={
          <SecureRoute initializeLogRocket>
            <FaxAuthBuilderPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.SERVICE_REQUEST}
        element={
          <SecureRoute>
            <ServiceRequestPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.EDIT_SERVICE_REQUEST_FAX}
        element={
          <SecureRoute initializeLogRocket>
            <ServiceRequestPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.EDIT_SR_SIDE_BY_SIDE}
        element={
          <SecureRoute initializeLogRocket>
            <FaxEditServiceRequestPage />
          </SecureRoute>
        }
      />
      {!isStagingEnvironment && (
        <Route
          path={routes.FEATURE_FLAGS}
          element={
            <SecureRoute>
              <FeatureFlagPage />
            </SecureRoute>
          }
        />
      )}
      <Route
        path={routes.CONFIGURATION_MANAGEMENT}
        element={
          <SecureRoute>
            <ConfigurationManagementPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.MY_ORGANIZATION}
        element={
          <SecureRoute>
            <MyOrganizationPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.ORGANIZATION_MANAGEMENT}
        element={
          <SecureRoute>
            <OrganizationManagementPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.ORGANIZATION_MANAGEMENT_LIST}
        element={
          <SecureRoute>
            <OrganizationManagementPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.OPS_USER_MANAGEMENT}
        element={
          <SecureRoute>
            <OpsUserManagementPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.FAX_SERVICE_REQUEST}
        element={
          <SecureRoute initializeLogRocket>
            <FaxServiceRequestPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.CLAIM_SERVICE_REQUEST}
        element={
          <SecureRoute>
            <ClaimServiceRequestPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.WITHDRAW_AND_REPLACE}
        element={
          <SecureRoute>
            <WithdrawAndReplacePage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.INTEGRATION_MANAGEMENT}
        element={
          <SecureRoute>
            <IntegrationManagementPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.INTEGRATION_OBSERVABILITY_MANAGEMENT}
        element={
          <SecureRoute>
            <IntegrationObservabilityPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.PATIENT_MANAGEMENT}
        element={
          <SecureRoute>
            <PatientManagementPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.PATIENT_MANAGEMENT_EDIT_PATIENT}
        element={
          <SecureRoute>
            <EditPatientCoveragesPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.CLINICAL_REVIEW}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <ClinicalReviewPageNextWrapper />
          </SecureRoute>
        }
      />
      <Route
        path={routes.VIEW_ONLY_REVIEW}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <ViewOnlyReview />
          </SecureRoute>
        }
      />
      <Route
        path={routes.DENIAL_REVIEW}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <DenialReviewPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.ADHOC_LETTER}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <AdhocLetterPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.NEW_OUTREACH}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <NewOutReachPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.LOG_OUTREACH_PAGE}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <LogOutreachPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.NEW_NOTE_PAGE}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <NewNotePage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.APPEAL_NOTE_PAGE}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <AppealNotePage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.CLINICAL_ASSESSMENT_TEST}
        element={
          <SecureRoute initializeLogRocket>
            <AssessmentTestPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.AUTHENTICATED_ORGANIZATION_ONBOARDING}
        element={
          <SecureRoute hideAppBar useSlimHeader>
            <OrganizationOnboardingPage />
          </SecureRoute>
        }
      />
      <Route path={routes.ORGANIZATION_ONBOARDING} element={<UnauthenticatedOrganizationOnboardingPage />} />
      <Route path="/implicit/callback" element={<MonitoringLoginCallback />} />
      <Route path={routes.UNAUTHORIZED_CHECK_STATUS} element={<UnauthorizedCheckStatusPage />} />
      <Route path={routes.SMART_ON_FHIR_LAUNCH} element={<SmartOnFhirLaunchPage />} />
      <Route
        path={routes.SMART_ON_FHIR_NOT_FOUND}
        element={
          <SmartOnFhirSecurityProvider>
            <SmartOnFhirNotFoundPage />
          </SmartOnFhirSecurityProvider>
        }
      />
      <Route
        path={routes.SMART_ON_FHIR_LANDING}
        element={
          <SmartOnFhirSecurityProvider>
            <SmartOnFhirLandingPage />
          </SmartOnFhirSecurityProvider>
        }
      />
      <Route
        path={routes.SMART_ON_FHIR_SERVICE_REQUEST}
        element={
          <SmartOnFhirSecurityProvider>
            <SmartOnFhirServiceRequestPageWrapper />
          </SmartOnFhirSecurityProvider>
        }
      />
      <Route
        path={routes.SMART_ON_FHIR_AUTH_BUILDER}
        element={
          <SmartOnFhirSecurityProvider>
            <AuthBuilderPage />
          </SmartOnFhirSecurityProvider>
        }
      />
      <Route
        path={routes.ADD_NEW_PATIENT}
        element={
          <SecureRoute>
            <AddNewPatientFormPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.FAX_AUDIT}
        element={
          <SecureRoute hideAppBar useSlimHeader initializeLogRocket>
            <FaxAuditPage />
          </SecureRoute>
        }
      />
      <Route
        path="/"
        element={
          <SecureRoute>
            <Navigate to={routes.DASHBOARD} replace />
          </SecureRoute>
        }
      />
      <Route path={routes.CYPRESS_LANDING} element={<NotFoundPage />} />
      <Route
        path="*"
        element={
          <SecureRoute>
            <NotFoundPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.REFERRAL_DASHBOARD}
        element={
          <SecureRoute initializeLogRocket>
            <ReferralsDashboard />
          </SecureRoute>
        }
      />
      <Route
        path={routes.REFERRAL_PATIENT_SUMMARY}
        element={
          <SecureRoute initializeLogRocket>
            <ReferralRequestPatientSummaryPaginationPage />
          </SecureRoute>
        }
      />
      <Route
        path={routes.REFERRAL_BUILDER}
        element={
          <SecureRoute initializeLogRocket>
            <ReferralRequestPage />
          </SecureRoute>
        }
      />
      <Route path={routes.REFERRAL_REQUEST} element={<SecureRoute initializeLogRocket></SecureRoute>} />
      <Route
        path={routes.FAX_REFERRAL_BUILDER}
        element={
          <SecureRoute initializeLogRocket>
            <FaxReferralsBuilderPage />
          </SecureRoute>
        }
      />
    </SentryRoutes>
  );
}
