import { Patient, ProcedureCode } from "@coherehealth/core-platform-api";
import { PalCheckFormSectionProps, SectionHeader } from "./HealthPlanPalCheckFormSectionConfigs";
import ProcedureCodeSelect from "components/AuthBuilder/ProcedureCodeSelect";
import { Checkbox, colorsLight } from "@coherehealth/common";

type Props = PalCheckFormSectionProps & { patientData: Patient | null };

export default function ProcedureCodeWithCheckboxSection({
  priorAuthRequirements,
  setPriorAuthRequirements,
  attemptedSubmit,
  resetPalResults,
  patientData,
}: Props) {
  const hasCoverage = patientData?.coverages && patientData.coverages.length;

  return (
    <>
      <SectionHeader>Procedure codes</SectionHeader>
      <ProcedureCodeSelect
        healthPlanName={hasCoverage ? patientData.coverages![0].healthPlanName : undefined}
        label="Search for CPT/HCPCS codes"
        attemptedSubmit={attemptedSubmit}
        procedureCodes={priorAuthRequirements.desiredProcedureCodes || []}
        setProcedureCodes={(codes: ProcedureCode[]) => {
          setPriorAuthRequirements({
            ...priorAuthRequirements,
            desiredProcedureCodes: codes || undefined,
            noPxService: undefined,
          });
          resetPalResults();
        }}
        disable={priorAuthRequirements?.noPxServiceRequired}
        blockDuplicateSelections
      />
      {priorAuthRequirements.showPxCheckbox && (
        <Checkbox
          style={{ color: colorsLight.font.secondary, marginBottom: "0px" }}
          label={"I do not need to submit procedure codes"}
          checked={Boolean(priorAuthRequirements.noPxServiceRequired)}
          onChange={() => {
            setPriorAuthRequirements({
              ...priorAuthRequirements,
              desiredProcedureCodes: [],
              noPxServiceRequired: !priorAuthRequirements.noPxServiceRequired,
            });
            resetPalResults();
          }}
        />
      )}
    </>
  );
}
