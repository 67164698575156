import { Card as BaseCard } from "@coherehealth/common";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { AddServiceRequestAttachmentProps, AddServiceRequestAttachments } from ".";

export default function AddAttachmentsCard(passthrough: AddServiceRequestAttachmentProps) {
  return (
    <Card>
      <AddServiceRequestAttachments {...passthrough} />
    </Card>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Card = styled(BaseCard)(({ theme }) => ({
  padding: theme.spacing(3, 3),
}));
