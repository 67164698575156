import { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import {
  useGetAssociateFaxToServiceRequest,
  ServiceRequestResponse,
  useCreateServiceRequestAttachment,
  ReferralRequestResponse,
  useCreateReferralRequestAttachment,
} from "@coherehealth/core-platform-api";

import { SelectedPatientInfo, useFeature, InlineButton, queueMgmtBaseUrl } from "@coherehealth/common";
import { Patient } from "@coherehealth/core-platform-api";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { error as logError } from "logger";
import { useSnackbar } from "notistack";
import { generatePath, useNavigate } from "react-router";
import routes from "routes";
import listRemove from "util/listRemove";
import { getPatientHealthPlanName } from "util/patientUtils";
import { WORKFLOW_REFERER_PARAM, useFullLocationPath } from "util/queryParams";

import { FaxSidebarView } from "../common";
import { IsCohereTemplateContext } from "../FaxAttachmentSidebar";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import UnattachedServiceRequest from "./UnattachedServiceRequest";
import { useBelongsToOpsGroup } from "authorization";
import { ServiceCase, useCompleteServiceCase } from "@coherehealth/qm-api";
import CommonFaxFooter from "../CommonFaxFooter";
import {
  useTrackUserInteraction,
  activityContextFromServiceRequest,
  activityContextFromCreateReferralRequest,
  activityContextFromReferralRequest,
} from "util/userActivityTracker";
import { CircularProgress } from "@material-ui/core";
import { AvailableRequestTypes, RequestTypeSelectionTab } from "./RequestTypeSelectionTab";
import { useGetPatientWithReferralEligibility } from "util/referralRequest";
import PatientReferralRequestsList from "./PatientReferralRequestsList";
import { useGetFaxIntakeConfigurationByPayer } from "hooks/useGetFeatureConfigurations";
import config from "api/config";

interface Props {
  serviceRequests: Array<ServiceRequestResponse> | null;
  referralRequests: Array<ReferralRequestResponse> | undefined;
  initialAttachedServiceRequestIds: Array<string | undefined>;
  initialAttachedReferralRequestIds: Array<string | undefined>;
  patient?: Patient;
  onCancel: () => void;
  onAllFileAttachmentsComplete: () => void;
  faxCohereId?: string;
  faxId?: string;
  showCreateNewServiceRequest?: boolean;
  showCreateNewReferralRequest?: boolean;
  setSidebarView: Dispatch<FaxSidebarView>;
  ocrServiceRequest?: ServiceRequestResponse;
  serviceCase?: ServiceCase | undefined | null;
  externalReferenceId?: string | undefined;
  healthPlanName?: string;
  setSelectedServiceId: Dispatch<string>;
  loadingServiceRequests: boolean;
  isSearchingByTrackingNumber?: boolean;
  showOnlyRequestOfType?: AvailableRequestTypes;
}

const useStyles = makeStyles((theme) => ({
  serviceRequestResultsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    paddingBottom: 72,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  fullWidthDivider: {
    margin: theme.spacing(4, -3, 3),
  },
  fullWidthBottomDivider: {
    margin: theme.spacing(0, -3, 0),
  },
  fullWidthBottomDividerWithMarginBlock: {
    margin: theme.spacing(3, -3, 0),
  },
  createServiceRequestBtn: {
    padding: theme.spacing(1),
  },
  scrollableResultList: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: "1 1 0",
    padding: theme.spacing(2, 3, 3),
    margin: theme.spacing(0, -3),
  },
  resultListHeader: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  leftButton: {
    alignItems: "flex-start",
    margin: "0px 8px 0px 12px",
  },
  rightButton: {
    minWidth: theme.spacing(25),
    margin: "0px 12px 0px 8px",
  },
  loadingSpinnerContainer: {
    display: "block",
    alignSelf: "center",
    margin: "24px auto 8px auto",
  },
  createButtonsContainer: {
    flex: 1,
    display: "flex",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
  },
  heightDivider: {
    width: "1px",
    height: "28px",
    alignSelf: "center",
  },
  ineligibleForReferralsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  resultsListContainer: {
    height: "100%",
    width: "100%",
  },
  cardPadding: {
    marginTop: "10px",
  },
}));

export function useGenerateFaxWorkflowRedirectPath(
  patient?: Patient,
  ocrServiceRequestId?: string,
  serviceCaseSpecialty?: string
) {
  const { faxDbId: faxId, caseId } = useContext(FaxAttachmentContext);
  const isGhpUser = useBelongsToOpsGroup("GEISINGER");
  const updatedFaxWorkflowAllUsers = useFeature("updatedFaxWorkflowAllUsers");
  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");
  const sideBySideInitialSrFaxIntakeWorkflowFeature = useFeature("sideBySideInitialSrFaxIntakeWorkflow");

  const fullLocationPath = useFullLocationPath();

  let path;
  const isHighMarkCaseBuild = useFeature("highmarkCaseBuild");
  if (sideBySideInitialSrFaxIntakeWorkflowFeature) {
    path = `${generatePath(routes.FAX_AUTH_BUILDER, { faxId: faxId, patientId: patient?.id || "" })}?`;
  } else if (updatedFaxWorkflowAllUsers || isGhpUser) {
    path = `${generatePath(routes.AUTH_BUILDER, { patientId: patient?.id || "" })}?faxId=${faxId}`;
  } else {
    path = `${generatePath(routes.FAX_SERVICE_REQUEST, { faxId: faxId })}?patientId=${patient?.id || ""}`;
  }

  path += ocrServiceRequestId ? `&serviceRequestId=${ocrServiceRequestId}` : "";
  path += caseId ? `&caseId=${caseId}` : "";

  // (getPatientHealthPlanName(patient) === "Highmark" && !isHighMarkCaseBuild) condition can be removed after casebuild is live
  if ((getPatientHealthPlanName(patient) === "Highmark" && !isHighMarkCaseBuild) || serviceCaseSpecialty === "triage") {
    path += `&workflowLength=${"short"}`;
  }

  path += `&${encodeURIComponent(WORKFLOW_REFERER_PARAM)}=${encodeURIComponent(fullLocationPath)}`;
  if (authBuilderParamNavigationFF) {
    path += `&faxStep=SIDE_BY_SIDE_VIEW`;
  }
  return path;
}

export function useGenerateReferralFaxWorkflowRedirectPath(patient?: Patient) {
  const { faxDbId: faxId, caseId } = useContext(FaxAttachmentContext);

  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");
  let path;
  path = `${generatePath(routes.FAX_REFERRAL_BUILDER, { faxId: faxId, patientId: patient?.id || "" })}?`;
  path += caseId ? `&caseId=${caseId}` : "";
  if (authBuilderParamNavigationFF) {
    path += `&faxStep=REFERRAL_SIDE_BY_SIDE_VIEW`;
  }
  return path;
}

export default function ExistingServiceRequestResults({
  serviceRequests,
  initialAttachedServiceRequestIds,
  initialAttachedReferralRequestIds,
  patient,
  onCancel,
  onAllFileAttachmentsComplete,
  showCreateNewServiceRequest,
  showCreateNewReferralRequest,
  faxCohereId,
  faxId,
  setSidebarView,
  ocrServiceRequest,
  serviceCase,
  externalReferenceId,
  healthPlanName,
  setSelectedServiceId,
  loadingServiceRequests,
  referralRequests,
  isSearchingByTrackingNumber,
  showOnlyRequestOfType,
}: Props) {
  const classes = useStyles();
  const { data: faxIntakeConfiguration } = useGetFaxIntakeConfigurationByPayer(healthPlanName ?? "");
  const { caseId } = useContext(FaxAttachmentContext);

  const { mutate: updateServiceCase } = useCompleteServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    onMutate: () => {
      window.location.assign(`${queueMgmtBaseUrl()}/case_complete/${caseId}`);
    },
  });
  const listAndCreateReferralsFeatureEnabled = faxIntakeConfiguration?.referralsCreationFromFaxEnabled ?? false;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const trackUserActivityInteraction = useTrackUserInteraction();
  const stage = useContext(IsCohereTemplateContext) ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW";

  const sideBySideInitialSrFaxIntakeWorkflowFeature = useFeature("sideBySideInitialSrFaxIntakeWorkflow");
  const addAttachmentToServiceRequestRefactorFF = useFeature("addAttachmentToServiceRequestRefactor");

  const [selectedRequestType, setSelectedRequestType] = useState<AvailableRequestTypes>(
    showOnlyRequestOfType ?? "service"
  );
  const [selectedServiceRequestIds, setSelectedServiceRequestIds] = useState<string[]>([]);
  const [selectedReferralRequestId, setSelectedReferralRequestId] = useState<string | null>(null);
  const [newlyAttachedServiceRequestIds, setNewlyAttachedServiceRequestIds] = useState<string[]>([]);
  const [newlyAttachedReferralRequestIds, setNewlyAttachedReferralRequestIds] = useState<string[]>([]);
  const [doFileUpload, setDoFileUpload] = useState(false);
  const selectedPatientReferralEligibility = useGetPatientWithReferralEligibility(patient?.id || "")
    ?.referralEligibility?.delegated;
  const {
    mutate: uploadSRFax,
    loading: uploadingSRFax,
    error: faxUploadSRError,
  } = useCreateServiceRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });
  const {
    mutate: uploadRRFax,
    loading: uploadingRRFax,
    error: faxUploadRRError,
  } = useCreateReferralRequestAttachment({
    id: "",
    requestOptions: { headers: { Accept: "application/json" } },
  });
  const { mutate: getAssociateFaxToServiceRequest, error: associateSubmissionFaxError } =
    useGetAssociateFaxToServiceRequest({
      queryParams: {
        externalReferenceId: serviceCase?.externalSources?.at(0)?.externalReferenceId || externalReferenceId,
        serviceRequestId: "",
      },
    });

  useEffect(() => {
    if (doFileUpload && newlyAttachedServiceRequestIds.length === selectedServiceRequestIds.length) {
      if (!Boolean(addAttachmentToServiceRequestRefactorFF)) {
        enqueueSnackbar("Attachments added", { variant: "success" });
      }

      onAllFileAttachmentsComplete();
    }
  }, [
    newlyAttachedServiceRequestIds.length,
    doFileUpload,
    enqueueSnackbar,
    selectedServiceRequestIds,
    onAllFileAttachmentsComplete,
    addAttachmentToServiceRequestRefactorFF,
  ]);
  useEffect(() => {
    if (
      Boolean(addAttachmentToServiceRequestRefactorFF) &&
      doFileUpload &&
      newlyAttachedServiceRequestIds?.length > 0 &&
      selectedServiceRequestIds?.length > 0
    ) {
      enqueueSnackbar(`Fax attached (${newlyAttachedServiceRequestIds.length}/${selectedServiceRequestIds.length})`, {
        variant: "success",
      });
    }
  }, [
    addAttachmentToServiceRequestRefactorFF,
    doFileUpload,
    enqueueSnackbar,
    newlyAttachedServiceRequestIds,
    selectedServiceRequestIds,
  ]);
  useEffect(() => {
    if (faxUploadSRError || faxUploadRRError) {
      const errorMessageSR =
        typeof faxUploadSRError?.data === "object"
          ? faxUploadSRError.data.message || faxUploadSRError.message
          : faxUploadSRError?.message;
      const errorMessageRR =
        typeof faxUploadRRError?.data === "object"
          ? faxUploadRRError.data.message || faxUploadRRError.message
          : faxUploadRRError?.message;
      enqueueSnackbar(`Failed to attach fax to service request: ${errorMessageSR || errorMessageRR}`, {
        variant: "error",
      });
    }
    if (associateSubmissionFaxError) {
      enqueueSnackbar(`Could not associate Highmark fax to service request, please try again`, {
        variant: "error",
      });
    }
  }, [associateSubmissionFaxError, enqueueSnackbar, faxUploadSRError, faxUploadRRError]);

  const isSRSelected = useCallback((id: string) => selectedServiceRequestIds.includes(id), [selectedServiceRequestIds]);
  const selectSR = useCallback(
    (id: string) => setSelectedServiceRequestIds((currSelected) => [...currSelected, id]),
    [setSelectedServiceRequestIds]
  );
  const unselectSR = useCallback(
    (id: string) => {
      const index = selectedServiceRequestIds.indexOf(id);
      if (index > -1) {
        setSelectedServiceRequestIds(listRemove(selectedServiceRequestIds, index));
      }
    },
    [selectedServiceRequestIds]
  );
  const isSRAttached = useCallback(
    (id: string) => newlyAttachedServiceRequestIds.includes(id) || initialAttachedServiceRequestIds.includes(id),
    [initialAttachedServiceRequestIds, newlyAttachedServiceRequestIds]
  );
  const onSRAttachmentSuccess = useCallback(
    (id: string) => setNewlyAttachedServiceRequestIds((currAttached) => [...currAttached, id]),
    [setNewlyAttachedServiceRequestIds]
  );
  const onSRAttachmentFailed = useCallback(
    (id: string) => {
      const errorMessage = `Adding fax attachment to service request failed (srId: ${id}, faxCohereId: ${faxCohereId})`;
      logError(errorMessage);
      enqueueSnackbar(errorMessage, { variant: "error" });
      setDoFileUpload(false);
      unselectSR(id);
    },
    [enqueueSnackbar, faxCohereId, unselectSR]
  );
  const isRRAttached = useCallback(
    (id: string) => newlyAttachedReferralRequestIds.includes(id) || initialAttachedReferralRequestIds.includes(id),
    [initialAttachedReferralRequestIds, newlyAttachedReferralRequestIds]
  );
  const isRRSelected = useCallback((id: string) => selectedReferralRequestId === id, [selectedReferralRequestId]);
  const selectRR = useCallback((id: string) => setSelectedReferralRequestId(id), [setSelectedReferralRequestId]);
  const unselectRR = useCallback((id: string) => {
    setSelectedReferralRequestId(null);
  }, []);

  const onRRAttachmentSuccess = useCallback(
    (id: string) => setNewlyAttachedReferralRequestIds((currAttached) => [...currAttached, id]),
    [setNewlyAttachedReferralRequestIds]
  );
  const onRRAttachmentFailed = useCallback(
    (id: string) => {
      const errorMessage = `Adding fax attachment to referral request failed (rrId: ${id}, faxCohereId: ${faxCohereId})`;
      logError(errorMessage);
      enqueueSnackbar(errorMessage, { variant: "error" });
      setDoFileUpload(false);
      unselectRR(id);
    },
    [enqueueSnackbar, faxCohereId, unselectRR]
  );
  const faxWorkflowRedirectPath = useGenerateFaxWorkflowRedirectPath(
    patient,
    ocrServiceRequest?.id,
    serviceCase?.specialty
  );

  const referralFaxWorkflowRedirectPath = useGenerateReferralFaxWorkflowRedirectPath(patient);
  const handleUploadAttachments = async () => {
    if (!!faxCohereId && selectedServiceRequestIds?.length > 0) {
      setDoFileUpload(true);
      const handleFaxSRUpload = async (serviceRequest: ServiceRequestResponse) => {
        const formData = new FormData();
        formData.set("faxId", faxCohereId);

        await uploadSRFax(formData as unknown as void, { pathParams: { id: serviceRequest.id } }).then(
          async () => {
            //track when a user attaches a fax to a service request
            trackUserActivityInteraction({
              event: "ATTACHED_FAX_TO_AUTH",
              stage: stage,
              activityContext: { ...activityContextFromServiceRequest(serviceRequest), faxId: faxId },
            }).finally(() => onSRAttachmentSuccess(serviceRequest.id));
          },
          () => {
            onSRAttachmentFailed(serviceRequest.id);
          }
        );
      };
      for (const selectedServiceId of selectedServiceRequestIds) {
        const selectedServiceRequest = serviceRequests?.find((serviceRequest) => {
          return serviceRequest.id === selectedServiceId;
        });

        if (!!selectedServiceRequest) {
          try {
            //If fax is a hmk fax, associate fax to service request before uploading fax
            if (healthPlanName === "Highmark") {
              await getAssociateFaxToServiceRequest({} as unknown as void, {
                queryParams: {
                  externalReferenceId: serviceCase?.externalSources?.at(0)?.externalReferenceId || externalReferenceId,
                  serviceRequestId: selectedServiceRequest.id ?? "",
                },
              });
            }

            await handleFaxSRUpload(selectedServiceRequest);
          } catch (error) {
            onSRAttachmentFailed(selectedServiceId);
          }
        }
      }
    }

    if (!!faxCohereId && !!selectedReferralRequestId) {
      const formData = new FormData();
      formData.set("faxId", faxCohereId);

      const selectedReferralRequest = referralRequests?.find((referralRequest) => {
        return referralRequest.id === selectedReferralRequestId;
      });

      if (!!selectedReferralRequest) {
        try {
          await uploadRRFax(formData as unknown as void, { pathParams: { id: selectedReferralRequestId } }).then(
            async () => {
              // track when a user attaches a fax to a referral request
              trackUserActivityInteraction({
                event: "ATTACHED_FAX_TO_REFERRAL",
                stage: stage,
                activityContext: { ...activityContextFromReferralRequest(selectedReferralRequest), faxId },
              }).finally(() => {
                onRRAttachmentSuccess(selectedReferralRequestId);
                updateServiceCase({
                  outcome: "FAX_ATTACHED_TO_RF",
                  dateCompleted: new Date().toISOString(),
                  description: "Fax attached to RF",
                  referralRequest: {
                    referralRequestId: selectedReferralRequest.id,
                    cohereId: selectedReferralRequest.cohereId,
                    dateCreated: new Date(selectedReferralRequest.dateCreated).toISOString(),
                    status: selectedReferralRequest.referralStatus,
                  },
                });
              });
            }
          );
        } catch (error) {
          onRRAttachmentFailed(selectedReferralRequestId);
        }
      }
    }
  };

  const handleCreateServiceClick = async () => {
    //track when a user clicks on "Create new authorization"
    trackUserActivityInteraction({
      event: "START_NEW_SR",
      stage: stage,
      activityContext: { ...activityContextFromCreateReferralRequest(patient?.id ?? ""), faxId: faxId },
    }).finally(() => {
      if (sideBySideInitialSrFaxIntakeWorkflowFeature) {
        navigate(faxWorkflowRedirectPath);
      } else {
        window.open(faxWorkflowRedirectPath);
        setSidebarView("CREATE_NEW_SR_CONFIRMATION");
      }
    });
  };

  const handleCreateReferralClick = async () => {
    //track when a user clicks on "Create new referral"
    trackUserActivityInteraction({
      event: "START_NEW_RF",
      stage: stage,
      activityContext: { ...activityContextFromCreateReferralRequest(patient?.id ?? ""), faxId: faxId },
    }).finally(() => navigate(referralFaxWorkflowRedirectPath));
  };

  const disableButton = selectedReferralRequestId !== null;
  const disableNewCtaButtons = disableButton || selectedServiceRequestIds.length !== 0;
  return (
    <div className={classes.serviceRequestResultsContainer}>
      {patient && (
        <>
          <SelectedPatientInfo patient={patient} />
          {!isSearchingByTrackingNumber && listAndCreateReferralsFeatureEnabled && (
            <RequestTypeSelectionTab
              selectedRequestType={selectedRequestType}
              onHandleTabChange={(requestType) => setSelectedRequestType(requestType)}
              totalAuthorizations={serviceRequests?.length || 0}
              totalReferrals={selectedPatientReferralEligibility ? referralRequests?.length || 0 : 0}
            />
          )}
          <Divider
            className={
              isSearchingByTrackingNumber || !listAndCreateReferralsFeatureEnabled
                ? classes.fullWidthBottomDividerWithMarginBlock
                : classes.fullWidthBottomDivider
            }
          />
        </>
      )}
      <div className={classes.scrollableResultList}>
        <Grid container spacing={2}>
          {loadingServiceRequests ? (
            <div className={classes.loadingSpinnerContainer}>
              <CircularProgress size={26} />
            </div>
          ) : (
            <>
              <div
                hidden={selectedRequestType === "service" || !listAndCreateReferralsFeatureEnabled}
                id={"referralsList"}
                className={classes.resultsListContainer}
              >
                <PatientReferralRequestsList
                  patientId={patient?.id}
                  referralRequests={referralRequests}
                  healthPlanName={healthPlanName}
                  selectedPatientReferralEligibility={selectedPatientReferralEligibility}
                  isRRSelected={isRRSelected}
                  isRRAttached={isRRAttached}
                  selectRR={selectRR}
                  unselectRR={unselectRR}
                  selectedReferralRequestId={selectedReferralRequestId}
                  selectedServiceRequestIds={selectedServiceRequestIds}
                  canAttach={selectedReferralRequestId === null && !selectedServiceRequestIds}
                />
              </div>

              <div
                hidden={selectedRequestType === "referral"}
                id={"serviceRequestsList"}
                className={classes.resultsListContainer}
              >
                {serviceRequests?.map((sr) => (
                  <Grid item xs={12} key={sr.id} className={classes.cardPadding}>
                    <UnattachedServiceRequest
                      serviceRequest={sr}
                      isSelected={isSRSelected(sr.id)}
                      isAttached={isSRAttached(sr.id)}
                      onSelect={selectSR}
                      onUnselect={unselectSR}
                      onAttachmentSuccess={onSRAttachmentSuccess}
                      onAttachmentFailed={onSRAttachmentFailed}
                      // If we have a "Create new authorization" primary action,
                      // then we want the attachment buttons in here to have the secondary style
                      showSecondaryButtonForAttach={showCreateNewServiceRequest}
                      triggerFaxUpload={doFileUpload && !Boolean(addAttachmentToServiceRequestRefactorFF)}
                      faxCohereId={faxCohereId}
                      faxId={faxId}
                      serviceCase={serviceCase}
                      healthPlanName={healthPlanName}
                      externalReferenceId={externalReferenceId}
                      setSidebarView={setSidebarView}
                      setSelectedServiceId={setSelectedServiceId}
                      uploadingAttachment={uploadingRRFax || uploadingSRFax}
                      disableAttachmentCta={disableButton}
                    />
                  </Grid>
                ))}
              </div>
            </>
          )}

          {isSearchingByTrackingNumber &&
            showCreateNewServiceRequest &&
            ((referralRequests && referralRequests.length > 0) || (serviceRequests && serviceRequests?.length > 0)) && (
              <div key="create-new-authorization-and-create-new-referral" className={classes.createButtonsContainer}>
                <InlineButton
                  disabled={disableNewCtaButtons}
                  className={classes.createServiceRequestBtn}
                  onClick={handleCreateServiceClick}
                  data-public
                >
                  Create new authorization
                </InlineButton>
                {selectedPatientReferralEligibility && listAndCreateReferralsFeatureEnabled && (
                  <>
                    <Divider className={classes.heightDivider} />
                    <InlineButton
                      disabled={disableNewCtaButtons}
                      className={classes.createServiceRequestBtn}
                      onClick={handleCreateReferralClick}
                      data-public
                    >
                      Create new referral
                    </InlineButton>
                  </>
                )}
              </div>
            )}

          {!isSearchingByTrackingNumber && showCreateNewServiceRequest && selectedRequestType === "service" && (
            <Grid item xs={6} key="create-new-authorization">
              <InlineButton
                disabled={disableNewCtaButtons}
                className={classes.createServiceRequestBtn}
                onClick={handleCreateServiceClick}
                data-public
              >
                Create new authorization
              </InlineButton>
            </Grid>
          )}

          {!isSearchingByTrackingNumber &&
            showCreateNewReferralRequest &&
            listAndCreateReferralsFeatureEnabled &&
            selectedRequestType === "referral" &&
            selectedPatientReferralEligibility && (
              <Grid item xs={6}>
                <InlineButton
                  disabled={disableNewCtaButtons}
                  className={classes.createServiceRequestBtn}
                  onClick={handleCreateReferralClick}
                  data-public
                >
                  Create new referral
                </InlineButton>
              </Grid>
            )}
        </Grid>
      </div>
      <CommonFaxFooter
        onPrimaryButtonClick={() => handleUploadAttachments()}
        disabledFinish={
          (selectedServiceRequestIds.length === 0 &&
            !selectedReferralRequestId &&
            initialAttachedServiceRequestIds.length === 0 &&
            initialAttachedReferralRequestIds.length === 0 &&
            newlyAttachedServiceRequestIds.length === 0 &&
            newlyAttachedReferralRequestIds.length === 0) ||
          uploadingSRFax ||
          uploadingRRFax
        }
        onCancelButtonClick={onCancel}
        setSidebarView={setSidebarView}
      />
    </div>
  );
}
