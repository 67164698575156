// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";

import InfoAlert from "../../common/InfoAlert";
import React, { memo, Dispatch, SetStateAction, useState } from "react";
import {
  ServiceRequestCreatePayload,
  ServiceRequestSearchResponse,
  UserResponse,
} from "@coherehealth/core-platform-api";
import ServiceRequestCard from "./ServiceRequestCard";
import { ServiceRequestFormContent } from "../../common/SharedServiceRequestFormComponents";
import { BlankDashboard, MagnifyingGlassIcon, PrimaryButton, useFeature } from "@coherehealth/common";
import BlankDashboardOld from "../images/BlankDashboard";
import { SearchBeyondOrgMatchFound } from "./SearchBeyondOrgMatchFound";
import DashboardResults from "./DashboardResults";
import EmptyResultsPatientLookup from "./EmptyResultsPatientLookup";
import { useNavigate } from "react-router";
import { routeToPatientSummaryFromReviewAfterDelete } from "util/routeUtils/routeUtils";

interface PatientCard {
  patientId: string;
  patientName: string;
}

interface Props {
  error: boolean;
  viewableServiceRequests?: ServiceRequestSearchResponse[];
  loading: boolean;
  displayMax: number;
  gatherRequirements?: boolean;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeleteDraft?: Function;
  isExistingAuthorization?: boolean;
  user?: UserResponse | null;
  cardStyles?: object;
  searchForPotentialDuplicates?: (srPayload: ServiceRequestCreatePayload) => Promise<boolean>;
  workflowId?: string;
  serviceRequestFormContents?: ServiceRequestFormContent[];
  getPotentialDuplicatesLoading?: boolean;
  onPotentialDuplicatesClose?: () => void;
  queryString?: string;
  beyondOrgSearchResponseType?: string;
  setBeyondOrgSearchResponseType?: Dispatch<SetStateAction<string>>;
  patientCardsToDisplay?: PatientCard[];
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ServiceRequestCardList = memo(
  ({
    error,
    viewableServiceRequests,
    loading,
    displayMax,
    gatherRequirements,
    onEdit,
    onDeleteDraft,
    isExistingAuthorization,
    user,
    cardStyles,
    searchForPotentialDuplicates,
    workflowId,
    serviceRequestFormContents,
    getPotentialDuplicatesLoading,
    onPotentialDuplicatesClose,
    queryString,
    beyondOrgSearchResponseType,
    setBeyondOrgSearchResponseType,
    patientCardsToDisplay,
    setModalOpen,
  }: Props) => {
    const searchableBeyondOrganizationFF = useFeature("searchableBeyondOrganization");
    const classes = useStyles();
    const navigate = useNavigate();

    const [attemptedSubmitSearchBeyondOrg, setAttemptedSubmitSearchBeyondOrg] = useState(false);
    const [formInputsValid, setFormInputsValid] = useState(true);
    const [patientMatchValidated, setPatientMatchValidated] = useState<boolean>(false);
    return (
      <CardList>
        {!error && loading && (
          <DashboardResults
            heading="Loading your services"
            message="This might take a few seconds..."
            icon={<BlankDashboard />}
          />
        )}
        {!loading &&
          viewableServiceRequests &&
          (viewableServiceRequests.length === 0 ? (
            searchableBeyondOrganizationFF &&
            beyondOrgSearchResponseType &&
            beyondOrgSearchResponseType !== "none" &&
            queryString ? (
              <DashboardResults
                heading={"We found a match for "}
                headingBold={queryString}
                message={
                  "This request is associated with someone outside of your organization. For security, we need you to verify the patient's details before granting access."
                }
                icon={<BlankDashboard />}
                form={
                  <SearchBeyondOrgMatchFound
                    queryString={queryString}
                    setBeyondOrgSearchResponseType={setBeyondOrgSearchResponseType}
                    beyondOrgSearchResponseType={beyondOrgSearchResponseType}
                    attemptedSubmit={attemptedSubmitSearchBeyondOrg}
                    setAttemptedSubmit={setAttemptedSubmitSearchBeyondOrg}
                    patientMatchValidated={patientMatchValidated}
                    setPatientMatchValidated={setPatientMatchValidated}
                    setFormInputsValid={setFormInputsValid}
                  />
                }
                error={
                  attemptedSubmitSearchBeyondOrg && !patientMatchValidated && formInputsValid ? (
                    <EmptyResultsPatientLookup />
                  ) : null
                }
              />
            ) : (
              <DashboardResults
                heading={
                  searchableBeyondOrganizationFF
                    ? queryString
                      ? `No results found for `
                      : "No results found"
                    : "No services yet"
                }
                message={
                  searchableBeyondOrganizationFF
                    ? "Try a different search or look up a patient to view their authorizations."
                    : "Adjust your filters or add an auth request."
                }
                icon={searchableBeyondOrganizationFF ? <MagnifyingGlassIcon /> : <BlankDashboardOld />}
                form={
                  searchableBeyondOrganizationFF && (
                    <PrimaryButton
                      className={classes.patientSearchButton}
                      onClick={() => setModalOpen && setModalOpen(true)}
                      data-public
                    >
                      Look up a patient
                    </PrimaryButton>
                  )
                }
                headingBold={searchableBeyondOrganizationFF ? queryString : undefined}
              />
            )
          ) : (
            viewableServiceRequests.slice(0, displayMax).map((s, idx) => (
              <div key={s.id || `service-request-fallback-${idx}`}>
                {s.reconClaim && <InfoAlert message="Claim">Claim number: {s.reconClaim.claimId}</InfoAlert>}
                <ServiceRequestCard
                  gatherRequirements={gatherRequirements}
                  serviceRequest={s}
                  onEdit={onEdit}
                  onDeleteDraft={onDeleteDraft}
                  isExistingAuthorization={isExistingAuthorization}
                  cardStyles={cardStyles}
                  user={user}
                  searchForPotentialDuplicates={searchForPotentialDuplicates}
                  workflowId={workflowId}
                  serviceRequestFormContents={serviceRequestFormContents}
                  getPotentialDuplicatesLoading={getPotentialDuplicatesLoading}
                  onPotentialDuplicatesClose={onPotentialDuplicatesClose}
                ></ServiceRequestCard>
              </div>
            ))
          ))}
        {searchableBeyondOrganizationFF &&
          patientCardsToDisplay &&
          patientCardsToDisplay?.length > 0 &&
          queryString &&
          !loading &&
          patientCardsToDisplay
            .filter((patient) => patient.patientId && patient.patientName)
            .map((patientCard) => {
              return (
                <div key={patientCard.patientId}>
                  <DashboardResults
                    headingBold={patientCard.patientName.split(" ").reverse().join(", ")}
                    message="Additional requests for this patient can be viewed on the patient summary."
                    form={
                      <WideButton
                        type="submit"
                        onClick={(event) => {
                          event.stopPropagation();
                          routeToPatientSummaryFromReviewAfterDelete(patientCard.patientId, navigate);
                        }}
                        style={{ marginTop: "32px" }}
                      >
                        Go to the patient summary
                      </WideButton>
                    }
                    style={{ marginBottom: 24 }}
                  />
                </div>
              );
            })}
      </CardList>
    );
  }
);

export default ServiceRequestCardList;

// Service request cards
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CardList = styled("div")({
  // These styles mostly help with centering the loading spinner
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WideButton = styled(PrimaryButton)({
  alignSelf: "center",
}) as typeof PrimaryButton;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    patientSearchButton: {
      marginTop: theme.spacing(4),
    },
    searchBeyondBoldText: {
      color: theme.palette.text.primary,
    },
    searchBeyondHeroText: {
      color: theme.palette.text.secondary,
      fontWeight: 400,
    },
    searchBeyondHeroHeading: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
      fontWeight: 400,
      fontFamily: "Gilroy-Bold",
      display: "flex",
      marginTop: theme.spacing(3),
    },
    searchBeyondBorder: {
      border: "1px solid #E5E5E5",
      boxSizing: "border-box",
      borderRadius: 5,
      padding: "0px 40px",
    },
    searchBeyondContainer: {
      display: "flex",
      justifyContent: "flex-start",
    },
    searchBeyondIconContainer: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(3),
    },
    inputContainer: {
      paddingRight: `${theme.spacing(7)}px`,
    },
    dialogContent: {
      padding: `${theme.spacing(4)}px 0px ${theme.spacing(2)}px 0px`,
    },
    patientCardContainer: {
      borderRadius: `${theme.spacing(1)}px`,
      border: "1px solid #E5E5E5",
      padding: `${theme.spacing(5)}px`,
      marginBottom: `10px`,
    },
    patientCardName: {
      padding: "0px",
      marginBottom: `16px`,
    },
    patientCardText: {
      padding: "0px",
      marginBottom: `${theme.spacing(5)}px`,
      color: theme.palette.text.tertiary,
      fontWeight: 400,
    },
    patientCardButton: { padding: "0px" },
  })
);
