import React from "react";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { styled, useTheme } from "@material-ui/core/styles";
import { Body2, Caption, H5 } from "../Typography";
import { Pill } from "../Pill";
import { ClinicalQuestionLink } from "@coherehealth/core-platform-api";
import { InlineButton } from "../InlineButton";
import { Launch } from "@material-ui/icons";
import { useFeature } from "../../components";

export interface Props {
  /** The number of this question on a form */
  ordinal: string;
  /** The text of this question */
  questionText: string;
  /** The question type */
  questionType?: string;
  /** An array of related links */
  relevantLinks?: ClinicalQuestionLink[];
  /** If this question is required or not */
  required?: boolean;
  /** Appears beneath the question text */
  subtext?: string;
  /** Hides the divider line between the header and the answer body of the question */
  hideQuestionAnswerDivider?: boolean;
}

export default function QuestionHeader({
  ordinal,
  questionText,
  questionType,
  relevantLinks,
  required,
  subtext,
  hideQuestionAnswerDivider = false,
}: Props) {
  const diagnosticImagingUpdatesEnabled = useFeature("diagnosticImagingUpdates");
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <QuestionText data-public>
            {ordinal}. {questionText}
          </QuestionText>
        </Grid>
        {diagnosticImagingUpdatesEnabled && relevantLinks && (
          <Grid container xs={12} style={{ marginLeft: theme.spacing(1) }}>
            {relevantLinks.map((link, index) => (
              <InlineButton
                key={index}
                href={link.url || ""}
                target="_blank"
                rel="noopener"
                startIcon={<Launch />}
                style={{
                  alignItems: "start",
                  justifyContent: "left",
                  marginRight: theme.spacing(3),
                  padding: 0,
                }}
              >
                <Body2>{link.label}</Body2>
              </InlineButton>
            ))}
          </Grid>
        )}
        <Grid
          item
          xs={2}
          style={{
            marginBottom: hideQuestionAnswerDivider ? theme.spacing(2) : 0,
            marginTop: questionType === "SINGLE_SELECT" ? theme.spacing(1) : 0,
          }}
        >
          <RequiredPill
            label={required ? "REQUIRED" : "OPTIONAL"}
            variant={required ? "warning" : "success"}
            fontVariant={"subtitle1"}
            dataPublic={true}
          />
        </Grid>
        {subtext && (
          <Grid item xs={10} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
            <Caption>{subtext}</Caption>
          </Grid>
        )}
      </Grid>
      {!hideQuestionAnswerDivider && <QuestionDivider />}
    </>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const RequiredPill = styled(Pill)(({ theme }) => ({
  padding: theme.spacing(1),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const QuestionText = styled(H5)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const QuestionDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));
