import { DropdownOption, SelectOptionsHook, formatDateToISODate, removeTimeFromDate } from "@coherehealth/common";
import { LineOfBusiness, PrimaryCareProvider, Provider, Unknown } from "@coherehealth/core-platform-api";
import { AutocompleteRenderOptionState } from "@material-ui/lab";
import { OptionType } from "components/ServiceRequest/ServiceRequestForm/components/FacilitySelectManual";
import { SetStateAction } from "react";
import { FormState } from "./formReducer";

export type ProviderOption = Provider &
  PrimaryCareProvider & {
    optionType?: OptionType;
  };
export type SingleSelectTypeT = Unknown & {
  optionType?: OptionType;
};

export type AutocompleteInputConfiguration = {
  useOptions: SelectOptionsHook<Unknown> | SelectOptionsHook<ProviderOption>;
  renderOption:
    | ((option: Unknown, state: AutocompleteRenderOptionState) => JSX.Element | undefined)
    | ((option: ProviderOption, state: AutocompleteRenderOptionState) => JSX.Element | undefined);
  setSelectedValue: ((n: Unknown) => void) | ((n: ProviderOption) => void);
};
export type DateInputConfiguration = {
  onChange?: (n: SetStateAction<string | null>) => void;
  onDateChange?: (n: SetStateAction<Date | null>) => void;
};

export type ConditionalNewPatientFormSectionT =
  | {
      input: "singleSelect";
      value: string | null;
      renderOption?: ((option: string, state: AutocompleteRenderOptionState) => React.ReactNode) | undefined;
      setSelectedValue: (fieldName: string, fieldValue: string | null) => void;
      getOptionLabel?: ((option: string) => string) | undefined;
      useOptions: SelectOptionsHook<string>;
    }
  | {
      input: "providerOption";
      value?: ProviderOption | null;
      setSelectedValue?: ((n: Unknown) => void) | ((n: ProviderOption) => void);
      setSelectedPcpValue?: (n: ProviderOption | null) => void;
      renderOption?:
        | ((option: Unknown, state: AutocompleteRenderOptionState) => JSX.Element | undefined)
        | ((option: ProviderOption, state: AutocompleteRenderOptionState) => JSX.Element | undefined);
      useOptions?: SelectOptionsHook<Unknown> | SelectOptionsHook<ProviderOption>;
      getOptionLabel?: ((option: unknown) => string) | ((option: ProviderOption) => string);
    }
  | {
      input: "date";
      value: Date | null;
      onDateChange?: (n: SetStateAction<Date | null>) => void;
      onChange?: (n: SetStateAction<string | null>) => void;
      handleSelectChange?: (fieldName: string, fieldValue?: string) => void;
    }
  | {
      input: "select";
      value?: string;
      options?: DropdownOption[];
      handleSelectChange?: (fieldName: string, fieldValue?: string) => void;
    }
  | {
      input: "text";
      value?: string;
      handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }
  | {
      input: "none";
      value: string;
      options?: DropdownOption[] | undefined;
    };

type CommonProps = {
  name: string;
  label: string;
  error?: boolean;
};

export type NewPatientFormSectionT = CommonProps & ConditionalNewPatientFormSectionT;

// Form sections for form context
export type PatientSectionT = {
  name: string;
  label: string;
  input: string;
  options?: DropdownOption;
};

export type PatientInfoT = {
  FIRST_NAME?: string;
  LAST_NAME?: string;
  MIDDLE_NAME?: string;
  DATE_OF_BIRTH?: string;
  GENDER?: string;
};
export type ContactInfoT = {
  ADDRESS_LINE_1?: string;
  ADDRESS_LINE_2?: string;
  CITY?: string;
  STATE?: string;
  ZIP_CODE?: string;
  NUMBER?: string;
  EXTENSION?: string;
  EMAIL?: string;
};
export type CoveragesSectionT = {
  PLAN_START_DATE?: string;
  PLAN_END_DATE?: string;
  HEALTH_PLAN?: string;
  LINE_OF_BUSINESS?: string;
  PRODUCT_ID?: string;
  MARKET?: string;
  LEGAL_ENTITY?: string;
  PLAN_TYPE?: string;
  LINE_OF_BUSINESS_DESCRIPTION?: string;
  GROUP_ID?: string;
  MARKET_NUMBER?: string;
  PRIMARY_CARE_PROVIDER?: ProviderOption | null;
  STATE_OF_ISSUE?: string;
  LINE_OF_BUSINESS_TYPE?: string;
  COVERAGE_PRODUCT_TYPE?: string;
};

export type FormError = {
  FIRST_NAME?: boolean;
  LAST_NAME?: boolean;
  DATE_OF_BIRTH?: boolean;
  GENDER?: boolean;
  ADDRESS_LINE_1?: boolean;
  ADDRESS_LINE_2?: boolean;
  PLAN_START_DATE?: boolean;
  CITY?: boolean;
  STATE?: boolean;
  ZIP_CODE?: boolean;
  PLAN_END_DATE?: boolean;
  HEALTH_PLAN?: boolean;
  LINE_OF_BUSINESS?: boolean;
  PRODUCT_ID?: boolean;
  MARKET?: boolean;
  LEGAL_ENTITY?: boolean;
  PLAN_TYPE?: boolean;
  LINE_OF_BUSINESS_DESCRIPTION?: boolean;
  GROUP_ID?: boolean;
  MARKET_NUMBER?: boolean;
  STATE_OF_ISSUE?: boolean;
  LINE_OF_BUSINESS_TYPE?: boolean;
  COVERAGE_PRODUCT_TYPE?: boolean;
  PRIMARY_CARE_PROVIDER?: boolean;
};

// Select Options
export const genderOptions = [
  { id: "F", label: "Female" },
  { id: "M", label: "Male" },
];

export const LINE_OF_BUSINESS_OPTIONS: { id: LineOfBusiness; label: string }[] = [
  {
    id: "Commercial",
    label: "Commercial",
  },
  {
    id: "Commercial_ASO",
    label: "Commercial ASO",
  },
  { id: "Commercial_Exchange", label: "Commercial Exchange" },
  { id: "MedSup", label: "MedSup" },
  {
    id: "Medicare",
    label: "Medicare",
  },
  {
    id: "Medicaid",
    label: "Medicaid",
  },
  { id: "Marketplace", label: "Marketplace" },
  { id: "Medicaid_Northeast", label: "Medicaid Northeast" },
  {
    id: "Other",
    label: "Other",
  },
  {
    id: "Other_TPA",
    label: "Other - TPA",
  },
  {
    id: "Other_CHIP",
    label: "Other - CHIP",
  },
];

// Default Form State for reducer
export const patientState = {
  FIRST_NAME: "",
  MIDDLE_NAME: "",
  LAST_NAME: "",
  DATE_OF_BIRTH: "",
  GENDER: "",
};
export const contactState = {
  ADDRESS_LINE_1: "",
  ADDRESS_LINE_2: "",
  CITY: "",
  STATE: "",
  ZIP_CODE: "",
};

export const coverageStateResetForHealthPlan = {
  PRODUCT_ID: "",
  MARKET: "",
  LEGAL_ENTITY: "",
  PLAN_TYPE: "",
  LINE_OF_BUSINESS_DESCRIPTION: "",
  GROUP_ID: "",
  LINE_OF_BUSINESS_TYPE: "",
  COVERAGE_PRODUCT_TYPE: "",
};

export const coveragesState = {
  PLAN_START_DATE: "",
  PLAN_END_DATE: "",
  HEALTH_PLAN: "",
  LINE_OF_BUSINESS: "",
  PRODUCT_ID: "",
  MARKET: "",
  MARKET_NUMBER: "",
  LEGAL_ENTITY: "",
  PLAN_TYPE: "",
  LINE_OF_BUSINESS_DESCRIPTION: "",
  GROUP_ID: "",
  PRIMARY_CARE_PROVIDER: {
    id: "",
    dateCreated: "",
    lastUpdated: "",
    address: { line1: "", line2: "", city: "", state: "" },
    name: "",
    npi: "",
  },
  STATE_OF_ISSUE: "",
  LINE_OF_BUSINESS_TYPE: "",
  COVERAGE_PRODUCT_TYPE: "",
};

export const initialFormError: FormError = {
  FIRST_NAME: true,
  LAST_NAME: true,
  DATE_OF_BIRTH: true,
  GENDER: true,
  ADDRESS_LINE_1: true,
  ADDRESS_LINE_2: true,
  CITY: true,
  STATE: true,
  ZIP_CODE: true,
  PLAN_START_DATE: true,
  PLAN_END_DATE: true,
  HEALTH_PLAN: true,
  LINE_OF_BUSINESS: true,
  PRODUCT_ID: true,
  MARKET: true,
  LEGAL_ENTITY: true,
  PLAN_TYPE: true,
  LINE_OF_BUSINESS_DESCRIPTION: true,
  GROUP_ID: true,
  MARKET_NUMBER: true,
  STATE_OF_ISSUE: true,
  LINE_OF_BUSINESS_TYPE: true,
  COVERAGE_PRODUCT_TYPE: true,
  PRIMARY_CARE_PROVIDER: true,
};

export const formErrorResetForHealthPlan: FormError = {
  PRODUCT_ID: true,
  MARKET: true,
  LEGAL_ENTITY: true,
  PLAN_TYPE: true,
  LINE_OF_BUSINESS_DESCRIPTION: true,
  GROUP_ID: true,
  MARKET_NUMBER: true,
  LINE_OF_BUSINESS_TYPE: true,
  COVERAGE_PRODUCT_TYPE: true,
};

export const initialFormState: FormState = {
  patientInfo: patientState,
  contactInfo: contactState,
  coveragesInfo: coveragesState,
  formError: initialFormError,
};
// format dates for BE
export const formattedDate = (dateSelection: null | Date) => {
  const date = dateSelection ? new Date(dateSelection) : null;
  const ignoreTime = date ? removeTimeFromDate(date) : null;
  const formatDate = ignoreTime ? formatDateToISODate(ignoreTime) : null;

  return formatDate === null ? "" : `${formatDate}`;
};

export const addOptionalLabel = (fieldId: string, optionalFields?: string[]): string => {
  return isOptionalField(fieldId, optionalFields) ? "(Optional)" : "";
};

export const isOptionalField = (fieldId: string, optionalFields?: string[]): boolean => {
  return Boolean(optionalFields?.includes(fieldId));
};
