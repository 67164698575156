import { useContext } from "react";
import { Patient, ProcedureCode } from "@coherehealth/core-platform-api";
import { PalCheckFormSectionProps } from "./HealthPlanPalCheckFormSectionConfigs";
import ProcedureCodeSelect from "components/AuthBuilder/ProcedureCodeSelect";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import { H4, H6, MiddleTruncateLabelWithEndornment } from "@coherehealth/common";
import { SuggestionContext } from "../SuggestionContext";
import { compareCodeListEqualityNoOrder, isNotNullOrUndefined } from "util/suggestionUtils";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";

type Props = PalCheckFormSectionProps & { patientData: Patient | null };

export default function ProcedureCodeSection({
  priorAuthRequirements,
  setPriorAuthRequirements,
  attemptedSubmit,
  generalAuthSubmissionStyling,
  patientData,
}: Props) {
  const { suggestedPriorAuthRequirements } = useContext(SuggestionContext);
  const hasCoverage = patientData?.coverages && patientData.coverages.length;
  const healthPlanName = hasCoverage ? patientData.coverages![0].healthPlanName : undefined;
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName,
    encounterType: priorAuthRequirements.encounterType,
    skipRequestTimingCheck: true,
  });

  const headerText = facilityBasedFeatureEnabled ? "Procedure codes (optional)" : "Procedure codes";

  const getPriorDesiredProcedureCodes = () => {
    // We map out the individual procedure codes here to be used in a moment to avoid displaying duplicate procedure codes.
    const individualProcedureCodes = priorAuthRequirements.desiredProcedureCodes?.map(
      (procedureCode) => procedureCode.code
    );

    // Here, we filter based on uniqueness using `individualProcedureCodes` - any procedure code that matches an earlier index indicates it's a duplicate and is thus dropped via the filter.
    return (
      priorAuthRequirements.desiredProcedureCodes?.filter((procedureCode, index) => {
        return individualProcedureCodes?.indexOf(procedureCode.code) === index;
      }) || []
    );
  };

  return (
    <>
      {generalAuthSubmissionStyling && <CustomSectionHeaderH4 data-public>{headerText}</CustomSectionHeaderH4>}
      {!generalAuthSubmissionStyling && <CustomSectionHeaderH6 data-public>{headerText}</CustomSectionHeaderH6>}
      <ProcedureCodeSelect
        healthPlanName={healthPlanName}
        encounterType={priorAuthRequirements.encounterType}
        label={
          <MiddleTruncateLabelWithEndornment
            leftLabel="Search for CPT/HCPCS codes"
            rightLabel={facilityBasedFeatureEnabled ? "(optional)" : ""}
          />
        }
        attemptedSubmit={attemptedSubmit}
        procedureCodes={getPriorDesiredProcedureCodes()}
        setProcedureCodes={(codes: ProcedureCode[]) => {
          setPriorAuthRequirements({
            ...priorAuthRequirements,
            desiredProcedureCodes: codes || undefined,
            noPxService: undefined,
            userSelectedNonPalCode: false,
          });
        }}
        isAutoFilled={
          suggestedPriorAuthRequirements?.desiredProcedureCodes
            ? compareCodeListEqualityNoOrder(
                suggestedPriorAuthRequirements.desiredProcedureCodes
                  .map((clientSV) => clientSV.procedureCodeEntity)
                  .filter(isNotNullOrUndefined),
                priorAuthRequirements?.desiredProcedureCodes
              )
            : false
        }
        blockDuplicateSelections
      />
    </>
  );
}

// Need -7px on top to match designs
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomSectionHeaderH4 = styled(H4)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: "-7px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomSectionHeaderH6 = styled(H6)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: "-7px",
}));
