import { H6 } from "@coherehealth/common";
import { ProcedureCode } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import RevenueCodeSelect from "../RevenueCodeSelect";
import { PalCheckFormSectionProps } from "./HealthPlanPalCheckFormSectionConfigs";

export default function RevenueCodeSection({
  priorAuthRequirements,
  setPriorAuthRequirements,
  attemptedSubmit,
  resetPalResults,
}: PalCheckFormSectionProps) {
  return (
    <>
      <CustomSectionHeader>Services</CustomSectionHeader>
      <RevenueCodeSelect
        label="Select Home Health services"
        attemptedSubmit={attemptedSubmit}
        procedureCodes={priorAuthRequirements.desiredProcedureCodes || []}
        setProcedureCodes={(codes: ProcedureCode[]) => {
          setPriorAuthRequirements({
            ...priorAuthRequirements,
            desiredProcedureCodes: codes || undefined,
            noPxService: undefined,
          });
          resetPalResults();
        }}
      />
    </>
  );
}

// Need -7px on top to match designs
// eslint-disable-next-line cohere-react/no-mui-styled-import
const CustomSectionHeader = styled(H6)(({ theme }) => ({
  marginTop: "-7px",
  marginBottom: theme.spacing(2),
}));
