import Button from "@material-ui/core/Button";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Link, useLocation } from "react-router-dom";
import routes from "routes";
import CoBrandedLogo from "../../common/CoBrandedLogo";
import { useFeature } from "@coherehealth/common";

interface Props {
  classes?: ClassNameMap;
  isDesktopScreen?: boolean;
  isVerySmallScreen?: boolean;
}

function HeaderLogo({ classes, isDesktopScreen, isVerySmallScreen }: Props) {
  const currentPage = useLocation().pathname;
  const referralManagementFF = useFeature("referralManagement");

  const getRoute = () => {
    if (!referralManagementFF) {
      return routes.DASHBOARD;
    }
    // not proud of this, but no eay alternative : /
    if (currentPage.includes("referral")) {
      return routes.REFERRAL_DASHBOARD;
    } else {
      return routes.DASHBOARD;
    }
  };

  return (
    <Button
      data-public
      className={classes?.bannerIcon}
      to={getRoute()}
      component={Link}
      aria-label="Cohere Health home"
    >
      <CoBrandedLogo isDesktopScreen={isDesktopScreen} isVerySmallScreen={isVerySmallScreen} />
    </Button>
  );
}

export default HeaderLogo;
