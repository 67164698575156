import { ReactNode, useState } from "react";
import { Caption } from "../Typography";
import Fade from "@material-ui/core/Fade";
import MuiTable, { TableProps as MuiTableProps } from "@material-ui/core/Table";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableCell, { TableCellProps as MuiTableCellProps } from "@material-ui/core/TableCell";
import MuiTableContainer, { TableContainerProps as MuiTableContainerProps } from "@material-ui/core/TableContainer";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableRow, { TableRowProps as MuiTableRowProps } from "@material-ui/core/TableRow";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled, Theme } from "@material-ui/core/styles";
import MuiDeleteIcon from "@material-ui/icons/Delete";
import { InlineButton } from "../InlineButton";
import { colorsLight } from "../../themes";

interface BaseRowData {
  id: string | number;
}

interface Props<T extends BaseRowData> extends MuiTableContainerProps {
  headers: string[];
  tableData: T[];
  onRemoveRow?: (rowIndex: number) => void;
  TableRowProps?: MuiTableRowProps;
  TableProps?: MuiTableProps;
  children: (props: RowRenderProps<T>) => CellRenderResult[];
  noPadding?: boolean;
  withDivider?: boolean;
  withRowDivider?: boolean;
  lightHeaderText?: boolean;
  responsive?: boolean;
}

interface CellRenderResult extends MuiTableCellProps {
  contents: ReactNode;
}
interface RowRenderProps<T> {
  rowData: T;
  rowIndex: number;
}

export default function FormTable<T extends BaseRowData>({
  headers,
  tableData,
  children,
  onRemoveRow,
  TableRowProps,
  TableProps,
  noPadding,
  withDivider,
  withRowDivider,
  lightHeaderText,
  responsive,
  ...muiTableContainerProps
}: Props<T>) {
  return (
    <MuiTableContainer style={{}} {...muiTableContainerProps}>
      <MuiTable {...TableProps}>
        <StyledMuiTableHead withDivider={withDivider}>
          <MuiTableRow>
            {headers.map((headerText, idx) => (
              <StyledMuiTableHeaderCell key={idx} noPadding={noPadding} lightHeaderText={lightHeaderText}>
                <Caption>{headerText}</Caption>
              </StyledMuiTableHeaderCell>
            ))}
          </MuiTableRow>
        </StyledMuiTableHead>
        <MuiTableBody>
          {tableData.map((rowData, idx) => {
            return (
              <TableRow<T>
                withRowDivider={withRowDivider}
                key={rowData.id}
                rowData={rowData}
                rowIndex={idx}
                onRemoveRow={onRemoveRow}
                noPadding={noPadding}
                responsive={responsive}
                {...TableRowProps}
              >
                {children}
              </TableRow>
            );
          })}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
}

interface TableRowProps<T extends BaseRowData>
  extends Pick<Props<T>, "children" | "onRemoveRow">,
    Omit<MuiTableRowProps, "children"> {
  rowData: T;
  rowIndex: number;
  noPadding?: boolean;
  withRowDivider?: boolean;
  responsive?: boolean;
}

function TableRow<T extends BaseRowData>({
  children,
  responsive,
  onRemoveRow,
  rowData,
  rowIndex,
  noPadding,
  withRowDivider = true,
  ...tableRowPassthroughProps
}: TableRowProps<T>) {
  const [hovered, setHovered] = useState(false);

  return (
    <StyledMuiTableRow
      withRowDivider={withRowDivider}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...tableRowPassthroughProps}
    >
      {children({ rowData, rowIndex }).map(({ contents, width, ...tableCellPassthroughProps }, cellIdx) => (
        <StyledMuiTableCell
          {...tableCellPassthroughProps}
          key={`row-${rowData.id}_cell-${cellIdx}`}
          noPadding={noPadding}
          width={width}
        >
          {contents}
        </StyledMuiTableCell>
      ))}
      {!!onRemoveRow && !responsive && (
        <StyledMuiTableCell align="right" noPadding={noPadding}>
          <Fade in={hovered}>
            <DeleteButton onClick={() => onRemoveRow(rowIndex)} startIcon={<DeleteIcon />}>
              Remove
            </DeleteButton>
          </Fade>
        </StyledMuiTableCell>
      )}
    </StyledMuiTableRow>
  );
}

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledMuiTableCell = styled(({ noPadding, width, ...other }) => <MuiTableCell {...other} />)<
  Theme,
  { noPadding: boolean | undefined; width?: number | string }
>(({ theme, noPadding, width }) => ({
  borderColor: theme.palette.divider,
  padding: theme.spacing(0.75, noPadding ? 0 : 0.75),
  width: width ? width : "auto",
  "&:nth-child(1)": {
    width: width ? width : "99px",
  },
  "&:nth-child(2)": {
    width: width ? width : "120px",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledMuiTableHead = styled(({ withDivider, ...other }) => <MuiTableHead {...other} />)<
  Theme,
  { withDivider: boolean | undefined }
>(({ theme, withDivider }) => ({
  "& .MuiTableCell-root": {
    borderBottom: withDivider ? "1px solid #E5E5E5" : "none",
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledMuiTableRow = styled(({ withRowDivider, ...other }) => <MuiTableRow {...other} />)<
  Theme,
  { withRowDivider: boolean | undefined }
>(({ theme, withRowDivider }) => ({
  "& .MuiTableCell-root": {
    borderBottom: withRowDivider ? "1px solid #E5E5E5" : "none",
    overflow: "hidden",
  },
  "&:last-child": {
    "& .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  "&.MuiTableRow-hover": {
    backgroundColor: "rgba(0, 0, 0, 0.0)",
    transition: theme.transitions.create("background-color"),
  },
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DeleteButton = styled(InlineButton)({
  color: colorsLight.font.secondary,
  "&:hover": {
    color: colorsLight.font.light,
  },
  fontSize: "15px",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const StyledMuiTableHeaderCell = styled(({ noPadding, lightHeaderText, ...other }) => <MuiTableCell {...other} />)<
  Theme,
  { noPadding: boolean | undefined; lightHeaderText: boolean | undefined }
>(({ theme, noPadding, lightHeaderText }) => ({
  padding: theme.spacing(0, noPadding ? 0 : 1.5, lightHeaderText ? 0.5 : 0, noPadding ? 0 : 1.5),
  color: lightHeaderText ? theme.palette.text.secondary : "initial",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DeleteIcon = styled(MuiDeleteIcon)({
  height: "24px",
  width: "24px",
});
