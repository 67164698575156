import {
  AuthorizationResponse,
  AuthStatus,
  ClinicalService,
  Patient,
  ProcedureCode,
} from "@coherehealth/core-platform-api";
import { Grid, makeStyles } from "@material-ui/core";
import { H6 } from "@coherehealth/design-system";
import UnitTextField from "../../ServiceRequest/ServiceRequestForm/components/UnitTextField";
import ProcedureCodeTable from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeTable";
import ProcedureCodeSelector from "../../ServiceRequest/ServiceRequestForm/components/ProcedureCodeSelector";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { constructProcedureCodes } from "util/clinicalAssessment";
import {
  MultiSelectDropdown,
  ProcedureCodeWithId,
  SingleSelectDropdown,
  TextField,
  withId,
} from "@coherehealth/common";
import { ServiceRequestFormContent } from "components/ServiceRequest";
import { NonCohereCodes } from "../common";
import { useSnackbar } from "notistack";
import listReplace from "util/listReplace";
import { useTrackUserImpression } from "util/userActivityTracker";
import { getPatientHealthPlanName } from "util/patientUtils";
import { getPendingAuthStatuses, useCanEditUnits } from "util/serviceRequest";
import { useTheme } from "@mui/material/styles";
import { useAuthorized } from "authorization";
import { UnitsContinuationWidth, UnitsInitialWidth } from "common/SharedServiceRequestFormComponents";
import { useLocation } from "react-router";
import { useIsFaxAuthBuilderWorkflow } from "util/attachmentUtil";
import { useDebouncedCallback, usePrevious } from "@react-hookz/web";
import usePxAttributeOptions from "hooks/usePxAttributeOptions";

export interface Props {
  formContent: ServiceRequestFormContent;
  clinicalService?: ClinicalService;
  procedureCodes: ProcedureCodeWithId[];
  setProcedureCodes: React.Dispatch<React.SetStateAction<ProcedureCodeWithId[]>>;
  setUnits: Dispatch<SetStateAction<string>>;
  setNonPalPDFOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNonPalProcedureCodes: Dispatch<SetStateAction<ProcedureCode[]>>;
  nonPalProcedureCodes: ProcedureCode[];
  nonCohereProcedureCodes: NonCohereCodes[];
  setNonCohereProcedureCodes: Dispatch<SetStateAction<NonCohereCodes[]>>;
  attemptedSubmit: boolean;
  palProcedureCodes: ProcedureCode[];
  setPalProcedureCodes: (pxs: ProcedureCode[]) => void;
  patient: Patient | null;
  authorization?: AuthorizationResponse;
  requestType?: string;
  authStatus: AuthStatus;
  isPxCodesValid?: boolean;
  onPatientSummary?: boolean;
  isUsedInPACheckRedesign?: boolean;
  lastElement: boolean;
  showExpedited: boolean;
  servicesFormContents?: ServiceRequestFormContent[];
  isFormContentMatchingServiceRequestPxCode?: boolean;
  setIsAdditionalUnitsRequested?: Dispatch<SetStateAction<boolean>>;
  allowedPxCodePerPayer?: number;
  doPalOrCrdCheck?: (payload: { desiredProcedureCodes: ProcedureCode[] }) => Promise<void>;
  showPxBasedAttributedField?: boolean;
  showPxAttributeValidation?: boolean;
}

const useStyles = makeStyles((theme) => ({
  initialUnits: {
    flex: `0 0 ${UnitsInitialWidth}px`,
    width: UnitsInitialWidth,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  continuationUnits: {
    flex: `0 1 ${UnitsContinuationWidth}px`,
    minWidth: "100px",
    marginBottom: theme.spacing(1),
  },
  procedureCodeErrorText: {
    color: theme.palette.error.dark,
    fontFamily: "Gilroy-Regular",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0.15px",
    marginBottom: "16px",
  },
}));

const AUTOFILL_TIMEOUT = 1200;
export default function ProceduresBucket({
  formContent,
  clinicalService,
  procedureCodes,
  setProcedureCodes,
  setUnits,
  setNonPalPDFOpen,
  setNonPalProcedureCodes,
  nonPalProcedureCodes,
  nonCohereProcedureCodes,
  attemptedSubmit,
  palProcedureCodes,
  setPalProcedureCodes,
  setNonCohereProcedureCodes,
  patient,
  authorization,
  requestType,
  authStatus,
  isPxCodesValid,
  onPatientSummary,
  isUsedInPACheckRedesign,
  isFormContentMatchingServiceRequestPxCode,
  setIsAdditionalUnitsRequested,
  servicesFormContents,
  allowedPxCodePerPayer,
  doPalOrCrdCheck,
  showPxBasedAttributedField,
  showPxAttributeValidation,
}: Props) {
  const trackUserActivityImpression = useTrackUserImpression();
  const [procedureCodeSelectionActive, setProcedureCodeSelectionActive] = useState(false);
  const [onBlurServiceLevelUnits, setOnBlurServiceLevelUnits] = useState(0);
  const prevOnBlurServiceLevelUnits = usePrevious(onBlurServiceLevelUnits);
  const isUnitsOnPx = clinicalService ? !!clinicalService?.isUnitsOnPx : true;
  const { enqueueSnackbar } = useSnackbar();
  const initialRender = useRef(true);
  const [autoFilled, setAutoFilled] = useState(false);
  const [serviceLevelUnits, setServiceLevelUnits] = useState(formContent?.units);
  const prevServiceLevelUnits = usePrevious(serviceLevelUnits);
  const previousCareType = usePrevious(formContent?.isInpatient);
  const prevProcedureCodes = usePrevious(procedureCodes);
  const [isSinglePxUpdate, setIsSinglePxUpdate] = useState(false);
  const cssClasses = useStyles();
  const { spacing } = useTheme();
  //temporary
  const minNumUnits = 1;
  const isContinuation = requestType === "CONTINUATION";
  const validUnits = isContinuation
    ? Number.isInteger(Number(formContent?.units)) && !!isPxCodesValid
    : parseInt(formContent?.units) > Math.max(0, minNumUnits - 1) && Number.isInteger(Number(formContent?.units));

  const location = useLocation();
  const isFaxAuthBuilderFlow = useIsFaxAuthBuilderWorkflow(location);

  const updateProcedureCodes = useCallback(() => {
    const newProcedureCodes = constructProcedureCodes({
      procedureCodes: procedureCodes,
      prevIsInpatient: previousCareType,
      isInpatient: formContent.isInpatient,
      prevServiceLevelUnits: prevServiceLevelUnits,
      serviceLevelUnits: formContent.units,
      clinicalServiceId: clinicalService?.id || "",
      isUnitsOnPx: isUnitsOnPx || false,
      isContinuation: isContinuation || false,
    }).map(withId);
    // this will need to be updated to only set procedure code data on current set of procedure codes
    setProcedureCodes(newProcedureCodes);
  }, [
    procedureCodes,
    previousCareType,
    formContent.isInpatient,
    formContent.units,
    prevServiceLevelUnits,
    clinicalService?.id,
    isUnitsOnPx,
    isContinuation,
    setProcedureCodes,
  ]);
  useEffect(() => {
    /** Check if serviceLevelUnits has not been set yet and serviceLevelUnits has lost focus, then set serviceLevelUnits **/
    if (serviceLevelUnits !== formContent?.units && prevOnBlurServiceLevelUnits !== onBlurServiceLevelUnits) {
      setServiceLevelUnits(formContent?.units);
      updateProcedureCodes();
    }
  }, [
    formContent.units,
    onBlurServiceLevelUnits,
    prevOnBlurServiceLevelUnits,
    serviceLevelUnits,
    updateProcedureCodes,
    setServiceLevelUnits,
  ]);

  // TODO this will need to be refactored since the move into this component
  //this is probably the cause of erasing old px codes when adding new
  const addPxCodeToFormContent = (codes: ProcedureCode[]) => {
    //close the selection dropdown after the user has made a selection
    setProcedureCodeSelectionActive(false);
    /**
     * if new codes are added then update construct and update state,
     * this is to make sure if the palCheck doesn't add the code we don't add the last code again
     */
    if (codes.length > procedureCodes.length) {
      const newProcedureCodes = constructProcedureCodes({
        procedureCodes: [codes[codes.length - 1]],
        prevIsInpatient: false,
        isInpatient: formContent.isInpatient,
        prevServiceLevelUnits: prevServiceLevelUnits,
        serviceLevelUnits: formContent.units,
        clinicalServiceId: clinicalService?.id || "",
        isUnitsOnPx: isUnitsOnPx || false,
        isContinuation,
      }).map(withId);

      setProcedureCodes((prev) => {
        return [...prev, ...newProcedureCodes];
      });
    }
  };

  useDebouncedCallback(
    () => {
      /** After debouncing for 1000, check if serviceLevelUnits has been set yet, if not set then set serviceLevelUnits **/
      if (serviceLevelUnits !== formContent?.units && !isUnitsOnPx) {
        setServiceLevelUnits(formContent?.units);
        updateProcedureCodes();
      }
    },
    [formContent?.units],
    1000
  );

  useEffect(() => {
    /** Check if care type was switched **/
    if (formContent?.isInpatient !== previousCareType) {
      setServiceLevelUnits(formContent?.units);
      updateProcedureCodes();
    }
  }, [formContent?.isInpatient, formContent?.units, previousCareType, serviceLevelUnits, updateProcedureCodes]);

  /* Checks if all procedureCodeUnits have changed */
  const hasProcedureCodeChanged = useCallback((): boolean => {
    /* If no new pxCodes have been added to the list and no singlePxField update has happened, then check all the curr pxCodes units !==  prev pxCode units */
    if (prevProcedureCodes && prevProcedureCodes.length === procedureCodes.length && !isSinglePxUpdate && isUnitsOnPx) {
      return prevProcedureCodes.every((prevCode) => {
        return procedureCodes.find((currCode) => prevCode.code === currCode.code && prevCode.units !== currCode.units);
      });
    }
    return false;
  }, [isSinglePxUpdate, isUnitsOnPx, prevProcedureCodes, procedureCodes]);

  useDebouncedCallback(
    () => {
      const exceedsMax = procedureCodes.filter(
        (pxCode) => pxCode.units && pxCode.maxUnits && pxCode.units > (pxCode.maxUnits ?? 1) * Number(serviceLevelUnits)
      );
      if (isSinglePxUpdate && exceedsMax && exceedsMax.length > 0) {
        trackUserActivityImpression({
          event: "EXCEEDED_MAX_UNITS_FOR_PXCODES",
          stage: "AUTH_CREATION",
          activityContext: {
            procedureCodes: exceedsMax,
            serviceRequestId: formContent?.id,
            cohereAuthId: formContent?.cohereId,
          },
        });
      }
      setIsSinglePxUpdate(false);
    },
    [procedureCodes, isSinglePxUpdate],
    1000
  );

  useEffect(() => {
    if (!initialRender.current && serviceLevelUnits && hasProcedureCodeChanged() && !isUnitsOnPx) {
      /** Apply autofill animation for 1200ms then revert back to original state **/
      setAutoFilled(true);
      setTimeout(() => {
        setAutoFilled(false);
      }, AUTOFILL_TIMEOUT);
      enqueueSnackbar(
        `Default units updated based on what most people request for ${serviceLevelUnits} ${
          !formContent.isInpatient ? "outpatient" : "inpatient"
        } service ${Number(serviceLevelUnits) > 1 ? "dates" : "date"}`,
        {
          variant: "info",
        }
      );
    }
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [
    enqueueSnackbar,
    formContent.isInpatient,
    prevProcedureCodes,
    procedureCodes,
    serviceLevelUnits,
    isSinglePxUpdate,
    hasProcedureCodeChanged,
    isUnitsOnPx,
  ]);

  useEffect(() => {
    if (!isFormContentMatchingServiceRequestPxCode && procedureCodes) {
      let calculatedUnits = formContent?.units;
      if (isUnitsOnPx) {
        calculatedUnits = procedureCodes.reduce((a, b) => a + (b.units ? b.units : 0), 0).toString();
      }
      if (calculatedUnits !== formContent?.units) {
        setUnits(calculatedUnits);
      }
    }
  }, [procedureCodes, setUnits, isUnitsOnPx, formContent?.units, isFormContentMatchingServiceRequestPxCode]);

  const updatePxCodeUnits = (rowIndex: number, units: string) => {
    const updatedProcedureCodes = [...procedureCodes];
    setIsSinglePxUpdate(true);
    setProcedureCodes(
      listReplace(updatedProcedureCodes, rowIndex, {
        ...updatedProcedureCodes[rowIndex],
        units: /^\d*$/.test(units)
          ? units !== ""
            ? Number(units)
            : undefined
          : updatedProcedureCodes[rowIndex]?.units,
      })
    );
  };

  const updatePxCodeSelectedDetails = (rowIndex: number, selectedDetails: string[]) => {
    const updatedProcedureCodes = [...procedureCodes];
    setIsSinglePxUpdate(true);
    setProcedureCodes(
      listReplace(updatedProcedureCodes, rowIndex, {
        ...updatedProcedureCodes[rowIndex],
        selectedDetails: selectedDetails,
      })
    );
  };

  // Function to update the selectedDetails for all procedure codes in the procedureCodes array
  const updatePxCodeSelectedDetailsForNotUnitsOnPx = (selectedDetails: string[]) => {
    // Create a shallow copy of the existing procedureCodes array to avoid mutating the original state
    let updated = [...procedureCodes];

    // Iterate through each procedure code in the procedureCodes array
    for (let i = 0; i < procedureCodes.length; i++) {
      // Update each procedure code with the new selectedDetails
      updated[i] = { ...updated[i], selectedDetails }; // Spread the existing properties and update selectedDetails
    }

    // Update the procedureCodes state with the modified array
    setProcedureCodes(updated);
  };

  const healthPlanName = getPatientHealthPlanName(patient || undefined) || "";
  const canEditUnits = useCanEditUnits({ authStatus });
  const canAddPxPendingSR = useAuthorized("ADD_PX_PENDING_SERV_REQUEST");
  // Iterates over each clinical service, sums up the length of procedure codes arrays
  const totalProcedureCount = servicesFormContents?.reduce((total, value) => total + value.procedureCodes.length, 0);
  // Checks if the total count is less than or equal to maxAllowedPxCodePerPayer
  const canAddMoreProcedureCodes = Boolean(
    totalProcedureCount && allowedPxCodePerPayer && totalProcedureCount < allowedPxCodePerPayer
  );
  const isPxCodeLimitExceeded = Boolean(
    allowedPxCodePerPayer &&
      allowedPxCodePerPayer > 0 &&
      totalProcedureCount &&
      totalProcedureCount > allowedPxCodePerPayer
  );

  // State to track whether the SingleSelectDropdown or MultiSelectDropDown is currently focused.
  // This is used to change the label from "Select details" to "Details" when the dropdown is focused.
  const [isFocused, setIsFocused] = useState(false);

  const { options: pxAttributeOptions } = usePxAttributeOptions(procedureCodes);

  // Function to retrieve the first valid relationship and attribute from an array of ProcedureCode objects
  function getRelationshipAndAttribute(procedureCodes: ProcedureCode[]) {
    // Iterate through each procedure code in the provided array
    for (const code of procedureCodes) {
      const pxCodeOptions = pxAttributeOptions.get(code?.code);
      // Check if both relationshipTo and attributeType are not null
      if (code.relationshipTo !== null && code.attributeType !== null && pxCodeOptions) {
        // Return an object containing the relationship and attribute if valid
        return {
          relationshipTo: pxCodeOptions,
          attributeType: code.attributeType,
        };
      }
    }
    // Return null if no valid entry is found
    return null;
  }

  // Call the function to get the relationship and attribute from the procedure codes
  const pxCodeRelationshipAndAttributes = getRelationshipAndAttribute(procedureCodes);

  // Check if the attribute type is "Free text" to determine the dropdown behavior
  const isAttributeTypeFreeText = pxCodeRelationshipAndAttributes?.attributeType === "Free text";

  // Create dropdown options from the relationshipTo array if the attributeType is not "Free text"
  // If it is "Single-select," generate dropdown options
  const dropdownOptions =
    !isAttributeTypeFreeText && pxCodeRelationshipAndAttributes?.relationshipTo
      ? pxCodeRelationshipAndAttributes.relationshipTo
      : []; // Return an empty array if the attribute type is free text or no relationshipTo is found

  // Create an array of selected details from the procedure codes
  const selectedDetails: string[] = procedureCodes
    .filter(
      (pxCode) =>
        pxCode.attributeType !== undefined && // Ensure attributeType is defined
        pxCode && // Ensure pxCode is truthy
        pxCode.relationshipTo && // Ensure relationshipTo is defined
        pxCode.relationshipTo.length > 0 // Ensure relationshipTo has at least one entry
    ) // Filter for relevant entries
    .flatMap((pxCode) => pxCode.selectedDetails || []) // Flatten the selected details from filtered procedure codes
    .filter(Boolean); // Remove any falsy values from the resulting array

  return (
    <>
      <Grid item xs={12} style={{ marginTop: spacing(4), paddingBottom: "16px" }}>
        <H6
          style={{ marginBottom: isFaxAuthBuilderFlow ? (isUnitsOnPx ? spacing(0.5) : spacing(2)) : spacing(1) }}
          data-public
        >
          {clinicalService?.name || "Uncategorized service"}
        </H6>
        {isPxCodeLimitExceeded && (
          <div className={cssClasses.procedureCodeErrorText}>
            {`${healthPlanName} allows up to ${allowedPxCodePerPayer} codes. Please remove codes to continue.`}
          </div>
        )}
      </Grid>
      {!isUnitsOnPx && !isContinuation && procedureCodes.length > 0 && (
        <Grid item className={cssClasses.initialUnits}>
          <UnitTextField
            initialUnits={formContent?.units?.toString() || clinicalService?.defaultUnits?.toString() || "1"}
            units={formContent.units}
            setUnits={setUnits}
            unitLabel={"Number of visits"}
            onBlurServiceLevelUnits={onBlurServiceLevelUnits}
            setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
            hasValidUnits={validUnits}
            disabled={!canEditUnits}
            defaultUnits={clinicalService?.defaultUnits}
            attemptedSubmit={attemptedSubmit}
            minNumUnits={1}
          />
        </Grid>
      )}

      {showPxBasedAttributedField &&
        pxCodeRelationshipAndAttributes?.attributeType === "Single-select" &&
        !isUnitsOnPx &&
        !isContinuation &&
        procedureCodes.length > 0 && (
          <Grid item xs={4}>
            {/* Show SingleSelectDropdown if attributeType is "Single-select" */}
            <SingleSelectDropdown
              options={dropdownOptions}
              label={
                isFocused && selectedDetails?.length
                  ? "Details"
                  : isFocused
                  ? "Details"
                  : !isFocused && selectedDetails?.length
                  ? "Details"
                  : "Select details"
              }
              onFocus={() => setIsFocused?.(true)}
              onBlur={() => setIsFocused?.(false)}
              disabled={!canEditUnits}
              error={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]}
              helperText={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0] ? "Required" : ""}
              value={selectedDetails?.[0] || ""}
              onChange={function (selectedDetails): void {
                if (updatePxCodeSelectedDetailsForNotUnitsOnPx !== undefined) {
                  updatePxCodeSelectedDetailsForNotUnitsOnPx([selectedDetails]);
                }
              }}
            />
          </Grid>
        )}

      {showPxBasedAttributedField &&
        pxCodeRelationshipAndAttributes?.attributeType === "Multi-select" &&
        !isUnitsOnPx &&
        !isContinuation &&
        procedureCodes.length > 0 && (
          <Grid item xs={4}>
            <MultiSelectDropdown
              options={dropdownOptions}
              selectedValues={Array.from(new Set([...selectedDetails]))}
              disabled={!canEditUnits}
              onChange={function (selectedDetails): void {
                if (updatePxCodeSelectedDetailsForNotUnitsOnPx !== undefined) {
                  updatePxCodeSelectedDetailsForNotUnitsOnPx(selectedDetails);
                }
              }}
              label={
                isFocused && selectedDetails?.length
                  ? "Details"
                  : isFocused
                  ? "Details"
                  : !isFocused && selectedDetails?.length
                  ? "Details"
                  : "Select details"
              }
              onFocus={() => setIsFocused?.(true)}
              onBlur={() => setIsFocused?.(false)}
              error={attemptedSubmit && showPxAttributeValidation && !Array.from(new Set([...selectedDetails])).length}
              helperText={
                attemptedSubmit && showPxAttributeValidation && !Array.from(new Set([...selectedDetails])).length
                  ? "Required"
                  : ""
              }
            />
          </Grid>
        )}

      {showPxBasedAttributedField &&
        isAttributeTypeFreeText &&
        !isUnitsOnPx &&
        !isContinuation &&
        procedureCodes.length > 0 && (
          <Grid item xs={4}>
            <TextField
              label={
                isFocused && selectedDetails?.length
                  ? "Details"
                  : isFocused
                  ? "Details"
                  : !isFocused && selectedDetails?.length
                  ? "Details"
                  : "Explain why an unlisted code is needed"
              }
              fullWidth
              multiline
              disabled={!canEditUnits}
              value={selectedDetails?.[0] || ""}
              onFocus={() => setIsFocused?.(true)}
              onBlur={() => setIsFocused?.(false)}
              error={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0]}
              helperText={attemptedSubmit && showPxAttributeValidation && !selectedDetails?.[0] ? "Required" : ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatePxCodeSelectedDetailsForNotUnitsOnPx) {
                  const newValue = event.target.value;
                  updatePxCodeSelectedDetailsForNotUnitsOnPx?.(newValue ? [newValue] : []);
                }
              }}
            />
          </Grid>
        )}

      {!isUnitsOnPx && isContinuation && procedureCodes.length > 0 && (
        <Grid
          item
          xs
          style={{
            display: "flex",
          }}
        >
          <Grid
            className={cssClasses.continuationUnits}
            style={{
              marginRight: spacing(2),
            }}
          >
            <UnitTextField
              initialUnits={
                authorization?.semanticProcedureCodes
                  ?.find((px) => procedureCodes.find((code) => code.code === px.code && code.groupId === px.groupId))
                  ?.approvedUnits?.toString() || "-"
              }
              units={
                authorization?.semanticProcedureCodes
                  ?.filter((px) => procedureCodes.find((code) => code.code === px.code && code.groupId === px.groupId))
                  ?.reduce((a, b) => (a > (b.approvedUnits ? b.approvedUnits : 0) ? a : b?.approvedUnits || 0), 0)
                  .toString() || "-"
              }
              setUnits={setUnits}
              unitLabel={"Previously approved visits"}
              onBlurServiceLevelUnits={onBlurServiceLevelUnits}
              setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
              // Don't check valid units when this is in the continuations workflow, since this will be a read-only field
              hasValidUnits={true}
              disabled={true}
              defaultUnits={clinicalService?.defaultUnits}
              attemptedSubmit={attemptedSubmit}
              minNumUnits={1}
            />
          </Grid>
          <Grid className={cssClasses.continuationUnits}>
            <UnitTextField
              units={formContent.units}
              initialUnits=""
              setUnits={setUnits}
              unitLabel={"Additional visits"}
              onBlurServiceLevelUnits={onBlurServiceLevelUnits}
              setOnBlurServiceLevelUnits={setOnBlurServiceLevelUnits}
              hasValidUnits={validUnits}
              defaultUnits={clinicalService?.defaultUnits}
              attemptedSubmit={attemptedSubmit}
              minNumUnits={minNumUnits}
              disabled={!canEditUnits}
              requestType={requestType}
              setIsAdditionalUnitsRequested={setIsAdditionalUnitsRequested}
            />
          </Grid>
        </Grid>
      )}
      <Grid xs={12} style={{ marginBottom: 14 }}>
        <ProcedureCodeTable
          hideHeaders={true}
          formContent={formContent}
          setNonPalPDFOpen={setNonPalPDFOpen}
          nonPalProcedureCodes={nonPalProcedureCodes}
          nonCohereProcedureCodes={nonCohereProcedureCodes}
          procedureCodes={procedureCodes}
          setProcedureCodes={setProcedureCodes}
          setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
          serviceLevelUnits={serviceLevelUnits}
          isUnitsOnPx={isUnitsOnPx}
          autoFilled={autoFilled}
          updatePxCodeUnits={updatePxCodeUnits}
          updatePxCodeSelectedDetails={updatePxCodeSelectedDetails}
          attemptedSubmit={attemptedSubmit}
          noPadding={true}
          withRowDivider={false}
          isContinuation={isContinuation}
          authorization={authorization}
          healthPlanName={healthPlanName}
          authStatus={authStatus}
          isPxCodesValid={isPxCodesValid}
          requestType={requestType}
          onPatientSummary={onPatientSummary}
          isUsedInPACheckRedesign={isUsedInPACheckRedesign}
          setIsAdditionalUnitsRequested={setIsAdditionalUnitsRequested}
          showPxBasedAttributedField={showPxBasedAttributedField}
          showPxAttributeValidation={showPxAttributeValidation}
        />
        {(!onPatientSummary || (getPendingAuthStatuses().includes(authStatus || "") && canAddPxPendingSR)) && (
          <ProcedureCodeSelector
            formContent={formContent}
            canAddMoreProcedureCodes={canAddMoreProcedureCodes && !isPxCodeLimitExceeded}
            allowedPxCodePerPayer={allowedPxCodePerPayer}
            palProcedureCodes={palProcedureCodes}
            setPalProcedureCodes={setPalProcedureCodes}
            setNonPalPDFOpen={setNonPalPDFOpen}
            nonPalProcedureCodes={nonPalProcedureCodes}
            setNonPalProcedureCodes={setNonPalProcedureCodes}
            nonCohereProcedureCodes={nonCohereProcedureCodes}
            setNonCohereProcedureCodes={setNonCohereProcedureCodes}
            procedureCodeSelectionActive={procedureCodeSelectionActive}
            setProcedureCodeSelectionActive={setProcedureCodeSelectionActive}
            procedureCodes={procedureCodes}
            setProcedureCodes={(codes) => addPxCodeToFormContent(codes)}
            patientId={patient?.id}
            healthPlanName={healthPlanName}
            attemptedSubmit={attemptedSubmit}
            error={formContent.isInpatient ? procedureCodes.length < 0 : !isPxCodesValid}
            isPxCodesValid={isPxCodesValid}
            doPalOrCrdCheck={doPalOrCrdCheck}
          />
        )}
      </Grid>
    </>
  );
}
