import { createContext, useState, useContext, useEffect, useMemo, useCallback } from "react";

import { Patient, ReferralRequestResponse, ServiceRequestResponse } from "@coherehealth/core-platform-api";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FaxAttachmentContext } from "./FaxAttachmentContext";
import { DocumentInfo } from "../DocumentViewer";
import AttachToServiceRequestSidebar from "./AttachToServiceRequestSidebar";
import CurrentlyAttachedServiceRequestsSidebar from "./CurrentlyAttachedServiceRequestsSidebar";
import CreateServiceRequestConfirmationSidebar from "./CreateServiceRequestConfirmationSidebar";
import CreateFaxNotice from "./CreateFaxNotice/index";
import UnworkableFax from "./UnworkableFax/index";
import { FaxSidebarView } from "./common";
import DownloadConfirmationSidebar from "./CreateFaxNotice/DownloadConfirmationSidebar";
import {
  useParsedQueryString,
  useGetServiceRequestByIdWithFallback,
  useGetReferralRequestByIdWithFallback,
  useFeature,
} from "@coherehealth/common";
import OCRSidebar from "./OCRSidebar";
import QueueSupervisorSidebar from "./QueueSupervisorSidebar";
import QueueAgentSidebar from "./QueueAgentSidebar";
import config from "api/config";
import { useSnackbar } from "notistack";
import { ServiceCase, useGetServiceCase } from "@coherehealth/qm-api";
import CompleteEditRequestSidebar from "./CompleteEditRequestSidebar";
import CompleteContinueRequestSidebar from "./CompleteContinueRequestSidebar";
import { useLocation, useNavigate } from "react-router";
import { warn as logWarning } from "logger";
import ForwardFax from "./ForwardFax/index";

interface Props {
  /**
   * Meta data about the file attachment to show
   */
  fileData: Partial<DocumentInfo>;
  /**
   * The blob data for fax file
   */
  blob?: Blob | null;
  /**
   * Refetch file data
   */
  refreshFileData?: () => void;
  /**
   * The width of the sidebar
   */
  width: number;
  /**
   * URL to the document
   */
  url?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: ({ width }: { width: number }) => width,
    height: "100%",
    paddingTop: theme.spacing(5),
  },
}));

export const IsCohereTemplateContext = createContext<boolean | undefined>(undefined);

export default function FaxAttachmentSidebar({ fileData, blob, refreshFileData, width, url }: Props) {
  const classes = useStyles({ width });
  const usesCohereTemplate = fileData.usesCohereTemplate === "Yes";
  const { enqueueSnackbar } = useSnackbar();
  const [sidebarView, setSidebarView] = useState<FaxSidebarView>();

  const [selectedPatient, setSelectedPatient] = useState<Patient>();
  const [ocrServiceRequest, setOcrServiceRequest] = useState<ServiceRequestResponse>();
  const [attachedToServiceRequests, setAttachedToServiceRequests] = useState<ServiceRequestResponse[]>([]);
  const [attachedToReferralRequests, setAttachedToReferralRequests] = useState<ReferralRequestResponse[]>([]);
  const [serviceCase, setServiceCase] = useState<ServiceCase | null>(null);
  const [srLoading, setSrLoading] = useState<boolean>(false);
  const [rrLoading, setRrLoading] = useState<boolean>(false);
  const { caseId, origin, queueId } = useContext(FaxAttachmentContext);
  const [selectedServiceId, setSelectedServiceId] = useState<string>("");
  const {
    data: initialServiceCase,
    loading: serviceCaseLoading,
    error: getServiceCaseError,
    refetch: getServiceCase,
  } = useGetServiceCase({
    id: caseId || "",
    base: `${config.QM_SERVICE_API_URL}`,
    lazy: true,
  });
  const { refetch: getServiceRequest, error: getServiceRequestError } = useGetServiceRequestByIdWithFallback({
    id: "",
    lazy: true,
  });
  const { refetch: getReferralRequest, error: getReferralRequestError } = useGetReferralRequestByIdWithFallback({
    id: "",
    lazy: true,
  });
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate();
  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");
  const qs = useParsedQueryString();

  useEffect(() => {
    const attachedToSRPromises: Promise<ServiceRequestResponse | null>[] = [];
    const attachedToRRPromises: Promise<ReferralRequestResponse | null>[] = [];
    setSrLoading(true);
    setRrLoading(true);
    fileData.attachedTo?.forEach((attachedTo) => {
      if (attachedTo.id) {
        if (attachedTo.type === "serviceRequest") {
          const res = getServiceRequest({ pathParams: { id: attachedTo.id } });
          res && attachedToSRPromises.push(res);
        } else if (attachedTo.type === "referralRequest") {
          const res = getReferralRequest({ pathParams: { id: attachedTo.id } });
          res && attachedToRRPromises.push(res);
        }
      }
    });
    const attachedToSR: ServiceRequestResponse[] = [];
    const attachedToRR: ReferralRequestResponse[] = [];
    Promise.all(attachedToSRPromises).then((SRs) => {
      SRs.forEach((sr) => sr && attachedToSR.push(sr));
      setAttachedToServiceRequests(attachedToSR);
      setSrLoading(false);
    });
    Promise.all(attachedToRRPromises).then((RRs) => {
      RRs.forEach((rr) => rr && attachedToRR.push(rr));
      setAttachedToReferralRequests(attachedToRR);
      setRrLoading(false);
    });
  }, [fileData.attachedTo, getServiceRequest, getReferralRequest]);

  useEffect(() => {
    if (caseId) {
      getServiceCase();
    }
  }, [caseId, getServiceCase]);

  useEffect(() => {
    setServiceCase(initialServiceCase);
  }, [initialServiceCase]);
  useEffect(() => {
    if (getServiceCaseError && caseId) {
      enqueueSnackbar(`Error fetching service case: ${getServiceCaseError.message}`, { variant: "error" });
    }
  }, [caseId, enqueueSnackbar, getServiceCaseError]);
  useEffect(() => {
    if (getServiceRequestError && !getServiceRequestError.message.includes("404")) {
      enqueueSnackbar(`Failed to load service request: ${getServiceRequestError.message}`, {
        variant: "error",
      });
    }
    if (getReferralRequestError && !getReferralRequestError.message.includes("404")) {
      enqueueSnackbar(`Failed to load referral request: ${getReferralRequestError.message}`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, getServiceRequestError, getReferralRequestError]);

  const getCurrentExpectedUrlTag = useCallback(
    (sidebarView: FaxSidebarView | undefined): string => {
      if (sidebarView === "AUTH_SEARCH_VIEW") {
        if (selectedPatient === undefined) {
          return "LOOKUP_VIEW";
        } else {
          return "SUMMARY_VIEW";
        }
      } else if (
        sidebarView === "EDIT_SERVICE_VIEW" ||
        sidebarView === "CREATE_NEW_SR_CONFIRMATION" ||
        sidebarView === "CONTINUE_SERVICE_VIEW"
      ) {
        return "FAX_VIEW";
      } else if (sidebarView === "CREATE_FAX_NOTICE") {
        return "FAX_BACK";
      } else if (sidebarView === "UNWORKABLE_FAX") {
        return "CASE_CLOSE";
      } else if (sidebarView === "QUEUE_SUPERVISOR_VIEW") {
        return "SUPERVISOR_VIEW";
      } else if (sidebarView === "CURRENTLY_ATTACHED_SERVICE_REQUESTS") {
        return "ATTACH_VIEW";
      } else if (sidebarView === "FORWARDED_FAX") {
        return "FAX_FORWARDED";
      } else {
        return "";
      }
    },
    [selectedPatient]
  );

  useEffect(() => {
    if (serviceCase?.caseType === "FAX_INTAKE" && location.hash !== getCurrentExpectedUrlTag(sidebarView)) {
      if (getCurrentExpectedUrlTag(sidebarView) !== "") {
        if (!authBuilderParamNavigationFF) {
          window.location.hash = getCurrentExpectedUrlTag(sidebarView);
        } else {
          const updatedSearchParams = new URLSearchParams(search);
          updatedSearchParams.set("faxStep", getCurrentExpectedUrlTag(sidebarView));
          navigate(`?${updatedSearchParams}#${getCurrentExpectedUrlTag(sidebarView)}`, {
            replace: true,
            state: location.state,
          });
        }
      } else {
        navigate(`?${search}`, { replace: true, state: location.state });
      }
    }

    //sentry error
    if (authBuilderParamNavigationFF && location.hash && !qs["faxStep"]) {
      logWarning(`fax Step query parameter does not exist in URL: ${location.hash}`);
    }
  }, [
    getCurrentExpectedUrlTag,
    authBuilderParamNavigationFF,
    location.hash,
    location.state,
    navigate,
    search,
    selectedPatient,
    serviceCase?.caseType,
    sidebarView,
    qs,
  ]);
  const viewProps = { fileData, refreshFileData, setSidebarView, serviceCase };
  useEffect(() => {
    const getSidebarView = (): FaxSidebarView => {
      if (origin === "qm_supervisor_dashboard") {
        return "QUEUE_SUPERVISOR_VIEW";
      } else if (origin === "qm_agent_dashboard") {
        return "QUEUE_AGENT_VIEW";
      } else if (attachedToServiceRequests.length > 0 || attachedToReferralRequests.length > 0) {
        return "CURRENTLY_ATTACHED_SERVICE_REQUESTS";
      } else {
        return "AUTH_SEARCH_VIEW";
      }
    };
    setSidebarView(getSidebarView());
  }, [origin, attachedToServiceRequests.length, attachedToReferralRequests.length]);

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      PaperProps={{
        style: {
          scrollbarGutter: "stable",
          overflowY: "scroll",
        },
      }}
    >
      <Container className={classes.container}>
        {srLoading || rrLoading ? (
          <CircularProgress size={40} />
        ) : (
          <>
            {sidebarView === "AUTH_SEARCH_VIEW" && (
              <IsCohereTemplateContext.Provider value={usesCohereTemplate}>
                <AttachToServiceRequestSidebar
                  selectedPatient={selectedPatient}
                  setSelectedPatient={setSelectedPatient}
                  variant="AUTH_SEARCH_VIEW"
                  setSelectedServiceId={setSelectedServiceId}
                  url={url}
                  {...viewProps}
                />
              </IsCohereTemplateContext.Provider>
            )}
            {sidebarView === "FINISH_OCR_SR" && (
              <AttachToServiceRequestSidebar
                selectedPatient={selectedPatient}
                setSelectedPatient={setSelectedPatient}
                setOcrServiceRequest={setOcrServiceRequest}
                variant="FINISH_OCR_SR"
                setSelectedServiceId={setSelectedServiceId}
                url={url}
                {...viewProps}
              />
            )}
            {sidebarView === "CREATE_NEW_SR_CONFIRMATION" && (
              <CreateServiceRequestConfirmationSidebar
                selectedPatient={selectedPatient}
                ocrServiceRequestId={ocrServiceRequest?.id || ""}
                {...viewProps}
              />
            )}
            {sidebarView === "CURRENTLY_ATTACHED_SERVICE_REQUESTS" && !usesCohereTemplate && (
              <CurrentlyAttachedServiceRequestsSidebar
                selectedPatient={selectedPatient}
                setSelectedPatient={setSelectedPatient}
                url={url}
                attachedToServiceRequests={attachedToServiceRequests}
                attachedToReferralRequest={attachedToReferralRequests[0]}
                {...viewProps}
              />
            )}
            {sidebarView === "CURRENTLY_ATTACHED_SERVICE_REQUESTS" && usesCohereTemplate && (
              <OCRSidebar
                selectedPatient={selectedPatient}
                setSelectedPatient={setSelectedPatient}
                url={url}
                {...viewProps}
              />
            )}
            {sidebarView === "CREATE_FAX_NOTICE" && <CreateFaxNotice {...viewProps} />}
            {sidebarView === "DOWNLOAD_CONFIRMATION" && (
              <DownloadConfirmationSidebar setFaxSidebarView={setSidebarView} />
            )}
            {sidebarView === "UNWORKABLE_FAX" && <UnworkableFax url={url} blob={blob} {...viewProps} />}
            {sidebarView === "FORWARDED_FAX" && <ForwardFax url={url} blob={blob} {...viewProps} />}
            {sidebarView === "QUEUE_SUPERVISOR_VIEW" && (
              <QueueSupervisorSidebar
                url={url}
                setServiceCase={setServiceCase}
                serviceCaseLoading={serviceCaseLoading}
                queueId={queueId}
                {...viewProps}
                data-public
              />
            )}
            {sidebarView === "QUEUE_AGENT_VIEW" && <QueueAgentSidebar url={url} {...viewProps} />}
            {sidebarView === "EDIT_SERVICE_VIEW" && (
              <CompleteEditRequestSidebar selectedServiceId={selectedServiceId} {...viewProps} />
            )}
            {sidebarView === "CONTINUE_SERVICE_VIEW" && (
              <CompleteContinueRequestSidebar
                selectedServiceId={selectedServiceId}
                selectedPatientId={selectedPatient?.id!}
                {...viewProps}
              />
            )}
          </>
        )}
      </Container>
    </Drawer>
  );
}
