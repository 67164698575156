import React from "react";
import { colorsLight, SingleSelectDropdown, themeLight, Tooltip } from "@coherehealth/common";
import { PalCheckFormSectionProps, SectionHeader } from "./HealthPlanPalCheckFormSectionConfigs";
import { useGetClinicalServices, ClinicalService, Patient } from "@coherehealth/core-platform-api";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { getPatientHealthPlanName } from "util/patientUtils";

interface Props extends PalCheckFormSectionProps {
  noPxService?: ClinicalService;
  setNoPxService: (service: ClinicalService | undefined) => void;
  patientData: Patient | null;
}

export default function NoPxServiceSection({
  priorAuthRequirements,
  noPxService,
  setNoPxService,
  attemptedSubmit,
  resetPalResults,
  patientData,
}: Props) {
  const { data } = useGetClinicalServices({
    queryParams: {
      pxRequiredToSubmit: false,
      healthPlanName: getPatientHealthPlanName(patientData || undefined, priorAuthRequirements.startDate) || "",
    },
  });
  const classes = useStyles();
  const showErrorAndHelperText = (validSelection?: ClinicalService) => {
    return attemptedSubmit && !validSelection;
  };

  return (
    <>
      <SectionHeader className={classes.sectionHeader}>
        What procedure is being requested?
        <Tooltip title="The following procedures can be submitted without procedure codes" placement={"top"}>
          <InfoOutlinedIcon className={classes.toolTip} />
        </Tooltip>
      </SectionHeader>
      <SingleSelectDropdown
        label="Select a procedure"
        data-testid="no-px-dropdown"
        options={dropdownOptionsFromClinicalServices(
          data?.sort((a, b) => a?.name?.localeCompare(b?.name || "") || 0) || []
        )}
        value={noPxService?.id || ""}
        error={showErrorAndHelperText(noPxService)}
        helperText={showErrorAndHelperText(noPxService) && "Required"}
        onChange={(val) => {
          resetPalResults();
          setNoPxService(data?.find((service) => service.id === val));
        }}
      />
    </>
  );
}

function dropdownOptionsFromClinicalServices(clinicalServices: ClinicalService[] | null) {
  return clinicalServices?.map((service) => ({ id: service.id || "", label: service.name || "" })) || [];
}

const useStyles = makeStyles({
  sectionHeader: {
    alignItems: "center",
    display: "inline-flex",
  },
  toolTip: {
    color: colorsLight.font.light,
    marginLeft: themeLight.spacing(1),
    fontSize: "18px",
  },
});
