import { useCallback, useState } from "react";

import { Caption, H6 } from "@coherehealth/common";
import {
  Guideline,
  MdReview,
  PeerToPeerReview,
  ProcedureCode,
  ServiceRequestResponse,
  MdReviewCoverageLevelDetails,
  PeerToPeerCoverageLevelDetails,
  ReviewType,
} from "@coherehealth/core-platform-api";
import { Divider, Grid } from "@material-ui/core";
import { GenericDoctorReview, useReviewsDispatch } from "components/ClinicalReview/reviewUtils/useReviews";
import PartialApprovalFlexibleCodes from "components/ServiceRequestStatusDisplay/PartialApproval/PartialApprovalFlexibleCodes";
import elementIsNonNull from "util/elementIsNonNull";
import { ReviewOutcomeDropdown } from "./ModalFields/components/ReviewOutcomeDropdown";
import { DisplayReviewOutcome } from "./mdReviewModalTypes";
import { isPartialApprovalOutcome } from "./mdReviewModalUtils";
import { isPostDenialP2P } from "components/ClinicalReview/reviewUtils/utils";
import listReplace from "util/listReplace";
import { useTheme } from "@material-ui/core/styles";
import GuidelinesUsedSection from "../GuidelinesUsedSection";
import { useProcedureCodesState } from "../../util/useProcedureCodesState";
import { useEffectDeepEquality } from "@coherehealth/common";

type ReviewUpdate = Partial<MdReview> | Partial<PeerToPeerReview>;

interface ReviewOutcomesProps<T extends GenericDoctorReview> {
  serviceRequest: ServiceRequestResponse;
  review: T;
  setReview: (update: ReviewUpdate) => void;
  attemptedSubmit: boolean;
  guidelinesFromReview: Guideline[];
  existingReviews?: ReviewType[] | null;
}

export default function GenericDoctorReviewMultiCoverageOutcomes<T extends GenericDoctorReview>({
  serviceRequest,
  review,
  setReview,
  attemptedSubmit,
  guidelinesFromReview,
  existingReviews,
}: ReviewOutcomesProps<T>) {
  const theme = useTheme();
  const { spacing } = theme;
  const [displayCaseOutcomes, setDisplayCaseOutcomes] = useState<DisplayReviewOutcome[]>(
    review.coverageLevelDetails?.map((detail) =>
      isMultiCoverageDoctorReviewOutcome(detail.reviewOutcome) ? detail.reviewOutcome : undefined
    ) || []
  );

  const fieldSetter = useCallback(
    <T extends GenericDoctorReview>(reviewUpdate: Partial<T>, index: number) => {
      const coverageLevelDetailsPayload = review.coverageLevelDetails?.slice();
      const previousDetail = coverageLevelDetailsPayload?.[index];
      const updatedDetail = { ...previousDetail, ...reviewUpdate };
      coverageLevelDetailsPayload?.splice(index, 1, updatedDetail);

      const topLevelOutcome = reviewOutcomeRollup(
        coverageLevelDetailsPayload?.map((detail) =>
          isMultiCoverageDoctorReviewOutcome(detail.reviewOutcome) ? detail.reviewOutcome : undefined
        ) || []
      );

      setReview({ coverageLevelDetails: coverageLevelDetailsPayload, reviewOutcome: topLevelOutcome });
    },
    [review.coverageLevelDetails, setReview]
  );

  const { notAllWithdrawn, notAllSamePendingReason } = useReviewOutcomeValidator(displayCaseOutcomes);

  const hasMissingOutcomeSelection = !review.coverageLevelDetails?.every((detail) => Boolean(detail.reviewOutcome));

  const withdrawnError = notAllWithdrawn && attemptedSubmit;
  const pendingError = notAllSamePendingReason && attemptedSubmit;

  const hasError = attemptedSubmit && Boolean(withdrawnError || pendingError);

  return (
    <Grid container item xs={12}>
      <Divider style={{ width: "100%", marginBottom: spacing(2) }} />
      {review.coverageLevelDetails?.map((detail, index) => (
        <Grid
          item
          container
          xs={12}
          key={`${detail.coverage?.lineOfBusinessType} - ${index}`}
          style={{ marginBottom: spacing(4) }}
        >
          <Grid item xs={12}>
            <H6 style={{ marginBottom: spacing(2) }}>{detail.coverage?.lineOfBusinessType}</H6>
            <ReviewOutcomes
              serviceRequest={serviceRequest}
              review={review}
              setReview={(outcome: Partial<GenericDoctorReview>) => fieldSetter(outcome, index)}
              displayCaseOutcome={displayCaseOutcomes[index]}
              setDisplayCaseOutcome={(outcome) =>
                setDisplayCaseOutcomes(listReplace(displayCaseOutcomes, index, outcome))
              }
              attemptedSubmit={attemptedSubmit}
              error={
                attemptedSubmit &&
                (isDropdownInEmptySubmissionError(hasMissingOutcomeSelection, displayCaseOutcomes[index]) ||
                  isDropdownInPendingError(pendingError, displayCaseOutcomes[index]) ||
                  withdrawnError)
              }
              disabled={
                serviceRequest.initialDecisionDisposition === "DENIED" ||
                (serviceRequest.initialDecisionDisposition === "PARTIALLY_APPROVED" &&
                  detail.reviewOutcome === "APPROVED")
              }
              guidelinesFromReview={guidelinesFromReview}
              coverageLevelDetail={detail}
              coverageIndex={index}
              existingReviews={existingReviews}
            />
          </Grid>
        </Grid>
      ))}
      {hasError && (
        <Grid item xs={12}>
          <Caption style={{ color: theme.palette.error.main }}>
            {withdrawnError
              ? "Withdrawn must be set for all coverages or no coverages"
              : "All pending coverages must have the same pending reason"}
          </Caption>
        </Grid>
      )}
    </Grid>
  );
}

interface ReviewOutcomesControl<T extends GenericDoctorReview> extends ReviewOutcomesProps<T> {
  displayCaseOutcome: DisplayReviewOutcome;
  setDisplayCaseOutcome: (outcomes: DisplayReviewOutcome) => void;
  error: boolean;
  coverageLevelDetail: MdReviewCoverageLevelDetails | PeerToPeerCoverageLevelDetails;
  coverageIndex: number;
  disabled: boolean;
}

function ReviewOutcomes<T extends GenericDoctorReview>({
  serviceRequest,
  review,
  setReview,
  displayCaseOutcome,
  setDisplayCaseOutcome,
  error,
  guidelinesFromReview,
  coverageLevelDetail,
  coverageIndex,
  disabled,
  existingReviews,
}: ReviewOutcomesControl<T>) {
  const { authStatus, clinicalServices, units, healthPlanName } = serviceRequest;
  const reviewOutcome = isMultiCoverageDoctorReviewOutcome(coverageLevelDetail.reviewOutcome)
    ? coverageLevelDetail.reviewOutcome
    : undefined;

  const isPartialApproval = isPartialApprovalOutcome(reviewOutcome);
  const isPostDenialP2PReview =
    review.reviewType === "MdReview" ? isPostDenialP2P(review, authStatus) : review.isPostDenialP2P;
  const showFlexibleCodes = isPartialApproval && !isPostDenialP2PReview;
  const collectGuidelineUsed = doesOutcomeRequireGuidelineUsedSelection(displayCaseOutcome);

  const {
    approvedProcedureCodes,
    pxCodesByClinicalService,
    approvedUnitsByClinicalService,
    updateApprovedUnitsByCS,
    updateSinglePxCodeUnit,
    approvedUnits,
  } = useProcedureCodesState({ serviceRequest, existingReviews, currentReviewId: review.id });

  const reviewsDispatch = useReviewsDispatch();

  const setUsedGuidelineIds = (updatedGuidelineId: string, updatedIndex: number) => {
    reviewsDispatch?.({
      type: "SET_MULTI_COVERAGE_USED_GUIDELINES",
      reviewId: review.id,
      payload: { updatedGuidelineId, updatedIndex, coverageIndex },
    });
  };

  const addUsedGuidelineIds = () => {
    reviewsDispatch?.({
      type: "ADD_MULTI_COVERAGE_USED_GUIDELINES",
      reviewId: review.id,
      payload: { coverageIndex },
    });
  };

  const deleteUsedGuidelineId = (deletedIndex: number) => {
    reviewsDispatch?.({
      type: "DELETE_MULTI_COVERAGE_USED_GUIDELINES",
      reviewId: review.id,
      payload: { deletedIndex, coverageIndex },
    });
  };

  const updateApprovedSemanticProcedureCodes = useCallback(
    (updatedCodes: ProcedureCode[]) => {
      reviewsDispatch?.({
        type: "SET_MULTI_COVERAGE_APPROVED_SEMANTIC_PROCEDURE_CODES",
        reviewId: review.id,
        payload: {
          coverageIndex,
          coverageSemanticProcedureCodes: updatedCodes,
        },
      });
    },
    [coverageIndex, review.id, reviewsDispatch]
  );

  useEffectDeepEquality(() => {
    updateApprovedSemanticProcedureCodes(approvedProcedureCodes);
  }, [updateApprovedSemanticProcedureCodes, approvedProcedureCodes]);

  return (
    <>
      <ReviewOutcomeDropdown
        serviceRequest={serviceRequest}
        review={review}
        setReview={setReview}
        displayCaseOutcome={displayCaseOutcome}
        disabled={disabled}
        setDisplayCaseOutcome={setDisplayCaseOutcome}
        error={error}
        coverageLevelDetail={coverageLevelDetail}
      />
      {collectGuidelineUsed && (
        <GuidelinesUsedSection
          guidelinesFromReview={guidelinesFromReview}
          usedGuidelineIds={
            coverageLevelDetail.usedGuidelineIds && coverageLevelDetail.usedGuidelineIds.length > 0
              ? coverageLevelDetail.usedGuidelineIds
              : [""]
          }
          setUsedGuidelineIds={setUsedGuidelineIds}
          addUsedGuidelineIds={addUsedGuidelineIds}
          deleteUsedGuidelineId={deleteUsedGuidelineId}
          usedGuidelines={
            review.usedGuidelines?.filter((guideline) =>
              coverageLevelDetail.usedGuidelineIds?.includes(guideline.id || "")
            ) || []
          }
        />
      )}
      {showFlexibleCodes && (
        <PartialApprovalFlexibleCodes
          pxCodesByClinicalService={pxCodesByClinicalService}
          approvedUnitsByClinicalService={approvedUnitsByClinicalService}
          updateApprovedUnitsByCS={updateApprovedUnitsByCS}
          clinicalServices={clinicalServices?.filter(elementIsNonNull) || []}
          serviceRequestUnits={units || 0}
          approvedServiceRequestUnits={approvedUnits}
          healthPlanName={healthPlanName || ""}
          updateSinglePxCodeUnit={updateSinglePxCodeUnit}
        />
      )}
    </>
  );
}

type PendingOutcome = "PENDING_MISSING_CLINICAL_INFO" | "PENDING_RN_REVIEW" | "PENDING_ADMIN_VOID";

type WithdrawnOutcome = "WITHDRAWN";

type ApprovedOutcome = "APPROVED";

//these represent the review outcomes available in Post Denial P2Ps that are not APPROVED
type PostDenialPeerToPeerUpholdOutcomes = "PARTIALLY_APPROVED" | "DENIED";

//these represent the review outcomes available that would lead a case into the denial workflow
type PreDenialTerminalReviewOutcomes = "RECOMMENDED_PARTIAL_APPROVAL" | "RECOMMENDED_DENIAL";

//any combination of these outcomes is valid - should only be relevant in Post Denial P2Ps
type TerminalPostDenialPeerToPeerMixedCaseOutcomesAllowed = ApprovedOutcome | PostDenialPeerToPeerUpholdOutcomes;

//any combination of these outcomes is valid
type TerminalPreDenialMixedCaseOutcomesAllowed = ApprovedOutcome | PreDenialTerminalReviewOutcomes;

type TerminalOutcome =
  | WithdrawnOutcome
  | ApprovedOutcome
  | PostDenialPeerToPeerUpholdOutcomes
  | PreDenialTerminalReviewOutcomes;
//note - DENIED and PARTIALLY_APPROVED are only here to handle post-denial P2P selections

type ReviewOutcome = PendingOutcome | TerminalOutcome | undefined;

const AVAILABLE_PENDING_REVIEW_OUTCOMES: PendingOutcome[] = [
  "PENDING_MISSING_CLINICAL_INFO",
  "PENDING_RN_REVIEW",
  "PENDING_ADMIN_VOID",
];

//this list is prioritized in terms of how we want to status the review outcome, from a mixed case of the following
const AVAILABLE_POST_DENIAL_PEER_TO_PEER_MIXED_CASE_OUTCOMES: TerminalPostDenialPeerToPeerMixedCaseOutcomesAllowed[] = [
  "APPROVED",
  "PARTIALLY_APPROVED",
  "DENIED",
];

//this list is prioritized in terms of how we want to status the review outcome, from a mixed case of the following
const AVAILABLE_PRE_DENIAL_MIXED_CASE_OUTCOMES: TerminalPreDenialMixedCaseOutcomesAllowed[] = [
  "APPROVED",
  "RECOMMENDED_PARTIAL_APPROVAL",
  "RECOMMENDED_DENIAL",
];

const AVAILABLE_REVIEW_OUTCOMES: ReviewOutcome[] = [
  "WITHDRAWN",
  ...AVAILABLE_PENDING_REVIEW_OUTCOMES,
  ...AVAILABLE_POST_DENIAL_PEER_TO_PEER_MIXED_CASE_OUTCOMES,
  ...AVAILABLE_PRE_DENIAL_MIXED_CASE_OUTCOMES,
];

const reviewOutcomeRollup = (reviewOutcomes: (ReviewOutcome | undefined)[]): ReviewOutcome => {
  const allDecisioned = reviewOutcomes.every((item) => Boolean(item));

  const allWithdrawn = reviewOutcomes.every((item) => item === "WITHDRAWN");

  const allApproved = reviewOutcomes.every((item) => item === "APPROVED");

  const allMixedCasePostDenialTerminalOutcome = reviewOutcomes.every(isMixedCasePostDenialTerminalOutcome);
  const allDenied = reviewOutcomes.every((item) => item === "DENIED");

  const allMixedCasePreDenialTerminalOutcome = reviewOutcomes.every(isMixedCasePreDenialTerminalOutcome);
  const allRecommendedDenial = reviewOutcomes.every((item) => item === "RECOMMENDED_DENIAL");

  const uniquePendingEntries: ReviewOutcome[] = findUniquePendingOutcomes(reviewOutcomes);

  const pendingStatus: ReviewOutcome = uniquePendingEntries[0];
  const multiplePendingStatuses = uniquePendingEntries.length > 1;
  const noPendingStatus = Boolean(!uniquePendingEntries.length);

  const allTerminallyDecisioned = allDecisioned && noPendingStatus;

  if (allTerminallyDecisioned) {
    if (allWithdrawn) {
      return "WITHDRAWN";
    } else if (allApproved) {
      return "APPROVED";
    } else if (allMixedCasePostDenialTerminalOutcome) {
      if (allDenied) {
        return "DENIED";
      } else {
        return "PARTIALLY_APPROVED";
      }
    } else if (allMixedCasePreDenialTerminalOutcome) {
      if (allRecommendedDenial) {
        return "RECOMMENDED_DENIAL";
      } else {
        return "RECOMMENDED_PARTIAL_APPROVAL";
      }
    } else {
      throw new Error("Unhandled group of review outcomes selected");
    }
  } else {
    if (allDecisioned) {
      if (multiplePendingStatuses) {
        return undefined;
      } else {
        return pendingStatus;
      }
    } else {
      return undefined;
    }
  }
};

const useReviewOutcomeValidator = (reviewOutcomes: (DisplayReviewOutcome | undefined)[]) => {
  const pendingOutcomes = reviewOutcomes.filter(isPendingOutcome);
  const uniquePendingEntries = findUniquePendingOutcomes(pendingOutcomes);
  const notAllSamePendingReason = uniquePendingEntries?.length > 1;
  const notAllWithdrawn = checkOutcomeUniformity("WITHDRAWN", reviewOutcomes);
  const notAllApproved = checkOutcomeUniformity("APPROVED", reviewOutcomes);

  return { notAllWithdrawn, notAllApproved, notAllSamePendingReason };
};

const checkOutcomeUniformity = (outome: string, reviewOutcomes: (string | undefined)[]) => {
  if (reviewOutcomes.includes(outome)) {
    return !reviewOutcomes.every((item) => item === outome);
  } else {
    return false;
  }
};

const findUniquePendingOutcomes = (reviewOutcomes: (ReviewOutcome | undefined)[]): ReviewOutcome[] =>
  Array.from(new Set(reviewOutcomes.filter((outcome) => outcome && isPendingOutcome(outcome))));

const isDropdownInPendingError = (errorState: boolean, outcome: DisplayReviewOutcome) =>
  errorState && isPendingOutcome(outcome);

const isDropdownInEmptySubmissionError = (errorState: boolean, outcome: DisplayReviewOutcome) => errorState && !outcome;

export const isMultiCoverageDoctorReviewOutcome = (value: string | undefined): value is ReviewOutcome => {
  return AVAILABLE_REVIEW_OUTCOMES.map((outcome) => outcome?.toString()).includes(value || "");
};

const isPendingOutcome = (value: string | undefined): value is PendingOutcome => {
  return AVAILABLE_PENDING_REVIEW_OUTCOMES.map((outcome) => outcome.toString()).includes(value || "");
};

const isMixedCasePostDenialTerminalOutcome = (
  value: string | undefined
): value is TerminalPostDenialPeerToPeerMixedCaseOutcomesAllowed => {
  return AVAILABLE_POST_DENIAL_PEER_TO_PEER_MIXED_CASE_OUTCOMES.map((outcome) => outcome.toString()).includes(
    value || ""
  );
};

const isMixedCasePreDenialTerminalOutcome = (
  value: string | undefined
): value is TerminalPreDenialMixedCaseOutcomesAllowed => {
  return AVAILABLE_PRE_DENIAL_MIXED_CASE_OUTCOMES.map((outcome) => outcome.toString()).includes(value || "");
};

const doesOutcomeRequireGuidelineUsedSelection = (outcome: DisplayReviewOutcome) =>
  Boolean(outcome && outcome !== "APPROVED" && outcome !== "CANNOT_WORK" && outcome !== "UPHOLD_INITIAL_APPROVAL");
