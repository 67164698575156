import { Dispatch, SetStateAction } from "react";
import { AuthBuilderWorkflowStep, ServiceRequestResponse, Vendor } from "@coherehealth/core-platform-api";
import { activityContextFromServiceRequest, TrackUserActivityProps } from "util/userActivityTracker";

interface Props {
  trackUserImpression: (props: TrackUserActivityProps) => Promise<void>;
  noAssessmentQuestions: boolean;
  setCaqSkipped: Dispatch<SetStateAction<boolean>>;
  isContinuationWorkflow?: boolean;
  isCurrentDedicatedNudge: boolean;
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void;
  clinicalAssessmentProviders: ClinicalAssessmentProvider[];
  goToReview: boolean;
  skipWhenVendorAssessmentFails?: boolean;
  serviceRequest?: ServiceRequestResponse;
  skippedCAQFastTrack?: boolean;
}

export type ClinicalAssessmentProvider = Vendor | undefined;

/**
 * Utils file for filtering data i.e., components based on certain workflow steps described.
 * @returns
 */
export const getWorkflowStepsThatUseCohereCard = () => {
  const workflowStepsArray = [
    "GATHER_REQUIREMENTS_MEDICAL",
    "GATHER_REQUIREMENTS_PHARMACY",
    "SELECT_SERVICE",
    "SELECT_SERVICES",
    "FILL_FORMS",
    "FILL_FORMS_CONTINUATION",
    "FILL_FORMS_GENERAL_AUTH_SUBMISSION",
    "FILL_FORMS_FAX",
    "CLINICAL_ASSESSMENT",
    "CLINICAL_ASSESSMENT_CONTINUATION",
    "VENDOR_CLINICAL_ASSESSMENT",
    "ADD_ATTACHMENTS",
    "ADD_ATTACHMENTS_CONTINUATION",
    "REVIEW_NUDGES",
    "PROVIDER_DETAILS",
    "SERVICE_DETAILS",
  ];

  return workflowStepsArray;
};

const getWorkFlowStep = () => {
  const location = window.location;
  return location?.hash?.substring(1);
};

/*
shouldUseCohereCard: Method used to help determine if we should use the Cohere Card 
for AuthBuilder components when being used in the workFlow
*/
export const shouldUseCohereCard = () => {
  const workflowStepsArray = getWorkflowStepsThatUseCohereCard();
  const workflowStepStr = getWorkFlowStep();
  return workflowStepsArray?.includes(workflowStepStr);
};

const CONTINUATIONS_WORKFLOW_STEPS = [
  "FILL_FORMS_CONTINUATION",
  "ADD_ATTACHMENTS_CONTINUATION",
  "CLINICAL_ASSESSMENT_CONTINUATION",
  "REVIEW_CONTINUATION",
];

export const onContinuationWorkFlow = () => {
  const workflowStepStr = getWorkFlowStep();
  return CONTINUATIONS_WORKFLOW_STEPS.includes(workflowStepStr);
};

/**
 * State changes that should be executed before going back from 'ADD_ATTACHMENTS' step
 *
 * If there is an specific scenario that skips the 'ADD_ATTACHMENTS' step, this method should
 * be called instead of setting the workflow step to "ADD_ATTACHMENTS"
 *
 * i.e if Fax intake auths should skip 'Add Attachments page' then when navigating back during the
 * authbuild flow, if it is in a fax intake flow this method needs to be called instead
 * of setWorkflowStep("ADD_ATTACHMENTS");
 */
export function setStateBeforeBackFromAttachments(
  authFlowType: string,
  setClinicalAssessmentProviders: Dispatch<SetStateAction<ClinicalAssessmentProvider[]>>,
  setServiceRequestPatientAttributes: Dispatch<SetStateAction<Set<string> | undefined>>,
  setAttemptedServiceRequestFormsSubmit: Dispatch<SetStateAction<boolean>>,
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void
) {
  if (authFlowType === "MEDICAL") {
    setClinicalAssessmentProviders([]);
    setServiceRequestPatientAttributes(undefined);
    setAttemptedServiceRequestFormsSubmit(false);
    setWorkflowStep("SERVICE_DETAILS");
  } else {
    //authflowtype === PHARMACY
    setWorkflowStep("GATHER_REQUIREMENTS_PHARMACY");
  }
}

/**
 * State changes that should be executed before going back from 'ADD_ATTACHMENTS_CONTINUATION' step
 *
 * If there is an specific scenario that skips the 'ADD_ATTACHMENTS_CONTINUATION' step, this method should
 * be called instead of setting the workflow step to "ADD_ATTACHMENTS_CONTINUATION"
 *
 * i.e if Fax intake continuations should skip 'Add Attachments page' then when navigating back during the
 * continuation flow, if it is in a fax intake flow this method needs to be called instead
 * of setWorkflowStep("ADD_ATTACHMENTS_CONTINUATION");
 */
export function setStateBeforeBackFromAttachmentsContinuation(
  draftSRId: string,
  getDraftServiceRequest: () => Promise<void>,
  setWorkflowStep: (step: AuthBuilderWorkflowStep) => void
) {
  setWorkflowStep("FILL_FORMS_CONTINUATION");
  if (draftSRId) {
    getDraftServiceRequest();
  }
}

/**
 * Navigate to the next workflow step depending on CAQs status
 */
export const navigateStepEvalClinicalAssessment = async ({
  trackUserImpression,
  noAssessmentQuestions,
  setCaqSkipped,
  isContinuationWorkflow,
  isCurrentDedicatedNudge,
  setWorkflowStep,
  clinicalAssessmentProviders,
  goToReview,
  skipWhenVendorAssessmentFails,
  serviceRequest,
  skippedCAQFastTrack,
}: Props) => {
  if (goToReview) {
    const props: TrackUserActivityProps = {
      event: noAssessmentQuestions
        ? "CLINICAL_ASSESSMENT_SKIPPED_NO_QUESTIONS"
        : !!skippedCAQFastTrack
        ? "CLINICAL_ASSESSMENT_SKIPPED_FAST_TRACK"
        : "CLINICAL_ASSESSMENT_SKIPPED_AA",
      stage: "CLINICAL_ASSESSMENT",
    };
    if (!skipWhenVendorAssessmentFails && !!serviceRequest) {
      props.activityContext = activityContextFromServiceRequest(serviceRequest);
    }
    await trackUserImpression(props);
    setCaqSkipped(true);
    if (isContinuationWorkflow) {
      setWorkflowStep("REVIEW_CONTINUATION");
    } else {
      setWorkflowStep(isCurrentDedicatedNudge ? "REVIEW_NUDGES" : "REVIEW");
    }
  } else {
    const props: TrackUserActivityProps = {
      event: "CLINICAL_ASSESSMENT_SHOWN",
      stage: "CLINICAL_ASSESSMENT",
    };
    if (!skipWhenVendorAssessmentFails && !!serviceRequest) {
      props.activityContext = activityContextFromServiceRequest(serviceRequest);
    }
    await trackUserImpression(props);
    setCaqSkipped(false);
    if (isContinuationWorkflow) {
      setWorkflowStep("CLINICAL_ASSESSMENT_CONTINUATION");
    } else {
      setWorkflowStep(
        !!clinicalAssessmentProviders && clinicalAssessmentProviders.includes("HealthHelp")
          ? "VENDOR_CLINICAL_ASSESSMENT"
          : "CLINICAL_ASSESSMENT"
      );
    }
  }
};

/***
 * Intersection between caq observation codes and searchable statements for clinical assessment rules
 */
export const getIntersectionObservationCodesAndSearchStatements = (
  serviceRequestPatientAttributes: Set<string> | undefined,
  clinicalAssessmentSnomedCodes: Set<string> | undefined
) => {
  const caqSearchableStatementsIntersect =
    serviceRequestPatientAttributes &&
    [...Array.from(serviceRequestPatientAttributes)].filter((value) => clinicalAssessmentSnomedCodes?.has(value));

  return caqSearchableStatementsIntersect ? caqSearchableStatementsIntersect.length > 0 : false;
};
