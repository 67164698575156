import { colorsLight } from "@coherehealth/common";
import { makeStyles } from "@material-ui/core";

export const usePatientSearchResultInteractionStyles = makeStyles((theme) => ({
  patientDetails: {
    color: colorsLight.font.secondary,
    paddingTop: theme.spacing(0.5),
  },
  startAuthRequestButtonWrapper: {
    textAlign: "right",
    margin: 0,
    position: "relative",
    top: "50%",
    mstransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  startAuthRequestButton: {
    padding: theme.spacing(2, 2.25),
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  authTransferButtonWrapper: {
    height: "100%",
    display: "flex",
  },
  authTransferButton: {
    height: "min-content",
    display: "flex",
    alignSelf: "center",
  },
  tightSelectPatientButton: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    maxWidth: "157px",
  },
}));
