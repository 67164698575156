import React from "react";

import { OrganizationResponse } from "@coherehealth/core-platform-api";
import Container from "@material-ui/core/Container";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";

import ProviderOrgInfo from "./ProviderOrgInfo";
import ProviderOrgUsers from "./ProviderOrgUsers";
import { InlineButton, colorsLight, useMuiContainerStyles } from "@coherehealth/common";
import routes from "routes";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useAuthorized } from "authorization";

interface Props {
  userId: string;
  org: OrganizationResponse | null;
  loading: boolean;
  refetchOrg: () => void;
  internalUser: boolean;
}

interface Route {
  name: string;
  route: string;
}

interface BreadCrumbsProps {
  routes: Array<Route>;
}

export default function ManageSingleProviderOrg({ userId, org, loading, refetchOrg, internalUser }: Props) {
  const containerClasses = useMuiContainerStyles();
  const orgName = org?.name;
  const orgStatus = org?.status;
  const orgId = org?.id;

  const route = [
    {
      name: "All Organizations",
      route: routes.ORGANIZATION_MANAGEMENT_LIST,
    },
    {
      name: orgName || "",
      route: "",
    },
  ];

  const canEditOrganization = useAuthorized("CAN_EDIT_MY_ORGANIZATION");
  const canDeleteOrganization = useAuthorized("CAN_DELETE_MY_ORGANIZATION");

  return (
    <Container classes={containerClasses}>
      {internalUser && <BreadCrumbsHeader routes={route} />}
      <Section>
        <ProviderOrgInfo
          key={org?.id || ""}
          orgInfo={org}
          loading={loading}
          refetchOrg={refetchOrg}
          canEdit={canEditOrganization}
          canDelete={canDeleteOrganization}
        />
      </Section>
      {!loading && orgName && (
        <Section>
          <ProviderOrgUsers userId={userId} orgName={orgName} orgStatus={orgStatus} orgId={orgId} />
        </Section>
      )}
    </Container>
  );
}

const BreadCrumbsHeader = ({ routes }: BreadCrumbsProps) => {
  const numRoutes = routes.length;
  const isNotLast = (index: number) => index < numRoutes - 1;
  return (
    <Breadcrumbs style={{ paddingTop: 16 }}>
      {routes.map((route, idx) =>
        isNotLast(idx) ? (
          <Link color="inherit" to={route.route} style={{ textDecoration: "none" }} key={idx}>
            <InlineButton style={{ marginLeft: 0, paddingLeft: 0 }}>{route.name}</InlineButton>
          </Link>
        ) : (
          <InlineButton style={{ color: colorsLight.font.light }} disabled key={idx}>
            {route.name}
          </InlineButton>
        )
      )}
    </Breadcrumbs>
  );
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const Section = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2, 0, 4, 0),
}));
