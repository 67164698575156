import {
  Body1,
  InformativeModal,
  MultiSelectDropdown,
  PrimaryButton,
  Switch,
  TertiaryButton,
  TextField,
  Tooltip,
} from "@coherehealth/common";
import { User, PhoneNumber } from "@coherehealth/core-platform-api";
import React, { ComponentProps, useState } from "react";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { createStyles, makeStyles, styled, Theme } from "@material-ui/core/styles";
import parsePhoneNumber from "libphonenumber-js";
import { UserType } from "types/userType";
import { Grid } from "@material-ui/core";
import PhoneInput from "common/PhoneInput";
import { DEFAULT_SPECIALTY_OPTIONS as defaultSpecialtyOptions } from "../../../util/organization";
import { UserInput } from "./EditMemberModal";

interface Props {
  open: boolean;
  member: UserInput;
  onClose: () => void;
  approvingPendingUser: boolean;
  denyingPendingUser: boolean;
  approvePendingMember: (user: User) => Promise<void>;
  denyPendingMember: (user: User) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchLabel: {
      display: "flex",
      justifyContent: "space-between",
      paddingRight: theme.spacing(1),
      width: "100%",
    },
    switchLabelPlacementStartOverrides: {
      marginLeft: 0,
    },
  })
);

export default function AdmitMemberModal({
  open,
  member,
  onClose,
  approvePendingMember,
  denyPendingMember,
  approvingPendingUser,
  denyingPendingUser,
}: Props) {
  const [newMemberInfo, setNewMemberInfo] = useState<UserInput>(member);
  const hasValidPhoneNumber = parseAsPhoneNumber(newMemberInfo.phone)?.number?.match(/^\d{10}$/);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const classes = useStyles();

  const allFieldsFilled =
    Boolean(newMemberInfo.firstName) &&
    Boolean(newMemberInfo.lastName) &&
    Boolean(newMemberInfo.email) &&
    Boolean(newMemberInfo.phone) &&
    Boolean(newMemberInfo.title) &&
    Boolean(newMemberInfo.specialties);

  const handleApprove = () => {
    if (allFieldsFilled) {
      setAttemptedSubmit(false);
      approvePendingMember(newMemberInfo);
    }
  };

  const handleDeny = () => {
    if (allFieldsFilled) {
      setAttemptedSubmit(false);
      denyPendingMember(newMemberInfo);
    }
  };

  return (
    <InformativeModal
      // style={{ width: "880px", margin: "auto" }}
      headerText={`${newMemberInfo.firstName} ${newMemberInfo.lastName}`}
      subHeaderText={"Review member information"}
      open={open}
      onClose={onClose}
      dialogWidth={"sm"}
      dataPublic
    >
      <Grid container spacing={2} alignItems="center" justify="center" style={{ marginTop: "16px" }}>
        <Row>
          <TextField
            fullWidth
            name="First name"
            label="First name"
            value={newMemberInfo.firstName}
            onChangeValue={(firstName) => setNewMemberInfo({ ...newMemberInfo, firstName })}
            data-public
          />
        </Row>
        <Row>
          <TextField
            fullWidth
            name="Last name"
            label="Last name"
            value={newMemberInfo.lastName}
            onChangeValue={(lastName) => setNewMemberInfo({ ...newMemberInfo, lastName })}
            data-public
          />
        </Row>
        <Row>
          <PhoneInput
            fullWidth
            name="Phone number"
            label="Phone number"
            value={parseAsPhoneNumber(newMemberInfo.phone)}
            onChange={(phone: PhoneNumber) => {
              setNewMemberInfo({
                ...newMemberInfo,
                phone: phone.extension ? `${phone.number} ext. ${phone.extension}` : phone.number,
              });
            }}
            error={attemptedSubmit && !hasValidPhoneNumber}
            helperText={attemptedSubmit && !hasValidPhoneNumber && "Please provide an exactly ten digit phone number."}
            type="tel"
            data-public
          />
        </Row>
        <Row>
          <Tooltip
            title="Cannot change e-mail after user has been activated. If you need to modify this field, please de-activate this user and create a new user with the updated e-mail."
            data-public
          >
            <div>
              <TextField value={newMemberInfo.email} disabled fullWidth data-public />
            </div>
          </Tooltip>
        </Row>
        <Row>
          <TextField
            fullWidth
            name="Title"
            label="Title (e.g. Pre-cert manager)"
            value={newMemberInfo.title}
            onChangeValue={(title) => setNewMemberInfo({ ...newMemberInfo, title })}
            data-public
          />
        </Row>
        <Row>
          <MultiSelectDropdown
            label="User specialties (for any vendor, not just Cohere)"
            options={defaultSpecialtyOptions}
            onChange={(specialties) => setNewMemberInfo({ ...newMemberInfo, specialties })}
            selectedValues={newMemberInfo.specialties}
            maxMenuHeight={"280px"}
            data-public
          />
        </Row>
        <Row>
          <SwitchContainer>
            <Switch
              fullWidth
              checked={newMemberInfo.roles?.includes(UserType.BackOfficeAdmin)}
              label={
                <SwitchLabel isAdmin={newMemberInfo.roles?.includes(UserType.BackOfficeAdmin) || false} data-public />
              }
              onChange={(checked) =>
                setNewMemberInfo({
                  ...newMemberInfo,
                  roles: checked ? [UserType.BackOfficeAdmin] : [UserType.BackOffice],
                })
              }
              FormControlLabelProps={{
                classes: {
                  label: classes.switchLabel,
                  labelPlacementStart: classes.switchLabelPlacementStartOverrides,
                },
                labelPlacement: "start",
              }}
              data-public
            />
          </SwitchContainer>
        </Row>
      </Grid>
      <ApproveButton
        data-tracking-id="admin-verify-access-to-org-button"
        loading={approvingPendingUser}
        onClick={handleApprove}
        disabled={!allFieldsFilled}
        data-public
      >
        Verify account
      </ApproveButton>
      <TertiaryButton
        warning
        data-tracking-id="admin-deny-access-to-org-button"
        loading={denyingPendingUser}
        onClick={handleDeny}
        data-public
      >
        Reject access
      </TertiaryButton>
    </InformativeModal>
  );
}

const Row = (props: ComponentProps<typeof Grid>) => <Grid item xs={12} {...props} />;

const parseAsPhoneNumber = (number?: string): PhoneNumber => {
  const parsed = parsePhoneNumber(number || "", "US");
  const phone = {
    number: parsed?.nationalNumber?.toString(),
    extension: parsed?.ext?.toString(),
  };
  return phone;
};

// eslint-disable-next-line cohere-react/no-mui-styled-import
const SwitchContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  padding: theme.spacing(1.5),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ApproveButton = styled(PrimaryButton)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

const SwitchLabel = ({ isAdmin }: { isAdmin: boolean }) => (
  <>
    <Body1>Organization admin</Body1>
    <Body1>{isAdmin ? "Yes" : "No"}</Body1>
  </>
);
