import React, { Dispatch, PropsWithChildren, useRef } from "react";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import compact from "lodash/compact";
import useIsOverflow from "hooks/useIsOverflow";
import {
  Body1,
  Caption,
  H4,
  colorsDark,
  colorsLight,
  Tooltip,
  formatDateStr,
  formatDateStrWithCustomFormat,
  SummaryCardDrugIcon,
  patientDisplayName,
  useGeneralAuthSubmissionWorflowOn,
  extractClinicalServicesText,
  useFeature,
} from "@coherehealth/common";
import { Link } from "react-router-dom";
import { ServiceRequestSearchResponse, UserResponse } from "@coherehealth/core-platform-api";
// eslint-disable-next-line cohere-react/no-mui-styled-import
import { styled } from "@material-ui/core/styles";
import ServiceRequestRelationDisplay from "../ServiceRequestRelationDisplay";
import { useTheme } from "@material-ui/core/styles";
import { getClinicalServicesText } from "util/authorization";
import { formatServiceName } from "util/serviceRequest";
import { Typography } from "@material-ui/core";
import AuthVisibilityMessage from "common/AuthVisibilityMessage";
import { routeToPatientSummaryFromReview } from "util/routeUtils/routeUtils";
import { ServiceRequestStatusDisplay } from "components/ServiceRequestStatusDisplay/StatusBanner/ServiceRequestStatusDisplay";

export interface Props {
  serviceRequest: ServiceRequestSearchResponse;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onEdit?: Function;
  hover: boolean;
  setHover: Dispatch<boolean>;
  // HACK FIX ME (disabling just to initially release ban-types rule: fix this!!!!)
  // eslint-disable-next-line @typescript-eslint/ban-types
  onDeleteDraft?: Function;
  isExistingAuthorization?: boolean;
  user?: UserResponse | null;
}

const M_DASH = "\u2014";

export default function ServiceRequestSummary({
  serviceRequest,
  onEdit,
  hover,
  setHover,
  onDeleteDraft,
  isExistingAuthorization,
  user,
}: Props) {
  const generalAuthSubmissionWorkflowEnabled = useGeneralAuthSubmissionWorflowOn(serviceRequest.healthPlanName || "");
  const facilityBasedClinicalServicePriority = useFeature("facilityBasedClinicalServicePriority");

  const textElementRef = useRef<HTMLInputElement | null>(null);
  const { hoverStatus } = useIsOverflow(textElementRef);
  const {
    intakeTimestamp,
    tatStartTimestamp,
    patient,
    procedureCodes,
    startDate,
    endDate,
    serviceRequestRelations,
    healthPlanName,
    healthPlanDisplayName,
    type,
    prescribedDrug,
    dateCreated,
  } = serviceRequest;
  const clinicalServiceNames = getClinicalServicesText(serviceRequest.clinicalServices);
  const serviceDetail = facilityBasedClinicalServicePriority
    ? extractClinicalServicesText(
        serviceRequest.clinicalServices || [],
        serviceRequest.authCategory,
        serviceRequest.authSubcategory,
        serviceRequest.palCategory,
        "--"
      )
    : serviceRequest?.encounterType === "INPATIENT"
    ? formatServiceName(serviceRequest) || "--"
    : clinicalServiceNames || serviceRequest.clinicalService?.name || procedureCodes?.[0]?.description || "None";

  const theme = useTheme();
  const serviceDatesTitle = compact([startDate, endDate]).length <= 1 ? "Date of service" : "Dates of service";
  const formattedServiceDates = compact([startDate, endDate]).map(formatDateStr).join(` ${M_DASH} `) || "none";

  const procecureCodesTitle = procedureCodes && procedureCodes.length <= 1 ? "Procedure code" : "Procedure codes";
  const procedureCodesText = procedureCodes?.map((px) => px.code).join(", ") || "--";

  // eslint-disable-next-line cohere-react/no-mui-styled-import
  const Body1WithTooltip = styled(Typography)(({ theme }) => ({
    maxWidth: "100%",
    overflow: "hidden",
    lineHeight: "18px",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    "&:hover": {
      background: hoverStatus ? "rgba(0, 0, 0, 0.04)" : undefined,
      "background-blend-mode": hoverStatus ? "multiply" : undefined,
    },
  }));

  const submissionDate =
    type === "Pharmacy"
      ? formatDateStrWithCustomFormat(dateCreated, "M/d/yyyy h:mm a")
      : intakeTimestamp
      ? formatDateStrWithCustomFormat(tatStartTimestamp ?? intakeTimestamp, "M/d/yyyy h:mm a")
      : M_DASH;
  const canCreateContinuation =
    (isExistingAuthorization && !serviceRequest.clinicalService?.hasUnitsOnPxChanged) || false;
  const serviceDetailFields =
    type === "Pharmacy" ? (
      <>
        <Grid item style={{ width: theme.spacing(4), marginTop: "auto", marginBottom: "auto" }}>
          <SummaryCardDrugIcon
            style={{
              marginLeft: theme.spacing(1.5),
              height: theme.spacing(4),
              width: theme.spacing(4),
            }}
          />
        </Grid>
        <Grid item style={{ marginLeft: theme.spacing(3) }} xs={6}>
          <LightCaption>Drug</LightCaption>
          <WrappingBody>{prescribedDrug?.drugDescription || M_DASH}</WrappingBody>
        </Grid>
        <Grid item xs={5}>
          <LightCaption>Submission date</LightCaption>
          <WrappingBody>{submissionDate}</WrappingBody>
        </Grid>
      </>
    ) : (
      <>
        <Grid item style={{ marginLeft: 0 }} xs={4} data-public>
          <LightCaption>Service</LightCaption>
          {generalAuthSubmissionWorkflowEnabled ? (
            <Tooltip
              title={serviceDetail}
              placement="top"
              arrow
              disableHoverListener={!hoverStatus}
              data-testid="cs-tooltip"
              style={{ zIndex: 9999 }}
            >
              <div>
                <Body1WithTooltip variant="body1" ref={textElementRef}>
                  {serviceDetail}
                </Body1WithTooltip>
              </div>
            </Tooltip>
          ) : (
            <span>{serviceDetail}</span>
          )}
        </Grid>
        <Grid item xs={3} data-public>
          <LightCaption>{procecureCodesTitle}</LightCaption>
          <WrappingBody>{procedureCodesText}</WrappingBody>
        </Grid>
        <Grid item xs={3} data-public>
          <LightCaption>Submission date</LightCaption>
          <WrappingBody>{submissionDate}</WrappingBody>
        </Grid>
        <Grid item xs={3} data-public>
          <LightCaption>{serviceDatesTitle}</LightCaption>
          <WrappingBody>{formattedServiceDates}</WrappingBody>
        </Grid>
      </>
    );

  const serviceRequestIsRestricted = serviceRequest?.isRestricted;
  const serviceRequestIsViewable = !serviceRequest?.isRestricted && serviceRequest?.hasRestrictedCodes;

  return (
    <Grid container spacing={3}>
      <Row style={{ position: "relative" }}>
        <ServiceRequestNavLink
          patientId={patient?.id}
          reviewServiceRequestId={serviceRequest.id}
          data-testid={`summary-card-link-${serviceRequest.id}`}
        >
          {!isExistingAuthorization && (
            <PatientInfoRow>
              <H4>{(patient && patientDisplayName(patient)) || "Patient name missing"}</H4>
              <PatientDemographicsContainer>
                <PatientDemographics>
                  <LightCaption>DOB</LightCaption>
                  <Body1>{formatDateStr(patient?.dateOfBirth)}</Body1>
                </PatientDemographics>
                <PatientDemographics>
                  <LightCaption>Member ID</LightCaption>
                  <Body1>{patient?.memberId || "Unknown"}</Body1>
                </PatientDemographics>
                <PatientDemographics data-public>
                  <LightCaption>Health plan</LightCaption>
                  <Body1>{healthPlanDisplayName ?? healthPlanName ?? "Unknown"}</Body1>
                </PatientDemographics>
              </PatientDemographicsContainer>
            </PatientInfoRow>
          )}
          {!isExistingAuthorization && !serviceRequestIsRestricted && <DividerWithMargin />}
          {!serviceRequestIsRestricted && <ServiceDetailRow>{serviceDetailFields}</ServiceDetailRow>}
        </ServiceRequestNavLink>
      </Row>
      <Row style={{ paddingTop: "10px" }}>
        <ServiceRequestStatusDisplay
          serviceRequest={serviceRequest}
          onEdit={onEdit}
          setHover={setHover}
          onDeleteDraft={onDeleteDraft}
          canCreateContinuation={canCreateContinuation}
          actionConfiguration={{
            printActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(!hover || serviceRequestIsRestricted),
            },
            startContinuationActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(!hover || serviceRequestIsRestricted),
            },
            draftContinueActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(serviceRequestIsRestricted),
            },
            draftDeleteActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(serviceRequestIsRestricted),
            },
            editStatusActionConfiguration: {
              disabled: Boolean(serviceRequestIsRestricted),
              hidden: Boolean(!hover || serviceRequestIsRestricted),
            },
          }}
        />
      </Row>
      {serviceRequestRelations?.map((relation) => (
        <Row key={relation.id}>
          <ServiceRequestRelationDisplay
            sourceServiceRequestId={serviceRequest.id}
            serviceRequestRelation={relation}
            patientId={patient?.id}
          />
        </Row>
      ))}
      <AuthVisibilityMessage
        serviceRequest={serviceRequest}
        user={user}
        isRestricted={serviceRequestIsRestricted}
        isViewable={serviceRequestIsViewable}
        visibilityToggleStatus={serviceRequest?.restrictedDataSettings?.allowEditForAuthorizationParticipants}
        renderedFromServiceRequestSummary={true}
      />
    </Grid>
  );
}

const Row: typeof Grid = ({ children, ...passthrough }: Parameters<typeof Grid>[0]) => (
  <Grid item xs={12} {...passthrough}>
    {children}
  </Grid>
);

// eslint-disable-next-line cohere-react/no-mui-styled-import
const LightCaption = styled(Caption)(({ theme }) => ({
  color: theme.palette.type === "dark" ? colorsDark.font.secondary : colorsLight.font.light,
  margin: "2px 0px",
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientInfoRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientDemographicsContainer = styled("div")({
  display: "flex",
});
// eslint-disable-next-line cohere-react/no-mui-styled-import
const PatientDemographics = styled("div")({
  paddingLeft: 32,
  display: "flex",
  alignItems: "center",
  "& span": {
    marginTop: 2,
    marginRight: 8,
  },
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
const DividerWithMargin = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const WrappingBody = styled(Body1)(({ theme }) => ({
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "break-spaces",
  marginTop: theme.spacing(1),
  lineHeight: "18px",
}));
// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceDetailRow = styled(Row)({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
});

// eslint-disable-next-line cohere-react/no-mui-styled-import
// const ServiceDetailLabelDetail = styled(Grid)(({ theme }) => ({
//   paddingLeft: "2%",
//   lineHeight: "15px",
// }));

// eslint-disable-next-line cohere-react/no-mui-styled-import
const ServiceRequestNavLink = styled(
  ({
    children,
    patientId,
    reviewServiceRequestId,
    ...props
  }: PropsWithChildren<{ patientId?: string; reviewServiceRequestId: string }>) => (
    <Link
      to={patientId ? routeToPatientSummaryFromReview({ serviceRequestId: reviewServiceRequestId, patientId }) : "#"}
      {...props}
    >
      {children}
    </Link>
  )
)({
  color: "inherit",
  textDecoration: "none",
  "&:focus, &:hover, &:visited, &:link, &:active": {
    textDecoration: "none",
  },
});
