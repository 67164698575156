import { Dispatch, FunctionComponent, SetStateAction, useContext, useState } from "react";
import { ReferralRequestFormContent } from "../ReferralRequestForm";
import {
  Location,
  Patient,
  Provider,
  useGetProvidersOrFacilities,
  UseGetProvidersOrFacilitiesProps,
} from "@coherehealth/core-platform-api";
import { ReferralFormConfiguration } from "components/ReferralManagement/FormContentSpecification/ReferralRequestFormContentSpecifications";
import { getCurrentCoverage, getPatientHealthPlanName } from "util/patientUtils";
import { Grid, makeStyles } from "@material-ui/core";
import {
  SelectOptionsHook,
  SingleSelectForProviderFacilitySelect,
  useLazyLoadingQueryOptionsHook,
} from "@coherehealth/common";
import {
  getAddress,
  getLocationsForTin,
  getLocationsWithOptions,
  getTinListWithOptions,
  getTinWithOptions,
  LocationOption,
  ProviderOption,
  TinOption,
  useProviderStyles,
} from "components/ServiceRequest/ServiceRequestForm/components/ProviderSelectManual";
import {
  providerFacilityAddressRenderer,
  providerFacilityDropdownRender,
  providerFacilityTinRenderer,
} from "components/ServiceRequest/ProviderFacilityDropdownRender";
import { RecentProvidersContext } from "common/RecentProviders/context";
// * Code tag RECENT_PROVIDERS_8B12CDB1
import Recents from "common/RecentProviders";

function getUseProvidersDedicatedToHealthPlan(
  patient: Patient | undefined,
  useCMSProviders: string,
  ignoreTin: boolean,
  selectedLocation: Location | undefined | null,
  setCurrSpecialty?: Dispatch<SetStateAction<string | undefined>>,
  specialty?: string | undefined,
  currSpecialty?: string
): SelectOptionsHook<ProviderOption> {
  return function useProviders(selectOptionsParams) {
    const patientCoverage = getCurrentCoverage(patient);
    const rpn = patientCoverage?.payerCustomFields?.find(
      (field) => field.fieldName?.toLocaleLowerCase() === "rpn"
    )?.valueString;

    const externalReferenceId = selectedLocation?.externalReferenceId;

    const asOfToday = new Date();
    const healthPlanName = patient ? getPatientHealthPlanName(patient, asOfToday) : undefined;
    const queryParams = {
      healthPlanName: healthPlanName,
      ignoreTin: ignoreTin,
      useCMSProviders: useCMSProviders,
      rpn,
      externalReferenceId,
      specialty,
    };

    return useLazyLoadingQueryOptionsHook({
      useGetHook: (args: UseGetProvidersOrFacilitiesProps) => useGetProvidersOrFacilities({ ...args, debounce: 600 }),
      additionalQueryParams: { ...queryParams, recordType: "PROVIDER" },
      reloadData: setCurrSpecialty ? currSpecialty !== specialty : false,
      onReloadData: () => setCurrSpecialty && setCurrSpecialty(specialty),
      ...selectOptionsParams,
    });
  };
}

export const useReferralProviderStyles = makeStyles((theme) => ({
  providerFlex: {
    "@media (max-width: 499px)": {
      flex: `1 1 584px`,
    },
    "@media (min-width: 500px) and (max-width: 1169px)": {
      flex: `1 1 calc(100vw - 48px)`,
    },
    "@media (min-width: 1170px)": {
      flex: `1 1 40%`,
    },
    minWidth: "200px",
  },
  addressFlex: {
    "@media (max-width: 499px)": {
      flex: `1 1 50%`,
    },
    "@media (min-width: 500px) and (max-width: 1169px)": {
      flex: `1 1 50%`,
    },
    "@media (min-width: 1170px)": {
      flex: `1 1 40%`,
    },
  },
  tin: {
    "@media (max-width: 499px)": {
      width: "calc(100vw - 98px)",
    },
    "@media (min-width: 500px)": {
      width: theme.spacing(20),
    },
  },
}));

export const ReferringProviderSelect: FunctionComponent<{
  error: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  rrFormContent: ReferralRequestFormContent;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  patient?: Patient;
  formConfiguration?: ReferralFormConfiguration;
  hideTinField?: boolean;
}> = ({
  error,
  npiError,
  tinError,
  addressError,
  rrFormContent,
  setRRFormContent,
  patient,
  formConfiguration,
  hideTinField = false,
}) => {
  const classes = useProviderStyles();
  // * Code tag RECENT_PROVIDERS_8B12CDB1
  const { trackingStateClickChangeCheck } = useContext(RecentProvidersContext);

  const healthPlanName = patient ? getPatientHealthPlanName(patient, new Date()) : undefined; //-- Needed for recents
  const refProviderClasses = useReferralProviderStyles();

  return (
    <Grid container spacing={2}>
      <Grid item className={refProviderClasses.providerFlex}>
        <SingleSelectForProviderFacilitySelect<ProviderOption>
          disablePortal
          markSelectedOptions={false}
          error={error || npiError}
          clearable={formConfiguration?.referringProviderNPI.fieldSpec === "OPTIONAL"}
          helperText={error ? "Required" : npiError ? "NPI Required" : ""}
          label={"Referring provider"}
          emptyLabel={hideTinField ? "Referring provider by NPI or name" : "Referring provider by NPI, TIN, or name"}
          useOptions={getUseProvidersDedicatedToHealthPlan(
            patient,
            "true",
            hideTinField,
            null,
            undefined,
            "" // we dont have to filter referring provider based of specialty. Sending this as empty as we use same logic for specialist
          )}
          getOptionLabel={({ name, npi }) => `${name} / NPI - ${npi}`}
          renderOption={({ name, tinList, npi, optionType }) =>
            providerFacilityDropdownRender(name, tinList, npi, optionType, undefined, hideTinField, false, true)
          }
          selectedValue={rrFormContent.selectedReferringProvider || null}
          setSelectedValue={async (provider) => {
            if (["noResults", "someResults"].includes(provider?.optionType || "")) {
              setRRFormContent((prevContent) => ({
                ...prevContent,
                selectedReferringProvider: provider,
                referringProviderSelectedAddress: undefined,
                referringProviderSelectedTin: undefined,
              }));
            } else if (provider === null) {
              setRRFormContent((prevContent) => ({
                ...prevContent,
                selectedReferringProvider: null,
                referringProviderSelectedAddress: null,
                referringProviderSelectedTin: null,
              }));
            } else {
              const selectedTin = provider?.tinList?.length === 1 ? provider.tinList[0] : undefined;
              let locationsForTin = getLocationsForTin(selectedTin, provider, hideTinField);
              let selectedLocation: Location = {};
              if (locationsForTin && locationsForTin.length === 1) {
                selectedLocation = locationsForTin[0];
              }
              if (
                hideTinField ||
                (provider?.locations &&
                  provider.locations.length > 0 &&
                  provider?.source === "CMS" &&
                  !selectedLocation.address)
              ) {
                if (provider?.locations && provider.locations.length > 0) {
                  selectedLocation = provider.locations[0];
                }
              }
              const selectedReferringProvider: Provider = {
                ...provider,
                selectedLocation: selectedLocation,
                type: "PROVIDER",
              } as Provider;
              setRRFormContent((prevContents) => ({
                ...prevContents,
                selectedReferringProvider: selectedReferringProvider,
                referringProviderSelectedTin: selectedTin,
                referringProviderSelectedAddress: selectedLocation,
              }));
            }
            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("ReferringProvider");
            }
          }}
          data-testid="referring-provider"
          dataPublic={true}
        />
      </Grid>
      {!hideTinField && (
        <Grid item>
          <SingleSelectForProviderFacilitySelect<TinOption>
            disablePortal
            label={"TIN"}
            clearable={formConfiguration?.referringProviderTIN.fieldSpec === "OPTIONAL"}
            markSelectedOptions={false}
            disabled={!Boolean(rrFormContent?.selectedReferringProvider)}
            selectedValue={getTinWithOptions(
              rrFormContent.referringProviderSelectedTin ||
                rrFormContent?.selectedReferringProvider?.selectedLocation?.tin
            )}
            setSelectedValue={async (tin) => {
              if (tin && tin.id !== "") {
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  referringProviderSelectedTin: tin.id,
                }));

                let locationsForTin = getLocationsForTin(tin.id, rrFormContent.selectedReferringProvider);
                let selectedLocation: Location = {};
                if (locationsForTin && locationsForTin?.length === 1) {
                  selectedLocation = locationsForTin[0];
                }
                const selectedReferringProvider: Provider = {
                  ...rrFormContent?.selectedReferringProvider,
                  selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                } as Provider;
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  referringProviderSelectedAddress: selectedLocation.address ? selectedLocation : undefined,
                  selectedReferringProvider: selectedReferringProvider,
                }));
              } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                const referringProvider: Provider = {
                  ...rrFormContent.selectedReferringProvider,
                  selectedLocation: undefined,
                } as Provider;
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  selectedReferringProvider: referringProvider,
                  referringProviderSelectedAddress: undefined,
                  referringProviderSelectedTin: undefined,
                }));
              }
              // * Code tag RECENT_PROVIDERS_8B12CDB1
              if (trackingStateClickChangeCheck) {
                trackingStateClickChangeCheck("ReferringProvider");
              }
            }}
            useOptions={() => ({
              options: getTinListWithOptions(rrFormContent.selectedReferringProvider?.tinList),
              optionsLoading: false,
              filterOptions: (options, state) =>
                options.filter((option) => option.val.toLowerCase().includes(state.inputValue.toLowerCase())),
            })}
            error={tinError}
            className={tinError ? `${classes.error} ${refProviderClasses.tin}` : refProviderClasses.tin}
            helperText={tinError && "Required"}
            getOptionLabel={({ val }) => val}
            renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType, true)}
            data-testid="referral-provider-tin"
            dataPublic={false}
          />
        </Grid>
      )}
      <Grid item className={refProviderClasses.addressFlex}>
        <SingleSelectForProviderFacilitySelect<LocationOption>
          disablePortal
          label={"Address"}
          clearable={formConfiguration?.referringProviderAddress.fieldSpec === "OPTIONAL"}
          disabled={
            !(
              Boolean(rrFormContent?.selectedReferringProvider) &&
              (hideTinField ||
                Boolean(rrFormContent?.referringProviderSelectedTin) ||
                (rrFormContent?.selectedReferringProvider?.source === "CMS" &&
                  !rrFormContent?.selectedReferringProvider?.manuallyCreated))
            )
          }
          markSelectedOptions={false}
          useOptions={() => ({
            options: getLocationsWithOptions(
              getLocationsForTin(
                rrFormContent?.referringProviderSelectedTin,
                rrFormContent?.selectedReferringProvider,
                hideTinField
              ),
              false,
              rrFormContent?.referringProviderSelectedTin,
              hideTinField
            ),
            optionsLoading: false,
            filterOptions: (options, state) =>
              options.filter(
                (option) =>
                  option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.optionType === "noResults" ||
                  option?.optionType === "someResults"
              ),
          })}
          selectedValue={
            rrFormContent?.referringProviderSelectedAddress ||
            rrFormContent?.selectedReferringProvider?.selectedLocation ||
            null
          }
          helperText={addressError && "Required"}
          getOptionLabel={({ address }) => getAddress(address)}
          setSelectedValue={(location) => {
            let referringProvider: Provider;
            if (["noResults", "someResults"].includes(location?.optionType || "")) {
              referringProvider = {
                ...rrFormContent?.selectedReferringProvider,
                selectedLocation: undefined,
                type: "PROVIDER",
              } as Provider;
              setRRFormContent((prevContent) => ({
                ...prevContent,
                referringProviderSelectedAddress: undefined,
                selectedReferringProvider: referringProvider,
              }));
            } else {
              referringProvider = {
                ...rrFormContent?.selectedReferringProvider,
                selectedLocation: location,
                type: "PROVIDER",
              } as Provider;
              setRRFormContent((prevContent) => ({
                ...prevContent,
                referringProviderSelectedAddress: location,
                selectedReferringProvider: referringProvider,
              }));
            }
            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("ReferringProvider");
            }
          }}
          error={addressError}
          renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType, true)}
          className={addressError ? classes.error : undefined}
          data-testid="requesting-provider-address"
          dataPublic={true}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: 0 }}>
        <Recents<ReferralRequestFormContent>
          bucketName="ReferringProvider"
          setFormContent={setRRFormContent}
          referralProviderId={rrFormContent?.selectedReferringProvider?.id}
          healthPlan={healthPlanName}
        />
      </Grid>
    </Grid>
  );
};

export const PerformingSpecialistSelect: FunctionComponent<{
  providerError: boolean;
  npiError?: boolean;
  tinError?: boolean;
  addressError?: boolean;
  rrFormContent: ReferralRequestFormContent;
  setRRFormContent: Dispatch<SetStateAction<ReferralRequestFormContent>>;
  patient?: Patient;
  formConfiguration?: ReferralFormConfiguration;
  setHasPerformingSpecialistAttestation?: Dispatch<SetStateAction<boolean>>;
  hideTinField?: boolean;
}> = ({
  providerError,
  npiError,
  tinError,
  addressError,
  rrFormContent,
  setRRFormContent,
  patient,
  formConfiguration,
  setHasPerformingSpecialistAttestation,
  hideTinField = false,
}) => {
  const classes = useProviderStyles();
  const healthPlanName = patient ? getPatientHealthPlanName(patient, new Date()) : undefined;
  const refProviderClasses = useReferralProviderStyles();
  const isPerformingSpecialistNpiOptional = formConfiguration?.performingSpecialistNPI.fieldSpec === "OPTIONAL";
  const isPerformingSpecialistOptional =
    rrFormContent?.medicalOrBehavioral === "Behavioral Health" && healthPlanName === "BCBS South Carolina";
  // * Code tag RECENT_PROVIDERS_8B12CDB1
  const { trackingStateClickChangeCheck } = useContext(RecentProvidersContext);
  const [currSpecialty, setCurrSpecialty] = useState<string | undefined>("");

  return (
    <Grid container spacing={2}>
      <Grid item className={refProviderClasses.providerFlex}>
        <SingleSelectForProviderFacilitySelect<ProviderOption>
          disablePortal
          clearable={isPerformingSpecialistNpiOptional || isPerformingSpecialistOptional}
          error={providerError || npiError}
          markSelectedOptions={false}
          helperText={providerError ? "Required" : npiError ? "NPI Required" : ""}
          label={hideTinField ? "Specialist" : "Specialist (optional)"}
          emptyLabel={
            isPerformingSpecialistOptional
              ? "Specialist by NPI, or name (optional)"
              : hideTinField
              ? "Specialist by NPI, or name"
              : "Specialist by NPI, TIN, or name (optional)"
          }
          useOptions={getUseProvidersDedicatedToHealthPlan(
            patient,
            "true",
            hideTinField,
            rrFormContent?.facilitySelectedAddress,
            setCurrSpecialty,
            rrFormContent?.specialty,
            currSpecialty
          )}
          getOptionLabel={({ name, npi }) =>
            `${name} / NPI - ${npi || (isPerformingSpecialistNpiOptional ? "N/A" : "")}`
          }
          renderOption={({ name, tinList, npi, optionType }) =>
            providerFacilityDropdownRender(
              name,
              tinList,
              npi,
              optionType,
              (tin: string | null) => setRRFormContent({ ...rrFormContent, performingSpecialistSelectedTin: tin }),
              hideTinField,
              isPerformingSpecialistNpiOptional,
              true
            )
          }
          selectedValue={rrFormContent?.selectedPerformingSpecialist || null}
          setSelectedValue={async (provider) => {
            setHasPerformingSpecialistAttestation?.(false);
            const selectedTin = provider?.tinList?.length === 1 ? provider.tinList[0] : undefined;
            let locationsForTin = getLocationsForTin(selectedTin, provider, hideTinField);

            let selectedLocation: Location = {};
            if (locationsForTin && locationsForTin?.length === 1) {
              selectedLocation = locationsForTin[0];
            }
            if (
              hideTinField ||
              (provider?.source === "CMS" && !provider?.manuallyCreated && !selectedLocation.address)
            ) {
              if (provider?.locations && provider.locations.length > 0) {
                selectedLocation = provider.locations[0];
              }
            }
            // Removed fallback for fetching locations, and address for a provider if not present
            if (["noResults", "someResults"].includes(provider?.optionType || "")) {
              setRRFormContent((prevContent) => ({
                ...prevContent,
                selectedPerformingSpecialist: provider,
                performingSpecialistSelectedAddress: undefined,
                performingSpecialistSelectedTin: undefined,
              }));
            } else if (provider === null) {
              //clear provider form content if it is nullified by the user
              setRRFormContent((prevContent) => {
                return {
                  ...prevContent,
                  performingSpecialistSelectedAddress: null,
                  selectedPerformingSpecialist: null,
                  performingSpecialistSelectedTin: null,
                };
              });
            } else {
              const performingSpecialist: Provider = {
                ...provider,
                selectedLocation: selectedLocation,
                type: "PROVIDER",
              } as Provider;
              setRRFormContent((prevContent) => ({
                ...prevContent,
                selectedPerformingSpecialist: performingSpecialist,
                performingSpecialistSelectedTin: selectedTin,
                performingSpecialistSelectedAddress: selectedLocation.address ? selectedLocation : undefined,
              }));
            }
            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("PerformingSpecialist");
            }
          }}
          data-testid="performing-specialist-npi"
          dataPublic={true}
        />
      </Grid>
      {!hideTinField && (
        <Grid item>
          <SingleSelectForProviderFacilitySelect<TinOption>
            disablePortal
            label={"TIN"}
            clearable={formConfiguration?.performingSpecialistTIN.fieldSpec === "OPTIONAL"}
            markSelectedOptions={false}
            disabled={!Boolean(rrFormContent?.selectedPerformingSpecialist)}
            helperText={tinError && "Required"}
            setSelectedValue={async (tin) => {
              if (tin && tin?.id !== "") {
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  performingSpecialistSelectedTin: tin.id,
                }));
                let locationsForTin = getLocationsForTin(
                  tin.id,
                  rrFormContent?.selectedPerformingSpecialist,
                  hideTinField
                );
                let selectedLocation: Location = {};
                if (locationsForTin && locationsForTin?.length === 1) {
                  selectedLocation = locationsForTin[0];
                }

                const performingSpecialist: Provider = {
                  ...rrFormContent?.selectedPerformingSpecialist,
                  selectedLocation: selectedLocation.address ? selectedLocation : undefined,
                } as Provider;

                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  performingSpecialistSelectedAddress: selectedLocation.address ? selectedLocation : undefined,
                  selectedPerformingSpecialist: performingSpecialist,
                }));
              } else if (["noResults", "someResults"].includes(tin?.optionType || "")) {
                const performingSpecialist: Provider = {
                  ...rrFormContent?.selectedPerformingSpecialist,
                  selectedLocation: undefined,
                } as Provider;
                setRRFormContent((prevContent) => ({
                  ...prevContent,
                  performingSpecialistSelectedTin: undefined,
                  performingSpecialistSelectedAddress: undefined,
                  selectedPerformingSpecialist: performingSpecialist,
                }));
              }
              // * Code tag RECENT_PROVIDERS_8B12CDB1
              if (trackingStateClickChangeCheck) {
                trackingStateClickChangeCheck("PerformingSpecialist");
              }
            }}
            useOptions={() => ({
              options: getTinListWithOptions(rrFormContent?.selectedPerformingSpecialist?.tinList),
              optionsLoading: false,
              filterOptions: (options, state) =>
                options.filter((option) => option.val.toLowerCase().includes(state.inputValue.toLowerCase())),
            })}
            className={tinError ? `${classes.error} ${refProviderClasses.tin}` : refProviderClasses.tin}
            data-testid="provider-specialist-tin"
            selectedValue={getTinWithOptions(
              rrFormContent?.performingSpecialistSelectedTin ||
                rrFormContent?.selectedPerformingSpecialist?.selectedLocation?.tin
            )}
            error={tinError}
            getOptionLabel={({ val }) => val}
            renderOption={({ id, val, optionType }) => providerFacilityTinRenderer(id, val, optionType, true)}
            dataPublic={false}
          />
        </Grid>
      )}
      <Grid item className={refProviderClasses.addressFlex}>
        <SingleSelectForProviderFacilitySelect<LocationOption>
          disablePortal
          label={"Address"}
          clearable={formConfiguration?.performingSpecialistAddress.fieldSpec === "OPTIONAL"}
          disabled={
            !(
              Boolean(rrFormContent?.selectedPerformingSpecialist) &&
              (hideTinField ||
                Boolean(rrFormContent?.performingSpecialistSelectedTin) ||
                (rrFormContent?.selectedPerformingSpecialist?.source === "CMS" &&
                  !rrFormContent?.selectedPerformingSpecialist?.manuallyCreated))
            )
          }
          markSelectedOptions={false}
          useOptions={() => ({
            options: getLocationsWithOptions(
              getLocationsForTin(
                rrFormContent?.performingSpecialistSelectedTin,
                rrFormContent?.selectedPerformingSpecialist,
                hideTinField
              ),
              false,
              rrFormContent?.performingSpecialistSelectedTin,
              hideTinField
            ),
            optionsLoading: false,
            filterOptions: (options, state) =>
              options.filter(
                (option) =>
                  option?.address?.city?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.line1?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.line2?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.state?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.address?.zipCode?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                  option?.optionType === "noResults" ||
                  option?.optionType === "someResults"
              ),
          })}
          selectedValue={
            rrFormContent?.performingSpecialistSelectedAddress ||
            rrFormContent?.selectedPerformingSpecialist?.selectedLocation ||
            null
          }
          helperText={addressError && "Required"}
          getOptionLabel={({ address }) => getAddress(address)}
          setSelectedValue={(location) => {
            if (["noResults", "someResults"].includes(location?.optionType || "")) {
              const performingSpecialist: Provider = {
                ...rrFormContent?.selectedPerformingSpecialist,
                selectedLocation: undefined,
                type: "PROVIDER",
              } as Provider;
              setRRFormContent((prevContent) => ({
                ...prevContent,
                performingSpecialistSelectedAddress: undefined,
                selectedPerformingSpecialist: performingSpecialist,
              }));
            } else {
              const performingSpecialist: Provider = {
                ...rrFormContent?.selectedPerformingSpecialist,
                selectedLocation: location,
                type: "PROVIDER",
              } as Provider;
              setRRFormContent((prevContent) => ({
                ...prevContent,
                performingSpecialistSelectedAddress: location,
                selectedPerformingSpecialist: performingSpecialist,
              }));
            }
            // * Code tag RECENT_PROVIDERS_8B12CDB1
            if (trackingStateClickChangeCheck) {
              trackingStateClickChangeCheck("PerformingSpecialist");
            }
          }}
          error={addressError}
          className={addressError ? classes.error : undefined}
          renderOption={({ address, optionType }) => providerFacilityAddressRenderer(address, optionType, true)}
          data-testid="performing-provider-address"
          dataPublic={true}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: 0 }}>
        <Recents
          bucketName="PerformingSpecialist"
          setFormContent={setRRFormContent}
          performingSpecialistId={rrFormContent?.selectedPerformingSpecialist?.id}
          healthPlan={healthPlanName}
        />
      </Grid>
    </Grid>
  );
};
