import { ReactNode } from "react";

import NonPalPDF from "./NonPalPDF";
import { BlobProvider } from "@react-pdf/renderer";
import { PrintoutParams } from "../../DetailsPrintoutProvider";
import { Patient, Coverage, ProcedureCode } from "@coherehealth/core-platform-api";
import useMemoDeepCompare from "util/useDeepMemo";

interface Props {
  patient?: Patient;
  nonPalProcedureCodes: ProcedureCode[];
  children: (params: PrintoutParams) => ReactNode;
  coverage: Coverage | undefined;
  nonPalSubmissionEnabled?: boolean;
}

export default function NonPalPDFProvider({
  patient,
  nonPalProcedureCodes,
  children,
  coverage,
  nonPalSubmissionEnabled,
}: Props) {
  const Memoed = () => {
    return useMemoDeepCompare(
      () => (
        <BlobProvider
          key={Math.random()}
          document={
            <NonPalPDF
              patient={patient}
              nonPalProcedureCodes={nonPalProcedureCodes}
              coverage={coverage}
              nonPalSubmissionEnabled={nonPalSubmissionEnabled}
            />
          }
        >
          {children}
        </BlobProvider>
      ),
      [children, patient, nonPalProcedureCodes]
    );
  };

  return <Memoed />;
}
