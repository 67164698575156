/* Generated by restful-react */

import React from "react";
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps,
} from "restful-react";
export const SPEC_VERSION = "1.0.0";
export interface BulkUpdateIntegrationStatusPayload {
  integrationName?: string;
  eventId?: string;
  status?: string;
}

export interface IntegrationExecution {
  eventId?: string;
  integrationName?: string;
  objectId?: string;
  integrationType?: IntegrationType;
  dateCreated?: string;
  lastUpdated?: string;
  status?: IntegrationStatus;
  stage?: string;
  retryCount?: string;
  maxRetries?: string;
  requestJson?: string;
  responseJson?: string;
  snapshot?: string;
  errorDetails?: string;
  objectType?: string;
  sourceTopic?: string;
  displayName?: string;
}

export interface IntegrationExecutionSearchPayload {
  integrationType?: string;
  status?: string;
  dateMin?: string;
  dateMax?: string;
  max?: string;
}

export type IntegrationStatus =
  | "PENDING"
  | "RETRIED"
  | "ALARMED"
  | "STAGED"
  | "READY"
  | "PROCESSING"
  | "SENT"
  | "REQUEST_ERROR"
  | "COMPLETE"
  | "FAILED"
  | "WITHDRAWN"
  | "UNDELIVERABLE"
  | "SKIPPED"
  | "INTERNALLY_COMPLETE"
  | "AWAITING_CALLBACK"
  | "NOT_STARTED";

export interface IntegrationStatusUpdatePayload {
  integrationStatus?: IntegrationStatus;
  integrationType?: string;
}

export type IntegrationType =
  | "AUTHORIZATION_OUTBOUND"
  | "AUTHORIZATION_OUTBOUND_ENRICHMENT"
  | "AUTHORIZATION_INBOUND"
  | "ATTACHMENTS"
  | "MAIL_VENDOR_OUTBOUND"
  | "MAIL_VENDOR_INBOUND"
  | "VAT_OUTBOUND"
  | "VAT_INBOUND"
  | "SALESFORCE"
  | "CAREWEBQI"
  | "FINAL_DETERMINATION_FAX"
  | "REFERRALS_OUTBOUND"
  | "REFERRALS_OUTBOUND_ENRICHMENT"
  | "REFERRALS_INBOUND";

export interface Object {
  [key: string]: any;
}

export interface GetAllIntegrationExecutionLogsQueryParams {
  objectId: string;
}

export type GetAllIntegrationExecutionLogsProps = Omit<
  GetProps<
    IntegrationExecution[],
    unknown,
    GetAllIntegrationExecutionLogsQueryParams,
    void
  >,
  "path"
>;

/**
 * Search for all integration execution objects given object id
 */
export const GetAllIntegrationExecutionLogs = (
  props: GetAllIntegrationExecutionLogsProps
) => (
  <Get<
    IntegrationExecution[],
    unknown,
    GetAllIntegrationExecutionLogsQueryParams,
    void
  >
    path={`/integrationExecution/log`}
    {...props}
  />
);

export type UseGetAllIntegrationExecutionLogsProps = Omit<
  UseGetProps<
    IntegrationExecution[],
    unknown,
    GetAllIntegrationExecutionLogsQueryParams,
    void
  >,
  "path"
>;

/**
 * Search for all integration execution objects given object id
 */
export const useGetAllIntegrationExecutionLogs = (
  props: UseGetAllIntegrationExecutionLogsProps
) =>
  useGet<
    IntegrationExecution[],
    unknown,
    GetAllIntegrationExecutionLogsQueryParams,
    void
  >(`/integrationExecution/log`, props);

export type SaveProps = Omit<
  MutateProps<IntegrationExecution, unknown, void, IntegrationExecution, void>,
  "path" | "verb"
>;

/**
 * Save/Create an integration execution object using IntegrationExecution Payload
 */
export const Save = (props: SaveProps) => (
  <Mutate<IntegrationExecution, unknown, void, IntegrationExecution, void>
    verb="POST"
    path={`/integrationExecution/log`}
    {...props}
  />
);

export type UseSaveProps = Omit<
  UseMutateProps<
    IntegrationExecution,
    unknown,
    void,
    IntegrationExecution,
    void
  >,
  "path" | "verb"
>;

/**
 * Save/Create an integration execution object using IntegrationExecution Payload
 */
export const useSave = (props: UseSaveProps) =>
  useMutate<IntegrationExecution, unknown, void, IntegrationExecution, void>(
    "POST",
    `/integrationExecution/log`,
    props
  );

export type UpdateIntegrationExecutionLogByIDProps = Omit<
  MutateProps<
    Object,
    unknown,
    void,
    BulkUpdateIntegrationStatusPayload[],
    void
  >,
  "path" | "verb"
>;

/**
 * Update integration status for a give object ids
 */
export const UpdateIntegrationExecutionLogByID = (
  props: UpdateIntegrationExecutionLogByIDProps
) => (
  <Mutate<Object, unknown, void, BulkUpdateIntegrationStatusPayload[], void>
    verb="PATCH"
    path={`/integrationExecution/log/bulkUpdateStatus`}
    {...props}
  />
);

export type UseUpdateIntegrationExecutionLogByIDProps = Omit<
  UseMutateProps<
    Object,
    unknown,
    void,
    BulkUpdateIntegrationStatusPayload[],
    void
  >,
  "path" | "verb"
>;

/**
 * Update integration status for a give object ids
 */
export const useUpdateIntegrationExecutionLogByID = (
  props: UseUpdateIntegrationExecutionLogByIDProps
) =>
  useMutate<Object, unknown, void, BulkUpdateIntegrationStatusPayload[], void>(
    "PATCH",
    `/integrationExecution/log/bulkUpdateStatus`,
    props
  );

export interface GetAllIntegrationExecutionsLogByIntegrationNameAndStatusQueryParams {
  status: string;
}

export interface GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams {
  integrationName: string;
}

export type GetAllIntegrationExecutionsLogByIntegrationNameAndStatusProps =
  Omit<
    GetProps<
      IntegrationExecution[],
      unknown,
      GetAllIntegrationExecutionsLogByIntegrationNameAndStatusQueryParams,
      GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams
    >,
    "path"
  > &
    GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams;

/**
 * Retrieve all integration execution log objects given integration name and status
 */
export const GetAllIntegrationExecutionsLogByIntegrationNameAndStatus = ({
  integrationName,
  ...props
}: GetAllIntegrationExecutionsLogByIntegrationNameAndStatusProps) => (
  <Get<
    IntegrationExecution[],
    unknown,
    GetAllIntegrationExecutionsLogByIntegrationNameAndStatusQueryParams,
    GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams
  >
    path={`/integrationExecution/log/integration/${integrationName}`}
    {...props}
  />
);

export type UseGetAllIntegrationExecutionsLogByIntegrationNameAndStatusProps =
  Omit<
    UseGetProps<
      IntegrationExecution[],
      unknown,
      GetAllIntegrationExecutionsLogByIntegrationNameAndStatusQueryParams,
      GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams
    >,
    "path"
  > &
    GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams;

/**
 * Retrieve all integration execution log objects given integration name and status
 */
export const useGetAllIntegrationExecutionsLogByIntegrationNameAndStatus = ({
  integrationName,
  ...props
}: UseGetAllIntegrationExecutionsLogByIntegrationNameAndStatusProps) =>
  useGet<
    IntegrationExecution[],
    unknown,
    GetAllIntegrationExecutionsLogByIntegrationNameAndStatusQueryParams,
    GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams
  >(
    (
      paramsInPath: GetAllIntegrationExecutionsLogByIntegrationNameAndStatusPathParams
    ) =>
      `/integrationExecution/log/integration/${paramsInPath.integrationName}`,
    { pathParams: { integrationName }, ...props }
  );

export interface GetIntegrationExecutionLogByIdAndIntegrationTypeQueryParams {
  integrationType: string;
}

export interface GetIntegrationExecutionLogByIdAndIntegrationTypePathParams {
  objectId: string;
}

export type GetIntegrationExecutionLogByIdAndIntegrationTypeProps = Omit<
  GetProps<
    IntegrationExecution,
    unknown,
    GetIntegrationExecutionLogByIdAndIntegrationTypeQueryParams,
    GetIntegrationExecutionLogByIdAndIntegrationTypePathParams
  >,
  "path"
> &
  GetIntegrationExecutionLogByIdAndIntegrationTypePathParams;

/**
 * Search for an integration execution log by id and integration type
 */
export const GetIntegrationExecutionLogByIdAndIntegrationType = ({
  objectId,
  ...props
}: GetIntegrationExecutionLogByIdAndIntegrationTypeProps) => (
  <Get<
    IntegrationExecution,
    unknown,
    GetIntegrationExecutionLogByIdAndIntegrationTypeQueryParams,
    GetIntegrationExecutionLogByIdAndIntegrationTypePathParams
  >
    path={`/integrationExecution/log/object/${objectId}`}
    {...props}
  />
);

export type UseGetIntegrationExecutionLogByIdAndIntegrationTypeProps = Omit<
  UseGetProps<
    IntegrationExecution,
    unknown,
    GetIntegrationExecutionLogByIdAndIntegrationTypeQueryParams,
    GetIntegrationExecutionLogByIdAndIntegrationTypePathParams
  >,
  "path"
> &
  GetIntegrationExecutionLogByIdAndIntegrationTypePathParams;

/**
 * Search for an integration execution log by id and integration type
 */
export const useGetIntegrationExecutionLogByIdAndIntegrationType = ({
  objectId,
  ...props
}: UseGetIntegrationExecutionLogByIdAndIntegrationTypeProps) =>
  useGet<
    IntegrationExecution,
    unknown,
    GetIntegrationExecutionLogByIdAndIntegrationTypeQueryParams,
    GetIntegrationExecutionLogByIdAndIntegrationTypePathParams
  >(
    (
      paramsInPath: GetIntegrationExecutionLogByIdAndIntegrationTypePathParams
    ) => `/integrationExecution/log/object/${paramsInPath.objectId}`,
    { pathParams: { objectId }, ...props }
  );

export type SearchProps = Omit<
  MutateProps<
    IntegrationExecution[],
    unknown,
    void,
    IntegrationExecutionSearchPayload,
    void
  >,
  "path" | "verb"
>;

/**
 * Search for integration execution objects using IntegrationExecutionSearchPayload
 */
export const Search = (props: SearchProps) => (
  <Mutate<
    IntegrationExecution[],
    unknown,
    void,
    IntegrationExecutionSearchPayload,
    void
  >
    verb="POST"
    path={`/integrationExecution/log/search`}
    {...props}
  />
);

export type UseSearchProps = Omit<
  UseMutateProps<
    IntegrationExecution[],
    unknown,
    void,
    IntegrationExecutionSearchPayload,
    void
  >,
  "path" | "verb"
>;

/**
 * Search for integration execution objects using IntegrationExecutionSearchPayload
 */
export const useSearch = (props: UseSearchProps) =>
  useMutate<
    IntegrationExecution[],
    unknown,
    void,
    IntegrationExecutionSearchPayload,
    void
  >("POST", `/integrationExecution/log/search`, props);

export interface RetryKafkaBasedIntegrationPathParams {
  objectId: string;
}

export type RetryKafkaBasedIntegrationProps = Omit<
  MutateProps<
    Object,
    unknown,
    void,
    IntegrationStatusUpdatePayload,
    RetryKafkaBasedIntegrationPathParams
  >,
  "path" | "verb"
> &
  RetryKafkaBasedIntegrationPathParams;

/**
 * Resend failed integrations to kafka consumer
 */
export const RetryKafkaBasedIntegration = ({
  objectId,
  ...props
}: RetryKafkaBasedIntegrationProps) => (
  <Mutate<
    Object,
    unknown,
    void,
    IntegrationStatusUpdatePayload,
    RetryKafkaBasedIntegrationPathParams
  >
    verb="POST"
    path={`/integrationExecution/retryKafkaBasedIntegration/${objectId}`}
    {...props}
  />
);

export type UseRetryKafkaBasedIntegrationProps = Omit<
  UseMutateProps<
    Object,
    unknown,
    void,
    IntegrationStatusUpdatePayload,
    RetryKafkaBasedIntegrationPathParams
  >,
  "path" | "verb"
> &
  RetryKafkaBasedIntegrationPathParams;

/**
 * Resend failed integrations to kafka consumer
 */
export const useRetryKafkaBasedIntegration = ({
  objectId,
  ...props
}: UseRetryKafkaBasedIntegrationProps) =>
  useMutate<
    Object,
    unknown,
    void,
    IntegrationStatusUpdatePayload,
    RetryKafkaBasedIntegrationPathParams
  >(
    "POST",
    (paramsInPath: RetryKafkaBasedIntegrationPathParams) =>
      `/integrationExecution/retryKafkaBasedIntegration/${paramsInPath.objectId}`,
    { pathParams: { objectId }, ...props }
  );
