import { Dispatch, useCallback, useContext, useEffect, useState } from "react";

import {
  ServiceRequestSelectionCardView,
  formatDateToISODate,
  useFeature,
  plusDays,
  HEALTH_HELP_V2_NAME,
} from "@coherehealth/common";
import {
  BaseReview,
  ServiceRequestCommandPayload,
  ServiceRequestResponse,
  useAddServiceRequestOnAuthorization,
  useCreateServiceRequestAttachment,
  useGetAllReviews,
  useGetAuthValidityWindow,
  useGetAssociateFaxToServiceRequest,
  useUpdateServiceRequest,
} from "@coherehealth/core-platform-api";
import { useSnackbar } from "notistack";
import { useTrackUserInteraction, activityContextFromServiceRequest } from "util/userActivityTracker";
import { IsCohereTemplateContext } from "../FaxAttachmentSidebar";
import {
  isAuthorizationValidForContinuations,
  getLatestServiceRequest,
  isSRMaybeValidForContinuations,
} from "util/authorization";
import { generatePath, useNavigate } from "react-router";
import { continuationWorkflowPath, sideBySideContinuationWorkflowPath } from "util/serviceRequest";
import { FaxAttachmentContext } from "../FaxAttachmentContext";
import useGetFacilityBasedRequestConfigurationByPayer from "hooks/useGetFeatureConfigurations";
import { ServiceCase } from "@coherehealth/qm-api";
import { FaxSidebarView } from "../common";
import routes from "routes";
import { calculateInitializedEndDate } from "components/AuthBuilder/common";
import { getParametersFaxIntake, FAX_INTAKE, QUEUE_FAX_INTAKE, setParams } from "util/queryParams";
import { useGetAuthorizationByIdWithFallback } from "@coherehealth/common";

interface ViewProps {
  serviceRequest: ServiceRequestResponse | null;
  mostRecentReview?: BaseReview;
  loadingReview?: boolean;
  loadingServiceRequest?: boolean;
  onSelect?: (serviceRequestId: string) => void;
  onUnselect?: (serviceRequestId: string) => void;
  onRemove?: (serviceRequestId: string) => void;
  /**
   * This prop is for the actual remove attachment action, not just "unselecting" it
   */
  showRemoveButton?: boolean;
  isSelected?: boolean;
  showSecondaryButtonForAttach?: boolean;
  attachingFile?: boolean;
  isAttached?: boolean;
  showPatientAlert?: boolean;
  setSidebarView: Dispatch<FaxSidebarView>;
  setSelectedServiceId: Dispatch<string>;
  disableAttachmentCta?: boolean;
}

interface UnattachedServiceRequestProps extends Omit<ViewProps, "mostRecentReview" | "loadingReview"> {
  serviceRequest: ServiceRequestResponse;
  /**
   * A value of true will run an effect that attaches the given fax id to this service request
   */
  triggerFaxUpload: boolean;
  /**
   * The fax id to upload
   */
  faxCohereId: string | undefined;
  faxId: string | undefined;
  serviceCase: ServiceCase | undefined | null;
  healthPlanName: string | undefined;
  externalReferenceId: string | undefined;
  disableAttachmentCta?: boolean;
  /**
   * Callback for after the fax is successfully attached to the service request
   */
  onAttachmentSuccess: (id: string) => void;
  /**
   * Callback for if the fax did not successfully attach to the service request
   */
  onAttachmentFailed: (id: string) => void;
  uploadingAttachment: boolean;
}

export default function UnattachedServiceRequestCard({
  triggerFaxUpload,
  faxCohereId,
  faxId,
  serviceRequest,
  isAttached,
  isSelected,
  onAttachmentSuccess,
  onAttachmentFailed,
  serviceCase,
  healthPlanName,
  externalReferenceId,
  setSidebarView,
  setSelectedServiceId,
  uploadingAttachment,
  disableAttachmentCta,
  ...passthroughProps
}: UnattachedServiceRequestProps) {
  const { enqueueSnackbar } = useSnackbar();
  const authBuilderParamNavigationFF = useFeature("authBuilderParamNavigation");
  const sideBySideEditSrFaxIntakeWorkflow = useFeature("sideBySideEditSrFaxIntakeWorkflow");
  const addAttachmentToServiceRequestRefactorFF = useFeature("addAttachmentToServiceRequestRefactor");

  const navigate = useNavigate();
  const {
    data: existingReviews,
    error: existingReviewsError,
    loading: loadingReviews,
  } = useGetAllReviews({
    serviceRequestId: serviceRequest.id,
    queryParams: { max: 20 },
  });
  const {
    mutate: uploadFax,
    loading: uploadingFax,
    error: faxUploadError,
  } = useCreateServiceRequestAttachment({
    id: serviceRequest.id,
    requestOptions: { headers: { Accept: "application/json" } },
  });
  const { facilityBasedFeatureEnabled } = useGetFacilityBasedRequestConfigurationByPayer({
    healthPlanName: serviceRequest?.healthPlanName || undefined,
    encounterType: serviceRequest?.encounterType,
    skipRequestTimingCheck: true,
    delegatedVendorName: serviceRequest?.delegatedVendor,
  });
  const { mutate: getAssociateFaxToServiceRequest, error: associateSubmissionFaxError } =
    useGetAssociateFaxToServiceRequest({
      queryParams: {
        externalReferenceId: serviceCase?.externalSources?.at(0)?.externalReferenceId || externalReferenceId,
        serviceRequestId: serviceRequest?.id,
      },
    });

  const {
    error: getAuthorizationError,
    refetch: getAuthorizationById,
    data: authorization,
    loading: authorizationLoading,
  } = useGetAuthorizationByIdWithFallback({
    id: serviceRequest?.authorization?.id || "",
    queryParams: {
      view: "expanded",
    },
    lazy: true,
  });

  const { mutate: patchServiceRequest } = useUpdateServiceRequest({
    id: serviceRequest?.id ?? "",
  });

  useEffect(() => {
    if (serviceRequest.delegatedVendor !== HEALTH_HELP_V2_NAME && isSRMaybeValidForContinuations(serviceRequest)) {
      getAuthorizationById();
    }
  }, [getAuthorizationById, serviceRequest]);

  useEffect(() => {
    if (existingReviewsError) {
      enqueueSnackbar(`Failed to load reviews for service request: ${existingReviewsError.message}`, {
        variant: "error",
      });
    }
    if (faxUploadError) {
      const errorMessage =
        typeof faxUploadError.data === "object"
          ? faxUploadError.data.message || faxUploadError.message
          : faxUploadError.message;
      enqueueSnackbar(`Failed to attach fax to service request: ${errorMessage}`, {
        variant: "error",
      });
    }
    if (getAuthorizationError) {
      enqueueSnackbar(`Failed to authorization for service request: ${getAuthorizationError.message}`, {
        variant: "error",
      });
    }
    if (associateSubmissionFaxError) {
      enqueueSnackbar(`Could not associate Highmark fax to service request, please try again`, {
        variant: "error",
      });
    }
  }, [associateSubmissionFaxError, enqueueSnackbar, existingReviewsError, faxUploadError, getAuthorizationError]);

  const trackUserActivityInteraction = useTrackUserInteraction();

  const stage = useContext(IsCohereTemplateContext) ? "COHERE_TEMPLATE_FAX_FLOW" : "NON_COHERE_TEMPLATE_FAX_FLOW";
  const { caseId, queueId } = useContext(FaxAttachmentContext);

  // ugh this is so gross but is a product of how the restful react hooks work
  const [blockUploadTrigger, setBlockUploadTrigger] = useState(false);
  const shouldAttemptFileUpload = triggerFaxUpload && isSelected && !isAttached && !blockUploadTrigger;
  useEffect(() => {
    //This useEffect should be removed after addAttachmentToServiceRequestRefactorFF cleanup
    if (!Boolean(addAttachmentToServiceRequestRefactorFF) && shouldAttemptFileUpload) {
      // If the fax upload is triggered, the fax is selected, and we have not/are not currently uploading the fax
      if (!faxCohereId) {
        // this _shouldn't_ happen, but just in case
        onAttachmentFailed(serviceRequest.id);
      } else {
        // If we don't explicitly "block" the upload, any additional re-renders could cause an extraneous request to be sent
        setBlockUploadTrigger(true);
        const formData = new FormData();
        formData.set("faxId", faxCohereId);
        const handleFaxUpload = () => {
          uploadFax(formData as unknown as void).then(
            async () => {
              //track when a user attaches a fax to a service request
              trackUserActivityInteraction({
                event: "ATTACHED_FAX_TO_AUTH",
                stage: stage,
                activityContext: { ...activityContextFromServiceRequest(serviceRequest), faxId: faxId },
              }).finally(() => onAttachmentSuccess(serviceRequest.id));
            },
            () => {
              onAttachmentFailed(serviceRequest.id);
              setBlockUploadTrigger(false);
            }
          );
        };
        //If fax is a hmk fax, associate fax to service request before uploading fax
        if (healthPlanName === "Highmark") {
          getAssociateFaxToServiceRequest().then(
            () => {
              handleFaxUpload();
            },
            () => {
              onAttachmentFailed(serviceRequest.id);
              setBlockUploadTrigger(false);
            }
          );
        } else {
          handleFaxUpload();
        }
      }
    }
  }, [
    faxCohereId,
    uploadFax,
    onAttachmentSuccess,
    onAttachmentFailed,
    serviceRequest,
    shouldAttemptFileUpload,
    trackUserActivityInteraction,
    stage,
    faxId,
    healthPlanName,
    getAssociateFaxToServiceRequest,
    patchServiceRequest,
    serviceCase,
    associateSubmissionFaxError,
    addAttachmentToServiceRequestRefactorFF,
  ]);

  const latestSr = authorization && getLatestServiceRequest(authorization)?.[1];
  const showCreateContinuation =
    !authorizationLoading &&
    !serviceRequest.clinicalService?.hasUnitsOnPxChanged &&
    serviceRequest.delegatedVendor !== HEALTH_HELP_V2_NAME &&
    serviceRequest?.allowContinuations &&
    isAuthorizationValidForContinuations(serviceRequest, latestSr);

  const { data: authValidityWindow, refetch: refetchAuthValidityWindow } = useGetAuthValidityWindow({ lazy: true });
  const {
    mutate: createNewServiceRequestOnAuth,
    loading: loadingContinuation,
    error: createContinuationError,
  } = useAddServiceRequestOnAuthorization({
    id: serviceRequest.authorization?.id || "",
    requestOptions: { headers: { Accept: "application/json" } },
  });

  useEffect(() => {
    if (createContinuationError) {
      enqueueSnackbar(
        typeof createContinuationError.data === "object"
          ? createContinuationError.data?.message
          : createContinuationError.message,
        { variant: "error" }
      );
    }
  }, [createContinuationError, enqueueSnackbar]);

  const navigateToContinuationWorkflowStep = useCallback(
    (serviceRequestId: string, patientId: string, authorizationId: string, caseId?: string) => {
      const { pathname, search, hash } = sideBySideEditSrFaxIntakeWorkflow
        ? sideBySideContinuationWorkflowPath(faxId || "", patientId, serviceRequestId)
        : continuationWorkflowPath(patientId, serviceRequestId);

      setSelectedServiceId(serviceRequestId);
      const updatedSearchParams = getParametersFaxIntake(search, faxId, caseId, queueId);

      const url = !authBuilderParamNavigationFF
        ? `${pathname}?${updatedSearchParams}#${hash}`
        : `${pathname}?${setParams("faxStep", updatedSearchParams, hash)}#${hash}`;

      if (sideBySideEditSrFaxIntakeWorkflow) {
        navigate(url);
      } else {
        window.open(url, "_blank");
        setSidebarView("CONTINUE_SERVICE_VIEW");
      }
    },
    [
      authBuilderParamNavigationFF,
      faxId,
      navigate,
      queueId,
      setSelectedServiceId,
      setSidebarView,
      sideBySideEditSrFaxIntakeWorkflow,
    ]
  );

  const onContinuationClick = useCallback(async () => {
    // creates a new empty draft SR on the authorization and directs the user to the fill forms continuation page for the newly created draft
    const payload: ServiceRequestCommandPayload = {
      authStatus: "DRAFT",
      urgency: { isExpedited: false },
      units: 0,
      requestType: "CONTINUATION",
      workflowStep: "FILL_FORMS_CONTINUATION",
      encounterType: serviceRequest.encounterType,
      continuationGeneratedFromServiceRequest: {
        id: serviceRequest.id,
      },
    };
    const today = new Date();
    let endDate;
    const initializedEndDate = calculateInitializedEndDate(
      today,
      serviceRequest.clinicalServices || [],
      serviceRequest.patient?.coverages || []
    );
    if (initializedEndDate) {
      endDate = initializedEndDate;
    }
    const maxDuration = Math.max(...(serviceRequest.clinicalServices?.map((cs) => cs?.defaultDuration || 0) || [0]));
    if (maxDuration > 0) {
      endDate = plusDays(maxDuration, today);
    }
    if (serviceRequest?.patient?.id && serviceRequest?.startDate) {
      await refetchAuthValidityWindow({
        queryParams: { patient: serviceRequest.patient.id, startDate: serviceRequest.startDate },
      });
    }
    const lastValidDate = authValidityWindow?.validityWindowEndDate
      ? new Date(authValidityWindow.validityWindowEndDate)
      : endDate;
    payload.endDate = formatDateToISODate(lastValidDate);
    const addDraftSRResponse = await createNewServiceRequestOnAuth(payload);
    if (faxCohereId) {
      const formData = new FormData();
      formData.set("faxId", faxCohereId);
      await uploadFax(formData as unknown as void, { pathParams: { id: addDraftSRResponse.id } });
    }
    navigateToContinuationWorkflowStep(
      addDraftSRResponse?.id,
      addDraftSRResponse?.patient?.id || "",
      addDraftSRResponse?.authorization?.id || "",
      caseId
    );
  }, [
    serviceRequest.id,
    serviceRequest.encounterType,
    serviceRequest?.patient?.id,
    serviceRequest.patient?.coverages,
    serviceRequest.startDate,
    serviceRequest.clinicalServices,
    authValidityWindow?.validityWindowEndDate,
    createNewServiceRequestOnAuth,
    faxCohereId,
    navigateToContinuationWorkflowStep,
    caseId,
    refetchAuthValidityWindow,
    uploadFax,
  ]);

  const onEditClick = useCallback(() => {
    if (serviceRequest?.id && serviceRequest?.id !== "") {
      setSelectedServiceId(serviceRequest.id);
      if (sideBySideEditSrFaxIntakeWorkflow) {
        navigate(
          generatePath(routes.EDIT_SR_SIDE_BY_SIDE, {
            faxId: faxId,
            serviceRequestId: serviceRequest.id,
            caseId: caseId,
          })
            .concat(`?${FAX_INTAKE}=${faxId}&${QUEUE_FAX_INTAKE}=${queueId}`)
            .concat(authBuilderParamNavigationFF ? `&faxStep=EDIT_VIEW` : "")
            .concat(queueId ? "#EDIT_VIEW" : "")
        );
      } else {
        window.open(
          generatePath(routes.EDIT_SERVICE_REQUEST_FAX, {
            serviceRequestId: serviceRequest.id,
            caseId: caseId,
          })
            .concat(`?${FAX_INTAKE}=${faxId}&${QUEUE_FAX_INTAKE}=${queueId}`)
            .concat(authBuilderParamNavigationFF ? `&faxStep=EDIT_VIEW` : "")
            .concat(queueId ? "#EDIT_VIEW" : "")
        );
        setSidebarView("EDIT_SERVICE_VIEW");
      }
    }
  }, [
    serviceRequest.id,
    setSelectedServiceId,
    sideBySideEditSrFaxIntakeWorkflow,
    navigate,
    caseId,
    faxId,
    queueId,
    authBuilderParamNavigationFF,
    setSidebarView,
  ]);

  const showEditRequest = latestSr?.authStatus !== "DRAFT";

  return (
    <ServiceRequestSelectionCardView
      serviceRequest={serviceRequest}
      isAttached={isAttached}
      isSelected={isSelected}
      mostRecentReview={existingReviews ? existingReviews[existingReviews.length - 1] : undefined}
      loadingReview={loadingReviews}
      attachingFile={Boolean(addAttachmentToServiceRequestRefactorFF) ? uploadingAttachment : uploadingFax}
      showCreateContinuation={showCreateContinuation}
      onContinuationClick={onContinuationClick}
      loadingContinuation={loadingContinuation || uploadingFax}
      authorizationLoading={authorizationLoading}
      isFacilityBasedServiceRequest={!!facilityBasedFeatureEnabled}
      showEditRequest={showEditRequest}
      onEditClick={onEditClick}
      disableAttachmentCta={disableAttachmentCta}
      {...passthroughProps}
    />
  );
}
