import React, { Dispatch, SetStateAction, useEffect, useState, useContext } from "react";
import { H6, OPS_GROUP_TO_HEALTH_PLAN_NAME, RadioGroup, useFeature } from "@coherehealth/common";
import format from "date-fns/format";
import {
  ServiceRequestSearchRequestBody,
  ServiceRequestCounts,
  useGetHealthPlan,
  HealthPlan,
} from "@coherehealth/core-platform-api";
import { Divider, makeStyles } from "@material-ui/core";
import { RadioOptionsWithExpandedButton } from "common/RadioOptionsWithExpandedButton";
import { User, UserContext } from "UserContext";

import { useGetUserById } from "@coherehealth/core-platform-api";
import { ssoToHealthPlanDisplayNameMap } from "../../common/CoBrandedLogo";

interface Props {
  radioId: string;
  queryParams: ServiceRequestSearchRequestBody;
  setQueryParams: Dispatch<SetStateAction<ServiceRequestSearchRequestBody>>;
  serviceRequestCounts: ServiceRequestCounts;
  selectedHealthPlan?: string;
  setSelectedHealthPlan?: React.Dispatch<React.SetStateAction<string>>;
  coBrandingPayerLogosFF?: boolean;
}
export interface AvailableHealthPlan {
  id: string;
  label: string;
  displayNames?: string[];
}

export interface HealthPlanOption {
  id: string;
  label: string;
  parentHealthPlanName?: string;
}

const useStyles = makeStyles((theme) => ({
  radioGroupHealthPlanContainer: {
    marginBottom: theme.spacing(3),
  },
  radioGroupAuthStatusContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const COUNT_MAX = 10000;

const baseStatusOptions: Record<
  string,
  Partial<{
    startDate: string;
    sort: string;
    authStatus: string;
    withdrawnReason: string;
  }>
> = {
  All: {
    sort: "lastUpdated:desc",
  },
  Upcoming: {
    startDate: `ge:${format(new Date(Date.now()), "yyyy-MM-dd")}`,
    sort: "startDate:asc",
  },
  "Pending Review": {
    authStatus: "in:PENDING,INTAKE,RECOMMENDED_DENIAL,PENDING_EXTERNAL_DETERMINATION",
    sort: "startDate:asc",
  },
  Approved: {
    authStatus: "eq:APPROVED",
    sort: "startDate:asc",
  },
  Denied: {
    authStatus: "in:DENIED",
    sort: "startDate:asc",
  },
  "Partially Approved": {
    authStatus: "in:PARTIALLY_APPROVED",
    sort: "startDate:asc",
  },
  Draft: {
    authStatus: "eq:DRAFT",
    sort: "startDate:asc",
  },
  Withdrawn: {
    authStatus: "eq:WITHDRAWN",
    withdrawnReason: "ne:INTEGRATION_FAILURE",
    sort: "startDate:asc",
  },
  Completed: {
    authStatus: "eq:APPROVED",
    startDate: `lt:${format(new Date(Date.now()), "yyyy-MM-dd")}`,
    sort: "startDate:desc",
  },
};

export const filterHealthPlansByOpsGroup = (user: User, healthPlans: HealthPlan[]) => {
  //get list of available health plan names based off user's ops group access
  const userAccessibleHealthPlans = OPS_GROUP_TO_HEALTH_PLAN_NAME[user?.opsGroup ?? ""] ?? [];
  //filter healthPlans by ones accessible to user
  return healthPlans.filter((healthPlan) => userAccessibleHealthPlans.includes(healthPlan?.name ?? ""));
};

export const createBaseHealthPlanOptions = (opsGroupHealthPlans: HealthPlan[]) => {
  //creates the slimmed down model with "All" entry which will be used to create the filter options
  return [
    { id: "allHealthPlan", label: "All", displayNames: [] }, // includes all health plans
    ...opsGroupHealthPlans
      .filter((plan) => plan.status?.toLowerCase() === "active")
      .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
      .map((plan) => ({
        id: plan.name || "",
        label: plan.name || "",
        displayNames: plan.displayNames,
      })),
  ];
};

export const createBaseHealthPlanFilterOptions = (availableHealthPlans: AvailableHealthPlan[]) => {
  // deep copy availableHealthPlans to avoid mutation
  const healthPlansCopy: AvailableHealthPlan[] = JSON.parse(JSON.stringify(availableHealthPlans));
  //create option for each healthPlan.displayName entry
  return healthPlansCopy.reduce((acc: HealthPlanOption[], curr: AvailableHealthPlan) => {
    if (curr.displayNames && curr.displayNames.length > 0) {
      curr.displayNames.forEach((displayName) => {
        //skip entry for BCBS South Carolina since Payer does not want to see that name within UI. BlueCrossBlueShield of South Carolina will be used instead (which will also be within the healthPlan's displayNames)
        if (displayName === "BCBS South Carolina") {
          return;
        }
        const option = { id: displayName, label: displayName, parentHealthPlanName: curr.id };
        acc = [...acc, option];
      });
    }
    return acc;
  }, Array.of({ id: "allHealthPlan", label: "All", displayNames: [] }));
};

export default function ServiceRequestFilter({
  radioId,
  queryParams,
  setQueryParams,
  serviceRequestCounts,
  selectedHealthPlan,
  setSelectedHealthPlan,
  coBrandingPayerLogosFF,
}: Props) {
  const { data: healthPlans } = useGetHealthPlan({});
  const partialApprovalEnabled = useFeature("partialApproval");
  const [selectedStatus, setSelectedStatus] = useState("All");
  let showAllOptions = false;
  const classes = useStyles();
  const [ssoValue, setSSOValue] = useState("");
  //this is created first and represents a collection of healthPlans with a status of ACTIVE. These are also conditionally filtered down based on the user's organization.healthPlans list
  const [availableHealthPlans, setAvailableHealthPlans] = useState<AvailableHealthPlan[]>();
  //these options are created from the availableHealthPlans and represent what will be used as the data source for the UI filters
  const [healthPlanFilterOptions, setHealthPlanFilterOptions] = useState<HealthPlanOption[]>();
  //these options are created from the healthPlanFilterOptions and represent the search query strings that will be used by the dashboard search
  const [healthPlanSearchOptions, setHealthPlanSearchOptions] =
    useState<Record<string, { healthPlanName: string; healthPlanDisplayName?: string | undefined }>>();
  const { getUser } = useContext(UserContext);
  const { refetch: fetchUserDetails } = useGetUserById({
    id: "",
    lazy: true,
  });

  useEffect(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    const ssoValue = urlParams.get("ssoCode");
    setSSOValue(ssoValue || "");
  }, []);

  useEffect(() => {
    if (coBrandingPayerLogosFF) {
      if (healthPlans) {
        //construct currentAvailableHealthPlans as base
        let currentAvailableHealthPlans: AvailableHealthPlan[] = [];
        getUser()
          ?.then((currentUser) => {
            const opsGroupHealthPlans = filterHealthPlansByOpsGroup(currentUser, healthPlans);
            //set currentAvailableHealthPlans values based off active health plans and user's ops group access
            currentAvailableHealthPlans = createBaseHealthPlanOptions(opsGroupHealthPlans);
            if (
              currentUser?.sub &&
              currentUser.groups &&
              currentUser.groups.filter((x) => x !== "Everyone").length > 0
            ) {
              // Only bother fetching user details if the user belongs to groups; otherwise we won't find one anyway
              fetchUserDetails({ pathParams: { id: currentUser.sub } })
                .then((userWithOrg) => {
                  if (userWithOrg) {
                    if (userWithOrg.organizationHealthPlans) {
                      //filter the currentAvailableHealthPlans by the health plans associated with the user's organization so the user only sees relevant filter options
                      //also check if any of the items within the user's org's health plans match any of the display names in a given health plan
                      currentAvailableHealthPlans = currentAvailableHealthPlans.filter(
                        (plan) =>
                          userWithOrg?.organizationHealthPlans &&
                          (userWithOrg?.organizationHealthPlans.includes(plan?.label || "") ||
                            plan?.displayNames?.some((displayName) =>
                              userWithOrg?.organizationHealthPlans?.includes(displayName)
                            ))
                      );
                      //remove displayNames which are not included in the users org
                      currentAvailableHealthPlans.forEach((healthPlan) => {
                        healthPlan.displayNames = healthPlan.displayNames?.filter((displayName) =>
                          userWithOrg?.organizationHealthPlans?.includes(displayName)
                        );
                      });
                    }
                  }
                  setAvailableHealthPlans(currentAvailableHealthPlans);
                })
                .catch((error) => {
                  console.warn("useGetUserById() error: ", error);
                });
            } else {
              setAvailableHealthPlans(currentAvailableHealthPlans);
            }
          })
          .catch((error) => {
            console.warn("getUser() error: ", error);
          });
      }
    }
  }, [healthPlans, coBrandingPayerLogosFF, fetchUserDetails, getUser]);

  useEffect(() => {
    if (availableHealthPlans && !healthPlanFilterOptions) {
      //create base filter options using health plans available to user
      const currentHealthPlanFilterOptions = createBaseHealthPlanFilterOptions(availableHealthPlans);
      //check if there is an ssoCode to make sure corresponding healthPlanDisplayName is part of currentHealthPlanFilterOptions
      if (ssoValue) {
        const healthPlanDisplayName = ssoToHealthPlanDisplayNameMap[ssoValue];
        if (healthPlanDisplayName) {
          const ssoResult = currentHealthPlanFilterOptions.find((hp) => hp.label === healthPlanDisplayName);
          //if filter does not have the healthPlanDisplayValue for this ssoCode, then add it
          if (!ssoResult) {
            currentHealthPlanFilterOptions.push({
              id: `${healthPlanDisplayName}`,
              label: healthPlanDisplayName,
            });
            //sort options after new entry is added
            currentHealthPlanFilterOptions.sort((a, b) => (a.label || "").localeCompare(b.label || ""));
          }
          //set selected filter value to ssoCode displayName
          setSelectedHealthPlan?.(healthPlanDisplayName);
        }
      }
      setHealthPlanFilterOptions(currentHealthPlanFilterOptions);
    }
  }, [availableHealthPlans, ssoValue, setSelectedHealthPlan, healthPlanFilterOptions]);

  useEffect(() => {
    if (healthPlanFilterOptions && !healthPlanSearchOptions) {
      const options = healthPlanFilterOptions.reduce((acc, curr) => {
        if (curr.id === "allHealthPlan") {
          acc[curr.id] = { healthPlanName: `in:${healthPlanFilterOptions.map((plan) => plan.label)}` };
        } else {
          acc[curr.id] = {
            healthPlanName: `eq:${curr.parentHealthPlanName ?? curr.id}`,
            healthPlanDisplayName: `eq:${curr.label}`,
          };
        }
        return acc;
      }, {} as Record<string, { healthPlanName: string; healthPlanDisplayName?: string }>);

      //if ssoCode value exists, set search parameter based off values linked to ssoCode
      if (ssoValue) {
        const healthPlanDisplayName = ssoToHealthPlanDisplayNameMap[ssoValue];
        if (healthPlanDisplayName) {
          //grab the parent health plan whose displayNames contain the displayName associated with the ssoCode
          const parentHealthPlan = availableHealthPlans?.find((healthPlan) =>
            healthPlan?.displayNames?.includes(healthPlanDisplayName)
          );
          let queryParam: ServiceRequestSearchRequestBody = {};
          queryParam = {
            ...queryParams,
            healthPlanName: `eq:${parentHealthPlan?.id ?? healthPlanDisplayName}`,
            healthPlanDisplayName: `eq:${healthPlanDisplayName}`,
          };
          //set the query params which power the dashboard search
          setQueryParams(queryParam);
        }
      }
      setHealthPlanSearchOptions(options);
    }
  }, [healthPlanFilterOptions, availableHealthPlans, queryParams, setQueryParams, ssoValue, healthPlanSearchOptions]);

  useEffect(() => {
    const queryParamAuthStatus = queryParams.authStatus?.substring(3);
    for (const [key, value] of Object.entries(baseStatusOptions)) {
      if (value.authStatus) {
        const status = value.authStatus.substring(3);
        if (status === queryParamAuthStatus) {
          if (queryParams.startDate) {
            setSelectedStatus("Completed");
          } else {
            setSelectedStatus(key);
          }
          break;
        }
      }
    }
  }, [queryParams]);

  const handleHealthPlanChange = (val: string) => {
    let queryParam: ServiceRequestSearchRequestBody = {};
    if (val === "allHealthPlan") {
      setSelectedHealthPlan?.("allHealthPlan");
      queryParam = {
        ...queryParams,
        healthPlanName: undefined,
        healthPlanDisplayName: undefined,
      };
    } else {
      setSelectedHealthPlan?.(val);
      queryParam = {
        ...queryParams,
        healthPlanName: healthPlanSearchOptions ? healthPlanSearchOptions[val]?.healthPlanName : undefined,
        healthPlanDisplayName: healthPlanSearchOptions
          ? healthPlanSearchOptions[val]?.healthPlanDisplayName
          : undefined,
      };
    }
    setQueryParams(queryParam);
  };

  const handleStatusChange = (val: string) => {
    const offsetOrNull = { offset: 0 };
    const { order, sort, authStatus, startDate, withdrawnReason, ...restOfQueryParams } = queryParams;
    setSelectedStatus(val);
    const qps = {
      ...restOfQueryParams,
      ...baseStatusOptions[val],
      ...offsetOrNull,
    };
    setQueryParams(qps);
  };

  const renderSRAuthRadioOption = () => {
    const filteredOptions = Object.keys(baseStatusOptions)
      .filter((label) => partialApprovalEnabled || label !== "Partially Approved")
      .map((label) => {
        const count = optionLabelCount(label, serviceRequestCounts) || 0;
        const maxedCount = Math.min(count, COUNT_MAX);
        const countLabel = `${maxedCount}${maxedCount >= COUNT_MAX ? "+" : ""}`;
        return {
          id: label,
          label: `${label} (${countLabel})`,
        };
      });

    return (
      <RadioGroup
        data-public
        id={radioId}
        options={filteredOptions}
        value={selectedStatus}
        onChange={(val) => handleStatusChange(val)}
      />
    );
  };

  const renderSRAuthRadioOptionWithHealthplan = () => {
    return (
      <>
        <>
          <div className={classes.radioGroupHealthPlanContainer}>
            <RadioOptionsWithExpandedButton
              options={healthPlanFilterOptions ?? []}
              selectedOption={selectedHealthPlan}
              onOptionChange={handleHealthPlanChange}
              heading="Health plan"
              showAllInitially={showAllOptions}
              numberOfItemsToShow={4}
            />
          </div>
        </>
        <Divider />
        <div className={classes.radioGroupAuthStatusContainer}>
          <H6 component="label" htmlFor={radioId} data-public>
            Status
          </H6>
          {renderSRAuthRadioOption()}
        </div>
      </>
    );
  };

  return (
    <div>
      {coBrandingPayerLogosFF && healthPlanFilterOptions?.length && healthPlanFilterOptions.length > 2 //only show if more than 1 value exists. check for more than two values in healthPlanFilterOptions to account for "All" option
        ? renderSRAuthRadioOptionWithHealthplan()
        : renderSRAuthRadioOption()}
    </div>
  );
}

function optionLabelCount(opt: string, serviceRequestCounts: ServiceRequestCounts) {
  switch (opt) {
    case "All":
      return serviceRequestCounts.all;
    case "Upcoming":
      return serviceRequestCounts.upcoming;
    case "Pending Review":
      return serviceRequestCounts.pendingReview;
    case "Approved":
      return serviceRequestCounts.approved;
    case "Denied":
      return serviceRequestCounts.denied;
    case "Partially Approved":
      return serviceRequestCounts.partiallyApproved;
    case "Draft":
      return serviceRequestCounts.draft;
    case "Withdrawn":
      return serviceRequestCounts.withdrawn;
    case "Completed":
      return serviceRequestCounts.completed;
    default:
      return 0;
  }
}
